import Layout from '@/modules/layout/components/layout';
import { i18n } from '@/i18n';

const HomePage = () =>
  import('@/modules/home/components/home-page.vue');
// const Dashboard = () =>
//   import('@/modules/home/components/dashboard.vue');


export default [
  {
    path: '/:lang?',
    // path: '',
    exact: true,
    component: Layout,
    children: [
      {
        name: 'home',
        path: '',
        component: HomePage,
        exact: true,
        meta: { auth: true },
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('menu.home') 
          next();
        },
      },
    ],
  },
  // {
  //   path: '/dashboard',
  //   exact: true,
  //   component: Layout,
  //   children: [
  //     {
  //       name: '',
  //       path: '',
  //       component: Dashboard,
  //       exact: true,
  //       meta: { auth: true },
  //     },
  //   ]
  // }
];
