import { render, staticRenderFns } from "./admin-profile.vue?vue&type=template&id=68b6f1d1&"
import script from "./admin-profile.vue?vue&type=script&lang=js&"
export * from "./admin-profile.vue?vue&type=script&lang=js&"
import style0 from "./admin-profile.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QCard,QCardSection,QIcon,QForm,QInput,QSelect,QBtn} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCard,QCardSection,QIcon,QForm,QInput,QSelect,QBtn})
