// import firebase from 'firebase/app';
import firebase from 'firebase/app';
import 'firebase/auth';        // for authentication
import 'firebase/storage';     // for storage
import 'firebase/database';    // for realtime database
import 'firebase/firestore';   // for cloud firestore
import 'firebase/messaging';   // for cloud messaging
import 'firebase/functions';   // for cloud functions
export class AuthToken {
  static async get() {
    if (
      firebase.apps.length &&
      firebase.auth().currentUser
    ) {
      return await firebase
        .auth()
        .currentUser.getIdToken(true);
    }

    return null;
  }
}
