import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-property';

export class PropertyService {
  static async list(filter, orderBy, limit, offset) {
    const response = await graphqlClient.query({
      query: gql `
        query PROPERTY_LIST(
          $filter: PropertyFilterInput
          $orderBy: PropertyOrderByEnum
          $limit: Int
          $offset: Int
        ) {
          propertyList(
            filter: $filter
            orderBy: $orderBy
            limit: $limit
            offset: $offset
          ) {
            count
            rows {
              id
              address
              cityId
              city
              description
              price
              income
              name
              images
              listedForId
              facilityListedFor
              phoneNumber
              regionId
              region
              spaceArea
              isClosed
              typeId
              facilityType
              postStatus
              adNumber
              createdBy
              createdAt
              updatedAt
              updatedBy
              viewers
            }
          }
        }
      `,

      variables: {
        filter,
        orderBy,
        limit,
        offset,
      },
    });
    return response.data.propertyList;
  }

  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation PROPERTY_UPDATE(
          $id: String!
          $data: PropertyInput!
        ) {
          propertyUpdate(id: $id, data: $data) {
            name
          }
        }
      `,

      variables: {
        id,
        data,
      },
    });

    return response.data.propertyUpdate;
  }
}