//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { UserModel } from '@/modules/auth/user-model';
// import { i18n } from '@/i18n';

const { fields } = UserModel;

export default {
  name: 'app-email-unverified-page',

  data() {
    return {
      rules: {
        email: fields.email.forFormRules(),
      },
      model: {},
    };
  },

  computed: {
    ...mapGetters({
      loading: 'auth/loadingEmailConfirmation',
      email: 'auth/currentUserEmail',
      is_screen_xs: 'layout/is_screen_xs',
      loadingVerify: 'auth/loadingVerify',
    }),

    fields() {
      return fields;
    },
  },

  methods: {
    ...mapActions({
      doSendEmailConfirmation:
        'auth/doSendEmailConfirmation',
      doSignout: 'auth/doSignout',
      doEmailVerified: 'auth/doEmailVerified',
    }),
    // i18n(code, args) {
    //   return i18n(code, args);
    // },
    i18n(key, args) {
      return this.$t(key, args);
    },
    async doSubmit() {
      await this.doSendEmailConfirmation(this.email);
    },
    async doVerify() {
      await this.doEmailVerified();
    },
  },
};
