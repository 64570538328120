import { render, staticRenderFns } from "./job-list-table.vue?vue&type=template&id=5289237d&scoped=true&"
import script from "./job-list-table.vue?vue&type=script&lang=js&"
export * from "./job-list-table.vue?vue&type=script&lang=js&"
import style0 from "./job-list-table.vue?vue&type=style&index=0&id=5289237d&lang=scss&scoped=true&"
import style1 from "./job-list-table.vue?vue&type=style&index=1&id=5289237d&lang=scss&scoped=true&"
import style2 from "./job-list-table.vue?vue&type=style&index=2&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5289237d",
  null
  
)

export default component.exports
import {QTable,QInnerLoading,QSpinnerIos,QRadio,QToggle,QInput,QIcon,QBtn,QTooltip,QTr,QTh,QTd,QCard} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QTable,QInnerLoading,QSpinnerIos,QRadio,QToggle,QInput,QIcon,QBtn,QTooltip,QTr,QTh,QTd,QCard})
