import { render, staticRenderFns } from "./prescription-view-modal.vue?vue&type=template&id=25fc88b8&scoped=true&"
import script from "./prescription-view-modal.vue?vue&type=script&lang=js&"
export * from "./prescription-view-modal.vue?vue&type=script&lang=js&"
import style0 from "./prescription-view-modal.vue?vue&type=style&index=0&id=25fc88b8&lang=scss&scoped=true&"
import style1 from "./prescription-view-modal.vue?vue&type=style&index=1&id=25fc88b8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25fc88b8",
  null
  
)

export default component.exports
import {QDialog,QCard,QIcon,QCardSection,QImg,QSpinnerIos,QTabs,QTab} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QDialog,QCard,QIcon,QCardSection,QImg,QSpinnerIos,QTabs,QTab})
