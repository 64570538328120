//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from "vuex";
// import firebase from 'firebase';
import i18n from '@/vueI18n'
export default {
  name: "app-header",
  data(){
    return{
    }
  },

  computed: {
    ...mapGetters({
      currentUserNameOrEmailPrefix: "auth/currentUserNameOrEmailPrefix",
      currentUserAvatar: "auth/currentUserAvatar",
      isMobile: "layout/isMobile",
      currentUser: "auth/currentUser",
      // notifications:'notification/rows',
      firstBatch: 'notification/firstBatch',
      currentUserListener: 'layout/currentUserListener',
      regionsListener: 'citiesRegions/list/regionsListener',
      citiesListener: 'citiesRegions/list/citiesListener',



    }),
    unreadNotifications(){
      if(this.firstBatch){
        return this.firstBatch.filter(notification => notification.status == "unread")
      }
      return []
    },
    lang(){
      return i18n.locale
    },
    isRTL() {
      // return getLanguageCode() == 'ar'
      // return localStorage.getItem('language') == 'ar'
      return i18n.locale == 'ar'
    },
  },
  
  methods: {
    ...mapActions({
      toggleMenu: "layout/toggleMenu",
      doSignout: "auth/doSignout",
      doFetchNotification:'notification/doFetch',
      doFetchNotifications: 'notification/doFetchNotifications',
      setLoadFirstRecord: 'notification/setLoadFirstRecord',
      setLoadMoreLast: 'notification/setLoadMoreLast',
    }),

    i18n(key, args) {
      // return i18n(key, args);
      return this.$t(key, args);

    },

    doSignoutAndUnsubscribe(){

        this.currentUserListener()
        // console.log(this.regionsListener);
        if(this.regionsListener){
          this.regionsListener()
        }
        if(this.citiesListener){
          this.citiesListener()
        }
        this.doSignout()
        
    },
    doEditProfile() {
      return this.$router.push(`/${this.lang}/profile`);
    },
    doToggleMenu() {
      this.$emit('toggleMenu')
    },
    goToNotifications(){
      const lang = localStorage.getItem('language') || 'en'
      if(this.$route.name != "notification"){
        this.$router.push(`/${lang}/notification`)
      }
      
    },
    notificationShow(message){
      this.$q.notify({
        message:message,
        position:'top-right',
        timeout: 3000
      })
    },
    async doFilter({userId}) {
      // filterSchema.cast(this.model);
      // const filter = {postStatus:"accepted"}
      // return this.doFetch({
      //   // filter,
      // });
      return this.doFetchNotification({userId});
    },
    
  },
  watch:{
  },
  async created(){
    this.doFetchNotifications({userId: this.currentUser.id, limit: 10})

  }

};
