import '@/shared/plugins/element';
import Vue from 'vue';
import Vuex from 'vuex';
import Router from 'vue-router';
import {
  setupComponentsFiltersDirectivesAndMixins,
  storeAsync,
  routerAsync,
} from '@/app-module';
import app from '@/app.vue';
import PortalVue from 'portal-vue';

// import { i18n } from '@/i18n';
import ProgressBar from '@/shared/progress-bar/progress-bar';
import VueSignature from "vue-signature-pad";
import AsyncComputed from 'vue-async-computed'

import './quasar';
import { colors } from 'quasar';
const { setBrand } = colors;
setBrand("myBrand","#642B73");
setBrand("myBrand2","#BF507A");
import vueSmoothScroll from 'vue2-smooth-scroll';

import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles

import BootstrapVue from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css' //Vuesax styles

// Custom Styles
import './scss/index.scss'

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';


import PrettyCheckbox from 'pretty-checkbox-vue';
Vue.use(PrettyCheckbox);
import '@mdi/font/css/materialdesignicons.css';
import './quasar'
import './quasar'
// import i18n from './i18n'
import i18n from './vueI18n'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import specific icons */
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { rtdbPlugin } from 'vuefire'
import CKEditor from 'ckeditor4-vue'


(async function() {
  // document.title = i18n('app.title');
  ProgressBar.start();
  // SettingsService.fetchAndApply();

  Vue.use(Router);
  Vue.use(PortalVue);
  Vue.config.productionTip = process.env.NODE_ENV === 'production';
  Vue.use(Vuex);
  setupComponentsFiltersDirectivesAndMixins();

  //#region [ ======================================= New Installer ======================================= ]
    Vue.use(BootstrapVue);
    Vue.use(vueSmoothScroll);
    AOS.init({
      once: false,
    })
    Vue.use(Vuesax, {
      primary:'#3A026C',
      success:'rgb(23, 201, 100)',
      danger:'rgb(242, 19, 93)',
      warning:'rgb(255, 130, 0)',
      dark:'rgb(255, 255, 255)',
      transparent:'rgb(255, 255, 255) '
    });
    Vue.use(VueSignature);
    Vue.use(AsyncComputed);  
    
    /* add icons to the library */
    library.add(faUserSecret)
    
    /* add font awesome icon component */
    Vue.component('font-awesome-icon', FontAwesomeIcon)
    Vue.use(rtdbPlugin)
    Vue.use(CKEditor)
  //#endregion =============================================================================================


  // eslint-disable-next-line

 
 
Vue.component('vue-phone-number-input', VuePhoneNumberInput);


  new Vue({
    store: storeAsync(),
    router: routerAsync(),
    i18n,
    render: (h) => h(app)
  }).$mount('#app');
})();
