//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DrugDialog from '@/modules/drugIndex/components/drug-dialog.vue';
import OfferViewModal from '@/modules/offer/components/offer-view-modal.vue';
import DrugIndexViewModal from '@/modules/drugIndex/components/drug-index-view-modal.vue';
import DrugIndexUpload from '@/modules/drugIndex/components/drug-index-upload.vue';

import { mapActions, mapGetters } from 'vuex';
import { DrugIndexPermissions } from '@/modules/drugIndex/drugIndex-permissions.js';
import { DrugIndexModel } from '@/modules/drugIndex/drugIndex-model';
const { fields } = DrugIndexModel;

  export default {
    name:'app-drugIndex-list-table',
    props:['versionTab', 'uploadingFile'],

    components: {
      [DrugDialog.name]: DrugDialog,
      [OfferViewModal.name]: OfferViewModal,
      [DrugIndexViewModal.name]: DrugIndexViewModal,
      [DrugIndexUpload.name]: DrugIndexUpload,
    },

    data() {
      return {
        searchBy: 'drugName',
        drug: null,
        viewDrug: false,
        disbaleBtn: true,
        drugsOld: [],
        filter: '',
        columns:[
          // { name: 'select', field: 'select', align: 'center', label: 'select' },
          { name: 'index', field: 'index', align: 'center', label: '#' },
          { name: 'drugName', field: 'drugName', align: 'center', label: 'drugName' },
          { name: "company", field: 'company', align: 'center', label: 'company' },
          { name: 'genericName', field: 'genericName', align: 'center', label: 'genericName'},
          { name: 'oldPrice', field: 'oldPrice', align: 'center', label: 'oldPrice' },
          { name: 'price', field: 'price', align: 'center', label: 'price' },
          { name: 'pharmacologicalUse', field: 'pharmacologicalUse', align: 'center', label: 'pharmacologicalUse' },
          { name: 'route', field: 'route', align: 'center', label: 'route' },
          { name: 'action', field: 'action', align: 'center', label: 'action' },
        ],
        pagination: {
          sortBy: 'desc',
          descending: false,
          page: 1,
          // rowsPerPage: this.$q.screen.width == 1024 ? 14 : 7
          rowsPerPage: 10
        },
        modalVisible: false,
        dialogVisible: false,
        dialogType: undefined,
        selectedRow: null,
        selectedId: null,
        page: 1,
        // limit: 10
      }
    },
    computed: {
      ...mapGetters({
        rows: 'drugIndex/list/rows',
        count: 'drugIndex/list/count',
        loading: 'drugIndex/list/loading',
        currentUser:'auth/currentUser',
        is_screen_xs: 'layout/is_screen_xs',

        drugIndexUpdatesSettings: 'drugIndex/list/drugIndexUpdatesSettings',
        drugIndexCurrentSettings: 'drugIndex/list/drugIndexCurrentSettings',
        drugs:'drugIndex/list/drugs',
        oldFilter: 'drugIndex/list/oldFilter',
        recordAfterLast:'drugIndex/list/recordAfterLast',
        recordBeforeFirst:'drugIndex/list/recordBeforeFirst',
        loadingCurrent:'drugIndex/list/loadingCurrent',
        drugsNew:'drugIndex/list/drugsNew',
        loadingNew:'drugIndex/list/loadingNew',
        destroyLoading:'drugIndex/destroy/loading',
        saveLoading: 'drugIndex/form/saveLoading',
        selectedRows: 'drugIndex/list/selectedRows',
        rowsFromRoute: 'drugIndex/list/rowsFromRoute',
        startPublish: 'drugIndex/list/startPublish',
      }),
      fields() {
        return fields;
      },
      hasPermissionToEdit() {
        return new DrugIndexPermissions(this.currentUser).edit;
      },
      hasPermissionToImport() {
        return new DrugIndexPermissions(this.currentUser).import;
      },
      hasPermissionToDestroy() {
        // console.log('user permission',new JobPermissions(this.currentUser).destroy)
        return new DrugIndexPermissions(this.currentUser).destroy;
      },
      hasPermissionToRead() {
        return new DrugIndexPermissions(this.currentUser).read;
      },
      pagesNumber() {
        return Math.ceil(this.drugs.length / this.pagination.rowsPerPage)
      },
    },
    created() {
      this.page = parseInt(this.$route.query.page) || 1

      if (this.$route.params.searchBy) {
        this.searchBy = this.$route.params.searchBy
      }

      if (this.$route.query.filter) {
        this.filter = this.$route.query.filter
      }
    },
    async mounted() {
      this.doFetchFromAlgolia({ 
        action: 'current' 
      })
      this.drugsOld = this.drugs
    },
    methods: {
      ...mapActions({
        doFetch: 'drugIndex/list/doFetch',
        doFetchFromAlgolia: 'drugIndex/list/doFetchFromAlgolia',
        changeCurrentPage: 'drugIndex/list/changeCurrentPage',

        setDisablePrevious: 'drugIndex/list/setDisablePrevious',
        setDisableNext: 'drugIndex/list/setDisableNext',
        getPreviousDrugsBatch: 'drugIndex/list/getPreviousDrugsBatch',
        setLoadMoreLast: 'drugIndex/list/setLoadMoreLast',
        setLoadFirstDrug: 'drugIndex/list/setLoadFirstDrug',
        resetCursor: 'drugIndex/list/resetCursor',
        setSelectRow: 'drugIndex/list/setSelectRow',
        pushInSelectedRows: 'drugIndex/list/pushInSelectedRows',
        removeFromSelectedRows: 'drugIndex/list/removeFromSelectedRows',
        setRows: 'drugIndex/list/setRows',
      }),
      i18n(key, args) {
        return this.$t(key, args);
      },
      onModalOpen(row, operation) {
        this.selectedRow = row;
        this.selectedId = row.id;

        switch (operation) {
          case 'delete':
            this.dialogType = 'delete'
            break;
          case 'edit':
            this.dialogType = 'edit'
            break;
          case 'view':
            this.dialogType = 'view'
            break;  
          default:
            this.dialogType = undefined
            break;
        }

        this.modalVisible = true
        this.dialogVisible = true
      },
      onModalClose() {
        this.dialogVisible = false;
        setTimeout(() => {
          this.modalVisible = false;
          this.dialogType = undefined
        }, 500);
      },
      close() {
        this.viewDrug = false
      },
      async nextPage() {
        this.page = parseInt(this.page) + 1
        if (this.filter != '') {
          this.$router.push({
            query: {
              page: this.page,
              filter: this.filter
            },
            params: {
              searchBy: this.searchBy
            }
          })
        } else {
          this.$router.push({
            query: {
              page: this.page,            
            },
            params: {
              searchBy: this.searchBy
            }
          })
        }

        this.doFetchFromAlgolia({ 
          action: 'next' 
        })
      },
      async previousPage(){
        this.page = this.page - 1
        if (this.filter != '') {
          this.$router.push({
            query: {
              page: this.page,
              filter: this.filter
            },
            params: {
              // versionTab: this.versionTab,
              searchBy: this.searchBy
            }
          })
        } else {
          this.$router.push({
            query: {
              page: this.page,
            },
            params: {
              // versionTab: this.versionTab,
              searchBy: this.searchBy
            }
          })
        }

        this.doFetchFromAlgolia({ 
          action: 'prev' 
        })
      },
      async loadMore() {
        await this.doFetch({limit: 10, searchBy: this.searchBy, filter: this.filter, versionTab: this.versionTab})
        this.page = parseInt(this.page) + 1

        if (this.filter != '') {
          this.$router.push({
            query: {
              page: this.page,
              filter: this.filter
            },
            params: {
              searchBy: this.searchBy
            }
          })
        } else {
          this.$router.push({
            query: {
              page: this.page,            
            },
            params: {
              versionTab: this.versionTab,
              searchBy: this.searchBy
            }
          })
        }
        this.drugsOld = this.drugs
      },
      async goToPreviousPage() {
        await this.getPreviousDrugsBatch({limit: 10, searchBy: this.searchBy, filter: this.filter, versionTab: this.versionTab})
        this.page = this.page - 1
        if (this.filter != '') {
          this.$router.push({
            query: {
              page: this.page,
              filter: this.filter
            },
            params: {
              versionTab: this.versionTab,
              searchBy: this.searchBy
            }
          })
        } else {
          this.$router.push({
            query: {
              page: this.page,
            },
            params: {
              versionTab: this.versionTab,
              searchBy: this.searchBy
            }
          })
        }
        this.drugsOld = this.drugs
      },
      async searchByFilter() {
        this.resetCursor()
        this.filter = this.filter.toUpperCase()
        this.page = 1

        if (this.filter != '') {
          this.$router.push({
            query: {
              page: this.page,
              filter: this.filter
            },
            params: {
              searchBy: this.searchBy,
              versionTab: this.versionTab
            }
          })
        } else {
          this.$router.push({
            query: {
              page: this.page,           
            },
            params: {
              searchBy: this.searchBy,
              versionTab: this.versionTab
            }
          })
        } 
        
        await this.doFetch({limit: 10, searchBy: this.searchBy, filter: this.filter, versionTab: this.versionTab})
        this.drugsOld = []
      },
      async removeSearch() {
        this.resetCursor()

        this.filter = ''
        this.page = 1
        this.$router.push({
          query: {
            page: this.page,
            // filter: ''
          },
          params: {
            searchBy: this.searchBy,
            versionTab: this.versionTab
          }
        })
        await this.doFetch({limit: 10,searchBy: this.searchBy, filter: this.filter, versionTab: this.versionTab})
      },
      selectRow(id) {
        if (!this.selectedRows.includes(id)) {
          this.pushInSelectedRows(id)
          this.setSelectRow({id,selected: true})
        } else {
          this.removeFromSelectedRows(id)
          this.setSelectRow({id,selected: false})
        }
      }
    },
    watch: {
      $route(to) {
        if (to.query.filter) {
          this.filter = to.query.filter
        } else {
          this.filter = ''
        }
        this.page = to.query.page
        this.changeCurrentPage(this.page)
      },
      rowsFromRoute(change) {
        const page  = this.$route.query.page
        const limit = this.limit
        const start = page * limit - limit
        const end = page * limit + 1
        const rowsFromRouteToBeDisplayed = change.slice(start, end)
        this.setRows(rowsFromRouteToBeDisplayed)
      },
      drugs(newval) {
        if (newval.length > 0) {
          this.setLoadFirstDrug()
          this.setLoadMoreLast()
          this.setDisablePrevious({searchBy: this.searchBy, filter: this.filter, versionTab: this.versionTab})
          this.setDisableNext({searchBy: this.searchBy, filter: this.filter, versionTab: this.versionTab})
        }
      },
      versionTab() {
        // this.filter = ''
        this.searchBy = 'drugName'
        this.drugsOld = []    
      },
    },
    
  }
