//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { JobModel } from '@/modules/job/job-model';
import { JobPermissions } from '@/modules/job/job-permissions';
// import { i18n, getLanguageCode } from '@/i18n';
import moment from 'moment';
// import firebase from 'firebase'

import TableViewPage from '@/shared/components/table-view-page.vue';
// import JobFormModal from '@/modules/job/components/job-form-modal.vue';
// import JobViewModal from '@/modules/job/components/job-view-modal.vue';
import PrescriptionViewModal from '@/modules/prescription/components/prescription-view-modal.vue';

const { fields } = JobModel;

export default {
  name: 'app-prescription-list-table',

  components: {
    [TableViewPage.name]: TableViewPage,
    // [JobFormModal.name]:JobFormModal,
    // [JobViewModal.name]:JobViewModal,
    [PrescriptionViewModal.name]:PrescriptionViewModal,

  },

   
  data() {
    return {
      filter: '',
      searchBy: 'title',
      pendingToggle: false,
      modalVisible: false,
      // rows:[],
      columns: [       
            {
          name: "title",
          required: true,
          label: 'title',
          align: 'center',
          field: 'title',
          // style: 'width: 5vw;',
        },
        {
          name: 'description',
          field: 'description',
          label: 'description',
          align: 'center',
          format: (val) => `${val}`,
          required: true,
          // sortable: true,
        },
        // { name: 'Description', align: 'center', label: 'Description', field: 'description',},
        { name: "views", align: 'center', label: 'views', field: 'views',},
        // { name: i18n('common.createdBy'), align: 'center', label: i18n('common.createdBy'), field: 'createdBy',},
        { name: "postStatus", align: 'center', label: 'postStatus', field: 'postStatus',},
        // { name: i18n('common.createdAt'), align: 'center', label: i18n('common.createdAt'), field: 'createdAt',},
        { name: "action", align: 'center', label: 'action', field: 'action',},
         


      ],
      rowsOld:[],
      // language: getLanguageCode(),
      dialogVisible: false,
      dialogType: undefined,
      selectedRow: null,
      selectedId: null,
      pageSize: 5,
      nextPage: 2,
      pagination: {
        page: 1,
        rowsPerPage: 10,
        // sortBy: 'desc',
        // descending: false,
        // rowsNumber: xx if getting data from a server
      },
      page:1,
      limit: 10
    };
  },
  computed: {
    ...mapGetters({
      rows: 'prescription/list/rows',
      recordAfterLast: 'prescription/list/recordAfterLast',
      recordBeforeFirst: 'prescription/list/recordBeforeFirst',
      count: 'prescription/list/count',
      // pagination: 'job/list/pagination',
      // paginationLayout: 'layout/paginationLayout',
      loading: 'prescription/list/loading',
      saveLoading: 'prescription/form/saveLoading',
      backgroundLoading: 'prescription/list/backgroundLoading',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      destroyLoading: 'prescription/form/saveLoading',
      is_screen_xs: 'layout/is_screen_xs',
      rowsFromRoute: 'prescription/list/rowsFromRoute'
    }),
    loadingAndUpdate(){
      if(this.loading || this.saveLoading){
        return true
      }
      return false
    },

    hasPermissionToEdit() {
      return new JobPermissions(this.currentUser).edit;
    },
    hasPermissionToDestroy() {
      return new JobPermissions(this.currentUser).destroy;
    },
    fields() {
      return fields;
    },
    pagesNumber () {
      return Math.ceil(this.rows.length / this.pagination.rowsPerPage)
    },
    rowsToBeDisplayed(){    
        
      if(this.rows.length > 0)
        return this.rows
      return this.rowsOld
    },
  },
  watch:{
     rowsFromRoute(change){
      const page  = this.$route.query.page
      const limit = this.limit
      const rowsFromRouteToBeDisplayed = change.slice(page * limit - limit , page * limit + 1)
      this.setRows(rowsFromRouteToBeDisplayed)

    },
    rows(newval){
       if(newval.length > 0){
        this.setLoadFirstRecord()
        this.setLoadMoreLast()
        this.setDisablePrevious({filter: this.filter})
        this.setDisableNext({filter: this.filter})
       
      }
  }
  },

  methods: {
    ...mapActions({
      doChangeSort: 'job/list/doChangeSort',
      doChangePaginationCurrentPage: 'job/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'job/list/doChangePaginationPageSize',
      doMountTable: 'job/list/doMountTable',
      doDestroy: 'job/destroy/doDestroy',
      doUpdate: 'prescription/form/doUpdate',
      doFetch: 'prescription/list/doFetch',
      doFetchFromRoute: 'prescription/list/doFetchFromRoute',
      getPreviousRecordsBatch:'prescription/list/getPreviousRecordsBatch',
      setDisablePrevious:'prescription/list/setDisablePrevious',
      setDisableNext:'prescription/list/setDisableNext',
      doGetRecordsCount: 'prescription/list/doGetRecordsCount',
      setLoadFirstRecord: 'prescription/list/setLoadFirstRecord',
      setLoadMoreLast:'prescription/list/setLoadMoreLast',
      resetCursor: 'prescription/list/resetCursor',
      setRows: 'prescription/list/setRows'

      // doFetchInBackground: 'job/list/doFetchInBackground',
      // doFetch: 'job/list/doFetch',
    }),
    isNumeric(value) {
      return /^\d+$/.test(value);
    },
    async loadMore(){
      await this.doFetch({limit: 10, filter: this.filter})
      this.page = this.page + 1
      if(this.filter != ''){
        this.$router.push({
          query: {
            page: this.page,
            filter: this.filter
          },
          params:{
            searchBy: this.searchBy
          }
        })
      }
      else{
        this.$router.push({
          query: {
            page: this.page,            
          },
          params:{
            searchBy: this.searchBy
          }
        })
      }
      this.rowsOld = this.rows
    },
    async goToPreviousPage(){
      await this.getPreviousRecordsBatch({limit: 10, filter:this.filter})
      this.page = this.page - 1
      if(this.filter != ''){
      this.$router.push({
        query: {
          page: this.page,
          filter: this.filter
        },
        params:{
          searchBy: this.searchBy
        }
      })
      }
      else{
        this.$router.push({
        query: {
          page: this.page,
        },
        params:{
          searchBy: this.searchBy
        }
      })
      }
      this.rowsOld = this.rows
    },
    async searchByFilter(){
      this.resetCursor()
      this.page = 1
      if(this.filter != ''){
      this.$router.push({
        query: {
          page: this.page,
          filter: this.filter
        },
        params:{
          searchBy: this.searchBy
        }
      })
      }
      else{
         this.$router.push({
        query: {
          page: this.page,           
        },
        params:{
          searchBy: this.searchBy
        }
      })
      }
      await this.doFetch({limit: 10, filter: this.filter})
      this.rowsOld = this.rows
      

    },
    async removeSearch(){
      this.resetCursor()
      // this.searchBy = 'name'
      this.filter = ''
      this.$router.push({
        query: {
          page: this.page,
          // filter: ''
        },
        params:{
          searchBy: this.searchBy
        }
      })
      await this.doFetch({limit: 10, filter: this.filter})
    },
    i18n(key, args) {
      return this.$t(key, args);
    },
    presenter(row, fieldName) {
      const val = JobModel.presenter(row, fieldName);
      return val ? val : 'ـــــــ';
    },
    presenterEnum(row, fieldName) {
      const options = this.fields[fieldName].options;
      const val = options.filter(el => el.value == row[fieldName])
      return val.length ? val[0].label : 'ـــــــ';
    },
    presenterMap(row, fieldName) {
      const val = JobModel.presenter(row, fieldName);
      return val[this.language] ? val[this.language] : null
    },
    presenterDate(row, fieldName) {
      const val = JobModel.presenter(row, fieldName)
      return val ? moment(val).locale(this.language).format("D MMM, YYYY") : 'ـــــــ'; 
    },
    presenterDay(row, fieldName) {
      return moment(JobModel.presenter(row, fieldName)).locale(this.language).format("dddd");
    },
    presenterTime(row, fieldName) {
      let now = moment().format('YYYY/MM/DD');
      let time = JobModel.presenter(row, fieldName)
      let date =  `${now} ${time}`;
      return moment(date).locale(this.language).format("hh:mm a"); 
    },

    onModalOpen(row, operation) {
      
      this.selectedRow = row;
      this.selectedId = row.id;
      switch (operation) {
        case 'delete':
          this.dialogType = 'delete'
          break;
        case 'edit':
          this.dialogType = 'edit'
          break;
        case 'view':
          this.dialogType = 'view'
          break;  
        default:
          this.dialogType = undefined
          break;
      }
      this.modalVisible = true
      this.dialogVisible = true
    },
    onModalClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.modalVisible = false;
        this.dialogType = undefined
      }, 500);
    },
    onModalSuccess() {
      this.onModalClose();
    },
    closeDialog(){
      this.dialogVisible = false;
      this.dialogType = ''
    },
    openDialog(row, operation){
      this.selectedRow = row;
      this.selectedId = row.id;
      switch (operation) {
        case 'delete':
          this.dialogType = 'delete'
          break;
        case 'edit':
          this.dialogVisible = true
          this.modalVisible = true
          break;
        default:
          break;
      }
      this.dialogVisible = true
    },

    async doDestroyWithConfirm({id, rejectionReason}) {
      
      try {
        let prescription = Object.assign({}, this.selectedRow);
        prescription.postStatus = "destroyed"
        prescription.rejectionReason = rejectionReason
        prescription.createdAt = new Date(prescription.createdAt.seconds * 1000)
        prescription.updatedAt = new Date(prescription.updatedAt.seconds * 1000)
        delete prescription.id
        // delete prescription.createdAtUpdated
        
        // delete facility.listedForName
        await this.doUpdate({id, values:prescription})
                // await this.doUpdatePrescription({id: this.id, values: prescription })
        // await this.doDestroy(id);
        // this.closeDialog();
        this.onModalClose()
      } catch (error) {
        // no
      }
    },
    async doAcceptPrecription(id,row){
      try {
        let prescription = Object.assign({}, row);
        prescription.postStatus = "accepted"
        // prescription.createdAt = new Date(prescription.createdAt.seconds * 1000)
        // prescription.updatedAt = new Date(prescription.updatedAt.seconds * 1000)
        delete prescription.id
        // delete prescription.userName
        // delete prescription.createdAtUpdated
        
        // delete facility.listedForName
        await this.doUpdate({id, values:prescription})
                // await this.doUpdatePrescription({id: this.id, values: prescription })
        // await this.doDestroy(id);
        // this.closeDialog();
        // this.onModalClose()
      } catch (error) {
        // no
      }
    },

    async navigateBackwardFroward() {
      if (this.$route.name == "prescription") {
        this.page = this.$route.query.page && this.$route.query.page > 0 && this.isNumeric(this.$route.query.page) ? parseInt(this.$route.query.page) : 1
        this.searchBy = 'title'        
        this.filter = this.$route.query.filter ? this.$route.query.filter : ''


        // this.setRows([]);
        this.resetCursor();
        if (this.$route.params.searchBy && this.$route.params.searchBy == 'title') {
            if(!this.$route.query.page || !this.isNumeric(this.$route.query.page) && this.$route.query.page <= 0){
              if(this.$route.query.filter){
                this.$router.push({
                  query: {
                    page: 1,
                    filter: this.$route.query.filter              
                  }
                })
              }
              else{
                this.$router.push({
                  query: {
                    page: 1,              
                  }
                })
              }
            }
          await this.doFetchFromRoute({page: this.page, limit: 10, searchBy: 'title', filter: this.$route.query.filter ? this.$route.query.filter : ''})
        } else {
            this.$router.push({
              params: {
                searchBy: 'title'
              },
              query: {
                page: 1,
              }
            })
            this.page = 1
            await this.doFetch({
              limit: 10, searchBy: 'title', filter: ''
            });
        }
        this.rowsOld = this.rows;
      } 
    },
  },


  mounted() {
    var that = this;
    window.onpopstate = async function () {
      that.navigateBackwardFroward();
    };
  },

  async created(){
    //#region [ ======== to set the searchBy, filter, page variables ======== ]
    this.page = this.$route.query.page && this.isNumeric(this.$route.query.page) && this.$route.query.page > 0 ? parseInt(this.$route.query.page) : 1
    this.searchBy = 'title'
    this.filter = this.$route.query.filter ? this.$route.query.filter : ''
    //#endregion
        if(this.$route.params.searchBy && this.$route.params.searchBy == 'title'){
          if(!this.$route.query.page || !this.isNumeric(this.$route.query.page) || this.$route.query.page <= 0){
            if(this.$route.query.filter){
              this.$router.push({
                query: {
                  page: 1,
                  filter: this.$route.query.filter              
                }
              })
            }
            else{
              this.$router.push({
                query: {
                  page: 1,              
                }
              })
            }
          }     
          await this.doFetchFromRoute({page: this.page, limit: 10, searchBy: 'title', filter: this.filter})
      }
      else{
        this.$router.push({
          params: {
            searchBy: 'title'
          },
          query: {
            page: 1,
          }
        })
        await this.doFetch({limit: 10,searchBy: 'title',filter: ''})
      }
      this.rowsOld = this.rows;



    // if(this.$route.params.searchBy && this.$route.params.searchBy == 'title'){
    //     if(this.$route.query.page){
    //       await this.doFetchFromRoute({page: this.$route.query.page, limit: 10, searchBy: this.$route.params.searchBy, filter: this.$route.query.filter ? this.$route.query.filter : ''})
    //     }
    //     else{
    //       if(this.$route.query.filter){
    //         this.$router.push({
    //           query: {
    //             page: 1,
    //             filter: this.$route.query.filter
    //           }
    //         })
    //       }
    //       else{
    //         this.$router.push({
    //           query: {
    //             page: 1,
    //           }
    //         })
    //       }
    //       await this.doFetchFromRoute({page: 1, limit: 10, searchBy: 'title', filter: this.$route.query.filter ? this.$route.query.filter : ''})
    //     }
    //   }
    //   else{
    //     this.$router.push({
    //       params: {
    //         searchBy: 'title'
    //       },
    //       query: {
    //         page: 1,
    //         // filter: null
    //       }
    //     })
    //     await this.doFetch({limit: 10,searchBy: 'title',filter:''})
    //   }
  }


};
