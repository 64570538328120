//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { FilterSchema } from '@/shared/form/filter-schema';
import { i18n } from '@/i18n';
import { UserModel } from '@/modules/auth/user-model';

const { fields } = UserModel;

const filterSchema = new FilterSchema([
  // fields.id,
  // fields.createdAtRange,
  // fields.role,
  fields.email,
  fields.fullName,
  // fields.userType,
]);

export default {
  name: 'app-iam-list-filter',
  props: ['userType'],

  data() {
    return {
      rules: filterSchema.rules(),
      model: {},
      searchLoading: false,
      resetLoading: false,
    };
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthFilter: 'layout/labelWidthFilter',
      loading: 'iam/list/loading',
      filter: 'iam/list/filter',
      is_screen_xs: 'layout/is_screen_xs',
    }),

    fields() {
      return fields;
    },
  },

  async mounted() {
    this.model = filterSchema.initialValues(
      this.filter,
      this.$route.query,
    );
    return this.doFilter();
  },

  methods: {
    ...mapActions({
      doReset: 'iam/list/doReset',
      doFetch: 'iam/list/doFetch',
    }),
    i18n(key, args) {
      return i18n(key, args);
    }, 
    async doResetFilter() {
      this.resetLoading = true
      this.model = filterSchema.initialValues();
      // this.$refs.form.clearValidate();
      await this.doReset();
      this.resetLoading = false
    },
    // OnClear() {
    //   this.model.role = []
    // },
    filterFn (val, update) {
      if (val === '') {
        update(() => {
          this.sparePartOptions = this.sparePartList

          // with Quasar v1.7.4+
          // here you have access to "ref" which
          // is the Vue reference of the QSelect
        })
        return
      }

      update(() => {
        const needle = val.toLowerCase()
        this.sparePartOptions = this.sparePartList.filter(v => v.label.toLowerCase().indexOf(needle) > -1)
      })
    },
    async doFilter() {
      // try {
      //   await this.$refs.form.validate();
      //   this.$refs.form.clearValidate();
      // } catch (error) {
      //   return;
      // }
      
      this.searchLoading = true
      let filter = filterSchema.cast(this.model);
      filter['userType'] = this.userType
      await this.doFetch({
        filter,
      });
      this.searchLoading = false
    },
  },
};
