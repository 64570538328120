import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-prescription';

export class PrescriptionService {
  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation PRESCRIPTION_UPDATE(
          $id: String!
          $data: PrescriptionInput!
        ) {
          prescriptionUpdate(id: $id, data: $data) {
            title
          }
        }
      `,
      variables: {
        id,
        data,
      },
    });
    return response.data.prescriptionUpdate;
  }
  static async list(filter, orderBy, limit, offset) {
    const response = await graphqlClient.query({
      query: gql `
        query PRESCRIPTION_LIST(
          $filter: PrescriptionFilterInput
          $orderBy: PrescriptionOrderByEnum
          $limit: Int
          $offset: Int
        ) {
          prescriptionList(
            filter: $filter
            orderBy: $orderBy
            limit: $limit
            offset: $offset
          ) {
            count
            rows {
              id
              createdBy
              updatedBy
              description
              imageURL
              title
              subscribers
              viewers
              postStatus
              createdAt
              updatedAt
            }
            
          }
        }
      `,

      variables: {
        filter,
        orderBy,
        limit,
        offset,
      },
    });

    return response.data.prescriptionList;
  }
  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation PRESCRIPTION_CREATE($data: PrescriptionInput!) {
          prescriptionCreate(data: $data) {
            title
            description
          }
        }
      `,

      variables: {
        data,
      },
    });

    return response.data.prescriptionCreate;
  }
}