//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
// import { i18n, getLanguageCode } from '@/i18n';
import i18n from '@/vueI18n'

export default {
  name: 'app-job-view-modal',

  props: ['visible', 'id', 'record'],

  data() {
    return {
      dialogType:undefined,
      createdAtUpdated: null,
      modalVisible:false,
      dialogDeleteVisible:false,
      selectedRow: null,
      selectedId: null,
    };
  },

  computed: {
    isRTL() {
      return i18n.locale == 'ar'
    },
    language(){
      return i18n.locale
    },
    ...mapGetters({
      // record: 'job/form/record',
      findLoading: 'job/form/findLoading',
      saveLoading: 'job/form/saveLoading',
      workplaces: 'job/view/workplaces',
      positions: 'job/view/positions',
      cities: 'job/view/cities'
    }),
    dialogVisible: {
      get: function() {
        return this.visible;
      },

      set: function(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },

  async created() {
    // console.log('heyyy',new Date(this.record.createdAt.seconds * 1000).toString().slice(0,16))
    // this.createdAtUpdated = new Date(this.record.createdAt).toString().slice(0,16)
    this.createdAtUpdated = new Date(this.record.createdAt.seconds * 1000).toString().slice(0,16)
    this.record['workplace'] = this.workplaces.find(el => el.id == this.record.workplaceId).name
    this.record['position'] = this.positions.find(el => el.id == this.record.positionId).name
    this.record['city'] = this.cities.find(el => el.id == this.record.cityId).name
  },
  methods: {
    ...mapActions({
      doFind: 'job/form/doFind',
      doNew: 'job/form/doNew',
      doUpdate: 'job/form/doUpdate',
      doCreate: 'job/form/doCreate',
      doGeneratePinCode: 'job/form/doGeneratePinCode',
    }),
    // i18n(code, args) {
    //   return i18n(code, args)
    // },
    i18n(key, args) {
      return this.$t(key, args);
    },
    doCancel() {
      this.dialogVisible = false;
    },
    doChangeLanguage() {
      this.tab == 'arabic'
        ? this.language = 'ar'
        : this.language = 'en'
    },
    close(){
       this.$emit('close')
    },
    onModalOpen(row, operation) {
      this.selectedRow = row;
      this.selectedId = row.id;
      switch (operation) {
        case 'delete':
          this.dialogType = 'delete'
          break;
        case 'edit':
          this.dialogType = 'edit'
          break;
        case 'view':
          this.dialogType = 'view'
          break;  
        default:
          this.dialogType = undefined
          break;
      }
      this.modalVisible = true
      this.dialogDeleteVisible = true
    },
    async doDestroyWithConfirm({ id, rejectionReason }) {
      try {
        let job = Object.assign({}, this.selectedRow);
        job.postStatus = "destroyed"
        job.rejectionReason = rejectionReason
        delete job.id
        delete job.workplace
        delete job.position
        delete job.city
        delete job.region
        delete job.createdAtUpdated
        job.createdAt = new Date(job.createdAt.seconds * 1000)
        job.updatedAt = new Date(job.updatedAt.seconds * 1000)
        if(job.dueDate){
          job.dueDate = new Date(job.dueDate.seconds * 1000)
        }
        else{
          job.dueDate = null
        }

        await this.doUpdate({id, values:job})
        this.onModalClose()
        if(!this.saveLoading){
          this.close()
        }
      } catch (error) {
        // no
      }
    },
    onModalClose() {
      this.dialogDeleteVisible = false;
      setTimeout(() => {
        this.modalVisible = false;
        this.dialogType = undefined
      }, 500);
    },
    onModalSuccess() {
      this.onModalClose();
    },
    async doAcceptJob(){
      let id = this.record.id
      let job = Object.assign({}, this.record);
        job.postStatus = "accepted"
        job.rejectionReason = null
        delete job.id
        delete job.workplace
        delete job.position
        delete job.city
        delete job.region
        delete job.createdAtUpdated
        job.createdAt = new Date(job.createdAt.seconds * 1000)
        job.updatedAt = new Date(job.updatedAt.seconds * 1000)
        if(job.dueDate){
          job.dueDate = new Date(job.dueDate.seconds * 1000)
        }
        else{
          job.dueDate = null
        }
        await this.doUpdate({id, values:job})
        if(!this.saveLoading){
          this.close()
        }
    }
  },
};
