//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import firebase from 'firebase/app'
import 'firebase/firestore';
import { i18n } from "@/i18n";
import { mapGetters,mapActions } from 'vuex'
export default {
name:'app-job-details',
props:['jobDetails','id'],

data(){
    return {
        job: null,
        createdAt: null,
        cityId: null,
        regionId: null,
        positionId: null,
        workplaceId: null,
        createdBy: null,
        city: null,
        region: null,
        position: null,
        workplace: null,
        userName: null,
        avatar: null,
        viewed: false,
        language: null
    }
},
computed:{
    jobDialog:{
        get(){
            return this.jobDetails
        },
        set(){
             this.$emit('closeJob')
        }
    },
    ...mapGetters({
        currentUser: 'auth/currentUser'
    })
},
methods:{
    close(){
         this.$emit('closeJob')
    },
     i18n(key, args) {
      return i18n(key, args);
    },
    ...mapActions({
        doUpdate: 'jobs/form/doUpdate'
    })
},
async mounted(){

},
async created(){
    this.language = localStorage.getItem('language')
    
   if(this.id !== null && this.id !== undefined) {

        const id = this.id
        await firebase.firestore().collection('job').onSnapshot(async () => {
        // var source = snapshot.metadata.fromCache ? "local cache" : "server";
        this.job = await firebase.firestore().collection("job").doc(id).get()
        // ================This is the viewers addition when opening the dialog ================= //
        // 
        // 
        //  ================will do it in website ================================================//
        // if(this.job.data().viewers) {
        //     this.job.data().viewers.forEach(element => {
               
        //         if(element === this.currentUser.id){
        //             console.log('ele  ',element)
        //             this.viewed = true
        //             console.log('found',this.viewed)
        //         }
                
        //     });


        // }
        // if(!this.viewed) {
        //     console.log('not found')
        //     let updatedJob = this.job.data()
        //     updatedJob.dueDate = new Date(updatedJob.dueDate * 1000)
        //     updatedJob.createdAt = new Date(updatedJob.createdAt * 1000)
        //     updatedJob.updatedAt = new Date(updatedJob.updatedAt * 1000)
        //     console.log('dueDate', updatedJob.dueDate)
        //     updatedJob.viewers.push(this.currentUser.id)
        //     console.log('viewers',updatedJob.viewers)
        //     updatedJob.views = updatedJob.views + 1
        //     delete updatedJob.id
        //     await this.doUpdate({id,values:updatedJob})
        // }
      
        this.positionId = this.job.data().positionId
        this.cityId = this.job.data().cityId
        this.regionId = this.job.data().regionId
        this.workplaceId  = this.job.data().workplaceId
        this.createdAt = this.job.data().createdAt
        this.createdBy = this.job.data().createdBy
        if(this.createdAt){
            if(this.createdAt.seconds) { 
            this.createdAt = new Date(this.job.data().createdAt.seconds * 1000)
            }
            this.createdAt = this.createdAt.toString().slice(0,15)
        }

        await firebase.firestore().collection('workplace').onSnapshot({ includeMetadataChanges: true },async () => {
        this.workplace = await firebase.firestore().collection("workplace").doc(this.workplaceId).get()
        })
        await firebase.firestore().collection('jobPosition').onSnapshot({ includeMetadataChanges: true },async () => {
        this.position = await firebase.firestore().collection("jobPosition").doc(this.positionId).get()
        })
        await firebase.firestore().collection('user').onSnapshot({ includeMetadataChanges: true },async () => {
        const user = await firebase.firestore().collection("user").doc(this.createdBy).get()
        this.userName = user.data().fullName
        if(user.data().avatars) {
            this.avatar = user.data().avatars['id']
        }
        })
        await firebase.firestore().collection('city').onSnapshot({ includeMetadataChanges: true },async () => {
        this.city = await firebase.firestore().collection("city").doc(this.cityId).get()
        this.region = await firebase.firestore().collection("city").doc(this.cityId).collection("regions").doc(this.regionId).get()
        })
        }) 
    }
   
}  
// await firebase.firestore().collection('job').onSnapshot({ includeMetadataChanges: true },async (snapshot) => {
    //     var source = snapshot.metadata.fromCache ? "local cache" : "server";
    //     console.log("Data came from " + source);
    //     const job = await firebase.firestore().collection("job").doc(id).get({ source:'cache' })
    //     this.job = job.data()
    //     console.log('job###',this.job)
    //     // this.createdAt = new Date(this.job.createdAt.seconds * 1000).toString().slice(0,16)
    //     this.createdAt = this.job.createdAt
    //     this.cityId = this.job.cityId
    //     this.regionId = this.job.regionId
    //     this.positionId = this.job.positionId
    //     this.workplaceId = this.job.workplaceId
    //     await firebase.firestore().collection('workplace').onSnapshot(async () => {
    //         this.workplace = await firebase.firestore().collection("workplace").doc(this.workplaceId).get()
    //     })
    //     await firebase.firestore().collection('jobPosition').onSnapshot(async () => {
    //         this.position = await firebase.firestore().collection("jobPosition").doc(this.positionId).get()
    //     })
    //     await firebase.firestore().collection('city').onSnapshot(async () => {
    //         this.city = await firebase.firestore().collection("city").doc(this.cityId).get()
    //         this.region = await firebase.firestore().collection("city").doc(this.cityId).collection('regions').doc(this.regionId).get()
    //     })
    
    // })
}
