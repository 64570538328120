import importerStore from '@/shared/importer/importer-store';
import { OfferService } from '@/modules/offer/offer-service';
import offerImporterFields from '@/modules/offer/offer-importer-fields';
import { i18n } from '@/i18n';

export default importerStore(
  OfferService.import,
  offerImporterFields,
  i18n('entities.offer.importer.fileName'),
  i18n('entities.offer.importer.hint'),
);
