import Roles from '@/security/roles';
const roles = Roles.values;

class Permissions {
  static get values() {
    return {
      // ============================================================================================================ //
      //                                                   IAM
      // ============================================================================================================ //
      //#region [ IAM ]
      iamEdit: {
        id: 'iamEdit',
        allowedRoles: [
          roles.owner,
          roles.iamSecurityReviewer,
          roles.editor,
        ],
        allowedStorageFolders: ['user'],
      },
      iamCreate: {
        id: 'iamCreate',
        allowedRoles: [
          roles.owner,
          roles.iamSecurityReviewer,
          roles.editor,
        ],
      },
      iamImport: {
        id: 'iamImport',
        allowedRoles: [
          roles.owner,
          roles.iamSecurityReviewer,
          roles.editor,
        ],
      },
      iamRead: {
        id: 'iamRead',
        allowedRoles: [
          roles.owner,
          roles.iamSecurityReviewer,
          roles.editor,
          roles.viewer,
        ],
      },
      iamUserAutocomplete: {
        id: 'iamUserAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,

          roles.orderEditor,
          roles.orderViewer,
        ],
      },
      //#endregion

      // ============================================================================================================ //
      //                                                   AUDITLOG
      // ============================================================================================================ //
      //#region [ AUDITLOG ]
      auditLogRead: {
        id: 'auditLogRead',
        allowedRoles: [roles.owner, roles.auditLogViewer, roles.viewer],
      },
      //#endregion

      // ============================================================================================================ //
      //                                                   SETTINGS
      // ============================================================================================================ //
      //#region [ SETTINGS ]
      settingsEdit: {
        id: 'settingsEdit',
        allowedRoles: [roles.owner],
      },
      //#endregion

      // ============================================================================================================ //
      //                                                   OFFERS
      // ============================================================================================================ //
      //#region [ OFFERS ]
      offerImport: {
        id: 'offerImport',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.offerEditor,
        ],
      },
      offerExport: {
        id: 'offerExport',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.offerEditor,
        ],
      },
      offerCreate: {
        id: 'offerCreate',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.offerEditor,
        ],
        allowedStorageFolders: ['offer'],
      },
      offerEdit: {
        id: 'offerEdit',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.offerEditor,
        ],
        allowedStorageFolders: ['offer'],
      },
      offerDestroy: {
        id: 'offerDestroy',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.offerEditor,
        ],
        allowedStorageFolders: ['offer'],
      },
      offerRead: {
        id: 'offerRead',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.offerEditor,
          roles.offerViewer,
        ],
      },
      offerAutocomplete: {
        id: 'offerAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.offerEditor,
          roles.offerViewer,
          roles.orderEditor,
          roles.orderViewer,
        ],
      },
      //#endregion


      // ============================================================================================================ //
      //                                                   SETTINGS
      // ============================================================================================================ //
      //#region [ SETTINGS ]
      settingImport: {
        id: 'settingImport',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.settingEditor,
        ],
      },
      settingExport: {
        id: 'settingExport',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.settingEditor,
        ],
      },
      settingCreate: {
        id: 'settingCreate',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.settingEditor,
        ],
        allowedStorageFolders: ['setting'],
      },
      settingEdit: {
        id: 'settingEdit',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.settingEditor,
        ],
        allowedStorageFolders: ['setting'],
      },
      settingDestroy: {
        id: 'settingDestroy',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.settingEditor,
        ],
        allowedStorageFolders: ['setting'],
      },
      settingRead: {
        id: 'settingRead',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.settingEditor,
          roles.settingViewer,
        ],
      },
      settingAutocomplete: {
        id: 'settingAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.settingEditor,
          roles.settingViewer,
          roles.orderEditor,
          roles.orderViewer,
        ],
      },
      //#endregion


      // ============================================================================================================ //
      //                                                  CONTACT
      // ============================================================================================================ //
      //#region [ CONTACT ]
      contactImport: {
        id: 'contactImport',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.contactEditor,
        ],
      },
      contactExport: {
        id: 'contactExport',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.contactEditor,
        ],
      },
      contactCreate: {
        id: 'contactCreate',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.contactEditor,
        ],
        allowedStorageFolders: ['contact'],
      },
      contactEdit: {
        id: 'contactEdit',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.contactEditor,
        ],
        allowedStorageFolders: ['contact'],
      },
      contactDestroy: {
        id: 'contactDestroy',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.contactEditor,
        ],
        allowedStorageFolders: ['contact'],
      },
      contactRead: {
        id: 'contactRead',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.contactEditor,
          roles.contactViewer,
        ],
      },
      contactAutocomplete: {
        id: 'contactAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.contactEditor,
          roles.contactViewer,
         
        ],
      },
      //#endregion




      // ============================================================================================================ //
      //                                                   JOBS
      // ============================================================================================================ //
      //#region [ JOBS ]
      jobImport: {
        id: 'jobImport',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.jobEditor,
        ],
      },
      jobExport: {
        id: 'jobExport',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.jobEditor,
        ],
      },
      jobCreate: {
        id: 'jobCreate',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.jobEditor,
        ],
        allowedStorageFolders: ['job'],
      },
      jobEdit: {
        id: 'jobEdit',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.jobEditor,
        ],
        allowedStorageFolders: ['job'],
      },
      jobDestroy: {
        id: 'jobDestroy',
        allowedRoles: [
          roles.owner,
          // 'editor',
          roles.editor,
          roles.entityEditor,
          roles.jobEditor,
        ],
        allowedStorageFolders: ['job'],
      },
      jobRead: {
        id: 'jobRead',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.jobEditor,
          roles.jobViewer,
        ],
      },
      jobAutocomplete: {
        id: 'jobAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.jobEditor,
          roles.jobViewer,
          roles.jobEditor,
          roles.jobViewer,
        ],
      },
      //#endregion

      // ============================================================================================================ //
      //                                                   PRESCRIPTIONS
      // ============================================================================================================ //
      //#region [ PRESCRIPTIONS ]
      prescriptionImport: {
        id: 'prescriptionImport',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.prescriptionEditor,
        ],
      },
      prescriptionExport: {
        id: 'prescriptionExport',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.prescriptionEditor,
        ],
      },
      prescriptionCreate: {
        id: 'prescriptionCreate',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.prescriptionEditor,
        ],
        allowedStorageFolders: ['prescription'],
      },
      prescriptionEdit: {
        id: 'jobEdit',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.prescriptionEditor,
        ],
        allowedStorageFolders: ['prescription'],
      },
      prescriptionDestroy: {
        id: 'prescriptionDestroy',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.prescriptionEditor,
        ],
        allowedStorageFolders: ['prescription'],
      },
      prescriptionRead: {
        id: 'prescriptionRead',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.prescriptionEditor,
          roles.prescripitonViewer,
        ],
      },
      prescriptionAutocomplete: {
        id: 'prescriptionAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.prescriptionEditor,
          roles.prescriptionViewer,
          roles.prescriptionEditor,
          roles.prescriptionViewer,
        ],
      },
      //#endregion

      // ============================================================================================================ //
      //                                                   PROPERTIES
      // ============================================================================================================ //
      //#region [ PROPERTIES ]
      propertyImport: {
        id: 'propertyImport',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.propertyEditor,
        ],
      },
      propertyExport: {
        id: 'propertyExport',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.propertyEditor,
        ],
      },
      propertyCreate: {
        id: 'propertyCreate',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.propertyEditor,
        ],
        allowedStorageFolders: ['property'],
      },
      propertyEdit: {
        id: 'propertyEdit',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.propertyEditor,
        ],
        allowedStorageFolders: ['property'],
      },
      propertyDestroy: {
        id: 'propertyDestroy',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.propertyEditor,
        ],
        allowedStorageFolders: ['property'],
      },
      propertyRead: {
        id: 'propertyRead',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.propertyEditor,
          roles.propertyViewer,
        ],
      },
      propertyAutocomplete: {
        id: 'propertyAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.propertyEditor,
          roles.propertyViewer,
          roles.propertyEditor,
          roles.propertyViewer,
        ],
      },
      //#endregion

      // ============================================================================================================ //
      //                                                   DRUGINDEX
      // ============================================================================================================ //
      //#region [ DRUGINDEX ]
      drugIndexImport: {
        id: 'drugIndexImport',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.drugIndexEditor,
        ],
      },
      drugIndexExport: {
        id: 'drugIndexExport',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.drugIndexEditor,
        ],
      },
      drugIndexCreate: {
        id: 'drugIndexCreate',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.drugIndexEditor,
        ],
        allowedStorageFolders: ['drugIndex'],
      },
      drugIndexEdit: {
        id: 'drugIndexEdit',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.drugIndexEditor,
        ],
        allowedStorageFolders: ['drugIndex'],
      },
      drugIndexDestroy: {
        id: 'drugIndexDestroy',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.drugIndexEditor,
        ],
        allowedStorageFolders: ['drugIndex'],
      },
      drugIndexRead: {
        id: 'drugIndexRead',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.drugIndexEditor,
          roles.drugIndexViewer,
        ],
      },
      drugIndexAutocomplete: {
        id: 'drugIndexAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.drugIndexEditor,
          roles.drugIndexViewer,
          roles.drugIndexEditor,
          roles.drugIndexViewer,
        ],
      },
      //#endregion

      // ============================================================================================================ //
      //                                                   ADMIN
      // ============================================================================================================ //
      //#region [ ADMIN ]
      adminImport: {
        id: 'drugIndexImport',
        allowedRoles: [
          roles.owner,
          // roles.editor,
          roles.entityEditor,
          roles.adminEditor,
        ],
      },
      adminExport: {
        id: 'drugIndexExport',
        allowedRoles: [
          roles.owner,
          // roles.editor,
          roles.entityEditor,
          roles.adminEditor,
        ],
      },
      adminCreate: {
        id: 'drugIndexCreate',
        allowedRoles: [
          roles.owner,
          // roles.editor,
          roles.entityEditor,
          roles.adminEditor,
        ],
        allowedStorageFolders: ['admin'],
      },
      adminEdit: {
        id: 'adminEdit',
        allowedRoles: [
          roles.owner,
          // roles.editor,
          roles.entityEditor,
          roles.adminEditor,
        ],
        allowedStorageFolders: ['admin'],
      },
      adminDestroy: {
        id: 'adminDestroy',
        allowedRoles: [
          roles.owner,
          // roles.editor,
          roles.entityEditor,
          roles.adminEditor,
        ],
        allowedStorageFolders: ['admin'],
      },
      adminRead: {
        id: 'adminRead',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.drugIndexEditor,
          roles.drugIndexViewer,
        ],
      },
      adminAutocomplete: {
        id: 'adminAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.adminEditor,
          roles.adminViewer,
          roles.adminEditor,
          roles.adminViewer,
        ],
      },
      //#endregion

      // ============================================================================================================ //
      //                                                   USER
      // ============================================================================================================ //
      //#region [ USER ]
      userImport: {
        id: 'userImport',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.userEditor,
        ],
      },
      userExport: {
        id: 'userExport',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.userEditor,
        ],
      },
      userCreate: {
        id: 'userCreate',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.userEditor,
        ],
        allowedStorageFolders: ['user'],
      },
      userEdit: {
        id: 'adminEdit',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.userEditor,
        ],
        allowedStorageFolders: ['user'],
      },
      userDestroy: {
        id: 'userDestroy',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.entityEditor,
          roles.userEditor,
        ],
        allowedStorageFolders: ['user'],
      },
      userRead: {
        id: 'userRead',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.userEditor,
          roles.userViewer,
        ],
      },
      userAutocomplete: {
        id: 'userAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.editor,
          roles.viewer,
          roles.entityEditor,
          roles.userEditor,
          roles.userViewer,
          roles.userEditor,
          roles.userViewer,
        ],
      },
      //#endregion

    };
  }

  static get asArray() {
    return Object.keys(this.values).map((value) => {
      return this.values[value];
    });
  }
}

export default Permissions;
