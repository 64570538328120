//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from "vuex";
// import { i18n } from "@/i18n";
import i18n from '@/vueI18n'
import MenuItem from '@/modules/layout/components/menu-item.vue';

// import { UsersPermissions } from '@/modules/users/users-permissions';
// import { AdminsPermissions } from '@/modules/admins/admins-permissions';
// import { DrugIndexPermissions } from '@/modules/drug-index/drug-index-permissions';
// import { PrescriptionsPermissions } from '@/modules/prescriptions/prescriptions-permissions';
// import { PropertiesPermissions } from '@/modules/properties/properties-permissions';
// import { RegionsPermissions } from '@/modules/regions/regions-permissions';
// import { CitiesPermissions } from '@/modules/cities/cities-permissions';
// import { OfferPermissions } from '@/modules/offer/offer-permissions';

export default {
  name: "app-menu",

  components: {
    [MenuItem.name]: MenuItem,
  },

  data() {
    return {
      link: '/',
      // lang:this.isRTL ? 'ar':'en'
    };
  },
  created(){
  },
  computed: {
    ...mapGetters({
      collapsed: "layout/menuCollapsed",
      isMobile: "layout/isMobile",
      currentUser: "auth/currentUser",
    }),
    lang() {
      return i18n.locale
    },
    isRTL() {
      return i18n.locale == 'ar'
    },
    isOpened() {
      return (type) => {
        const routePath = this.$route.path;
        if (type == 'database') {
          const active = 
            routePath === `/${this.lang}/iam/client` || routePath.startsWith(`${this.lang}/iam/client` + "/") ||
            routePath === `/${this.lang}/iam/admin` || routePath.startsWith('iam/admin' + "/") ||
            routePath === `/${this.lang}/drug-index` || routePath.startsWith('/drug-index' + "/") ||
            routePath === `/${this.lang}/prescription` || routePath.startsWith('/prescription' + "/") ||
            routePath === `/${this.lang}/job` || routePath.startsWith('/job' + "/") ||
            routePath === `/${this.lang}/property` || routePath.startsWith('/property' + "/") ||
            routePath === `/${this.lang}/region` || routePath.startsWith('/region' + "/") ||
            routePath === `/${this.lang}/city` || routePath.startsWith('/city' + "/") ||
            routePath === `/${this.lang}/offer` || routePath.startsWith('/offer' + "/") 
          return active ? 'database' : null
        } else if (type == 'policies') {
          const active = 
            routePath === `/${this.lang}/terms-and-conditions` || routePath.startsWith(`/${this.lang}/terms-and-conditions` + '/') ||
            routePath === `/${this.lang}/privacy-policy` || routePath.startsWith(`/${this.lang}/privacy-policy` + '/') 
          return active ? 'policies' : null
        }
      }
    },
    // isOpened() {
    //   const routePath = this.$route.path;
    //   const active = 
    //     routePath === `/${this.lang}/iam/client` || routePath.startsWith(`${this.lang}/iam/client` + "/") ||
    //     routePath === `/${this.lang}/iam/admin` || routePath.startsWith('iam/admin' + "/") ||
    //     routePath === `/${this.lang}/drug-index` || routePath.startsWith('/drug-index' + "/") ||
    //     routePath === `/${this.lang}/prescription` || routePath.startsWith('/prescription' + "/") ||
    //     routePath === `/${this.lang}/job` || routePath.startsWith('/job' + "/") ||
    //     routePath === `/${this.lang}/property` || routePath.startsWith('/property' + "/") ||
    //     routePath === `/${this.lang}/region` || routePath.startsWith('/region' + "/") ||
    //     routePath === `/${this.lang}/city` || routePath.startsWith('/city' + "/") ||
    //     routePath === `/${this.lang}/offer` || routePath.startsWith('/offer' + "/") 
    //   return active
    // },

    // hasPermissionToUsers() {
    //   return new UsersPermissions(this.currentUser).edit;
    // },

    // hasPermissionToAdmins() {
    //   return new AdminsPermissions(this.currentUser).read;
    // },

    // hasPermissionToIam() {
    //   return new IamPermissions(this.currentUser).read;
    // },

    // hasPermissionToDrugIndex() {
    //   return new DrugIndexPermissions(this.currentUser).read;
    // },

    // hasPermissionToPrescriptions() {
    //   return new PrescriptionsPermissions(this.currentUser).read;
    // },

    // hasPermissionToProperties() {
    //   return new PropertiesPermissions(this.currentUser).read;
    // },

    // hasPermissionToRegions() {
    //   return new RegionsPermissions(this.currentUser).read;
    // },

    // hasPermissionToCities() {
    //   return new CitiesPermissions(this.currentUser).read;
    // },

    // hasPermissionToOffer() {
    //   return new OfferPermissions(this.currentUser).read;
    // },
  },

  methods: {
    ...mapActions({
      collapseMenu: "layout/collapseMenu",
      
    }),
    // i18n(key, args) {
    //   return i18n(key, args);
    // },
     i18n(key, args) {
      return this.$t(key, args);
    },
    collapseMenuIfMobile() {
      if (this.isMobile) {
        this.collapseMenu();
      }
    },
    classFor(path, exact = false) {
      if (exact) {
        return {
          active: this.$route.path === path,
        };
      }

      const routePath = this.$route.path;
      const active = routePath === path || routePath.startsWith(path + "/");

      return {
        active,
      };
    },
  },
};
