import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';
import { i18n } from '@/i18n';

const OfferListPage = () =>
  import('@/modules/offer/components/offer-list-page.vue');
// const DistributorListPage = () =>
//   import('@/modules/offer/components/offer-distributor-page.vue');

// const OfferListPage = () =>
//   import('@/modules/offer/components/offer-list-page.vue');
// const OfferFormPage = () =>
//   import('@/modules/offer/components/offer-form-page.vue');

// const OfferExportPage = () => 
//   import('@/modules/offer/components/offer-export-page.vue')  
// const OfferViewPage = () =>
//   import('@/modules/offer/components/offer-view-page.vue');
// const OfferImporterPage = () =>
//   import('@/modules/offer/components/offer-importer-page.vue');

export default [
  {
    name: '',
    path: '/:lang?',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'offer',
        path: 'offer/:searchBy?',
        component: OfferListPage,
        meta: {
          auth: true,
          permission: Permissions.values.offerView,
        },
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('menu.offer') 
          next();
        },
      },
      // {
      //   name: 'distributor',
      //   path: '/distributor',
      //   component: DistributorListPage,
      //   meta: {
      //     auth: true,
      //     permission: Permissions.values.distributorView,
      //   },
      //   beforeEnter: (to, from, next) => {
      //     document.title =
      //       i18n('app.title') +
      //       ' | ' +
      //       i18n('menu.distributor') 
      //     next();
      //   },
      // },
      // {
      //   name: 'offer',
      //   path: '/offer',
      //   component: OfferListPage,
      //   meta: {
      //     auth: true,
      //     permission: Permissions.values.offerView,
      //   },
      // },
      // {
      //   name: 'offerNew',
      //   path: '/offer/new',
      //   component: OfferFormPage,
      //   meta: {
      //     auth: true,
      //     permission: Permissions.values.offerCreate,
      //   },
      // },
      // {
      //   name: 'offerEdit',
      //   path: '/offer/:id/edit',
      //   component: OfferFormPage,
      //   meta: {
      //     auth: true,
      //     permission: Permissions.values.offerEdit,
      //   },
      //   props: true,
      // },
      // {
      //   name: 'offerExport',
      //   path: '/offer/:id/export',
      //   component: OfferExportPage,
      //   meta: {
      //     auth: true,
      //     // permission: Permissions.values.offerEdit,
      //   },
      //   props: true,
      // },
      // {
      //   name: 'offerImporter',
      //   path: '/offer/import',
      //   component: OfferImporterPage,
      //   meta: {
      //     auth: true,
      //     permission: Permissions.values.offerImport,
      //   },
      // },
      // {
      //   name: 'offerView',
      //   path: '/offer/:id',
      //   component: OfferViewPage,
      //   meta: {
      //     auth: true,
      //     permission: Permissions.values.offerView,
      //   },
      //   props: true,
      // },
    ],
  },
];
