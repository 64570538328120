//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from "vuex";
// import firebase from 'firebase/app';
// import isElementInViewport from 'isElementInViewport'
// import firebase from 'firebase/app';
// import 'firebase/firestore';
import i18n from '@/vueI18n'
export default {
  name: "app",

  // created() {
  //   this.doInit();
  //   window.addEventListener('resize', this.handleResize);
  //   this.handleResize();
  // },
  async created() {
    const promises = [
      new Promise(() => this.doInitFirebase()),
      new Promise(() => this.doInit()),
    ];
    Promise.all(promises).then();

    // window.addEventListener('load', this.addToCache(['/']));
    // await this.doInit();
    // firebase.auth().languageCode = localStorage.getItem('language');
    window.addEventListener("resize", this.handleResize);
    // document.addEventListener('scroll', this.handleScroll);
    this.handleResize();
    //   firebase.firestore().enablePersistence()
    //   .catch(function(err) {
    //     if (err.code == 'failed-precondition') {
    //       console.log('failed-precondition')
    //         // Multiple tabs open, persistence can only be enabled
    //         // in one tab at a a time.
    //         // ...
    //     } else if (err.code == 'unimplemented') {
    //       console.log('unimplemented')
    //         // The current browser does not support all of the
    //         // features required to enable persistence
    //         // ...
    //     }
    // });
  },
  mounted() {
    if (localStorage.getItem("language") == "ar") {
      import(`quasar/lang/ar`).then((lang) => {
        this.$q.lang.set(lang.default);
      });
    } else {
      import(`quasar/lang/en-us`).then((lang) => {
        this.$q.lang.set(lang.default);
      });
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    // document.addEventListener('scroll', this.handleScroll);
  },

  methods: {
    ...mapActions({
      doInitFirebase: "auth/doInitFirebase",
      doInit: "auth/doInit",
      resize: "layout/resize",
      navigation: "layout/navigation",
    }),

    handleResize() {
      this.resize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    },

    
  },
    watch:{
    // 'i18n.locale'(newVal){
      
    //   console.log(newVal)
    //   // 
    //   },
      lang(newVal){
        

        if(newVal == 'ar') {
          document.getElementsByTagName("html")[0].setAttribute("dir","rtl")
          this.$q.lang.rtl = true
        }
        if(newVal == 'en') {
          document.getElementsByTagName("html")[0].setAttribute("dir","ltr")
          this.$q.lang.rtl = false
        }
      }
  },
  computed: {
    ...mapGetters({
      loadingInit: "auth/loadingInit",
      tab: "layout/tab",
    }),
    lang(){
      return i18n.locale
    }
  },
};
