import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';
import { i18n } from '@/i18n';

const SettingListPage = () =>
  import('@/modules/setting/components/setting-list-page.vue');

export default [
  {
    name: '',
    path: '/:lang?',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'setting',
        path: 'setting',
        component: SettingListPage,
        meta: {
          auth: true,
          permission: Permissions.values.settingView,
        },
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('menu.setting') 
          next();
        },
      },
    ],
  },
];
