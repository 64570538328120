import jobListStore from '@/modules/job/job-list-store';
import jobViewStore from '@/modules/job/job-view-store';
import jobImporterStore from '@/modules/job/job-importer-store';
import jobFormStore from '@/modules/job/job-form-store';
import jobDestroyStore from '@/modules/job/job-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: jobDestroyStore,
    form: jobFormStore,
    list: jobListStore,
    view: jobViewStore,
    importer: jobImporterStore,
  },
};
