import settingListStore from '@/modules/setting/setting-list-store';
// import offerViewStore from '@/modules/offer/offer-view-store';
// import offerImporterStore from '@/modules/offer/offer-importer-store';
// import offerFormStore from '@/modules/offer/offer-form-store';
// import offerDestroyStore from '@/modules/offer/offer-destroy-store';

export default {
  namespaced: true,

  modules: {
    // destroy: offerDestroyStore,
    // form: offerFormStore,
    list: settingListStore,
//     view: offerViewStore,
//     importer: offerImporterStore,
  },
};
