//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DeleteDialog from '@/modules/home/components/delete-dialog.vue';
import { i18n } from "@/i18n";
import { AdminPermissions } from '@/modules/admins/admins-permissions.js';
import { UserPermissions } from '@/modules/users/users-permissions.js';
import { mapGetters } from 'vuex';
export default {
    name:'app-table',
    props:['data','columns','user'],
    data(){
        return{
          language:'',
          deleteUser:false,
          pagination: {
          sortBy: 'desc',
          descending: false,
          page: 1,
          rowsPerPage: this.$q.screen.width == 1024 ? 14 : 7
      },
        }
    },
    components:{
         [DeleteDialog.name]: DeleteDialog,
    },
    computed: {
      ...mapGetters({
        currentUser: 'auth/currentUser'
      }),
      hasPermissionToEditAdmin() {
        return new AdminPermissions(this.currentUser).edit;
      },
      hasPermissionToImportAdmin() {
        return new AdminPermissions(this.currentUser).import;
      },
      hasPermissionToDestroyAdmin() {
        return new AdminPermissions(this.currentUser).destroy;
      },
      hasPermissionToReadAdmin(){
        return new AdminPermissions(this.currentUser).read;
      },
       hasPermissionToDestroyUser() {
        // console.log('user permission',new JobPermissions(this.currentUser).destroy)
        return new UserPermissions(this.currentUser).destroy;
      },
      hasPermissionToReadUser(){
        return new UserPermissions(this.currentUser).read;
      },
    pagesNumber () {
      return Math.ceil(this.data.length / this.pagination.rowsPerPage)
    }
  },
  methods:{
    i18n(key, args) {
      return i18n(key, args);
    },
    close(){
      this.deleteUser = false
    },
    goToUser(id){
      if(this.user == 'user')
        this.$router.push(`/users/${id}`)
      if(this.user == 'admin')
        this.$router.push(`/admins/${id}`)
    }
  },
  mounted(){
    this.language = localStorage.getItem('language')
  }
}
