import { OfferModel } from '@/modules/offer/offer-model';

const { fields } = OfferModel;

export default [
  fields.id,
  fields.name,
  fields.description,
  fields.unitPrice,
  fields.photos,
  fields.createdAt
];
