//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { JobModel } from '@/modules/job/job-model';
import { JobPermissions } from '@/modules/job/job-permissions';
// import { i18n} from '@/i18n';
import i18n from '@/vueI18n'
import moment from 'moment';
// import firebase from 'firebase'
import TableViewPage from '@/shared/components/table-view-page.vue';
// import JobFormModal from '@/modules/job/components/job-form-modal.vue';
// import JobViewModal from '@/modules/job/components/job-view-modal.vue';
const { fields } = JobModel;
import { routerAsync } from '@/app-module';


export default {
  name: 'app-iam-list-table',
  components: {
    [TableViewPage.name]: TableViewPage,
    // [JobFormModal.name]:JobFormModal,
    // [JobViewModal.name]:JobViewModal,
  },
  mounted() {
    var that = this;
    window.onpopstate = async function () {
      that.navigateBackwardFroward();
    };
  },
  data() {
    return {
      filter: '',
      modalVisible: false,
      searchBy: 'fullName',
      statusToggle: false, 
    
      columns: [
         {
          name: 'fullName',
          required: true,
          // label: i18n('drugDeal.users.firstName'),
          label: 'fullName',
          align: 'center',
          field: 'fullName',
          // style: 'width: 5vw;',
        },
        //     {
        //   name: 'firstName',
        //   required: true,
        //   label: 'firstName',
        //   align: 'center',
        //   field: 'firstName',
        // },
        // { name: 'lastName', align: 'center', 
        // label: 'lastName', 
        // field: 'lastName',},
        { name: 'phoneNumber', align: 'center', 
        label: 'phoneNumber', field: 'phone',},
        { name: 'emailAddress', align: 'center', label: 'emailAddress', field: 'email',},
        { name:  'status', align: 'center', label:  'status', field: 'disabled',},
        { name:  'action', align: 'center', label: 'action', field: 'action',},
         
      ],
      rowsOld:[],
      // language: getLanguageCode(),
      dialogVisible: false,
      dialogType: undefined,
      selectedRow: null,
      selectedId: null,
      pageSize: 5,
      nextPage: 2,
      pagination: {
        page: 1,
        rowsPerPage: 10,
        // sortBy: 'desc',
        // descending: false,
        // rowsNumber: xx if getting data from a server
      },
      removeTogglePointer: true,
      page:1,
      limit: 10,
      searchByOptions: ['fullName', 'email', 'phoneNumber'],
      status: '',
      accountType: undefined,

    };
  },

  computed: {
    language(){
      return i18n.locale
    },
    ...mapGetters({
      rows: 'iam/list/rows',
      rowsFromRoute: 'iam/list/rowsFromRoute',
      recordAfterLast: 'iam/list/recordAfterLast',
      recordBeforeFirst: 'iam/list/recordBeforeFirst',
      count: 'iam/list/count',
      // pagination: 'job/list/pagination',
      // paginationLayout: 'layout/paginationLayout',
      loading: 'iam/list/loading',
      backgroundLoading: 'iam/list/backgroundLoading',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      destroyLoading: 'iam/list/loading',
      is_screen_xs: 'layout/is_screen_xs',
      accountTypeLayout:'layout/accountType'

    }),
    hasPermissionToEdit() {
      return new JobPermissions(this.currentUser).edit;
    },
    hasPermissionToDestroy() {
      return new JobPermissions(this.currentUser).destroy;
    },
    fields() {
      return fields;
    },
    pagesNumber () {
      return Math.ceil(this.rows.length / this.pagination.rowsPerPage)
    },
    rowsToBeDisplayed(){    
        
      if(this.rows.length > 0){
        return this.rows
      }
      else{
        return this.rowsOld
      }
      
    },
  },
  watch:{
    rowsFromRoute(change){
      const page  = this.$route.query.page
      const limit = this.limit
      const rowsFromRouteToBeDisplayed = change.slice(page * limit - limit , page * limit + 1)
      this.setRows(rowsFromRouteToBeDisplayed)

    },
    rows(newval){
       if(newval.length > 0){
        
        // this.removeLastElement()
        this.setLoadFirstRecord()
        this.setLoadMoreLast()
        this.setDisablePrevious({searchBy: this.searchBy, filter: this.filter, status: this.status, accountType: this.$route.params.accountType})
        this.setDisableNext({searchBy: this.searchBy, filter: this.filter, status: this.status, accountType: this.$route.params.accountType})
       
      }
    },
    async accountTypeLayout() {
      this.rowsOld = this.rows   
      this.filter = '' 
      this.searchBy = 'fullName'
      this.statusToggle = false
    },
    //#region 
    // async statusToggle(newval){
    //   console.log('statusToggle',newval);
    //   if(newval){
    //     this.searchBy = 'disabled'
    //     // this.filter = ''
    //     this.resetCursor()
    //    if(this.$route.params.searchBy != 'disabled') {
    //       // this.page = 1
    //       
    //       if(this.filter != ''){
    //         this.$router.push({
    //           params:{
    //             searchBy: 'disabled'
    //           },
    //           query:{
    //             page: this.page,
    //             filter: this.filter
    //           }
    //       })
    //       }
    //       else{
    //         this.$router.push({
    //           params:{
    //             searchBy: 'disabled'
    //           },
    //           query:{
    //             page: this.page,
    //           }
    //         })
    //       }

    //     }   
    //     await this.doFetch({limit: 10,searchBy: this.searchBy,filter: this.filter, accountType: this.$route.params.accountType})

        
    //   }
    //   else{
    //     if(this.removeTogglePointer){
    //       this.searchBy = 'fullName'
    //       // this.page = 1
    //       this.resetCursor()
    //       if(this.filter != ''){
    //         this.$router.push({
    //           params:{
    //             searchBy: this.searchBy
    //           },
    //           query:{
    //             page: this.page,
    //             filter: this.filter
    //           }
    //         })
    //       }
    //       else{
    //         this.$router.push({
    //           params:{
    //             searchBy: this.searchBy
    //           },
    //           query:{
    //             page: this.page,
    //           }
    //         })
    //       }
    //       await this.doFetch({limit: 10,searchBy: this.searchBy, filter: this.filter, accountType: this.$route.params.accountType})
    //       this.removeTogglePointer = true
    //     }
    //     else{ 
    //       // this.filter = ''
    //       this.resetCursor()
    //       await this.doFetch({limit: 10,searchBy: this.searchBy, filter: this.filter, accountType: this.$route.params.accountType})
    //       this.removeTogglePointer = true
    //     }
        
    //   }
    // },
    //#endregion
  },
  methods: {
    //  i18n(code, args) {
    //   return i18n(code, args)
    // },
    i18n(key, args) {
      return this.$t(key, args);
    },
    ...mapActions({
      doMountTable: 'iam/list/doMountTable',
      doFetchInBackground: 'iam/list/doFetchInBackground',
      doDisableAllSelected: 'iam/list/doDisableAllSelected',
      doEnableAllSelected: 'iam/list/doEnableAllSelected',


      doFetch: 'iam/list/doFetch',
      doFetchFromRoute: 'iam/list/doFetchFromRoute',
      getPreviousRecordsBatch:'iam/list/getPreviousRecordsBatch',
      setDisablePrevious:'iam/list/setDisablePrevious',
      setDisableNext:'iam/list/setDisableNext',
      doGetRecordsCount: 'iam/list/doGetRecordsCount',
      setLoadFirstRecord: 'iam/list/setLoadFirstRecord',
      setLoadMoreLast:'iam/list/setLoadMoreLast',
      resetCursor: 'iam/list/resetCursor',
      setRows: 'iam/list/setRows'

    }),
    isNumeric(value) {
      return /^\d+$/.test(value);
    },
    async loadMore(){
      await this.doFetch({limit: 10,searchBy: this.searchBy, filter: this.filter, accountType: this.$route.params.accountType})
      this.page = this.page + 1
      if(this.filter != ''){
        this.$router.push({
          query: {
            page: this.page,
            filter: this.filter
          },
          params:{
            searchBy: this.searchBy
          }
        })
      }
      else{
        this.$router.push({
          query: {
            page: this.page,            
          },
          params:{
            searchBy: this.searchBy
          }
        })
      }
      this.rowsOld = this.rows
    },
    async goToPreviousPage(){
      await this.getPreviousRecordsBatch({limit: 10, searchBy: this.searchBy, filter: this.filter, accountType: this.$route.params.accountType, status: this.status})      
      this.page = this.page - 1
      let query = {}
      query['page'] = this.page
      if(this.filter != ''){
        query['filter'] = this.filter
      }
      if(this.status == 'disabled'){
        query['status'] = 'disabled'
      }
      let params = {}
      params['searchBy'] = this.searchBy
      
      this.$router.push({
        params,
        query
      })
      this.rowsOld = this.rows




      // await this.getPreviousRecordsBatch({limit: 10,searchBy: this.searchBy, filter: this.filter, accountType: this.$route.params.accountType})
      //  this.page = this.page - 1
      // if(this.filter != ''){
      // this.$router.push({
      //   query: {
      //     page: this.page,
      //     filter: this.filter
      //   },
      //   params:{
      //     searchBy: this.searchBy
      //   }
      // })
      // }
      // else{
      //   this.$router.push({
      //   query: {
      //     page: this.page,
      //   },
      //   params:{
      //     searchBy: this.searchBy
      //   }
      // })
      // }
      // this.rowsOld = this.rows
    },
    async searchByFilter(){
      // console.log('filter',this.filter);
      // console.log('searcgBy',this.searchBy);
      // this.resetCursor()
      // this.page = 1
      // if(this.filter != ''){
      // this.$router.push({
      //   query: {
      //     page: this.page,
      //     filter: this.filter
      //   },
      //   params:{
      //     searchBy: this.searchBy
      //   }
      // })
      // }
      // else{
      //    this.$router.push({
      //   query: {
      //     page: this.page,           
      //   },
      //   params:{
      //     searchBy: this.searchBy
      //   }
      // })
      // } 
      // await this.doFetch({limit: 10, searchBy: this.searchBy, filter: this.filter, accountType: this.$route.params.accountType})
      // this.rowsOld = []
    
      this.resetCursor()
      this.page = 1

      let query = {}
      query['page'] = this.page
      if(this.filter != ''){
        query['filter'] = this.filter
      }
      if(this.status == 'disabled'){
        query['status'] = 'disabled'
      }
      let params = {}
      params['searchBy'] = this.searchBy
      
      this.$router.push({
        params,
        query
      })

      
      await this.doFetch({limit: 10, searchBy: this.searchBy, filter: this.filter, accountType: this.$route.params.accountType, status: this.status})
      this.rowsOld = this.rows

    },
    async removeSearch(){
      this.resetCursor()
      this.filter = ''
      this.page = 1

      let query = {}
      query['page'] = this.page
      // query['filter'] = this.filter
      
      if(this.status == 'disabled'){
        query['status'] = 'disabled'
      }
      let params = {}
      params['searchBy'] = this.searchBy
      
      this.$router.push({
        params,
        query
      })
      await this.doFetch({limit: 10,searchBy: this.searchBy, filter: this.filter, accountType: this.$route.params.accountType, status: this.status})
    



      // this.resetCursor()
      // // this.searchBy = 'fullName'
      // console.log('remove',this.searchBy);
      // this.filter = ''
      // this.page = 1
      // this.$router.push({
      //   query: {
      //     page: this.page,
      //     // filter: ''
      //   },
      //   params:{
      //     searchBy: this.searchBy
      //   }
      // })
      // await this.doFetch({limit: 10,searchBy: this.searchBy, filter: this.filter, accountType: this.$route.params.accountType})
    },
    // removeToggle(){
    //   this.searchByFilter()
    //   // this.statusToggle = false
    // },
    async statusToggleFetch(){
      this.resetCursor()

        this.page = 1
        let query = {}
        query['page'] = this.page
        if(this.filter != ''){
          query['filter'] = this.filter
        }
        
        let params = {}  
        params['searchBy'] = this.searchBy
        


        if(this.statusToggle){          
          this.status = 'disabled'
          query['status'] = this.status     
        }
        else{
          this.status = ''
        }
        this.$router.push({
              params,
              query
            })  
        
        
        await this.doFetch({limit: 10, searchBy: this.searchBy, filter: this.filter, status: this.status, accountType: this.$route.params.accountType})
        // must add
        this.rowsOld = this.rows
    },
    // removeStatusToggle(){
    //   this.removeTogglePointer = !this.removeTogglePointer
    // },
    presenter(row, fieldName) {
      const val = JobModel.presenter(row, fieldName);
      return val ? val : 'ـــــــ';
    },
    presenterEnum(row, fieldName) {
      const options = this.fields[fieldName].options;
      const val = options.filter(el => el.value == row[fieldName])
      return val.length ? val[0].label : 'ـــــــ';
    },
    presenterMap(row, fieldName) {
      const val = JobModel.presenter(row, fieldName);
      return val[this.language] ? val[this.language] : null
    },
    presenterDate(row, fieldName) {
      const val = JobModel.presenter(row, fieldName)
      return val ? moment(val).locale(this.language).format("D MMM, YYYY") : 'ـــــــ'; 
    },
    presenterDay(row, fieldName) {
      return moment(JobModel.presenter(row, fieldName)).locale(this.language).format("dddd");
    },
    presenterTime(row, fieldName) {
      let now = moment().format('YYYY/MM/DD');
      let time = JobModel.presenter(row, fieldName)
      let date =  `${now} ${time}`;
      return moment(date).locale(this.language).format("hh:mm a"); 
    },
    onModalOpen(row, operation) {
      this.selectedRow = row;
      this.selectedId = row.id;
      switch (operation) {
        case 'delete':
          this.dialogType = 'delete'
          break;
        case 'edit':
          this.dialogType = 'edit'
          break;
        case 'view':
          this.dialogType = 'view'
          break;  
        default:
          this.dialogType = undefined
          break;
      }
      this.modalVisible = true
      this.dialogVisible = true
    },
    onModalClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.modalVisible = false;
        this.dialogType = undefined
      }, 500);
    },
    onModalSuccess() {
      this.onModalClose();
    },
    closeDialog(){
      this.dialogVisible = false;
      this.dialogType = ''
    },
    openDialog(row, operation){
      this.selectedRow = row;
      this.selectedId = row.id;
      switch (operation) {
        case 'delete':
          this.dialogType = 'delete'
          break;
        case 'edit':
          this.dialogVisible = true
          this.modalVisible = true
          break;
        default:
          break;
      }
      this.dialogVisible = true
    },
    async doDestroyWithConfirm(id) {
      try {
        await this.doDisableAllSelected([id])
        
        this.onModalClose()
      } catch (error) {
        // no
      }
    },
    async goToUser(id){
      // if(this.user == 'user')
      if(this.accountTypeLayout == "client" || this.$route.params.accountType == "client"){
        if(this.$route.path != `/iam/client/view/${id}`)
        // this.$router.push(`/iam/client/${id}`)
        routerAsync().push(`/${this.language}/iam/client/view/${id}`);
      }
      
      if(this.accountTypeLayout == "admin" || this.$route.params.accountType == "admin"){
        if(this.$route.path != `/${this.language}/iam/admin/view/${id}`)
        // this.$router.push(`/${this.language}/iam/admin/${id}`)
        routerAsync().push(`/${this.language}/iam/admin/view/${id}`);

      }
      // this.$router.push(`/iam/admin/${id}`)
 
    },
    async doEnableUser(id){
      try{
        await this.doEnableAllSelected([id])
      } catch{
        // 
      }
    },

    async navigateBackwardFroward() {
      
      if (this.$route.name == "iam") {

      this.page = this.$route.query.page && this.isNumeric(this.$route.query.page) ?  parseInt(this.$route.query.page) : 1
      if(this.$route.query.status == 'disabled'){
        this.status = 'disabled'
        this.statusToggle = true
      }  
      else{
        this.status = ''
        this.statusToggle = false
      }

      this.searchBy = this.$route.params.searchBy && this.searchByOptions.includes(this.$route.params.searchBy) ? this.$route.params.searchBy : 'fullName'
      this.filter = this.$route.query.filter ? this.$route.query.filter : ''

        this.resetCursor();
        if (this.$route.params.searchBy &&
          this.searchByOptions.includes(this.$route.params.searchBy)) {
            
            if(!this.$route.query.page || !this.isNumeric(this.$route.query.page)){
              
              if(this.$route.query.filter){
                this.$router.push({
                  query: {
                    page: 1,
                    filter: this.$route.query.filter              
                  }
                })
              }
              else{
                this.$router.push({
                  query: {
                    page: 1,              
                  }
                })
              }
            }
            
          await this.doFetchFromRoute({page: this.page, limit: 10, searchBy: this.searchBy, filter: this.filter, status: this.status, accountType: this.$route.params.accountType})
        } else {
            this.$router.push({
              params: {
                searchBy: 'fullName'
              },
              query: {
                page: 1,
              }
            })
            this.page = 1
            await this.doFetch({
              limit: 10, searchBy: 'fullName', filter: '', status: this.status, accountType: this.$route.params.accountType
            });
        }
        this.rowsOld = this.rows;
      } 
    },
 
  },
  // created(){
  //   this.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1
  //   if(this.$route.params.searchBy){
  //     this.searchBy = this.$route.params.searchBy
  //     if(this.searchBy == 'disabled'){
  //       this.statusToggle = true
  //       this.removeTogglePointer = !this.removeTogglePointer
  //     }
  //   }
  //   if(this.$route.query.filter){
  //     this.filter = this.$route.query.filter
  //   }
   
  // }

  async created(){
    this.accountType = this.$route.params.accountType
    this.resetCursor()

    //#region [ ======== to set the searchBy, filter, page variables ======== ]
    this.page = this.$route.query.page && this.isNumeric(this.$route.query.page) ?  parseInt(this.$route.query.page) : 1
    if(this.$route.query.status == 'disabled'){
      this.status = 'disabled'
      this.statusToggle = true
    }
    this.searchBy = this.$route.params.searchBy && this.searchByOptions.includes(this.$route.params.searchBy) ? this.$route.params.searchBy : 'fullName'
    this.filter = this.$route.query.filter ? this.$route.query.filter : ''
    //#endregion

    if(this.$route.params.searchBy && this.searchByOptions.includes(this.$route.params.searchBy)){
          if(!this.$route.query.page || !this.isNumeric(this.$route.query.page)){
            if(this.$route.query.filter){
              this.$router.push({
                query: {
                  page: 1,
                  filter: this.$route.query.filter              
                }
              })
            }
            else{
              this.$router.push({
                query: {
                  page: 1,              
                }
              })
            }
          }     
          
          await this.doFetchFromRoute({page: this.page, limit: 10, searchBy: this.searchBy, filter: this.filter, accountType: this.accountType, status: this.status})
          
      }
      else{
        this.$router.push({
          params: {
            searchBy: 'fullName'
          },
          query: {
            page: 1,
          }
        })
        await this.doFetch({limit: 10,searchBy: 'fullName',filter:'', status: this.status})
      }
      this.rowsOld = this.rows;

  }
  
};
