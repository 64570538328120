import { IamService } from '@/modules/iam/iam-service';
import iamListExporterFields from '@/modules/iam/iam-list-exporter-fields';
import Message from '@/shared/message/message';
import { i18n } from '@/i18n';
import Errors from '@/shared/error/errors';
import Exporter from '@/shared/exporter/exporter';
import { firestoreAction,vuexfireMutations } from 'vuexfire'
import firebase from 'firebase/app';

const INITIAL_PAGE_SIZE = 10;

export default {
  namespaced: true,

  state: {
    rows: [],
    rowsFromRoute: [],

    // //#region  vuexfire variables
    loadMoreLastRecord: null,
    loadFirstRecord: null,
    recordAfterLast: [],
    recordBeforeFirst: [],
    totalRecords: null,
    pagesNumber: null,
    pageCount: 0,
    mountedTable: false,
    // //#endregion

    count: 0,
    loading: false,
    filter: {},
    pagination: {},
    sorter: {},

    table: null,
  },

  getters: {
    loading: (state) => state.loading,

    exportLoading: (state) => state.exportLoading,

    rows: (state) => state.rows || [],
    rowsFromRoute: (state) => state.rowsFromRoute,
    // //#region vuexfire getters
    mountedTable: (state) => state.mountedTable,
    loadMoreLastRecord: (state) => {
      return state.loadMoreLastRecord
    },
    loadFirstRecord: (state) =>{
      return state.loadFirstRecord
    },
    recordAfterLast: (state) => state.recordAfterLast,
    recordBeforeFirst: (state) => state.recordBeforeFirst,
    totalRecords: (state) => state.totalRecords,
    pagesNumber: (state) => state.pagesNumber,
    pageCount: (state) => state.pageCount,   
    // //#endregion

    count: (state) => state.count,

    hasRows: (state, getters) => getters.count > 0,

    orderBy: (state) => {
      const sorter = state.sorter;

      if (!sorter) {
        return null;
      }

      if (!sorter.prop) {
        return null;
      }

      let direction =
        sorter.order === 'descending' ? 'DESC' : 'ASC';

      return `${sorter.prop}_${direction}`;
    },

    filter: (state) => state.filter,

    limit: (state) => {
      const pagination = state.pagination;

      if (!pagination || !pagination.pageSize) {
        return INITIAL_PAGE_SIZE;
      }

      return pagination.pageSize;
    },

    offset: (state) => {
      const pagination = state.pagination;

      if (!pagination || !pagination.pageSize) {
        return 0;
      }

      const currentPage = pagination.currentPage || 1;

      return (currentPage - 1) * pagination.pageSize;
    },

    pagination: (state, getters) => {
      return {
        ...state.pagination,
        total: getters.count,
        showSizeChanger: true,
      };
    },

    selectedRows: (state) => {
      return state.table ? state.table.selection : [];
    },
  },

  mutations: {
    RESETED(state) {
      state.rows = [];
      state.count = 0;
      state.loading = false;
      state.filter = { userType: state.filter['userType'] };
      state.pagination = {};
      state.sorter = {};
      if (state.table) {
        state.table.clearSelection();
      }
    },

    UNSELECT_ALL(state) {
      if (state.table) {
        state.table.clearSelection();
      }
    },

    TABLE_MOUNTED(state, payload) {
      state.table = payload;
    },

    PAGINATION_CHANGED(state, payload) {
      state.pagination = payload || {};
    },

    PAGINATION_CURRENT_PAGE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: payload || 1,
        pageSize:
          previousPagination.pageSize || INITIAL_PAGE_SIZE,
      };
    },

    PAGINATION_PAGE_SIZE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: previousPagination.currentPage || 1,
        pageSize: payload || INITIAL_PAGE_SIZE,
      };
    },

    SORTER_CHANGED(state, payload) {
      state.sorter = payload || {};
    },

    FETCH_STARTED(state) {
      state.loading = true;

      // if (state.table) {
      //   state.table.clearSelection();
      // }

      // state.filter =
      //   payload && payload.filter ? payload.filter : {};
      // state.pagination =
      //   payload && payload.keepPagination
      //     ? state.pagination
      //     : {};
    },
    FETCH_STARTED_IN_BACKGROUND(state, payload) {
      state.backgroundLoading = true;
      if (state.table) {
        state.table.clearSelection();
      }

      state.filter =
        payload && payload.filter ? payload.filter : {};
      state.pagination =
        payload && payload.keepPagination
          ? state.pagination
          : {};
    },
    FETCH_SUCCESS(state) {
      state.loading = false;
      // state.rows = payload.rows;
      // state.count = payload.count;
    },

    FETCH_ERROR(state) {
      state.loading = false;
      // state.rows = [];
      // state.count = 0;
    },

    EXPORT_STARTED(state) {
      state.exportLoading = true;
    },

    EXPORT_SUCCESS(state) {
      state.exportLoading = false;
    },

    EXPORT_ERROR(state) {
      state.exportLoading = false;
    },

    REMOVE_ALL_SELECTED_STARTED(state) {
      state.loading = true;
    },

    REMOVE_ALL_SELECTED_ERROR(state) {
      state.loading = false;
    },

    REMOVE_ALL_SELECTED_SUCCESS(state) {
      if (state.table) {
        state.table.clearSelection();
      }
    },

    CHANGE_STATUS_SELECTED_STARTED(state) {
      state.loading = true;
    },

    CHANGE_STATUS_SELECTED_ERROR(state) {
      state.loading = false;
    },

    CHANGE_STATUS_SELECTED_SUCCESS(state) {
      state.loading = false
      // if (state.table) {
      //   state.table.clearSelection();
      // }
    },


    // //#region VuexfireMutations
    ...vuexfireMutations,
    SET_MOUNTEDTABLE(state,payload){
      state.mountedTable = payload
    },
    SET_LOADMORE_LAST(state) {
      // state.rows.pop()
      // console.log('state.rows',state.rows.pop());
      state.loadMoreLastRecord = state.rows[state.rows.length - 1]._doc
      state.recordAfterLast = state.rows[state.rows.length - 1]
    },
    SET_LOADFIRSTRECORD(state){
      state.loadFirstRecord = state.rows[0]._doc
    },
    RESET_CURSOR(state){
      state.loadMoreLastRecord = null
      state.loadFirstRecord = null
    },
    REMOVELASTELEMENT(state){
      state.rows.pop()
    },
    SET_TOTAL_RECORDS(state,payload){
      state.totalRecords = payload
      state.pagesNumber = Math.ceil(state.totalRecords / 10)
    },
    SET_PAGE_COUNT(state,payload){
      state.pageCount = payload
    },
    SET_ROWS(state, payload){
      state.rows = payload
      
    },
    // //#endregion
  },

  actions: {
    doUnselectAll({ commit }) {
      commit('UNSELECT_ALL');
    },

    doMountTable({ commit }, table) {
      commit('TABLE_MOUNTED', table);
    },

    async doReset({ commit, dispatch, getters }) {
      commit('RESETED');
      return dispatch('doFetch', { filter: getters.filter });
    },

    async doExport({ commit, getters }) {
      try {
        if (!iamListExporterFields || !iamListExporterFields.length) {
          throw new Error('iamListExporterFields is required');
        }

        commit('EXPORT_STARTED');

        const filter = getters.filter;

        const response = await IamService.fetchUsers(
          filter,
          getters.orderBy,
          null,
          null,
        );

        new Exporter(
          iamListExporterFields,
          i18n('iam.users.exporterFileName'),
        ).transformAndExportAsExcelFile(response.rows);

        commit('EXPORT_SUCCESS');
      } catch (error) {
        Errors.handle(error);

        commit('EXPORT_ERROR');
      }
    },

    doChangePagination(
      { commit, getters, dispatch },
      pagination,
    ) {
      commit('PAGINATION_CHANGED', pagination);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },

    doChangePaginationPageSize(
      { commit, getters, dispatch },
      pageSize,
    ) {
      commit('PAGINATION_PAGE_SIZE_CHANGED', pageSize);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },

    doChangePaginationCurrentPage(
      { commit, getters, dispatch },
      currentPage,
    ) {
      commit(
        'PAGINATION_CURRENT_PAGE_CHANGED',
        currentPage,
      );
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },

    doChangeSort({ commit, getters, dispatch }, sorter) {
      commit('SORTER_CHANGED', sorter);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },


    // //#region vuexfire
     setMountedTable({commit},value){
      commit('SET_MOUNTEDTABLE',value)
    },
    doFetch:  firestoreAction(async ({bindFirestoreRef,getters,commit},payload) => {
      commit('FETCH_STARTED')
      
      const customSerializer = (doc) => {
        const data = doc.data()
        Object.defineProperty(data, '_doc', { value: doc })
        return data
      }
      // const db = firebase.firestore()
      // var userCollection = null

      var db = firebase.firestore().collection('user')
      var userCollection = null
      if(payload.status == 'disabled'){
        
       db = db.where('disabled', '==', true)
      }

      // if(payload.accountType == 'client'){

        switch (payload.searchBy){
          case 'fullName':
            
            userCollection = getters.loadMoreLastRecord ? 
              db.orderBy('fullName','asc')
              .startAt(payload.filter)
              .endAt(payload.filter + "\uf8ff")
              .startAfter(getters.loadMoreLastRecord)
              .where('accountType', '==', payload.accountType)
              .limit(payload.limit || 10) :
              db.orderBy('fullName','asc')              
              .where('accountType', '==', payload.accountType)
              .startAt(payload.filter)
              .endAt(payload.filter+"\uf8ff")
              .limit(payload.limit || 10)
            break
          case 'email':
            userCollection = getters.loadMoreLastRecord ? 
              db.orderBy('email','asc')
              .startAt(payload.filter)
              .endAt(payload.filter + "\uf8ff")
              .startAfter(getters.loadMoreLastRecord)
              .where('accountType', '==', payload.accountType)
              .limit(payload.limit || 10) :
              db.orderBy('email','asc')              
              .where('accountType', '==', payload.accountType)
              .startAt(payload.filter)
              .endAt(payload.filter+"\uf8ff")
              .limit(payload.limit || 10)
            break
          case 'phoneNumber':
            // if(payload.filter != ''){
              userCollection = getters.loadMoreLastRecord ? 
              db.orderBy('phoneNumber','asc')
              .startAt(payload.filter)
              .endAt(payload.filter + "\uf8ff")
              .startAfter(getters.loadMoreLastRecord)
              .where('accountType', '==', payload.accountType)
              .limit(payload.limit || 10) :
              db.orderBy('phoneNumber','asc')
              .startAt(payload.filter)
              .endAt(payload.filter+"\uf8ff")              
              .where('accountType', '==', payload.accountType)
              .limit(payload.limit || 10)
            // }
            // else{
            //   
            //   userCollection = getters.loadMoreLastRecord ? 
            //   db.orderBy('phoneNumber','asc')
            //   .startAfter(getters.loadMoreLastRecord)
            //   .where('accountType', '==', payload.accountType)
            //   .limit(payload.limit || 10) :
            //   db.orderBy('phoneNumber','asc')              
            //   .where('accountType', '==', payload.accountType)
            //   .limit(payload.limit || 10)
            // }
            break
        }
      // }
      //#region 
      // if(payload.accountType == 'admin'){
      //     switch (payload.searchBy){
      //       case 'fullName':
      //         // console.log();
      //         
      //         userCollection = getters.loadMoreLastRecord ? 
      //           db.orderBy('fullName','asc')
      //           .startAt(payload.filter)
      //           .endAt(payload.filter + "\uf8ff")
      //           .startAfter(getters.loadMoreLastRecord)
      //           .where('accountType', '==', 'admin')
      //           .limit(payload.limit || 10) :
      //           db.orderBy('fullName','asc')                
      //           .where('accountType', '==', 'admin')
      //           .startAt(payload.filter)
      //           .endAt(payload.filter+"\uf8ff")
      //           .limit(payload.limit || 10)
      //         break
      //       case 'email':
      //         userCollection = getters.loadMoreLastRecord ? 
      //           db.orderBy('email','asc')
      //           .startAt(payload.filter)
      //           .endAt(payload.filter + "\uf8ff")
      //           .startAfter(getters.loadMoreLastRecord)
      //           .where('accountType', '==', 'admin') 
      //           .limit(payload.limit || 10) :
      //           db.orderBy('email','asc')                
      //           .where('accountType', '==', 'admin')
      //           .startAt(payload.filter)
      //           .endAt(payload.filter+"\uf8ff")
      //           .limit(payload.limit || 10)
      //         break
      //       case 'phoneNumber':
      //         console.log('case phone');
      //         if(payload.filter != ''){
      //           userCollection = getters.loadMoreLastRecord ? 
      //             db.orderBy('phoneNumber','asc')
      //             .startAt(payload.filter)
      //             .endAt(payload.filter + "\uf8ff")
      //             .startAfter(getters.loadMoreLastRecord)
      //             .where('accountType', '==', 'admin')
      //             .limit(payload.limit || 10) :
      //             db.orderBy('phoneNumber','asc')
      //             .where('accountType', '==', 'admin')
      //             .startAt(payload.filter)
      //             .endAt(payload.filter+"\uf8ff")
      //             .limit(payload.limit || 10)
      //           }
      //         else{
      //           userCollection = getters.loadMoreLastRecord ? 
      //             db.orderBy('fullName','asc')
      //             .startAt(payload.filter)
      //             .endAt(payload.filter + "\uf8ff")
      //             .startAfter(getters.loadMoreLastRecord)
      //             .where('accountType', '==', 'admin')
      //             .limit(payload.limit || 10) :
      //             db.orderBy('fullName','asc')                  
      //             .where('accountType', '==', 'admin')
      //             .startAt(payload.filter)
      //             .endAt(payload.filter+"\uf8ff")
      //             .limit(payload.limit || 10)
      //         }
      //       break
      //     }
      // }
      //#endregion
    
      await bindFirestoreRef(
        'rows',
        userCollection,
        { serialize: customSerializer }

      );
      commit('FETCH_SUCCESS')    
    }),
    doFetchFromRoute:  firestoreAction(async ({bindFirestoreRef,commit},payload) => {
      
      commit('FETCH_STARTED')
      
      const customSerializer = (doc) => {
        const data = doc.data()
        Object.defineProperty(data, '_doc', { value: doc })
        return data
      }
      // const db = firebase.firestore()
      // var userCollection = null

      var db = firebase.firestore().collection('user')
      var userCollection = null
      if(payload.status == 'disabled'){
       db = db.where('disabled', '==', true)
      }


      // const startAt = payload.page * payload.limit - payload.limit
      const endAt = payload.page * payload.limit

      const searchOptions = ['fullName', 'email', 'phoneNumber']
      if(searchOptions.includes(payload.searchBy))  {
        // if(payload.filter != ''){
          userCollection =              
            db.orderBy(payload.searchBy,'asc')            
            .where(payload.searchBy, '>=', payload.filter)
            .where(payload.searchBy, '<', payload.filter + "\uf8ff")
            .where('accountType','==', payload.accountType)
            .limit(endAt)    
        // }
        // else{
        //   console.log('payload',payload);
        //   userCollection =              
        //     db.orderBy(payload.searchBy,'asc')
        //     .where('accountType','==', payload.accountType)
        //     .limit(endAt)   
        // }
              
      }

      await bindFirestoreRef(
        'rowsFromRoute',
          userCollection,
        { serialize: customSerializer }

      );
      commit('FETCH_SUCCESS')    
    }),
    setRows({ commit }, payload){
      commit('FETCH_STARTED')
      commit('SET_ROWS',payload)
      commit('FETCH_SUCCESS')
    },
    setLoadMoreLast({commit}){
      commit('SET_LOADMORE_LAST')
    },
    resetCursor({commit}){
      commit('RESET_CURSOR')
    },
    setLoadFirstRecord({commit}){
      commit('SET_LOADFIRSTRECORD')
    },
    removeLastElement({commit}){
      commit('REMOVELASTELEMENT')
    },
    getPreviousRecordsBatch: firestoreAction(async ({bindFirestoreRef,getters,commit},payload) => {
      commit('SET_PAGE_COUNT',getters.pageCount - 1)
      const customSerializer = (doc) => {
        const data = doc.data()
        Object.defineProperty(data, '_doc', { value: doc })
        return data
      }
      // const db = firebase.firestore()
      // var userCollection = null   
      var db = firebase.firestore().collection('user')
      var userCollection = null
      if(payload.status == 'disabled'){
       db = db.where('disabled', '==', true)
      }
        switch (payload.searchBy){
          case 'fullName':
            userCollection = 
              db.orderBy('fullName','asc')
              .startAt(payload.filter)
              .endAt(payload.filter + "\uf8ff")
              .endBefore(getters.loadFirstRecord)
              .limitToLast(payload.limit || 10)
              .where('accountType', '==', payload.accountType) 
            break
          case 'email':
            userCollection = 
              db.orderBy('email','asc')
              .startAt(payload.filter)
              .endAt(payload.filter + "\uf8ff")
              .endBefore(getters.loadFirstRecord)
              .limitToLast(payload.limit || 10)
              .where('accountType', '==', payload.accountType) 
            break
          case 'phoneNumber':            
            // if(payload.filter !=''){
              userCollection = 
                db.orderBy('phoneNumber','asc')
                .startAt(payload.filter)
                .endAt(payload.filter + "\uf8ff")
                .endBefore(getters.loadFirstRecord)
                .limitToLast(payload.limit || 10)
                .where('accountType', '==', payload.accountType) 
            // }
            // else{
            //   console.log('phoneNumber',payload);
            //   console.log(getters.loadFirstRecord);
            //   userCollection = db.collection('user')
            //     .orderBy('phoneNumber','asc')
            //     .endBefore(getters.loadFirstRecord)
            //     .limitToLast(payload.limit || 10)
            //     .where('accountType', '==', payload.accountType) 
            // }            
            break
        }
        // }


        await bindFirestoreRef(
          'rows',
          userCollection
          ,
          { serialize: customSerializer }
        )
    }),
    setDisableNext: firestoreAction(async ({getters, bindFirestoreRef}, payload) => {
      // const db = firebase.firestore()
      // var userCollection = null

      var db = firebase.firestore().collection('user')
      var userCollection = null
      
      if(payload.status == 'disabled'){
       db = db.where('disabled', '==', true)
      }

      // if(payload.accountType == 'client'){
          switch (payload.searchBy){
            case 'fullName':
              userCollection = 
                db.orderBy('fullName','asc')
                .startAt(payload.filter)
                .endAt(payload.filter + "\uf8ff")
                .startAfter(getters.loadMoreLastRecord)
                .where('accountType', '==', payload.accountType)
                .limit(1)
            break
            case 'email':
              // console.log('email',payload.filter);
              userCollection = 
                db.orderBy('email','asc')
                .startAt(payload.filter)
                .endAt(payload.filter + "\uf8ff")
                .startAfter(getters.loadMoreLastRecord) 
                .where('accountType', '==', payload.accountType)
                .limit(1)
              // console.log('recordAfterLast store',getters.recordAfterLast);
              break
            case 'phoneNumber':
              // console.log('phoneNumber');
            
              // if(payload.filter != ''){
                userCollection = 
                  db.orderBy('phoneNumber','asc')
                  .startAt(payload.filter)
                  .endAt(payload.filter + "\uf8ff")
                  .startAfter(getters.loadMoreLastRecord) 
                  .where('accountType', '==', payload.accountType)
                  .limit(1)
              // }
              // else {
              //   userCollection = 
              //     db.orderBy('fullName','asc')
              //     .startAt(payload.filter)
              //     .endAt(payload.filter + "\uf8ff")
              //     .startAfter(getters.loadMoreLastRecord)
              //     .where('accountType', '==', payload.accountType)
              //     .limit(1)
              // }
              // console.log('recordAfterLast store',getters.recordAfterLast);
              break
          }  
      await bindFirestoreRef(
          'recordAfterLast',
          userCollection
          // ,
          // { serialize: customSerializer }
        )
      

    }),
    setDisablePrevious: firestoreAction(async ({getters, bindFirestoreRef}, payload) => {
      // const db = firebase.firestore()
      // var userCollection = null

      var db = firebase.firestore().collection('user')
      var userCollection = null
      
      if(payload.status == 'disabled'){
       db = db.where('disabled', '==', true)
      }

      // if(payload.accountType == 'client'){
          switch (payload.searchBy){
            case 'fullName':
              userCollection = 
                db.orderBy('fullName','asc')
                .startAt(payload.filter)
                .endAt(payload.filter + "\uf8ff")
                .endBefore(getters.loadFirstRecord)
                .limitToLast(1) 
                .where('accountType', '==', payload.accountType)
              break
            case 'email':
              userCollection = 
                db.orderBy('email','asc')
                .startAt(payload.filter)
                .endAt(payload.filter + "\uf8ff")
                .endBefore(getters.loadFirstRecord)
                .limitToLast(1) 
                .where('accountType', '==', payload.accountType)
              break
            case 'phoneNumber':
              // if(payload.filter != ''){
                userCollection = 
                db.orderBy('phoneNumber','asc')
                  .startAt(payload.filter)
                  .endAt(payload.filter + "\uf8ff")
                  .endBefore(getters.loadFirstRecord)
                  .limitToLast(1) 
                  .where('accountType', '==', payload.accountType)
              // }
              // else{
              //   userCollection = 
              //   db.orderBy('fullName','asc')
              //   .startAt(payload.filter)
              //   .endAt(payload.filter + "\uf8ff")
              //   .endBefore(getters.loadFirstRecord)
              //   .limitToLast(1) 
              //   .where('accountType', '==', payload.accountType)
              // }              
              break

          }         
      // }

      await bindFirestoreRef(
          'recordBeforeFirst',
          userCollection
          // ,
          // { serialize: customSerializer }
        )
      // console.log('recordBeforeFirst',getters.recordBeforeFirst);

    }),
  // //#endregion

    async doFetchInBackground(
      { commit, getters },
      { filter, keepPagination } = {},
    ) {
      try {
        commit('FETCH_STARTED_IN_BACKGROUND', { filter, keepPagination });

        const response = await IamService.fetchUsers(
          filter,
          getters.orderBy,
          // getters.limit,
          // getters.offset,
        );

        commit('FETCH_SUCCESS', {
          rows: response.rows,
          count: response.count,
        });
      } catch (error) {
        Errors.handle(error);
        commit('FETCH_ERROR');
      }
    },


    async doDisableAllSelected({
      
      commit,
      
    },[ids]) {
      try {
        // const selectedRows = getters.selectedRows;

        commit('CHANGE_STATUS_SELECTED_STARTED');
        await IamService.disable(ids);
        // await IamService.disable(
        //   selectedRows.map((user) => user.id),
        // );

        commit('CHANGE_STATUS_SELECTED_SUCCESS');

        Message.success(i18n('iam.doDisableAllSuccess'));

        // const filter = getters.filter;
        // dispatch('doFetch', { filter });
      } catch (error) {
        Errors.handle(error);

        commit('CHANGE_STATUS_SELECTED_ERROR');

        // const filter = getters.filter;
        // dispatch('doFetch', { filter });
      }
    },
    
    async doEnableAllSelected({
      commit,
    },[ids]) {
      try {
        // const selectedRows = getters.selectedRows;

        commit('CHANGE_STATUS_SELECTED_STARTED');
        await IamService.enable(ids);
        // await IamService.enable(
        //   selectedRows.map((user) => user.id),
        // );

        commit('CHANGE_STATUS_SELECTED_SUCCESS');

        Message.success(i18n('iam.doEnableAllSuccess'));

        // const filter = getters.filter;
        // dispatch('doFetch', { filter });
      } catch (error) {
        Errors.handle(error);

        commit('CHANGE_STATUS_SELECTED_ERROR');

        // const filter = getters.filter;
        // dispatch('doFetch', { filter });
      }
    },

    async doRemoveAllSelected({
      commit,
      getters,
      dispatch,
    }) {
      try {
        const selectedRows = getters.selectedRows;

        commit('REMOVE_ALL_SELECTED_STARTED');

        await IamService.remove(
          selectedRows.map((row) => row.email),
          [],
          true,
        );

        dispatch('REMOVE_ALL_SELECTED_SUCCESS');

        Message.success(
          i18n('iam.users.doRemoveAllSelectedSuccess'),
        );

        const filter = getters.filter;
        dispatch('doFetch', { filter });
      } catch (error) {
        Errors.handle(error);

        commit('REMOVE_ALL_SELECTED_ERROR');

        const filter = getters.filter;
        dispatch('doFetch', { filter });
      }
    },
  },
};
