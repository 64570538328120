import Layout from '@/modules/layout/components/layout';

// const DrugIndex = () =>
//   import('@/modules/drug-index/components/drug-index.vue');
const DrugIndexListPage = () =>
  import('@/modules/drugIndex/components/drug-index-list-page.vue');
export default [
    {
      path: '/:lang?',
      exact: true,
      component: Layout,
      children: [
        {
            name: 'drugIndex',
            path: 'drug-index/:versionTab?/:searchBy?',
            component: DrugIndexListPage,
            meta: { auth: true },
        },
      ]
    }
]