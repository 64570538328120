//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from "vuex";
import { i18n, getLanguageCode } from "@/i18n";
import firebase from 'firebase/app';
import 'firebase/auth';        // for authentication
import 'firebase/storage';     // for storage
import 'firebase/database';    // for realtime database
import 'firebase/firestore';   // for cloud firestore
import 'firebase/messaging';   // for cloud messaging
import 'firebase/functions';   // for cloud functions

export default {
  name: "app-layout",

  async created() {
    this.setAccountType(this.$route.params['accountType'])
    // Listening on CurrentUser Is Modified
    if(this.currentUser){    
    this.currentUserListener = await firebase.firestore().collection('user').where('id', '==', this.currentUser.id).onSnapshot(async snap => {
      snap.docChanges().forEach(change => {
        if (change.type === 'modified') {
          this.doRefreshCurrentUser()
        }
      });
    })
    this.setCurrentUserListener(this.currentUserListener)
    }
  },

  data() {
    return {
      drawer: false,
      left: false,
      miniState: false,
      
      language: getLanguageCode(),
      Menu_Top_Height: '120px',
      Menu_bottom_height: '80px',
      barStyle: {
        // right: "2px",
        borderRadius: "9px",
        backgroundColor: "#bf507a",
        width: "9px",
        opacity: 0.2,
      },

      currentUserListener: null
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      collapsed: 'layout/menuCollapsed',
      isMobile: 'layout/isMobile',
    }),
    asideWidth() {
      if (!this.isMobile ) {
        return 260;
      }
      return 200;
    },
    // currentBrowser() {
    //   return localStorage.getItem('browser')
    // }
  },
  methods: {
    ...mapActions({
      doRefreshCurrentUser: 'auth/doRefreshCurrentUser',
      setAccountType:'layout/setAccountType',
      setCurrentUserListener: 'layout/setCurrentUserListener'
    }),
    i18n(key, args) {
      return i18n(key, args);
    },
    toggleMenu() {
      this.drawer = !this.drawer
    },
    drawerClick(e) {
      // if in "mini" state and user
      // click on drawer, we switch it to "normal" mode
      if (this.miniState) {
        this.miniState = false;
        // notice we have registered an event with capture flag;
        // we need to stop further propagation as this click is
        // intended for switching drawer to "normal" mode only
        e.stopPropagation();
      }
    },
  },
};
