//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DrugIndexListTable from '@/modules/drugIndex/components/drug-index-list-table.vue';
import DrugIndexUpload from '@/modules/drugIndex/components/drug-index-upload.vue';
import UploadNewVersionModal from '@/modules/drugIndex/components/upload-new-version-modal.vue';
import ClearItemsModal from '@/modules/drugIndex/components/clear-items-modal.vue';

import { mapGetters, mapActions } from 'vuex';
import { JobPermissions } from '@/modules/job/job-permissions';
import { DrugIndexModel } from '@/modules/drugIndex/drugIndex-model';
const { fields } = DrugIndexModel;
// import { EventBus } from '@/event-bus.js';

export default {
  name: 'app-job-list-page',

  components: {
    [DrugIndexListTable.name]: DrugIndexListTable,
    [DrugIndexUpload.name]: DrugIndexUpload,
    [UploadNewVersionModal.name]: UploadNewVersionModal,
    [ClearItemsModal.name]: ClearItemsModal,
  },

  data() {
    return {
      modalVisible: false,
      dialogType: undefined,
      dialogVisible: false,
      loadingUploader: false,
      // fileId: null,
      filter: '',
      // searchBy: 'drugName',
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      loadingNew:'drugIndex/list/loadingNew',
      selectedRows: 'drugIndex/list/selectedRows',
      destroyLoading: 'drugIndex/destroy/loading', 
      saveLoading: 'drugIndex/form/saveLoading',   

      drugIndexUpdatesSettings: 'drugIndex/list/drugIndexUpdatesSettings',
      currentDrugIndexVersion: 'drugIndex/list/drugIndexVersion',
      fileUploaded: 'drugIndex/list/fileUploaded',
      publishLoading: 'drugIndex/form/publishLoading',
      startPublish: 'drugIndex/list/startPublish',
    }),
    fields() {
      return fields;
    },
    hasPermissionToCreate() {
      return new JobPermissions(this.currentUser).create;
    },
    drugIndexNewUploaded() {
      return this.drugIndexUpdatesSettings && this.drugIndexUpdatesSettings.status == "uploaded"
    },
  },
  async created() {
    this.runListenerForCurrentVersion()
    this.doFetchDrugIndexUpdatesSettings()
    //#region [ ======== to set the searchBy, filter, page variables ======== ]
    this.page = this.$route.query.page && this.isNumeric(this.$route.query.page) && this.$route.query.page > 0 ? parseInt(this.$route.query.page) : 1
    this.searchBy = 'drugName'
    this.filter = this.$route.query.filter ? this.$route.query.filter : '' 
    //#endregion

      this.$router.push({
        params: {
          searchBy: 'drugName',
        },
        query: {
          page: this.page,
        }
      })
    this.changeCurrentPage(this.page)
      if (!this.$route.query.page || !this.isNumeric(this.$route.query.page || this.$route.query.page <= 0)) {
        if (this.$route.query.filter) {
          this.$router.push({
            query: {
              page: 1,
              filter: this.$route.query.filter              
            }
          })
        } else {
          this.$router.push({
            query: {
              page: 1,              
            }
          })
        }
      // await this.doFetchFromRoute({page: this.page, limit: 10, searchBy: this.searchBy, filter: this.filter, versionTab: this.versionTab})
    } 
  },
  async mounted() {
    var that = this;
    window.onpopstate = async () => {
      that.navigateBackwardFroward();
    };
  },
  async destroyed() {
    this.resetCursor()
  },
  methods: {
    ...mapActions({
      doFetch: 'drugIndex/list/doFetch',
      doFetchFromRoute: 'drugIndex/list/doFetchFromRoute',
      resetCursor: 'drugIndex/list/resetCursor',
      doFetchDrugIndexUpdatesSettings: 'drugIndex/list/doFetchDrugIndexUpdatesSettings',
      removeFromSelectedRows: 'drugIndex/list/removeFromSelectedRows',
      doClearAllRecordsFromAlgoliaIndex: 'drugIndex/destroy/doClearAllRecordsFromAlgoliaIndex',

      doDestroyAll: 'drugIndex/destroy/doDestroyAll',
      doDeleteFileFromFirebaseStorge: 'drugIndex/destroy/doRemoveUploadedFileFromStorage',
      runListenerForCurrentVersion: 'drugIndex/list/runListenerForCurrentVersion',
      doPublish: 'drugIndex/form/doPublish',
      changeCurrentPage: 'drugIndex/list/changeCurrentPage',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    isNumeric(value) {
      return /^\d+$/.test(value);
    },
    uploaderLoading(loading) {
      this.loadingUploader = loading
    },
    doOpenModal(operation) {
      switch (operation) {
        case 'delete':
          this.dialogType = 'delete'
          break;
        case 'edit':
          this.dialogType = 'edit'
          break;
        case 'view':
          this.dialogType = 'view'
          break;  
        case 'upload':
          this.dialogType = 'upload'
          break;
        case 'clear':
          this.dialogType = 'clear'
          break;  
        default:
          this.dialogType = undefined
          break;
      }
      this.modalVisible = true
      this.dialogVisible = true
    },
    onModalClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.modalVisible = false;
        this.dialogType = undefined
      }, 200);
    },
    onModalSuccess() {
      this.onModalClose();
    },
    async doDiscardUploadedFile() {
      await this.doDeleteFileFromFirebaseStorge()
    },
    async doClearAllData() {
      await this.doClearAllRecordsFromAlgoliaIndex()
      this.onModalClose()
    },
    async doDestroyWithConfirm() {
      await this.doDestroyAll({ ids: this.selectedRows, collectionName:'drugIndexUpdates' })
      this.selectedRows.forEach((id) => {
        this.removeFromSelectedRows(id)
      })
      this.onModalClose()
    },
    async navigateBackwardFroward() {
      if (this.$route.name == "drugIndex") {
        this.page = this.$route.query.page && this.$route.query.page > 0 && this.isNumeric(this.$route.query.page) ? parseInt(this.$route.query.page) : 1      
        this.filter = this.$route.query.filter ? this.$route.query.filter : ''
        if (this.$route.params.searchBy && this.$route.params.searchBy =='title') {
          this.searchBy = this.$route.params.searchBy
        }

        this.resetCursor();

      if (this.$route.params.versionTab && this.versionTabOptions.includes(this.$route.params.versionTab) && this.$route.params.searchBy && this.$route.params.searchBy == "drugName") {
        if (!this.$route.query.page || !this.isNumeric(this.$route.query.page || this.$route.query.page <= 0)) {
            if (this.$route.query.filter) {
              this.$router.push({
                query: {
                  page: 1,
                  filter: this.$route.query.filter              
                }
              })
            }
            else{
              this.$router.push({
                query: {
                  page: 1,              
                }
              })
            }
          }     
          await this.doFetchFromRoute({page: this.page, limit: 10, searchBy: this.searchBy, filter: this.filter, versionTab: this.versionTab})
      }
      else{
        this.$router.push({
          params: {
            searchBy: 'drugName',
            versionTab: this.versionTab
          },
          query: {
            page: 1,
          }
        })
        await this.doFetch({limit:10, searchBy: 'drugName', filter: this.filter, versionTab: this.versionTab})
      }
      } 
    },
  },
};
