import { render, staticRenderFns } from "./drug-dialog.vue?vue&type=template&id=bcef4890&"
import script from "./drug-dialog.vue?vue&type=script&lang=js&"
export * from "./drug-dialog.vue?vue&type=script&lang=js&"
import style0 from "./drug-dialog.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QDialog,QCard,QIcon,QCardSection} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QDialog,QCard,QIcon,QCardSection})
