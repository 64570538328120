//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { EXCEL_EXTENSION } from '@/shared/excel/excel';
import { EXCEL_TYPE } from '@/shared/excel/excel';
// import {mapGetters} from 'vuex';
export default {
  name: 'app-importer-form',
  props: ['storePath','uploadIcon'],
  data(){
    return{
      fileName:'',
    }
  },

  computed: {

    errorMessage() {
      return this.$store.getters[
        `${this.storePath}/errorMessage`
      ];
    },

    accept() {
      return `${EXCEL_TYPE}, ${EXCEL_EXTENSION}`;
    },
  },
  // watch:{
  //   fileName(value){
  //     if(!value){

  //       this.$emit('noFileSelected')
  //     }
  //     else{

  //       this.$emit('fileSelected')
  //     }
  //   }
  // },
  methods: {
    onChange(file) {
    
      

      if (file) {
        this.fileName = file.name
    
        this.$store.dispatch(
          `${this.storePath}/doReadFile`,
          file.raw,
        );
        // this.$emit('fileSelected')
      }
      else{
        // this.$emit('noFileSelected')
      }
    },
  },
};
