//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { I18nUtil } from '@/shared/i18n/i18n-util';
import { getLanguages, getLanguageCode } from '@/i18n';

export default {
  name: 'app-i18n-toggle',

  data() {
    return {
      lang: getLanguageCode(),
    };
  },

  computed: {
    languages() {
      return getLanguages();
    },
    isRTL() {
      return this.lang == 'ar'
    },
  },

  methods: {
    doChangeLanguage(language) {
      
      if(language != localStorage.getItem('language')) {
        I18nUtil.doChangeLanguage(language);
      }
    },
  },
};
