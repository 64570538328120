import { JobService } from '@/modules/job/job-service';
import Errors from '@/shared/error/errors';
// import { routerAsync } from '@/app-module';
import Message from '@/shared/message/message';
import { i18n } from '@/i18n';

export default {
  namespaced: true,

  state: {
    loading: false,
  },

  getters: {
    loading: (state) => !!state.loading,
  },

  mutations: {
    DESTROY_ALL_STARTED(state) {
      state.loading = true;
    },

    DESTROY_ALL_SUCCESS(state) {
      state.loading = false;
    },

    DESTROY_ALL_ERROR(state) {
      state.loading = false;
    },

    DESTROY_STARTED(state) {
      state.loading = true;
    },

    DESTROY_SUCCESS(state) {
      state.loading = false;
    },

    DESTROY_ERROR(state) {
      state.loading = false;
    },
  },

  actions: {
    async doDestroy({ commit }, id) {
      // { commit, dispatch, rootGetters }
      try {
        // commit('DESTROY_STARTED');
        // const docs = await JobService.jobCanBeDeleted(id)
        // if(!docs){
        //   await JobService.destroyAll([id]);
        //   commit('DESTROY_SUCCESS');
        //   Message.success(i18n('entities.job.destroy.success'));
        // }
        // else{
        //   commit('DESTROY_ERROR');
        //   Message.error(i18n('entities.job.destroy.cantBeDeleted'));
        // }

        commit('DESTROY_STARTED');

        await JobService.destroyAll([id]);

        commit('DESTROY_SUCCESS');

        Message.success(i18n('entities.job.destroy.success'));

        // routerAsync().push('/job');

        // dispatch(`${'job/list'}/doFetch`,
        //   rootGetters[`${'job/list'}/filter`], { root: true },
        // );
      } catch (error) {
        Errors.handle(error);
        commit('DESTROY_ERROR');
      }
    },

    async doDestroyAll({ commit, dispatch }, ids) {
      // { commit, dispatch, rootGetters },
      try {
        commit('DESTROY_ALL_STARTED');

        await JobService.destroyAll(ids);

        commit('DESTROY_ALL_SUCCESS');

        dispatch(`${'job/list'}/doUnselectAll`, null, {
          root: true,
        });

        Message.success(i18n('entities.model.destroyAll.success'));

        // routerAsync().push('/job');

        // dispatch(`${'job/list'}/doFetch`,
        //   rootGetters[`${'job/list'}/filter`], { root: true },
        // );
      } catch (error) {
        Errors.handle(error);
        commit('DESTROY_ALL_ERROR');
      }
    },
  },
};
