import { DrugIndexService } from '@/modules/drugIndex/drugIndex-service';

import Errors from '@/shared/error/errors';
// import Message from '@/shared/message/message';
// import { i18n } from '@/i18n';

export default {
  namespaced: true,

  state: {
    loading: false,
  },

  getters: {
    loading: (state) => !!state.loading,
  },

  mutations: {
    DESTROY_ALL_STARTED(state) {
      state.loading = true;
    },

    DESTROY_ALL_SUCCESS(state) {
      state.loading = false;
    },

    DESTROY_ALL_ERROR(state) {
      state.loading = false;
    },

    DESTROY_STARTED(state) {
      state.loading = true;
    },

    DESTROY_SUCCESS(state) {
      state.loading = false;
    },

    DESTROY_ERROR(state) {
      state.loading = false;
    },
  },

  actions: {
    async doDestroyAll({ commit }, {ids,collectionName}) {
      try {
        commit('DESTROY_STARTED');
        await DrugIndexService.destroyAll(ids,collectionName);
        commit('DESTROY_SUCCESS');
        // Message.success(i18n('entities.job.destroy.success'));
      } catch (error) {
        Errors.handle(error);
        commit('DESTROY_ERROR');
      }
    },

    async doClearAllRecordsFromAlgoliaIndex({ commit, dispatch }) {
      try {
        commit('DESTROY_STARTED');
        // await DrugIndexService.deleteAllAlgoliaRecords()
        await DrugIndexService.clearAlgolia()
        await dispatch('drugIndex/list/doFetchFromAlgolia', { root: true })
        commit('DESTROY_SUCCESS');
      } catch(error) {
        Errors.handle(error);
        commit('DESTROY_ERROR');
      }
    },

    async doRemoveUploadedFileFromStorage({ commit }, filePath) {
      try {
        commit('DESTROY_STARTED');
        let file_path= filePath
        if (!file_path) {
          const file = await DrugIndexService.findDataFromRealTimeDatabase(`setting/file-details`)
          if (!file || !file.privateUrl) {
            commit('DESTROY_SUCCESS');
            return
          }
          file_path = file.privateUrl
        }
        await DrugIndexService.deleteFileFromFirebaseStorage(file_path);
        // await DrugIndexService.addDataInRealTimeDatabase(`setting/drugIndexFile`, '');
        await DrugIndexService.addDataInRealTimeDatabase(`setting/file-details`, '');
        await DrugIndexService.settingsUpdate({
          id: "drugIndexUpdates",
          status: "pending",
          drugFileName: null,
          currentNumberOfChunks: 0,
          currentNumberOfDrugs: 0,
          totalNumberOfChunks: 0,
          totalNumberOfDrugs: 0,
        })
        commit('DESTROY_SUCCESS');
      } catch (error) {
        Errors.handle(error);
        commit('DESTROY_ERROR');
      }
    },

    // async doDestroyAll({ commit, dispatch }, ids) {
    //   // { commit, dispatch, rootGetters },
    //   try {
    //     commit('DESTROY_ALL_STARTED');

    //     await DrugIndexService.destroyAll(ids);

    //     commit('DESTROY_ALL_SUCCESS');

    //     dispatch(`${'job/list'}/doUnselectAll`, null, {
    //       root: true,
    //     });

    //     Message.success(i18n('entities.model.destroyAll.success'));

    //     // routerAsync().push('/job');

    //     // dispatch(`${'job/list'}/doFetch`,
    //     //   rootGetters[`${'job/list'}/filter`], { root: true },
    //     // );
    //   } catch (error) {
    //     Errors.handle(error);
    //     commit('DESTROY_ALL_ERROR');
    //   }
    // },
  },
};
