//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import HomeCardCounter from '@/modules/home/components/home-card-counter.vue';
import { mapGetters } from 'vuex';
// import { i18n} from '@/i18n';
import i18n from '@/vueI18n'
// import firebase from 'firebase';
import firebase from 'firebase/app';
import 'firebase/auth';        // for authentication
import 'firebase/storage';     // for storage
import 'firebase/database';    // for realtime database
import 'firebase/firestore';   // for cloud firestore
import 'firebase/messaging';   // for cloud messaging
import 'firebase/functions';   // for cloud functions
export default {
  // name: 'app-home-item-summary',
  name: 'app-home-list-cards',
  components: {
    [HomeCardCounter.name]: HomeCardCounter,
  },
 
  
  async created() {
    if(this.currentUser) {
      firebase.database().ref('stats/prescriptionsCount').on('value',(snapshot) => {
        this.prescriptionsCount = snapshot.val()
        this.prescriptionsLoading = false
        // this.jobsLoading = false
        // realtimeValue = snapshot.val()
      });
      firebase.database().ref('stats/jobsCount').on('value',(snapshot) => {
        this.jobsCount = snapshot.val()
        this.jobsLoading = false
        // realtimeValue = snapshot.val()
      });
      
      firebase.database().ref('stats/offersCount').on('value',(snapshot) => {
        this.offersCount = snapshot.val()
        this.offersLoading = false
        // realtimeValue = snapshot.val()
      });
      firebase.database().ref('stats/visitsCount').on('value',(snapshot) => {
        this.visitsCount = snapshot.val()
        this.visitsLoading = false
        // this.offersCount = snapshot.val()
        // this.offersLoading = false
        // realtimeValue = snapshot.val()
      });
      firebase.database().ref('stats/clientsCount').on('value',(snapshot) => {
        this.registersCount = snapshot.val()
        this.registersLoading = false
        // this.offersCount = snapshot.val()
        // this.offersLoading = false
        // realtimeValue = snapshot.val()
      });
      // firebase.database().ref('setting/drugIndexVersion').on('value',(snapshot) => {
      //   this.drugIndexVersion = snapshot.val()
      //   firebase.database().ref(`stats/drugIndex/${this.drugIndexVersion}/count`).on('value',(snapshot) => {    
      //     this.drugIndexCount = snapshot.val()
      //     this.drugIndexLoading = false
      //   });
      // });
      firebase.database().ref('stats/drugsCount').on('value',(snapshot) => {
        this.drugIndexCount = snapshot.val()
        this.drugIndexLoading = false
        // this.offersCount = snapshot.val()
        // this.offersLoading = false
        // realtimeValue = snapshot.val()
      });
      // firebase.firestore().collection('settings').doc("drugIndexUpdates").onSnapshot(doc => {
      //   this.drugIndexCount = doc.data().totalNumberOfDrugs
      //   this.drugIndexLoading = false
      // })

      // await firebase.database().ref(`stats/drugsIndex/${this.drugIndexVersion}/count`).on('value',(snapshot) => {
      // // console.log('drugs',snapshot.val())
      // // let drugIndexVersions = snapshot.val()
      // // this.drugIndexCount = drugIndexVersions[Object.keys(drugIndexVersions)[Object.keys(drugIndexVersions).length - 1]]
      // this.drugIndexCount = snapshot.val()
      // this.drugIndexLoading = false
      // // this.offersCount = snapshot.val()
      // // this.offersLoading = false
      // // realtimeValue = snapshot.val()
      // });
    }
    // await firebase.firestore().collection('prescription').where('postStatus', '==', 'accepted').onSnapshot(async snap => {
    //   // console.log(`Prescription size ${snap.size}`);
    //   this.prescriptionsCount = snap.size
    //   this.prescriptionsLoading = false
    // })
  },
  data() {
    return {
      // language: getLanguageCode(),
      prescriptionsCount: 0,
      drugIndexCount: 0,
      jobsCount: 0,
      offersCount: 0,
      visitsCount: 0,
      registersCount: 0,
      prescriptionsLoading: true,
      drugIndexLoading: true,
      jobsLoading: true,
      offersLoading: true,
      visitsLoading: true,
      registersLoading: true,
       drugIndexVersion: 0
    };
  },
  computed: {
    prescriptions(){
            return this.i18n('home.prescriptions')
        },
    isRTL() {
      // return getLanguageCode() == 'ar'
      // return localStorage.getItem('language') == 'ar'
      return i18n.locale == 'ar'
    },
    ...mapGetters({
      currentUser: 'auth/currentUser'
      // productCount: 'product/list/count',
      // productLoading: 'product/list/loading',
      // sparePartCount: 'sparePart/list/count',
      // sparePartLoading: 'sparePart/list/loading',
      // accessoryCount: 'accessory/list/count',
      // accessoryLoading: 'accessory/list/loading',
      // importerCount: 'importer/list/count',
      // importerLoading: 'importer/list/loading',
      // exporterCount: 'exporter/list/count',
      // exporterLoading: 'exporter/list/loading',
    }),
    // totalCount() {
    //   return this.productCount + this.sparePartCount + this.accessoryCount + this.importerCount + this.exporterCount
    // },
    // totalLoading() {
    //   return this.productLoading || this.sparePartLoading || this.accessoryLoading || this.importerLoading || this.exporterLoading
    // },
  },
  methods: {
    // onItemClick (m) {
    //    console.log('Clicked on an Item',m.value)
    //   this.selectedMonth = m
    //   this.$emit('setFilterMonth',m.value)
    // },
    // i18n(label) {
    //   return i18n(label);
    // },
    i18n(key, args) {
      return this.$t(key, args);
    },
  }
};
