//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { FileUploader } from '@/shared/firebase/file-uploader';
import Errors from '@/shared/error/errors';
// import { mapActions, mapGetters } from 'vuex'
// import { EventBus } from '@/event-bus.js';

export default {
  name: 'app-file-upload',
  props: ['path', 'fileName', 'value', 'schema', 'max', 'triggerRemoveFile'],

  data() {
    return {
      fileList: (this.value || []).map((item) => ({
        ...item,
        url: item.publicUrl,
      })),
      loading: false,
    };
  },
  computed: {
    // ...mapGetters({
    //   drugIndexUpdatesSettings: 'drugIndex/list/drugIndexUpdatesSettings',
    //   loadingNew:'drugIndex/list/loadingNew',
    //   loadingCurrent:'drugIndex/list/loadingCurrent',
    // }),
    // isLoadingTable() {
    //   return this.loadingNew || this.loadingCurrent
    // },
    // isPending() {
    //   return this.drugIndexUpdatesSettings && this.drugIndexUpdatesSettings.status == "Pending"
    // },
    isFull() {
      const hasInputReference = !!this.$refs.files;
      return (this.max && (hasInputReference && this.$refs.files.uploadFiles.length >= this.max)) || (!hasInputReference && (this.value || []).length >= this.max);
    },
    accept() {
      return  this.schema.formats;
      // return '*';
      // return this.schema && this.schema.formats
      //   ? this.schema.formats
      //       .map((format) => `.${format}`)
      //       .join(',')
      //   : undefined;
    },
  },
  methods: {
    // ...mapActions({
    //   // settingsUpdate:'drugIndex/form/settingsUpdate'
    // }),
    async uploadFromRequest(request) {
      this.loading = true;
      this.$emit('loading', this.loading)
      return FileUploader.uploadFromRequest(
        this.path,
        request,
        this.schema,
        this.fileName,
      );
    },

    onSuccess(file) {
      // this.onRemove()
      this.fileList = this.$refs.files.uploadFiles
      if (this.fileList.length > 1) this.fileList.splice(0, 1)

      if (!file) {
        return;
      }

      this.$emit('input', [...(this.value || []), file]);
      this.loading = false;
      this.$emit('loading', this.loading)
    },

    onError(error) {
      Errors.showMessage(error);
      this.loading = false;
    },

    async onRemove(file, files) {
      await FileUploader.removeFile(file) 
      this.$emit('remove', file)
      // this.fileList = null
      // files = null
      this.$emit('input', (this.value || []).filter((item) =>
          files.some((file) =>
            file.response
              ? file.response.id === item.id
              : file.id === item.id,
          ),
        ),
      );
    },

    download(file) {
      window.open(file.url, '_blank');
    },
  },
  watch:{
    triggerRemoveFile(newval) {
      if (newval) {
        this.onRemove()
      }
    }
  },
};
