import Layout from '@/modules/layout/components/layout';

const AdminProfile = () =>
  import('@/modules/admins/components/admin-profile.vue');
const Admins = () =>
  import('@/modules/admins/components/admins.vue');
  export default [
    {
      path: '',
      exact: true,
      component: Layout,
      children: [
        {
          name: '',
          path: 'admins',
          component: Admins,
          exact: true,
          meta: { auth: true },
        },
        {
          name:'admin-profile',
          path:'admins/:id',
          component:AdminProfile,
          exact: true,
          meta: { auth: true }
        },
      ]
    }
  
  ]