//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SettingFormModal from '@/modules/setting/components/setting-form-modal.vue';
import i18n from '@/vueI18n';
export default {
    name:'app-setting-list-card',
    props:['title','formTitle','message','entity'],
    components: {
        [SettingFormModal.name]:SettingFormModal,
    },
    data(){
        return {
            modalVisible: false,
            dialogVisible: false,
        }
    },
    computed:{
       
         isRTL() {
      // return getLanguageCode() == 'ar'
      // return localStorage.getItem('language') == 'ar'
      return i18n.locale == 'ar'
    },
    },
    methods:{
        onModalOpen() {
            setTimeout(() => {
               this.modalVisible = true
            this.dialogVisible = true 
            },100)
            
           
        },
        onModalClose() {
        this.dialogVisible = false;
        setTimeout(() => {
            this.modalVisible = false;
        }, 500);
        //  setTimeout(() => {
        
        // }, 500);
        },
        onModalSuccess() {
            this.onModalClose();
           this.$emit('openSuccess')
            
        // 
        },
    }

}
