import Errors from '@/shared/error/errors';
// import { routerAsync } from '@/app-module';
import { IamService } from '@/modules/iam/iam-service';
import Message from '@/shared/message/message';
import { i18n } from '@/i18n';
import { firestoreAction,vuexfireMutations } from 'vuexfire'
import firebase from 'firebase/app';

export default {
  namespaced: true,

  state: {
    loading: false,
    record: null,
  },

  getters: {
    record: (state) => state.record,
    loading: (state) => !!state.loading,
  },

  mutations: {
    CHANGE_STATUS_STARTED(state) {
      state.loading = true;
    },

    CHANGE_STATUS_ERROR(state) {
      state.loading = false;
    },

    FIND_STARTED(state) {
      state.record = null;
      state.loading = true;
    },

    FIND_SUCCESS(state) {
      state.loading = false;
    },

    FIND_ERROR(state) {
      state.record = null;
      state.loading = false;
    },

    ...vuexfireMutations,

  },

  actions: {
    doFind:  firestoreAction(async ({bindFirestoreRef,commit},payload) => {
      commit('FIND_STARTED');
      const db = firebase.firestore()
      const userCollection = db.collection('user').doc(payload.id)
      await bindFirestoreRef(
        'record',
        userCollection,
        // { serialize: customSerializer }

      );
      commit('FIND_SUCCESS');

    }),

    // async doFind({ commit }, id) {
    //   try {
    //     commit('FIND_STARTED');
    //     console.log('id',id);
    //     // const record = await IamService.find(id);
        
    //     commit('FIND_SUCCESS', record);
    //   } catch (error) {
    //     Errors.handle(error);
    //     commit('FIND_ERROR');
    //     routerAsync().push('/iam');
    //   }
    // },

    async doToggleStatus({ getters, commit, dispatch }) {
      try {
        const record = getters.record;

        commit('CHANGE_STATUS_STARTED');

        const isEnabling = !!record.disabled;

        if (isEnabling) {
          await IamService.enable([record.id]);
        } else {
          await IamService.disable([record.id]);
        }

        if (isEnabling) {
          Message.success(i18n('iam.doEnableSuccess'));
        } else {
          Message.success(i18n('iam.doDisableSuccess'));
        }

        dispatch('doFind', record.id);
      } catch (error) {
        Errors.handle(error);
        commit('CHANGE_STATUS_ERROR');
      }
    },
  },
};
