import { JobService } from '@/modules/job/job-service';
import Errors from '@/shared/error/errors';
import { routerAsync } from '@/app-module';
import { firestoreAction,vuexfireMutations } from 'vuexfire'
import firebase from 'firebase/app';

export default {
  namespaced: true,

  state: {
    loading: false,
    record: null,
    workplaces: null,
    positions: null,
    cities: null
  },

  getters: {
    record: (state) => state.record,
    loading: (state) => !!state.loading,
    workplaces: (state) => state.workplaces,
    positions: (state) => state.positions,
    cities: (state) => state.cities
  },

  mutations: {
    FIND_STARTED(state) {
      state.record = null;
      state.loading = true;
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.loading = false;
    },

    FIND_ERROR(state) {
      state.record = null;
      state.loading = false;
    },
    // #region vuexfire
    ...vuexfireMutations
  },

  actions: {
    async doFind({ commit }, id) {
      try {
        commit('FIND_STARTED');
        const record = await JobService.find(id);
        commit('FIND_SUCCESS', record);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        routerAsync().push('/job');
      }
    },
    doFetchWorkplaces: firestoreAction(async ({bindFirestoreRef}) => {
      const customSerializer = (doc) => {
        // console.log('workplace doc',doc.id);
        const data = doc.data()
        Object.defineProperty(data, 'id', { value: doc.id })
        return data
      }
      const db = firebase.firestore()
      const workplaceCollection = db.collection('workplace')
        await bindFirestoreRef(
            `workplaces`,
            workplaceCollection,
            { serialize: customSerializer })
        // console.log('payload.record.workplaceId',getters.workplaces);
    }),
    doFetchJobPositions: firestoreAction(async ({bindFirestoreRef}) => {
      const customSerializer = (doc) => {
        // console.log('workplace doc',doc.id);
        const data = doc.data()
        Object.defineProperty(data, 'id', { value: doc.id })
        return data
      }
      const db = firebase.firestore()
      const jobPositionCollection = db.collection('jobPosition')
        await bindFirestoreRef(
            `positions`,
            jobPositionCollection,
            { serialize: customSerializer })
        // console.log('positions',getters.positions);
    }),
    doFetchCities: firestoreAction(async ({bindFirestoreRef}) => {
      // const customSerializer = (doc) => {
      //   // console.log('workplace doc',doc.id);
      //   const data = doc.data()
      //   Object.defineProperty(data, 'id', { value: doc.id })
      //   return data
      // }
      const db = firebase.firestore()
      const cityCollection = db.collection('city')
        await bindFirestoreRef(
            `cities`,
            cityCollection,
            // { serialize: customSerializer }
            )
        // console.log('cities',getters.cities)
    })
  },
};
