//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { i18n } from '@/i18n';
import { mapActions, mapGetters } from 'vuex';
// import Message from '@/shared/message/message';


export default {
  name: 'app-delete-item-page',
  props: ['visible', 'id', 'loading', 'storePath', 'icon', 'message', 'service'],

  data() {
    return {
      rejectionReasonAr: null,
      rejectionReasonEn: null,
      showRejectionReasonSection: false
    };
  },
  computed: {
    ...mapGetters({
      // settings:'settings/settings',
      // loading: `${this.storePath}/loading`,
    }),
    dialogVisible: {
      get: function() {
        return this.visible;
      },

      set: function(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },

  methods: {
    ...mapActions({
      // doFindSettings:'settings/doFind',
      // isItemExist:'product/list/isItemExist',
    }),
    i18n(code, args) {
      return i18n(code, args);
    },
    doCancel() {
      this.dialogVisible = false;
    },
    async doSubmitWithRejectionReason(){
        await this.$emit('submit', 
        {
          id: this.id,
          rejectionReason: {
            en: this.rejectionReasonEn,
            ar: this.rejectionReasonAr
          }
        })

      // }
    },
    async doSubmit() {
      // if(this.service == 'job' || this.service == 'property' || this.service == 'prescription'){
      //   this.showRejectionReasonSection = true
      //   await this.$emit('submit', 
      //   {
      //     id: this.id,
      //     rejectionReason: {
      //       en: this.rejectionReasonEn,
      //       ar: this.rejectionReasonAr
      //     }
      //   })

      // }
      // else{
      if(this.service != 'job' && this.service != 'property' && this.service != 'prescription'){      
        await this.$emit('submit', this.id)
      }   
      else{
        this.showRejectionReasonSection = true
      }   
    },
  },
};
