//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import CityListTable from '@/modules/cities/components/city-list-table.vue';
import RegionListTable from '@/modules/cities/components/region-list-table.vue';
import { mapGetters, mapActions } from 'vuex';
import { OfferPermissions } from '@/modules/offer/offer-permissions';
// import firebase from 'firebase';
import firebase from 'firebase/app';
import 'firebase/auth';        // for authentication
import 'firebase/storage';     // for storage
import 'firebase/database';    // for realtime database
import 'firebase/firestore';   // for cloud firestore
import 'firebase/messaging';   // for cloud messaging
import 'firebase/functions';   // for cloud functions
import i18n from '@/vueI18n';
import RegionFormModal from '@/modules/cities/components/region-form-modal.vue';

export default {
  name: 'app-offer-list-page',

  components: {
    [RegionListTable.name]: RegionListTable,
    [RegionFormModal.name]:RegionFormModal,
  },
  data() {
    return {
      isListView: false,
      modalVisible: false,
      dialogVisible: false,
      successDialog: false,
      regionsListener: null
    }
  },
  computed: {
    lang(){
            return i18n.locale
        },
    ...mapGetters({
      rows: 'citiesRegions/list/rows',
      count: 'citiesRegions/list/count',
      loading: 'citiesRegions/list/loading',
      is_screen_xs: 'layout/is_screen_xs',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
    }),
    hasPermissionToCreate() {
      return new OfferPermissions(this.currentUser).create;
    },
  },

  async created() {
      document.title =
                this.i18n('app.title') +
                ' | ' +
                this.i18n('menu.region')
    
    this.regionsListener = await firebase.firestore().collection('city').onSnapshot(async () => {
      this.doFilterInBackground();
      
    });
    this.setRegionsListener(this.regionsListener)
  },
  async mounted() {
    await this.doFilter();
    // this.regionListener()
  },
  watch:{
    // loading(newval,oldval){
    //   
    //   // console.log(watch)
    //   console.log('newvale',newval)
    //   console.log('oldval',oldval)
    // },
    lang(){
      document.title =
                this.i18n('app.title') +
                ' | ' +
                this.i18n('menu.region')
    }
  },
  methods: {
     i18n(key, args) {
      return this.$t(key, args);
    },
    ...mapActions({
      doFetch: 'citiesRegions/list/doFetch',
      doFetchInBackground: 'citiesRegions/list/doFetchInBackground',
      setRegionsListener: 'citiesRegions/list/setRegionsListener'
    }),


    doOpenModal() {
      this.dialogVisible = true;
      this.modalVisible = true;
    },
    onModalClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.modalVisible = false;
      }, 500);
    },
    onModalSuccess() {
      this.successDialog = true
    },
  
    async doFilter() {
      // filterSchema.cast(this.model);
      const filter = {}
      return this.doFetch({
        filter,
      });
    },
    async doFilterInBackground() {
      const filter = {}
      return this.doFetchInBackground({
        filter,
      });
    },
  },

};
