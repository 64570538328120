import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';
// import DecimalRangeField from '@/shared/fields/decimal-range-field';
// import DecimalField from '@/shared/fields/decimal-field';
// import ImageField from '@/shared/fields/image-field';
// import ImagesField from '@/shared/fields/images-field';
import IntegerField from '@/shared/fields/integer-field';
import StringArrayField from '@/shared/fields/string-array-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
// import BooleanField from '@/shared/fields/boolean-field';
import JsonField from '@/shared/fields/json-field';
// import ImagesField from '@/shared/fields/images-field';


function label(name) {
  return i18n(`entities.job.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.job.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  address: new StringField('address', label('address'), { required: true }),
  cityId: new StringField('cityId', label('cityId'), { required: true }),
  description: new StringField('description', label('description'), { required: true }),
  dueDate: new DateTimeField('dueDate', label('dueDate')),
  emailAddress: new StringField('emailAddress', label('emailAddress'), { required: true }),
  name: new StringField('createdBy', label('createdBy'), { required: true }),
  phoneNumber: new StringField('phoneNumber', label('phoneNumber'), { required: true }),
  positionId: new StringField('positionId', label('positionId'), { required: true }),
  status: new EnumeratorField('status', label('status'), [
    { value: 'hiring', label: enumeratorLabel('status', 'hiring') },
    { value: 'hired', label: enumeratorLabel('status', 'hired') },
  ],{ required: true }),
  postStatus: new EnumeratorField('postStatus', label('postStatus'), [
    { value: 'pending', label: enumeratorLabel('postStatus', 'pending') },
    { value: 'accepted', label: enumeratorLabel('postStatus', 'accepted') },
    { value: 'deleted', label: enumeratorLabel('postStatus', 'deleted') },
  ],{ required: true }),
  regionId: new StringField('regionId', label('regionId'), { required: true }),
  salary: new JsonField('title', label('title'), { required: true }),
  views: new IntegerField('views', label('views'), { required: true }),
  viewers: new StringArrayField('viewers', label('viewers'), {}),
  workplaceId: new StringField('workplaceId', label('workplaceId'), { required: true }),

  createdBy: new StringField('createdBy', label('createdBy'), { required: true }),
  updatedBy: new StringField('updatedBy',label('updatedBy'), {}),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt',label('updatedAt')), 
  createdAtRange: new DateTimeRangeField( 'createdAtRange', label('createdAtRange')),
};

export class JobModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
