import { render, staticRenderFns } from "./job-list-page.vue?vue&type=template&id=30c2abad&scoped=true&"
import script from "./job-list-page.vue?vue&type=script&lang=js&"
export * from "./job-list-page.vue?vue&type=script&lang=js&"
import style0 from "./job-list-page.vue?vue&type=style&index=0&id=30c2abad&lang=scss&scoped=true&"
import style1 from "./job-list-page.vue?vue&type=style&index=1&id=30c2abad&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30c2abad",
  null
  
)

export default component.exports
import {QBreadcrumbs,QIcon,QBreadcrumbsEl,QCard} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QBreadcrumbs,QIcon,QBreadcrumbsEl,QCard})
