import drugIndexFormStore from '@/modules/drugIndex/drugIndex-form-store';
import drugIndexDestroyStore from '@/modules/drugIndex/drugIndex-destroy-store';
import drugIndexListStore from '@/modules/drugIndex/drugIndex-list-store';
import drugIndexImporterStore from '@/modules/drugIndex/drugIndex-importer-store';
export default {
  namespaced: true,
  modules: {
    list: drugIndexListStore,
    form: drugIndexFormStore,
    destroy:drugIndexDestroyStore,
    importer: drugIndexImporterStore,

  },
};