//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IamListFilter from '@/modules/iam/components/iam-list-filter.vue';
import IamListTable from '@/modules/iam/components/iam-list-table.vue';
import IamListToolbar from '@/modules/iam/components/iam-list-toolbar.vue';
// import { i18n } from '@/i18n';
import i18n from '@/vueI18n';

import { mapGetters, mapActions } from 'vuex';
// import firebase from 'firebase';
// import firebase from 'firebase/app';
import 'firebase/auth';        // for authentication
import 'firebase/storage';     // for storage
import 'firebase/database';    // for realtime database
import 'firebase/firestore';   // for cloud firestore
import 'firebase/messaging';   // for cloud messaging
import 'firebase/functions';   // for cloud functions
export default {
  name: 'app-iam-list-page',

  components: {
    [IamListFilter.name]: IamListFilter,
    [IamListTable.name]: IamListTable,
    [IamListToolbar.name]: IamListToolbar,
  },

  data() {
    return {
      tab: 'admins',
      accountType: undefined,
      searchByOptions: ['fullName', 'email', 'phoneNumber']
    }
  },
    computed: {
      lang(){
                
            return i18n.locale
        },
    ...mapGetters({
      rows: 'iam/list/rows',
      count: 'iam/list/count',
      loading: 'iam/list/loading',
      is_screen_xs: 'layout/is_screen_xs',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      accountTypeLayout:'layout/accountType'
    }),
    isAdmin(){
      if(this.$route.path.includes('admin'))
        return true
      return false
    }
    },
    watch:{
      $route(to){
        if(to.params.accountType == 'client'){
          document.title =
                    this.i18n('app.title') +
                    ' | ' +
                    this.i18n('menu.user')
        }
        if(to.params.accountType == 'admin'){
          document.title =
                    this.i18n('app.title') +
                    ' | ' +
                    this.i18n('menu.admin')
        }
      },
      lang(){
        if(this.$route.params.accountType == 'client'){
          document.title =
                    this.i18n('app.title') +
                    ' | ' +
                    this.i18n('menu.user')
        }
        if(this.$route.params.accountType == 'admin'){
          document.title =
                    this.i18n('app.title') +
                    ' | ' +
                    this.i18n('menu.admin')
        }
      },
      async accountTypeLayout() {
      // react to route changes...
      
      
      this.resetCursor()
      await this.doFetch({limit: 10,searchBy: 'fullName',filter:'',accountType: this.$route.params.accountType})

    
    }
    },
  methods: {
    i18n(key, args) {
      // return i18n(key, args)
      return this.$t(key, args);

    },
    ...mapActions({
      doFetch: 'iam/list/doFetch',
      doFetchFromRoute: 'iam/list/doFetchFromRoute',
      doFetchInBackground: 'iam/list/doFetchInBackground',
      resetCursor: 'iam/list/resetCursor'
    }),
    async doFilter() {
      // filterSchema.cast(this.model);
      const filter = {
        accountType: this.accountTypeLayout
      }
      return this.doFetch({
        filter,
      });
    },
    async doFilterInBackground() {
      const filter = {
        accountType: this.accountTypeLayout
      }

      return this.doFetchInBackground({
        filter,
      });
    },
  
  },
  created(){
    if(this.$route.params.accountType == 'client'){
      document.title =
                this.i18n('app.title') +
                ' | ' +
                this.i18n('menu.user')
    }
    if(this.$route.params.accountType == 'admin'){
      document.title =
                this.i18n('app.title') +
                ' | ' +
                this.i18n('menu.admin')
    }
    
  }
  // async mounted() {
  //   this.accountType = this.$route.params.accountType
  // //   console.log('account type mounted',this.$route.params.accountType)
  //   this.resetCursor()
  //   // await this.doFetch({limit: 10,searchBy: 'fullName',filter:'',accountType: this.$route.params.accountType})

  // if(this.$route.params.searchBy && (this.searchByOptions.includes(this.$route.params.searchBy) || this.$route.params.searchBy == 'disabled')){
  //       if(this.$route.query.page){
  //         await this.doFetchFromRoute({ accountType: this.$route.params.accountType, page: this.$route.query.page, limit: 10, searchBy: this.$route.params.searchBy, filter: this.$route.query.filter ? this.$route.query.filter : ''})
  //       }
  //       else{
  //         if(this.$route.query.filter){
  //           this.$router.push({
  //             query: {
  //               page: 1,
  //               filter: this.$route.query.filter
  //             }
  //           })
  //         }
  //         else{
  //           this.$router.push({
  //             query: {
  //               page: 1,
  //             }
  //           })
  //         }
          
  //         await this.doFetchFromRoute({accountType: this.$route.params.accountType, page: 1, limit: 10, searchBy: this.$route.params.searchBy ? this.$route.params.searchBy : 'fullName', filter: this.$route.query.filter ? this.$route.query.filter : ''})
  //       }
  //     }
  //     else{
  //       this.$router.push({
  //         params: {
  //           searchBy: 'fullName'
  //         },
  //         query: {
  //           page: this.$route.params.page ? this.$route.params.page : 1,
  //         }
  //       })
  //       await this.doFetch({limit: 10,searchBy: 'fullName',filter:'',accountType: this.$route.params.accountType})
  //     }
  // },
};
