//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CityListTable from '@/modules/cities/components/city-list-table.vue';
import CityFormModal from '@/modules/cities/components/city-form-modal.vue';
import { mapGetters, mapActions } from 'vuex';
import { CitiesRegionsPermissions } from '@/modules/cities/citiesRegions-permissions';
// import firebase from 'firebase';
import firebase from 'firebase/app';
import 'firebase/auth';        // for authentication
import 'firebase/storage';     // for storage
import 'firebase/database';    // for realtime database
import 'firebase/firestore';   // for cloud firestore
import 'firebase/messaging';   // for cloud messaging
import 'firebase/functions';   // for cloud functions
// import { i18n } from "@/i18n";
import i18n from '@/vueI18n';

export default {
  name: 'app-city-list-page',

  components: {
    [CityListTable.name]: CityListTable,
    [CityFormModal.name]: CityFormModal
  },
  data() {
    return {
      isListView: false,
      modalVisible: false,
      dialogVisible: false,
      successDialog: false,
      citiesListener: null
    }
  },
  computed: {
     lang(){
            return i18n.locale
        },
    ...mapGetters({
      rows: 'citiesRegions/list/rows',
      count: 'citiesRegions/list/count',
      loading: 'citiesRegions/list/loading',
      is_screen_xs: 'layout/is_screen_xs',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
    }),
    hasPermissionToCreate() {
      return new CitiesRegionsPermissions(this.currentUser).create;
    },
    
  },
  watch:{
    // rows(newval){
    //   console.log('rows',newval);

    // },
    lang(){
      document.title =
                this.i18n('app.title') +
                ' | ' +
                this.i18n('menu.city')
    }
  },

  async created() {
    document.title =
                this.i18n('app.title') +
                ' | ' +
                this.i18n('menu.city')
    // if(!this.mountedTable){
    //   // this.resetCursor()
    //   // await this.doFetch({limit: 10,searchBy: 'name',filter:''})
    //   await this.doFetch()
    //   this.setMountedTable(true)
    // }
      this.citiesListener = await firebase.firestore().collection('city').onSnapshot(async () => {
      this.doFilterInBackground();
      // querySnapshot.docChanges().forEach(change => {
      //   if (change.type === 'modified') {

      //   }
      // });
    });
    this.setCitiesListener(this.citiesListener)
  },
  async mounted() {
    await this.doFilter();
  },
  methods: {
    i18n(key, args) {
      // return i18n(key, args)
      return this.$t(key, args);

    },
    ...mapActions({
      doFetch: 'citiesRegions/list/doFetch',
      doFetchInBackground: 'citiesRegions/list/doFetchInBackground',
      setMountedTable: 'citiesRegions/list/setMountedTable',
      resetCursor: 'citiesRegions/list/resetCursor',
      setCitiesListener: 'citiesRegions/list/setCitiesListener'

    }),
    // i18n(key, args) {
    //   return i18n(key, args);
    // },
    // displaySwitch(view) {
    //   switch (view) {
    //     case 'card':
    //       this.isListView = false
    //       break;
    //     case 'list':
    //       this.isListView = true
    //       break;
    //     default:
    //       break;
    //   }
    // },
    doOpenModal() {
      this.dialogVisible = true;
      this.modalVisible = true;
    },
    onModalClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.modalVisible = false;
      }, 500);
    },
    onModalSuccess() {
      this.successDialog = true
    },
  
    async doFilter() {
      // filterSchema.cast(this.model);
      // const filter = {}
      return this.doFetch({
        // filter,
      });
    },
    async doFilterInBackground() {
      // const filter = {}
      return this.doFetchInBackground({
        // filter,
      });
    },
  },

};
