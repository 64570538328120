//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { i18n } from '@/i18n';
import { mapActions, mapGetters } from 'vuex';
// import Message from '@/shared/message/message';
import { DrugIndexModel } from '@/modules/drugIndex/drugIndex-model';
const { fields } = DrugIndexModel;

export default {
  name: 'app-upload-new-version-modal',
  props: ['visible'],

  data() {
    return {
      file: '',
      expanded: true,
      waitLoading: false,
      triggerRemoveFile: false,
      uploaderLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      currentDrugIndexVersion: 'drugIndex/list/drugIndexVersion',
      // publishLoading: 'drugIndex/form/publishLoading',
    }),
    dialogVisible: {
      get: function() {
        return this.visible;
      },

      set: function(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    fields() {
      return fields;
    },
    nextFileName() {
      return `drugIndex-version-${this.currentDrugIndexVersion + 1}`
    },
  },
  methods: {
    ...mapActions({
      doAddFileDetails: 'drugIndex/form/doAddFileDetails',
      doUpdateFileDetails: 'drugIndex/form/doUpdateFileDetails',
      settingsUpdate: 'drugIndex/form/settingsUpdate',
      // doPublish: 'drugIndex/form/doPublish',
    }),
    i18n(code, args) {
      return i18n(code, args);
    },
    doCancel() {
      this.dialogVisible = false;
    },
    doClose() {
      if (this.uploaderLoading) {
        return 
      } else {
        this.$emit('close')
      }
    },
    isStartUploading(isStarting) {
      console.log('Uploader is starting =', isStarting);
      this.uploaderLoading = isStarting
      this.$emit('uploaderLoading', isStarting)
    },
    async addFileDetailsInRealTime(value) {
      this.waitLoading = true
      if (value && value.length) {
        const file = value[0]
        console.log(file);
        await this.doAddFileDetails(file)
        await this.settingsUpdate({
          id: "drugIndexUpdates",
          status: "uploaded",
          drugFileName: file.name,
          currentNumberOfChunks: 0,
          currentNumberOfDrugs: 0,
          totalNumberOfChunks: 0,
          totalNumberOfDrugs: 0,
        })
      } else {
        this.doAddFileDetails('')
      }
      this.waitLoading = false
      this.$emit('close');
    },
    async removeFileDetails(file) {
      console.log('File = ', file);
      this.doAddFileDetails('')
      this.settingsUpdate({
        id: "drugIndexUpdates",
        status: "pending",
        drugFileName: null,
        currentNumberOfChunks: 0,
        currentNumberOfDrugs: 0,
        totalNumberOfChunks: 0,
        totalNumberOfDrugs: 0,
      })
    }
  },
};
