import { PropertyService } from "@/modules/property/property-service";
// import jobListExporterFields from '@/modules/job/job-list-exporter-fields';
import Errors from "@/shared/error/errors";
// import Exporter from '@/shared/exporter/exporter';,firebaseAction
import { firestoreAction, vuexfireMutations } from "vuexfire";
import firebase from "firebase/app";

const INITIAL_PAGE_SIZE = 20;

export default {
  namespaced: true,

  state: {
    rows: [],
    rowsFromRoute: [],
    loadMoreLastRecord: null,
    loadFirstRecord: null,
    recordBeforeFirst: [],
    recordAfterLast: [],
    userProperties: [],

    totalRecords: null,
    pagesNumber: null,
    pageCount: 0,
    mountedTable: false,
    count: 0,
    loading: false,
    backgroundLoading: false,
    filter: {},
    pagination: {},
    sorter: {},

    table: null,
  },

  getters: {
    backgroundLoading: (state) => state.backgroundLoading,
    loading: (state) => state.loading,

    exportLoading: (state) => state.exportLoading,

    rows: (state) => state.rows || [],
    rowsFromRoute: (state) => state.rowsFromRoute || [],
    mountedTable: (state) => state.mountedTable,
    loadMoreLastRecord: (state) => {
      return state.loadMoreLastRecord;
    },
    loadFirstRecord: (state) => {
      return state.loadFirstRecord;
    },
    recordBeforeFirst: (state) => state.recordBeforeFirst,
    recordAfterLast: (state) => state.recordAfterLast,
    userProperties: (state) => state.userProperties,

    totalRecords: (state) => state.totalRecords,
    pagesNumber: (state) => state.pagesNumber,
    pageCount: (state) => state.pageCount,
    count: (state) => state.count,

    hasRows: (state, getters) => getters.count > 0,

    orderBy: (state) => {
      const sorter = state.sorter;

      if (!sorter) {
        return null;
      }

      if (!sorter.prop) {
        return null;
      }

      let direction = sorter.order === "descending" ? "DESC" : "ASC";

      return `${sorter.prop}_${direction}`;
    },

    filter: (state) => state.filter,

    limit: (state) => {
      const pagination = state.pagination;

      if (!pagination || !pagination.pageSize) {
        return INITIAL_PAGE_SIZE;
      }

      return pagination.pageSize;
    },

    offset: (state) => {
      const pagination = state.pagination;

      if (!pagination || !pagination.pageSize) {
        return 0;
      }

      const currentPage = pagination.currentPage || 1;

      return (currentPage - 1) * pagination.pageSize;
    },

    pagination: (state, getters) => {
      return {
        ...state.pagination,
        total: getters.count,
        showSizeChanger: true,
      };
    },

    selectedRows: (state) => {
      return state.table ? state.table.selection : [];
    },
  },

  mutations: {
    RESETED(state) {
      state.rows = [];
      state.count = 0;
      state.loading = false;
      state.filter = {};
      state.pagination = {};
      state.sorter = {};
      if (state.table) {
        state.table.clearSelection();
      }
    },

    UNSELECT_ALL(state) {
      if (state.table) {
        state.table.clearSelection();
      }
    },

    TABLE_MOUNTED(state, payload) {
      state.table = payload;
    },

    PAGINATION_CHANGED(state, payload) {
      state.pagination = payload || {};
    },

    PAGINATION_CURRENT_PAGE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: payload || 1,
        pageSize: previousPagination.pageSize || INITIAL_PAGE_SIZE,
      };
    },

    PAGINATION_PAGE_SIZE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: previousPagination.currentPage || 1,
        pageSize: payload || INITIAL_PAGE_SIZE,
      };
    },

    SORTER_CHANGED(state, payload) {
      state.sorter = payload || {};
    },

    FETCH_STARTED_IN_BACKGROUND(state, payload) {
      state.backgroundLoading = true;
      if (state.table) {
        state.table.clearSelection();
      }

      state.filter = payload && payload.filter ? payload.filter : {};
      state.pagination =
        payload && payload.keepPagination ? state.pagination : {};
    },
    // , payload
    FETCH_STARTED(state) {
      state.loading = true;

      // if (state.table) {
      //   state.table.clearSelection();
      // }

      // state.filter =
      //   payload && payload.filter ? payload.filter : {};
      // state.pagination =
      //   payload && payload.keepPagination
      //     ? state.pagination
      //     : {};
    },
    // , payload
    FETCH_SUCCESS(state) {
      state.loading = false;
      // state.backgroundLoading = false;
      // state.rows = payload.rows;
      // state.count = payload.count;
    },

    FETCH_ERROR(state) {
      state.loading = false;
      state.backgroundLoading = false;
      state.rows = [];
      state.count = 0;
    },

    EXPORT_STARTED(state) {
      state.exportLoading = true;
    },

    EXPORT_SUCCESS(state) {
      state.exportLoading = false;
    },

    EXPORT_ERROR(state) {
      state.exportLoading = false;
    },
    SET_MOUNTEDTABLE(state, payload) {
      state.mountedTable = payload;
    },
    // //#region VuexfireMutations
    ...vuexfireMutations,
    SET_LOADMORE_LAST(state) {
      // state.rows.pop()
      // console.log('state.rows',state.rows.pop());
      state.loadMoreLastRecord = state.rows[state.rows.length - 1]._doc;
      state.recordAfterLast = state.rows[state.rows.length - 1];
    },
    SET_LOADFIRSTRECORD(state) {
      state.loadFirstRecord = state.rows[0]._doc;
    },
    RESET_CURSOR(state) {
      state.loadMoreLastRecord = null;
      state.loadFirstRecord = null;
    },
    REMOVELASTELEMENT(state) {
      state.rows.pop();
    },
    SET_TOTAL_RECORDS(state, payload) {
      state.totalRecords = payload;
      state.pagesNumber = Math.ceil(state.totalRecords / 10);
    },
    SET_PAGE_COUNT(state, payload) {
      state.pageCount = payload;
      // console.log('SET_PAGE_COUNT',state.pageCount);
    },

    SET_ROWS(state, payload) {
      state.rows = payload;
    },

    // //#endregion
  },

  actions: {
    doUnselectAll({ commit }) {
      commit("UNSELECT_ALL");
    },

    doMountTable({ commit }, table) {
      commit("TABLE_MOUNTED", table);
    },

    async doReset({ commit, dispatch }) {
      commit("RESETED");
      return dispatch("doFetch");
    },

    doChangePagination({ commit, getters, dispatch }, pagination) {
      commit("PAGINATION_CHANGED", pagination);
      const filter = getters.filter;
      dispatch("doFetch", { filter, keepPagination: true });
    },

    doChangePaginationPageSize({ commit, getters, dispatch }, pageSize) {
      commit("PAGINATION_PAGE_SIZE_CHANGED", pageSize);
      const filter = getters.filter;
      dispatch("doFetch", { filter, keepPagination: true });
    },

    doChangePaginationCurrentPage({ commit, getters, dispatch }, currentPage) {
      commit("PAGINATION_CURRENT_PAGE_CHANGED", currentPage);
      const filter = getters.filter;
      dispatch("doFetch", { filter, keepPagination: true });
    },

    doChangeSort({ commit, getters, dispatch }, sorter) {
      commit("SORTER_CHANGED", sorter);
      const filter = getters.filter;
      dispatch("doFetch", { filter, keepPagination: true });
    },
    setMountedTable({ commit }, value) {
      commit("SET_MOUNTEDTABLE", value);
    },
    // //#region Vuexfire
    // doGetRecordsCount: firebaseAction( async ({ bindFirebaseRef, getters }) => {
    //   const db = firebase.database()
    //   // bindFirebaseRef('documents', db.ref('setting'))
    //  await bindFirebaseRef('totalRecords', db.ref('setting/property').on('value'))
    //  console.log('getters.totalRecords',getters.totalRecords);
    //   // unbindFirebaseRef('documents')
    //   // unbindFirebaseRef('currentDocument')
    // }),
    async doGetRecordsCount({ commit }) {
      await firebase
        .database()
        .ref("stats/propertiesCount")
        .on("value", (snapshot) => {
          commit("SET_TOTAL_RECORDS", snapshot.val());
        });
    },

    doFetch: firestoreAction(
      async ({ bindFirestoreRef, getters, commit }, payload) => {
        // getters.pageCount = getters.pageCount + 1
        // commit('SET_PAGE_COUNT',getters.pageCount + 1)
        commit("FETCH_STARTED");
        const customSerializer = (doc) => {
          const data = doc.data();
          Object.defineProperty(data, "_doc", { value: doc });
          return data;
        };
        // const db = firebase.firestore()
        // var propertyCollection = null
        var db = firebase.firestore().collection("property");
        var propertyCollection = null;
        if (payload.postStatus == "pending") {
          db = db.where("postStatus", "==", "pending");
        }
        if (payload.openedStatus == "opened") {
          db = db.where("isClosed", "==", false);
        }
        switch (payload.searchBy) {
          case "name":
            propertyCollection = getters.loadMoreLastRecord
              ? db
                  .limit(payload.limit || 10)
                  .orderBy("name", "asc")
                  .startAt(payload.filter)
                  .endAt(payload.filter + "\uf8ff")
                  .startAfter(getters.loadMoreLastRecord)
              : db
                  .orderBy("name", "asc")
                  .limit(payload.limit || 10)
                  .startAt(payload.filter)
                  .endAt(payload.filter + "\uf8ff");
            break;
          case "address":
            propertyCollection = getters.loadMoreLastRecord
              ? db
                  .limit(payload.limit || 10)
                  .orderBy("address", "asc")
                  .startAt(payload.filter)
                  .endAt(payload.filter + "\uf8ff")
                  .startAfter(getters.loadMoreLastRecord)
              : db
                  .orderBy("address", "asc")
                  .limit(payload.limit || 10)
                  .startAt(payload.filter)
                  .endAt(payload.filter + "\uf8ff");
            break;
          case "spaceArea":
            if (payload.filter != "") {
              propertyCollection = getters.loadMoreLastRecord
                ? db
                    .orderBy("spaceArea", "asc")
                    .startAt(parseInt(payload.filter, 10))
                    .endAt(parseInt(payload.filter + "\uf8ff", 10))
                    .startAfter(getters.loadMoreLastRecord)
                    .limit(payload.limit || 10)
                : db
                    .orderBy("spaceArea", "asc")
                    .startAt(parseInt(payload.filter, 10))
                    .endAt(parseInt(payload.filter + "\uf8ff", 10))
                    .limit(payload.limit || 10);
            } else {
              propertyCollection = getters.loadMoreLastRecord
                ? db
                    .limit(payload.limit || 10)
                    .orderBy("spaceArea", "asc")
                    .startAfter(getters.loadMoreLastRecord)
                : db.orderBy("spaceArea", "asc").limit(payload.limit || 10);
            }

            break;
        }
        await bindFirestoreRef("rows", propertyCollection, {
          serialize: customSerializer,
        });
        commit("FETCH_SUCCESS");
      }
    ),
    doFetchFromRoute: firestoreAction(
      async ({ bindFirestoreRef, commit }, payload) => {
        commit("FETCH_STARTED");

        const customSerializer = (doc) => {
          const data = doc.data();
          Object.defineProperty(data, "_doc", { value: doc });
          return data;
        };
        // const db = firebase.firestore()
        // var propertyCollection = null

        var db = firebase.firestore().collection("property");
        var propertyCollection = null;
        if (payload.postStatus == "pending") {
          db = db.where("postStatus", "==", "pending");
        }
        if (payload.openedStatus == "opened") {
          db = db.where("isClosed", "==", false);
        }

        // const startAt = payload.page * payload.limit - payload.limit
        const endAt = payload.page * payload.limit;
        // .startAt(payload.filter)
        // .endAt(payload.filter + "\uf8ff")
        const searchOptions = ["name", "address"];
        if (searchOptions.includes(payload.searchBy)) {
          propertyCollection = db
            .orderBy(payload.searchBy, "asc")
            .where(payload.searchBy, ">=", payload.filter)
            .where(payload.searchBy, "<", payload.filter + "\uf8ff")
            .limit(endAt);
        } else {
          if (payload.searchBy == "spaceArea") {
            if (payload.filter != "") {
              propertyCollection = db
                .orderBy("spaceArea")
                .startAt(parseInt(payload.filter, 10))
                .endAt(parseInt(payload.filter + "\uf8ff", 10))
                .limit(endAt);
            } else {
              propertyCollection = db.orderBy("spaceArea").limit(endAt);
            }
          } else {
            propertyCollection = db.orderBy("createdAt", "desc").limit(endAt);
          }
        }
        await bindFirestoreRef("rowsFromRoute", propertyCollection, {
          serialize: customSerializer,
        });

        // const rowsFromRouteToBeDisplayed = getters.rowsFromRoute.slice(payload.page * payload.limit - payload.limit , payload.page * payload.limit + 1)
        // console.log('rowsFromRouteToBeDisplayed',rowsFromRouteToBeDisplayed);
        // commit('SET_ROWS',rowsFromRouteToBeDisplayed)
        commit("FETCH_SUCCESS");
      }
    ),

    setRows({ commit }, payload) {
      commit("FETCH_STARTED");
      commit("SET_ROWS", payload);
      commit("FETCH_SUCCESS");
    },

    setLoadMoreLast({ commit }) {
      commit("SET_LOADMORE_LAST");
    },
    resetCursor({ commit }) {
      commit("RESET_CURSOR");
    },
    setLoadFirstRecord({ commit }) {
      commit("SET_LOADFIRSTRECORD");
    },
    removeLastElement({ commit }) {
      commit("REMOVELASTELEMENT");
    },
    getPreviousRecordsBatch: firestoreAction(
      async ({ bindFirestoreRef, getters }, payload) => {
        const customSerializer = (doc) => {
          const data = doc.data();
          Object.defineProperty(data, "_doc", { value: doc });
          return data;
        };
        // const db = firebase.firestore()
        //   var propertyCollection = null
        var db = firebase.firestore().collection("property");
        var propertyCollection = null;
        if (payload.postStatus == "pending") {
          db = db.where("postStatus", "==", "pending");
        }
        if (payload.openedStatus == "opened") {
          db = db.where("isClosed", "==", false);
        }
        switch (payload.searchBy) {
          case "name":
            propertyCollection = db
              .orderBy("name", "asc")
              .startAt(payload.filter)
              .endAt(payload.filter + "\uf8ff")
              .endBefore(getters.loadFirstRecord)
              .limitToLast(payload.limit || 10);
            break;
          case "address":
            propertyCollection = db
              .orderBy("address", "asc")
              .startAt(payload.filter)
              .endAt(payload.filter + "\uf8ff")
              .endBefore(getters.loadFirstRecord)
              .limitToLast(payload.limit || 10);
            break;
          case "spaceArea":
            if (payload.filter != "") {
              propertyCollection = db
                .orderBy("spaceArea", "asc")
                .startAt(parseInt(payload.filter, 10))
                .endAt(parseInt(payload.filter + "\uf8ff", 10))
                .endBefore(getters.loadFirstRecord)
                .limitToLast(payload.limit || 10);
            } else {
              propertyCollection = db
                .orderBy("spaceArea", "asc")
                .endBefore(getters.loadFirstRecord)
                .limitToLast(payload.limit || 10);
            }

            break;
        }
        await bindFirestoreRef("rows", propertyCollection, {
          serialize: customSerializer,
        });
      }
    ),
    setDisableNext: firestoreAction(
      async ({ getters, bindFirestoreRef }, payload) => {
        // const db = firebase.firestore()
        // var propertyCollection = null
        var db = firebase.firestore().collection("property");
        var propertyCollection = null;
        if (payload.postStatus == "pending") {
          db = db.where("postStatus", "==", "pending");
        }
        if (payload.openedStatus == "opened") {
          db = db.where("isClosed", "==", false);
        }
        switch (payload.searchBy) {
          case "name":
            propertyCollection = db
              .orderBy("name", "asc")
              .startAt(payload.filter)
              .endAt(payload.filter + "\uf8ff")
              .startAfter(getters.loadMoreLastRecord)
              .limit(1);
            break;
          case "address":
            propertyCollection = db
              .orderBy("address", "asc")
              .startAt(payload.filter)
              .endAt(payload.filter + "\uf8ff")
              .startAfter(getters.loadMoreLastRecord)
              .limit(1);
            break;
          case "spaceArea":
            if (payload.filter != "") {
              propertyCollection = db
                .orderBy("spaceArea", "asc")
                .startAt(parseInt(payload.filter, 10))
                .endAt(parseInt(payload.filter + "\uf8ff", 10))
                .startAfter(getters.loadMoreLastRecord)
                .limit(1);
            } else {
              propertyCollection = db
                .orderBy("spaceArea", "asc")
                .startAfter(getters.loadMoreLastRecord)
                .limit(payload.limit || 10);
            }

            break;
        }

        // var propertyCollection = db.collection('property')
        //     .orderBy('name', 'asc')
        //     .limit(1)
        //     .startAfter(getters.loadMoreLastRecord)
        await bindFirestoreRef("recordAfterLast", propertyCollection);
      }
    ),
    setDisablePrevious: firestoreAction(
      async ({ getters, bindFirestoreRef }, payload) => {
        // console.log('setDisablePrevious',getters.loadFirstRecord);
        // const db = firebase.firestore()
        // var propertyCollection = null
        var db = firebase.firestore().collection("property");
        var propertyCollection = null;
        if (payload.postStatus == "pending") {
          db = db.where("postStatus", "==", "pending");
        }
        if (payload.openedStatus == "opened") {
          db = db.where("isClosed", "==", false);
        }
        switch (payload.searchBy) {
          case "name":
            propertyCollection = db
              .orderBy("name", "asc")
              .startAt(payload.filter)
              .endAt(payload.filter + "\uf8ff")
              .endBefore(getters.loadFirstRecord)
              .limitToLast(1);
            break;
          case "address":
            propertyCollection = db
              .orderBy("address", "asc")
              .startAt(payload.filter)
              .endAt(payload.filter + "\uf8ff")
              .endBefore(getters.loadFirstRecord)
              .limitToLast(1);
            break;
          case "spaceArea":
            if (payload.filter != "") {
              propertyCollection = db
                .orderBy("spaceArea", "asc")
                .startAt(parseInt(payload.filter, 10))
                .endAt(parseInt(payload.filter + "\uf8ff", 10))
                .endBefore(getters.loadFirstRecord)
                .limitToLast(1);
            } else {
              propertyCollection = db
                .orderBy("spaceArea", "asc")
                .endBefore(getters.loadFirstRecord)
                .limitToLast(1);
            }

            break;
        }

        // var propertyCollection = db.collection('property')
        //     .orderBy('name','asc')
        //     .endBefore(getters.loadFirstRecord)
        //     .limitToLast(1)
        await bindFirestoreRef("recordBeforeFirst", propertyCollection);
      }
    ),

    doFetchUserProperty: firestoreAction(
      async ({ bindFirestoreRef, commit }, payload) => {
        commit("FETCH_STARTED");
        const db = firebase.firestore();
        const propertyCollection = db
          .collection("property")
          .where("createdBy", "==", payload.userId);
        await bindFirestoreRef("userProperties", propertyCollection);
        commit("FETCH_SUCCESS");
      }
    ),

    // //#endregion

    // async doFetch(
    //   { commit,  },
    //   { filter, keepPagination } = {},
    // ) {
    //   try {
    //     commit('FETCH_STARTED', { filter, keepPagination });

    //     const response = await PropertyService.list(
    //       filter,
    //     //   getters.orderBy,
    //       // getters.limit,
    //       // getters.offset,
    //     );
    //         console.log('response rows',response.rows)
    //     commit('FETCH_SUCCESS', {
    //       rows: response.rows,
    //       count: response.count,
    //     });
    //   } catch (error) {
    //     Errors.handle(error);
    //     commit('FETCH_ERROR');
    //   }
    // },

    async doFetchInBackground({ commit }, { filter, keepPagination } = {}) {
      try {
        commit("FETCH_STARTED_IN_BACKGROUND", { filter, keepPagination });

        const response = await PropertyService
          .list
          //   filter,
          //   getters.orderBy,
          // getters.limit,
          // getters.offset,
          ();

        commit("FETCH_SUCCESS", {
          rows: response.rows,
          count: response.count,
        });
      } catch (error) {
        Errors.handle(error);
        commit("FETCH_ERROR");
      }
    },
  },
};
