//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { i18n } from '@/i18n';
import { mapActions, mapGetters } from 'vuex';
// import Message from '@/shared/message/message';


export default {
  name: 'app-clear-items-page',
  props: ['visible', 'loading'],

  data() {
    return {
      text: null,
    };
  },
  computed: {
    ...mapGetters({}),
    dialogVisible: {
      get: function() {
        return this.visible;
      },

      set: function(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    confirm() {
      return this.text === 'CLEAR'
    },
  },

  methods: {
    ...mapActions({}),
    i18n(code, args) {
      return i18n(code, args);
    },
    doCancel() {
      this.dialogVisible = false;
    },
    async doSubmit() {
      await this.$emit('submit', this.id)
    },
  },
};
