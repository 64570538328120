import prescriptionFormStore from '@/modules/prescription/prescription-form-store';
import prescriptionListStore from '@/modules/prescription/prescription-list-store';

export default {
    namespaced: true,
  
    modules: {
      form: prescriptionFormStore,
      list: prescriptionListStore
    },
  };
  