//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from "vuex";
import { JobModel } from "@/modules/job/job-model";
import { JobPermissions } from "@/modules/job/job-permissions";
// import { i18n } from '@/i18n';
// import { i18n } from '@/i18n';
import moment from "moment";
// import firebase from 'firebase'
import firebase from "firebase/app";
import "firebase/firestore";
import TableViewPage from "@/shared/components/table-view-page.vue";
// import JobFormModal from '@/modules/job/components/job-form-modal.vue';
import JobViewModal from "@/modules/job/components/job-view-modal.vue";

const { fields } = JobModel;

export default {
  name: "app-job-list-table",

  components: {
    [TableViewPage.name]: TableViewPage,
    // [JobFormModal.name]:JobFormModal,
    [JobViewModal.name]: JobViewModal,
  },

  data() {
    return {
      filter: "",
      searchBy: "name",
      postStatus: "",
      status: "",
      pendingToggle: false,
      hiringToggle: false,
      modalVisible: false,
      // rows:[],
      columns: [
        {
          name: "name",
          field: "name",
          label: "name",
          align: "center",
          format: (val) => `${val}`,
          required: true,
          // style: 'width: 5vw;',
        },
        {
          name: "description",
          field: "description",
          label: "description",
          align: "center",
          format: (val) => `${val}`,
          required: true,
          // sortable: true,
        },
        {
          name: "address",
          field: "address",
          label: "address",
          align: "center",
          format: (val) => `${val}`,
          required: true,
          // style: 'width: 5vw;',
        },
        {
          name: "emailAddress",
          field: "emailAddress",
          label: "emailAddress",
          align: "center",
          format: (val) => `${val}`,
          required: true,
          // sortable: true,
        },
        {
          name: "phoneNumber",
          field: "phoneNumber",
          label: "phoneNumber",
          align: "center",
          format: (val) => `${val}`,
          required: true,
          // style: 'width: 5vw;',
        },
        {
          name: "postStatus",
          field: "postStatus",
          label: "postStatus",
          align: "center",
          format: (val) => `${val}`,
          required: true,
          // sortable: true,
        },
        {
          name: "status",
          field: "status",
          label: "status",
          align: "center",
          format: (val) => `${val}`,
          required: true,
          // style: 'width: 5vw;',
        },
        {
          name: "action",
          field: "action",
          label: "actions",
          align: "center",
        },
      ],
      rowsOld: [],
      // language: getLanguageCothis.de(),
      dialogVisible: false,
      dialogType: undefined,
      selectedRow: null,
      selectedId: null,
      pageSize: 5,
      nextPage: 2,
      pagination: {
        page: 1,
        rowsPerPage: 10,
        // sortBy: 'desc',
        // descending: false,
        // rowsNumber: xx if getting data from a server
      },

      removeTogglePointer: true,
      page: 1,
      limit: 10,
      searchByOptions: ["name", "address", "phoneNumber", "emailAddress"],
    };
  },
  computed: {
    ...mapGetters({
      rows: "job/list/rows",
      rowsFromRoute: "job/list/rowsFromRoute",
      recordAfterLast: "job/list/recordAfterLast",
      recordBeforeFirst: "job/list/recordBeforeFirst",
      count: "job/list/count",
      // pagination: 'job/list/pagination',
      // paginationLayout: 'layout/paginationLayout',
      loading: "job/list/loading",
      saveLoading: "job/form/saveLoading",
      backgroundLoading: "job/list/backgroundLoading",
      isMobile: "layout/isMobile",
      currentUser: "auth/currentUser",
      destroyLoading: "job/form/saveLoading",
      is_screen_xs: "layout/is_screen_xs",
      // workplaces: 'job/view/workplaces'
    }),
    loadingAndUpdate() {
      if (this.loading || this.saveLoading) {
        return true;
      } else {
        return false;
      }
    },

    hasPermissionToEdit() {
      return new JobPermissions(this.currentUser).edit;
    },
    hasPermissionToDestroy() {
      return new JobPermissions(this.currentUser).destroy;
    },
    fields() {
      return fields;
    },
    pagesNumber() {
      return Math.ceil(this.rows.length / this.pagination.rowsPerPage);
    },
    rowsToBeDisplayed() {
      if (this.rows.length > 0) return this.rows;
      return this.rowsOld;
    },
  },
  watch: {
    rowsFromRoute(change) {
      const page = this.$route.query.page;
      const limit = this.limit;
      const rowsFromRouteToBeDisplayed = change.slice(
        page * limit - limit,
        page * limit + 1
      );
      this.setRows(rowsFromRouteToBeDisplayed);
    },
    rows(newval) {
      if (newval.length > 0) {
        // this.removeLastElement()
        this.setLoadFirstRecord();
        this.setLoadMoreLast();
        this.setDisablePrevious({
          searchBy: this.searchBy,
          filter: this.filter,
          postStatus: this.postStatus,
          status: this.status,
        });
        this.setDisableNext({
          searchBy: this.searchBy,
          filter: this.filter,
          postStatus: this.postStatus,
          status: this.status,
        });
      }
    },
    // async pendingToggle(newval){
    //     console.log('pendingToggle',newval);
    //     if(newval){
    //       this.hiringToggle = false
    //       this.resetCursor()
    //       this.searchBy = 'postStatus'
    //       // in case of clicking pending toggle before reloading
    //       // because when reloading we don't need to push the router again it is already pushed with same query and params
    //       if(this.$route.params.searchBy != 'postStatus') {
    //         this.page = 1
    //         if(this.filter != ''){
    //           this.$router.push({
    //             params:{
    //               searchBy: 'postStatus'
    //             },
    //             query:{
    //               page: this.page,
    //               filter: this.filter
    //             }
    //           })
    //         }
    //         else{
    //           this.$router.push({
    //             params:{
    //               searchBy: 'postStatus'
    //             },
    //             query:{
    //               page: this.page,
    //             }
    //           })
    //         }

    //       }
    //
    //       await this.doFetch({limit: 10,searchBy: this.searchBy,filter: this.filter})
    //     }
    //     else{
    //       if(!this.hiringToggle && this.removeTogglePointer){
    //         this.searchBy = 'name'
    //         // this.filter = ''
    //         this.page = 1
    //         this.resetCursor()
    //         if(this.filter != ''){
    //           this.$router.push({
    //             params:{
    //               searchBy: this.searchBy
    //             },
    //             query:{
    //               page: this.page,
    //               filter: this.filter
    //             }
    //           })
    //         }
    //         else{
    //           this.$router.push({
    //             params:{
    //               searchBy: this.searchBy
    //             },
    //             query:{
    //               page: this.page,
    //             }
    //           })
    //         }

    //         await this.doFetch({limit: 10,searchBy: this.searchBy, filter: this.filter})
    //         this.removeTogglePointer = true
    //       }
    //       if(!this.hiringToggle && !this.removeTogglePointer){
    //         this.resetCursor()
    //         // await this.doFetch({limit: 10,searchBy: this.searchBy, filter: this.filter})
    //         this.removeTogglePointer = true
    //       }
    //     }
    //     this.rowsOld = this.rows
    //   },
    //   async hiringToggle(newval){
    //     console.log('hiringToggle',newval);
    //     if(newval){
    //       this.pendingToggle = false
    //       this.searchBy = 'status'
    //       // this.filter = false
    //       // this.filter = ''
    //       //
    //       if(this.$route.params.searchBy != 'status') {
    //         this.page = 1
    //         if(this.filter != ''){
    //         this.$router.push({
    //           params:{
    //             searchBy: 'status'
    //           },
    //           query:{
    //             page: this.page,
    //             filter: this.filter
    //           }
    //         })
    //         }
    //         else{
    //           this.$router.push({
    //           params:{
    //             searchBy: 'status'
    //           },
    //           query:{
    //             page: this.page,
    //           }
    //         })
    //         }
    //       }
    //       this.resetCursor()
    //       await this.doFetch({limit: 10,searchBy: this.searchBy,filter: this.filter})
    //     }
    //     else{
    //       if(!this.pendingToggle && this.removeTogglePointer){
    //         this.searchBy = 'name'
    //         // this.filter = ''
    //         this.page = 1
    //         this.resetCursor()
    //         if(this.filter != ''){
    //         this.$router.push({
    //           params:{
    //             searchBy: this.searchBy
    //           },
    //           query:{
    //             page: this.page,
    //             filter: this.filter
    //           }
    //         })
    //         }
    //         else{
    //           this.$router.push({
    //           params:{
    //             searchBy: this.searchBy
    //           },
    //           query:{
    //             page: this.page,
    //           }
    //         })
    //         }
    //         await this.doFetch({limit: 10,searchBy: this.searchBy, filter: this.filter})
    //         this.removeTogglePointer = true
    //       }
    //       if(!this.pendingToggle && !this.removeTogglePointer){
    //         this.resetCursor()

    //         // await this.doFetch({limit: 10,searchBy: this.searchBy, filter: this.filter})
    //         this.removeTogglePointer = true
    //       }

    //     }
    //     this.rowsOld = this.rows
    //   },
  },

  methods: {
    ...mapActions({
      doChangeSort: "job/list/doChangeSort",
      doChangePaginationCurrentPage: "job/list/doChangePaginationCurrentPage",
      doChangePaginationPageSize: "job/list/doChangePaginationPageSize",
      doMountTable: "job/list/doMountTable",
      doDestroy: "job/destroy/doDestroy",
      doUpdate: "job/form/doUpdate",
      // doFetchInBackground: 'job/list/doFetchInBackground',
      doFetch: "job/list/doFetch",
      doFetchFromRoute: "job/list/doFetchFromRoute",
      doFetchJobPositions: "job/view/doFetchJobPositions",
      doFetchWorkplaces: "job/view/doFetchWorkplaces",
      doFetchCities: "job/view/doFetchCities",

      getPreviousRecordsBatch: "job/list/getPreviousRecordsBatch",
      setDisablePrevious: "job/list/setDisablePrevious",
      setDisableNext: "job/list/setDisableNext",
      doGetRecordsCount: "job/list/doGetRecordsCount",
      setLoadFirstRecord: "job/list/setLoadFirstRecord",
      setLoadMoreLast: "job/list/setLoadMoreLast",
      resetCursor: "job/list/resetCursor",
      setRows: "job/list/setRows",
    }),
    isNumeric(value) {
      return /^\d+$/.test(value);
    },
    async loadMore() {
      await this.doFetch({
        limit: 10,
        searchBy: this.searchBy,
        filter: this.filter,
        postStatus: this.postStatus,
        status: this.status,
      });
      this.page = this.page + 1;

      let query = {};
      query["page"] = this.page;
      if (this.filter != "") {
        query["filter"] = this.filter;
      }
      if (this.postStatus == "pending") {
        query["postStatus"] = "pending";
      }
      if (this.status == "hiring") {
        query["status"] = "hiring";
      }
      let params = {};
      params["searchBy"] = this.searchBy;

      this.$router.push({
        params,
        query,
      });

      this.rowsOld = this.rows;
    },
    async goToPreviousPage() {
      await this.getPreviousRecordsBatch({
        limit: 10,
        searchBy: this.searchBy,
        filter: this.filter,
        postStatus: this.postStatus,
        status: this.status,
      });

      this.page = this.page - 1;

      let query = {};
      query["page"] = this.page;
      if (this.filter != "") {
        query["filter"] = this.filter;
      }
      if (this.postStatus == "pending") {
        query["postStatus"] = "pending";
      }
      if (this.status == "hiring") {
        query["status"] = "hiring";
      }
      let params = {};
      params["searchBy"] = this.searchBy;

      this.$router.push({
        params,
        query,
      });

      this.rowsOld = this.rows;
    },
    async searchByFilter() {
      this.resetCursor();
      this.page = 1;

      //#region
      // if(this.filter != ''){
      // this.$router.push({
      //   query: {
      //     page: this.page,
      //     filter: this.filter
      //   },
      //   params:{
      //     searchBy: this.searchBy
      //   }
      // })
      // }
      // else{
      //   console.log('mustttttt');
      //    this.$router.push({
      //   query: {
      //     page: this.page,
      //   },
      //   params:{
      //     searchBy: this.searchBy
      //   }
      // })
      // }
      //#endregion

      let query = {};
      query["page"] = this.page;
      if (this.filter != "") {
        query["filter"] = this.filter;
      }
      if (this.postStatus == "pending") {
        query["postStatus"] = "pending";
      }
      if (this.status == "hiring") {
        query["status"] = "hiring";
      }
      let params = {};
      params["searchBy"] = this.searchBy;

      this.$router.push({
        params,
        query,
      });

      await this.doFetch({
        limit: 10,
        searchBy: this.searchBy,
        filter: this.filter,
        postStatus: this.postStatus,
        status: this.status,
      });

      // this.rowsOld = []
      this.rowsOld = this.rows;
    },
    async removeSearch() {
      this.resetCursor();
      // this.searchBy = 'name'
      this.filter = "";
      this.page = 1;

      let query = {};
      query["page"] = this.page;
      // query['filter'] = this.filter

      if (this.postStatus == "pending") {
        query["postStatus"] = "pending";
      }
      if (this.status == "hiring") {
        query["status"] = "hiring";
      }
      let params = {};
      params["searchBy"] = this.searchBy;

      this.$router.push({
        params,
        query,
      });

      // this.$router.push({
      //   query: {
      //     page: this.page,
      //     // filter: ''
      //   },
      //   params:{
      //     searchBy: this.searchBy
      //   }
      // })
      await this.doFetch({
        limit: 10,
        searchBy: this.searchBy,
        filter: this.filter,
        postStatus: this.postStatus,
        status: this.status,
      });
    },
    i18n(key, args) {
      return this.$t(key, args);
    },
    presenter(row, fieldName) {
      const val = JobModel.presenter(row, fieldName);
      return val ? val : "ـــــــ";
    },
    presenterEnum(row, fieldName) {
      const options = this.fields[fieldName].options;
      const val = options.filter((el) => el.value == row[fieldName]);
      return val.length ? val[0].label : "ـــــــ";
    },
    presenterMap(row, fieldName) {
      const val = JobModel.presenter(row, fieldName);
      return val[this.language] ? val[this.language] : null;
    },
    presenterDate(row, fieldName) {
      const val = JobModel.presenter(row, fieldName);
      return val
        ? moment(val).locale(this.language).format("D MMM, YYYY")
        : "ـــــــ";
    },
    presenterDay(row, fieldName) {
      return moment(JobModel.presenter(row, fieldName))
        .locale(this.language)
        .format("dddd");
    },
    presenterTime(row, fieldName) {
      let now = moment().format("YYYY/MM/DD");
      let time = JobModel.presenter(row, fieldName);
      let date = `${now} ${time}`;
      return moment(date).locale(this.language).format("hh:mm a");
    },

    onModalOpen(row, operation) {
      this.selectedRow = row;
      this.selectedId = row.id;
      switch (operation) {
        case "delete":
          this.dialogType = "delete";
          break;
        case "edit":
          this.dialogType = "edit";
          break;
        case "view":
          this.dialogType = "view";
          break;
        default:
          this.dialogType = undefined;
          break;
      }
      this.modalVisible = true;
      this.dialogVisible = true;
    },
    onModalClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.modalVisible = false;
        this.dialogType = undefined;
      }, 500);
    },
    onModalSuccess() {
      this.onModalClose();
    },
    closeDialog() {
      this.dialogVisible = false;
      this.dialogType = "";
    },
    openDialog(row, operation) {
      this.selectedRow = row;
      this.selectedId = row.id;
      switch (operation) {
        case "delete":
          this.dialogType = "delete";
          break;
        case "edit":
          this.dialogVisible = true;
          this.modalVisible = true;
          break;
        default:
          break;
      }
      this.dialogVisible = true;
    },

    async doDestroyWithConfirm({ id, rejectionReason }) {
      try {
        let job = Object.assign({}, this.selectedRow);
        job.postStatus = "destroyed";
        job.rejectionReason = rejectionReason;
        delete job.id;
        delete job.workplace;
        delete job.position;
        delete job.city;
        delete job.region;
        delete job.createdAtUpdated;
        job.createdAt = new Date(job.createdAt.seconds * 1000);
        job.updatedAt = new Date(job.updatedAt.seconds * 1000);
        job.publishedAt = new Date(job.publishedAt.seconds * 1000);
        if (job.dueDate) {
          job.dueDate = new Date(job.dueDate.seconds * 1000);
        } else {
          job.dueDate = null;
        }

        await this.doUpdate({ id, values: job });
        this.onModalClose();
      } catch (error) {
        // no
      }
    },

    async doAcceptJob(id, row) {
      let job = Object.assign({}, row);
      job.postStatus = "accepted";
      job.rejectionReason = null;
      delete job.id;
      delete job.workplace;
      delete job.position;
      delete job.city;
      delete job.region;
      delete job.createdAtUpdated;
      job.createdAt = new Date(job.createdAt.seconds * 1000);
      job.updatedAt = new Date(job.updatedAt.seconds * 1000);
      const updatedTime = firebase.firestore.Timestamp.fromDate(
        new Date()
      ).toDate();

      job.publishedAt = updatedTime;
      if (job.dueDate) {
        job.dueDate = new Date(job.dueDate.seconds * 1000);
      } else {
        job.dueDate = null;
      }

      await this.doUpdate({ id, values: job });
    },
    async pendingToggleFetch() {
      this.resetCursor();

      this.page = 1;
      let query = {};
      query["page"] = this.page;
      if (this.filter != "") {
        query["filter"] = this.filter;
      }
      if (this.status == "hiring") {
        query["status"] = this.status;
      }
      let params = {};
      params["searchBy"] = this.searchBy;

      if (this.pendingToggle) {
        this.postStatus = "pending";
        query["postStatus"] = this.postStatus;
      } else {
        this.postStatus = "";
        // this.$router.replace({ params });
      }
      this.$router.push({
        params,
        query,
      });

      await this.doFetch({
        limit: 10,
        searchBy: this.searchBy,
        filter: this.filter,
        postStatus: this.postStatus,
        status: this.status,
      });
      // must add
      this.rowsOld = this.rows;
    },
    async hiringToggleFetch() {
      this.resetCursor();

      this.page = 1;
      let query = {};
      query["page"] = this.page;
      if (this.filter != "") {
        query["filter"] = this.filter;
      }
      let params = {};
      params["searchBy"] = this.searchBy;
      if (this.postStatus == "pending") {
        query["postStatus"] = this.postStatus;
      }

      if (this.hiringToggle) {
        // must push in route
        this.status = "hiring";
        query["status"] = this.status;
      } else {
        // must remove from route
        this.status = "";
      }

      this.$router.push({
        params,
        query,
      });
      await this.doFetch({
        limit: 10,
        searchBy: this.searchBy,
        filter: this.filter,
        postStatus: this.postStatus,
        status: this.status,
      });
      this.rowsOld = this.rows;
      // this.removeTogglePointer = !this.removeTogglePointer
    },
    removeToggle() {
      this.searchByFilter();
      // this.hiringToggle = false
      // this.pendingToggle = false
    },
    async navigateBackwardFroward() {
      if (this.$route.name == "job") {
        this.page =
          this.$route.query.page && this.isNumeric(this.$route.query.page)
            ? parseInt(this.$route.query.page)
            : 1;
        if (this.$route.query.postStatus == "pending") {
          this.postStatus = "pending";
          this.pendingToggle = true;
        } else {
          this.postStatus = "";
          this.pendingToggle = false;
        }
        if (this.$route.query.status == "hiring") {
          this.status = "hiring";
          this.hiringToggle = true;
        } else {
          this.status = "";
          this.hiringToggle = false;
        }
        this.searchBy =
          this.$route.params.searchBy &&
          this.searchByOptions.includes(this.$route.params.searchBy)
            ? this.$route.params.searchBy
            : "name";
        this.filter = this.$route.query.filter ? this.$route.query.filter : "";

        // this.page = this.$route.query.page && this.isNumeric(this.$route.query.page) ? parseInt(this.$route.query.page) : 1
        // if(this.$route.params.searchBy && this.searchByOptions.includes(this.$route.params.searchBy)){
        //   this.searchBy = this.$route.params.searchBy
        // }
        // else{
        //   this.searchBy = 'name'
        // }
        // this.filter = this.$route.query.filter ? this.$route.query.filter : ''

        // this.setRows([]);
        this.resetCursor();
        if (
          this.$route.params.searchBy &&
          this.searchByOptions.includes(this.$route.params.searchBy)
        ) {
          if (
            !this.$route.query.page ||
            !this.isNumeric(this.$route.query.page)
          ) {
            if (this.$route.query.filter) {
              this.$router.push({
                query: {
                  page: 1,
                  filter: this.$route.query.filter,
                },
              });
            } else {
              this.$router.push({
                query: {
                  page: 1,
                },
              });
            }
          }
          await this.doFetchFromRoute({
            page: this.page,
            limit: 10,
            searchBy: this.searchBy,
            filter: this.filter,
            postStatus: this.postStatus,
            status: this.status,
          });
        } else {
          this.$router.push({
            params: {
              searchBy: "name",
            },
            query: {
              page: 1,
            },
          });
          this.page = 1;
          await this.doFetch({
            limit: 10,
            searchBy: "name",
            filter: "",
            postStatus: this.postStatus,
            status: this.status,
          });
        }
        this.rowsOld = this.rows;
      }
    },
  },
  mounted() {
    var that = this;
    window.onpopstate = async function () {
      that.navigateBackwardFroward();
    };
  },
  async created() {
    //#region [ ======== to set the searchBy, filter, page variables ======== ]
    this.page =
      this.$route.query.page && this.isNumeric(this.$route.query.page)
        ? parseInt(this.$route.query.page)
        : 1;
    if (this.$route.query.postStatus == "pending") {
      this.postStatus = "pending";
      this.pendingToggle = true;
    }
    if (this.$route.query.status == "hiring") {
      this.status = "hiring";
      this.hiringToggle = true;
    }
    // this.postStatus = this.$route.query.postStatus == 'pending' ? 'pending' : ''
    // this.status = this.$route.query.status == 'hiring' ? 'hiring' : ''
    this.searchBy =
      this.$route.params.searchBy &&
      this.searchByOptions.includes(this.$route.params.searchBy)
        ? this.$route.params.searchBy
        : "name";
    this.filter = this.$route.query.filter ? this.$route.query.filter : "";
    //#endregion

    this.doFetchWorkplaces();
    this.doFetchJobPositions();
    this.doFetchCities();

    if (
      this.$route.params.searchBy &&
      this.searchByOptions.includes(this.$route.params.searchBy)
    ) {
      if (!this.$route.query.page || !this.isNumeric(this.$route.query.page)) {
        if (this.$route.query.filter) {
          this.$router.push({
            query: {
              page: 1,
              filter: this.$route.query.filter,
            },
          });
        } else {
          this.$router.push({
            query: {
              page: 1,
            },
          });
        }
      }
      await this.doFetchFromRoute({
        page: this.page,
        limit: 10,
        searchBy: "",
        filter: this.filter,
        postStatus: this.postStatus,
        status: this.status,
      });
    } else {
      this.$router.push({
        params: {
          searchBy: "name",
        },
        query: {
          page: 1,
          // filter: null
        },
      });
      await this.doFetch({
        limit: 10,
        searchBy: "name",
        filter: "",
        postStatus: this.postStatus,
        status: this.status,
      });
    }
    this.rowsOld = this.rows;
  },
};
