import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-city';

export class CitiesRegionsService {
    static async create(data) {
        const response = await graphqlClient.mutate({
          mutation: gql`
            mutation CITY_CREATE($data: CityInput!) {
              cityCreate(data: $data) {
                id
              }
            }
          `,
    
          variables: {
            data,
          },
        });
    
        return response.data.cityCreate;
      }
    //   static async list(filter, orderBy, limit, offset) {
    //     const response = await graphqlClient.query({
    //       query: gql `
    //         query CITY_LIST(
    //           $filter: CityFilterInput
    //           $orderBy: CityOrderByEnum
    //           $limit: Int
    //           $offset: Int
    //         ) {
    //           cityList(
    //             filter: $filter
    //             orderBy: $orderBy
    //             limit: $limit
    //             offset: $offset
    //           ) {
    //             count
    //             rows {
    //               id
    //               name
    //               regions{
    //                 name
    //               }
                  
                 
    //             }
    //           }
    //         }
    //       `,
    
    //       variables: {
    //         filter,
    //         orderBy,
    //         limit,
    //         offset,
    //       },
    //     });
    // console.log('city response ',response.data.cityList)
    //     return response.data.cityList;
    //   }
    static async list() {
      const response = await graphqlClient.query({
        query: gql `
          query CITY_LIST {
            cityList {
              count
              rows {
                id
                name
                regions{
                  name
                }
                
               
              }
            }
          }
        `,
  
        variables: {
         
        },
      });
      // console.log('city response ',response.data.cityList)
      return response.data.cityList;
    }
}