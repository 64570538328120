//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import JobListTable from '@/modules/job/components/job-list-table.vue';
// import JobFormModal from '@/modules/job/components/job-form-modal.vue';
import PrescripitonListTable from '@/modules/prescription/components/prescription-list-table.vue';
import { mapGetters, mapActions } from 'vuex';
import { PrescriptionPermissions } from '@/modules/prescription/prescription-permissions';
// import firebase from 'firebase';
// import firebase from 'firebase/app';
import 'firebase/auth';        // for authentication
import 'firebase/storage';     // for storage
import 'firebase/database';    // for realtime database
import 'firebase/firestore';   // for cloud firestore
import 'firebase/messaging';   // for cloud messaging
import 'firebase/functions';   // for cloud functions
import i18n from '@/vueI18n';


export default {
  name: 'app-prescription-list-page',

  components: {
    [PrescripitonListTable.name]: PrescripitonListTable,
    // [JobFormModal.name]: JobFormModal,
  },
  data() {
    return {
      isListView: false,
      modalVisible: false,
      dialogVisible: false,
    }
  },
  computed: {
    lang(){
            return i18n.locale
        },
    ...mapGetters({
      rows: 'prescription/list/rows',
      count: 'prescription/list/count',
      mountedTable: 'prescription/list/mountedTable',
      loading: 'prescription/list/loading',
      is_screen_xs: 'layout/is_screen_xs',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
    }),
    hasPermissionToCreate() {
      return new PrescriptionPermissions(this.currentUser).create;
    },
  },
  watch:{
    lang(){
            document.title =
                this.i18n('app.title') +
                ' | ' +
                this.i18n('menu.prescription') 
        },
  },
  async created() {
    document.title =
                this.i18n('app.title') +
                ' | ' +
                this.i18n('menu.prescription') 

      // if(this.$route.params.searchBy && this.$route.params.searchBy == 'title'){
      //   if(this.$route.query.page){
      //     await this.doFetchFromRoute({page: this.$route.query.page, limit: 10, searchBy: this.$route.params.searchBy, filter: this.$route.query.filter ? this.$route.query.filter : ''})
      //   }
      //   else{
      //     if(this.$route.query.filter){
      //       this.$router.push({
      //         query: {
      //           page: 1,
      //           filter: this.$route.query.filter
      //         }
      //       })
      //     }
      //     else{
      //       this.$router.push({
      //         query: {
      //           page: 1,
      //         }
      //       })
      //     }
      //     await this.doFetchFromRoute({page: 1, limit: 10, searchBy: 'title', filter: this.$route.query.filter ? this.$route.query.filter : ''})
      //   }
      // }
      // else{
      //   this.$router.push({
      //     params: {
      //       searchBy: 'title'
      //     },
      //     query: {
      //       page: 1,
      //       // filter: null
      //     }
      //   })
      //   await this.doFetch({limit: 10,searchBy: 'title',filter:''})
      // }
  },
  // async mounted() {
  //   await this.doFilter();
  // },
  methods: {
    i18n(key, args) {
      return this.$t(key, args);
    },
    ...mapActions({
      // #region vuexfire
      doFetch: 'prescription/list/doFetch',
      setMountedTable: 'prescription/list/setMountedTable',
      setDisablePrevious:'prescription/list/setDisablePrevious',
      setDisableNext:'prescription/list/setDisableNext',
      // #endregion
      doFetchInBackground: 'prescription/list/doFetchInBackground',
      doFetchFromRoute: 'prescription/list/doFetchFromRoute'
    }),

    // displaySwitch(view) {
    //   switch (view) {
    //     case 'card':
    //       this.isListView = false
    //       break;
    //     case 'list':
    //       this.isListView = true
    //       break;
    //     default:
    //       break;
    //   }
    // },
    
    doOpenModal() {
      this.dialogVisible = true;
      this.modalVisible = true;
    },
    onModalClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.modalVisible = false;
      }, 500);
    },
    onModalSuccess() {
      this.onModalClose();
    },
  
    // async doFilter() {
    //   // filterSchema.cast(this.model);
    //   // const filter = {postStatus:"accepted"}
    //   // return this.doFetch({
    //   //   // filter,
    //   // });
    //   return this.doFetch();
    // },
    // async doFilterInBackground() {
    //   // const filter = {postStatus:"accepted"}
    //   // return this.doFetchInBackground({
    //   //   filter,
    //   // });
    //   return this.doFetchInBackground()
    // },
  },

};
