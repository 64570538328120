import firebase from 'firebase/app';
import 'firebase/auth';        // for authentication
import 'firebase/storage';     // for storage
import 'firebase/database';    // for realtime database
import 'firebase/firestore';   // for cloud firestore
import 'firebase/messaging';   // for cloud messaging
import 'firebase/functions';   // for cloud functions
import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-auth';
import firebaseInit from '@/shared/firebase/firebase-init';
import Message from '@/shared/message/message';
import { i18n } from '@/i18n';

export default class AuthService {
  static init() {
    return firebaseInit();
  }
  static onAuthStateChanged(
    callbackSuccess,
    callbackError,
  ) {
    return firebase
      .auth()
      .onAuthStateChanged(callbackSuccess, callbackError);
  }

  static get_browser() {
    var ua = navigator.userAgent, tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []; 
    if(/trident/i.test(M[1])){
        tem=/\brv[ :]+(\d+)/g.exec(ua) || []; 
          return {name:'IE',version:(tem[1]||'')};
          }   
      if(M[1]==='Chrome'){
          tem=ua.match(/\bOPR|Edge\/(\d+)/)
          if(tem!=null)   {return {name:'Opera', version:tem[1]};}
          }   
      M=M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
      if((tem=ua.match(/version\/(\d+)/i))!=null) {M.splice(1,1,tem[1]);}
      return {
        name: M[0],
        version: M[1]
      };
  }


  static async sendWelcomeEmail(email, name) {
    return this.sendWelcomeEmailFromBackend(email, name);
  }
  static async sendWelcomeEmailFromBackend(email, name) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation AUTH_SEND_WELCOME_EMAIL($email: String!, $name: String) {
          authSendWelcomeEmail(email: $email, name: $name)
        }
      `,
      variables: {
        email,
        name,
      },
    });

    const res = response.data.authSendWelcomeEmail
    return res;
  }

  static async sendEmailVerification(authenticationUser) {
    // if (await this.isEmailConfigured()) {
    //   return this.sendEmailVerificationFromBackend();
    // }

    return this.sendEmailVerificationFromClient(authenticationUser);
  }

  static async sendEmailVerificationFromBackend() {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation AUTH_SEND_EMAIL_ADDRESS_VERIFICATION_EMAIL {
          authSendEmailAddressVerificationEmail
        }
      `,
    });

    return response.data
      .authSendEmailAddressVerificationEmail;
  }

  static async sendEmailVerificationFromClient(authenticationUser) {
    return authenticationUser.sendEmailVerification();
  }

  static async sendPasswordResetEmail(email) {
    
    if (await this.isEmailConfigured()) {
      return this.sendPasswordResetEmailFromBackend(email);
    }

    return this.sendPasswordResetEmailFromClient(email);
  }

  static async sendPasswordResetEmailFromBackend(email) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation AUTH_SEND_PASSWORD_RESET_EMAIL(
          $email: String!
        ) {
          authSendPasswordResetEmail(email: $email)
        }
      `,
      variables: {
        email,
      },
    });

    return response.data.authSendPasswordResetEmail;
  }

  static async sendPasswordResetEmailFromClient(email) {
    return firebase.auth().sendPasswordResetEmail(email);
  }

  static async checkEmailVerified() {
    await firebase.auth().currentUser.reload();
    await firebase
      .auth()
      .onAuthStateChanged((authenticationUser) => {
        if (authenticationUser.emailVerified) {
          window.location.reload();
        } else {
          Message.error(i18n('Email not verified yet'));
        }
      });
  }

  static async registerWithEmailAndPassword(
    email,
    password,
  ) {
    const credentials = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password);
    this.sendEmailVerification(credentials.user);
    return credentials.user;
  }

  static async signinWithSocial(
    provider,
    rememberMe = false,
  ) {
    const persistence = rememberMe
      ? firebase.auth.Auth.Persistence.LOCAL
      : firebase.auth.Auth.Persistence.SESSION;

    await firebase.auth().setPersistence(persistence);

    const providers = {
      google: firebase.auth.GoogleAuthProvider,
      facebook: firebase.auth.FacebookAuthProvider,
      twitter: firebase.auth.TwitterAuthProvider,
    };

    return firebase
      .auth()
      .signInWithPopup(new providers[provider]());
  }

  static async signinWithEmailAndPassword(
    email,
    password,
    rememberMe = false,
  ) {
    const persistence = rememberMe
      ? firebase.auth.Auth.Persistence.LOCAL
      : firebase.auth.Auth.Persistence.SESSION;

    await firebase.auth().setPersistence(persistence);

    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password);
  }

  // From Ghada
  static async addPendingRole(user) {
    const updatedUser = user
    updatedUser.roles.push('pending')
    await firebase.firestore().collection("user").doc(user.id).set(updatedUser)
    // const credentials = await firebase
    //   .auth()
    //   .createUserWithEmailAndPassword(email, password);
    // this.sendEmailVerification(credentials.user);
    // return credentials.user;
  }

  static async fetchMe() {
    const response = await graphqlClient.query({
      query: gql`
        {
          authMe {
            id
            authenticationUid
            fullName
            firstName
            lastName
            phoneNumber
            email
            roles
            disabled
            avatar
            accountType
            countryCode
            countryName
          }
        }
      `,
    });

    return response.data.authMe;
  }

  static async authCheck(phoneNumber) {
    const response = await graphqlClient.query({
      query: gql`
        {
          authCheck($phoneNumber : String!) {
            authCheck(phoneNumber: $phoneNumber)
          }
        }
      `,
      variables: {
        phoneNumber
      },
    });

    return response.data.authCheck;
  }

  static async authAdd(phoneNumber) {
    
    const response = await graphqlClient.mutate({
      mutation: gql`
        
          mutation authAdd($phoneNumber : String!) {
            authAdd(phoneNumber: $phoneNumber)
          }
      `,
      variables: {
        phoneNumber
      },
    });
    
    return response.data.authAdd;
  }

  static async isEmailConfigured() {
    const response = await graphqlClient.query({
      query: gql`
        {
          authIsEmailConfigured
        }
      `,
    });

    return response.data.authIsEmailConfigured;
  }

  // static async reauthenticateWithStorageToken() {
  //   try {
  //     const response = await graphqlClient.query({
  //       query: gql`
  //         {
  //           authStorageToken
  //         }
  //       `,
  //     });

  //     const token = response.data.authStorageToken;
  //     return firebase.auth().signInWithCustomToken(token);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }


  static async reauthenticateWithStorageToken() {
    try {
      let token = localStorage.getItem('token');
      if (!token) {
        const response = await graphqlClient.query({
          query: gql`
            {
              authStorageToken
            }
          `,
        });

        token = response.data.authStorageToken;
        localStorage.setItem(
          'token',
          this.encryptString(token, 'secret token'),
        );
      } else {
        token = this.decryptString(token, 'secret token');
      }

      return firebase.auth().signInWithCustomToken(token);
    } catch (error) {
      // console.error(error);
      // throw error
    }
  }

  static signout() {
    return firebase.auth().signOut();
  }

  static async updateProfile(
    fullName,
    phoneNumber,
    avatar,
    email,
    countryCode,
    countryName
  ) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation AUTH_UPDATE_PROFILE(
          $profile: UserProfileInput!
        ) {
          authUpdateProfile(profile: $profile)
        }
      `,

      variables: {
        profile: {
          fullName,
          phoneNumber,
          avatar,
          email,
          countryCode,
          countryName
        },
      },
    });
    return response.data.authUpdateProfile;
  }


  //#region [ Encryption Functions ]
  // ================================================================= //
  //                          ENCRYPT OBJECT                           //
  // ================================================================= //
  static encryptObject(object, key) {
    const cryptoJSON = require('crypto-json');
    const algorithm = 'aes256';
    const encoding = 'hex';
    const password = key;
    let keys = [];

    for (let [k, value] of Object.entries(object)) {
      if (value) {
        keys.push(k);
      }
    }
    const output = cryptoJSON.encrypt(object, password, {
      encoding,
      keys,
      algorithm,
    });
    return output;
  }
  // ================================================================= //
  //                          DECRYPT OBJECT                           //
  // ================================================================= //
  static decryptObject(encryptedObject, key) {
    const cryptoJSON = require('crypto-json');
    const algorithm = 'aes256';
    const encoding = 'hex';
    const password = key;
    let keys = [];

    for (let [k, value] of Object.entries(
      encryptedObject,
    )) {
      if (value) {
        keys.push(k);
      }
    }

    const output = cryptoJSON.decrypt(
      encryptedObject,
      password,
      {
        encoding,
        keys,
        algorithm,
      },
    );
    return output;
  }

  // ================================================================= //
  //                          ENCRYPT STRING                           //
  // ================================================================= //
  static encryptString(message, key) {
    const CryptoJS = require('crypto-js');

    // Encrypt
    var ciphertext = CryptoJS.AES.encrypt(message, key);
    return ciphertext;
  }

  // ================================================================= //
  //                          DECRYPT STRING                           //
  // ================================================================= //
  static decryptString(ciphertext, key) {
    const CryptoJS = require('crypto-js');

    // Decrypt
    var bytes = CryptoJS.AES.decrypt(
      ciphertext.toString(),
      key,
    );
    var plaintext = bytes.toString(CryptoJS.enc.Utf8);
    return plaintext;
  }
  // ================================================================= //
  //                      Object ENCRYPTION OTHER WAY                  //
  // ================================================================= //
  static encryption(data, key) {
    const CryptoJS = require('crypto-js');

    // Encrypt
    var ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      key,
    );
    return ciphertext;
  }

  // ================================================================= //
  //                      Object DECRYPTION OTHER WAY                  //
  // ================================================================= //
  static decryption(cipherData, key) {
    var CryptoJS = require('crypto-js');

    // Decrypt
    var bytes = CryptoJS.AES.decrypt(
      cipherData.toString(),
      key,
    );
    var decryptedData = JSON.parse(
      bytes.toString(CryptoJS.enc.Utf8),
    );
    return decryptedData;
  }
  //#endregion
}
