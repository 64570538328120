//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import OfferListTable from '@/modules/offer/components/offer-list-table.vue';
import OfferFormModal from '@/modules/offer/components/offer-form-modal.vue';

import { mapGetters, mapActions } from 'vuex';
import { OfferPermissions } from '@/modules/offer/offer-permissions';
// import firebase from 'firebase';
// import firebase from 'firebase/app';
import 'firebase/auth';        // for authentication
import 'firebase/storage';     // for storage
import 'firebase/database';    // for realtime database
import 'firebase/firestore';   // for cloud firestore
import 'firebase/messaging';   // for cloud messaging
import 'firebase/functions';   // for cloud functions
import i18n from '@/vueI18n'


export default {
  name: 'app-offer-list-page',

  components: {
    [OfferListTable.name]: OfferListTable,
    [OfferFormModal.name]: OfferFormModal,
  },
  data() {
    return {
      isListView: false,
      modalVisible: false,
      dialogVisible: false,
      successDialog: false,
    }
  },
  watch:{
    lang(){
            document.title =
                this.i18n('app.title') +
                ' | ' +
                this.i18n('menu.offer') 
        },
  },
  computed: {
    lang(){
            return i18n.locale
        },
    isRTL(){
        return i18n.locale == 'ar'
    },
    ...mapGetters({
      rows: 'offer/list/rows',
      count: 'offer/list/count',
      loading: 'offer/list/loading',
      is_screen_xs: 'layout/is_screen_xs',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      mountedTable: 'offer/list/mountedTable'
    }),
    hasPermissionToCreate() {
      return new OfferPermissions(this.currentUser).create;
    },
  },

  async created() {
    document.title =
                this.i18n('app.title') +
                ' | ' +
                this.i18n('menu.offer') 
    
    // if(!this.mountedTable){
    //   let language = this.isRTL ? 'ar' : 'en'
    //   await this.doFetch({limit: 10,searchBy: 'title',filter:'', language})
    //   this.setMountedTable(true)
    // }


    // let language = this.isRTL ? 'ar' : 'en'
    // if(this.$route.params.searchBy && this.$route.params.searchBy == "title"){
    //     if(this.$route.query.page){
    //       await this.doFetchFromRoute({page: this.$route.query.page, limit: 10, searchBy: this.$route.params.searchBy, filter: this.$route.query.filter ? this.$route.query.filter : '', language})
    //     }
    //     else{
    //       if(this.$route.query.filter){
    //         this.$router.push({
    //           // params: {
    //           //   searchBy: this.$route.params.searchBy
    //           // },
    //           query: {
    //             page: 1,
    //             filter: this.$route.query.filter
    //           }
    //         })
    //       }
    //       else{
    //         this.$router.push({
    //           // params: {
    //           //   searchBy: this.$route.params.searchBy
    //           // },
    //           query: {
    //             page: 1,
    //           }
    //         })
    //       }
    //       await this.doFetchFromRoute({page: 1, limit: 10, searchBy: this.$route.params.searchBy ? this.$route.params.searchBy : 'title', filter: this.$route.query.filter ? this.$route.query.filter : '', language})
    //     }
    //   }
    //   else{
    //     
    //     this.$router.push({
    //       params: {
    //         searchBy: 'title'
    //       },
    //       query: {
    //         page: 1,
    //         // filter: null
    //       }
    //     })
    //     await this.doFetch({limit: 10,searchBy: 'title',filter:'', language})
    //   }

  },
  destroyed(){
    this.resetCursor()
  },
  // async mounted() {
  //   // await this.doFilter();
  // },
  methods: {
    i18n(key, args) {
      return this.$t(key, args);
    },
    ...mapActions({
      doFetch: 'offer/list/doFetch',
      doFetchFromRoute: 'offer/list/doFetchFromRoute',
      setMountedTable:'offer/list/setMountedTable',
      doFetchInBackground: 'offer/list/doFetchInBackground',
      resetCursor: 'offer/list/resetCursor'
    }),

    // displaySwitch(view) {
    //   switch (view) {
    //     case 'card':
    //       this.isListView = false
    //       break;
    //     case 'list':
    //       this.isListView = true
    //       break;
    //     default:
    //       break;
    //   }
    // },
    doOpenModal() {
      this.dialogVisible = true;
      this.modalVisible = true;
    },
    onModalClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.modalVisible = false;
      }, 500);
    },
    onModalSuccess() {
      this.successDialog = true
      
    },
  
    async doFilter() {
      // filterSchema.cast(this.model);
      const filter = {}
      return this.doFetch({
        filter,
      });
    },
    async doFilterInBackground() {
      const filter = {}
      return this.doFetchInBackground({
        filter,
      });
    },
  },

};
