//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import JobListTable from '@/modules/job/components/job-list-table.vue';
// import JobFormModal from '@/modules/job/components/job-form-modal.vue';
import { mapGetters, mapActions } from 'vuex';
import { JobPermissions } from '@/modules/job/job-permissions';
// import firebase from 'firebase';
// import firebase from 'firebase/app';
import 'firebase/auth';        // for authentication
import 'firebase/storage';     // for storage
import 'firebase/database';    // for realtime database
import 'firebase/firestore';   // for cloud firestore
import 'firebase/messaging';   // for cloud messaging
import 'firebase/functions';   // for cloud functions
import i18n from '@/vueI18n';

export default {
  name: 'app-job-list-page',
  components: {
    [JobListTable.name]: JobListTable,
    // [JobFormModal.name]: JobFormModal,
  },
  data() {
    return {
      isListView: false,
      modalVisible: false,
      dialogVisible: false,
      searchByOptions: ['name', 'address', 'phoneNumber', 'emailAddress', 'postStatus', 'status'],
      page: 1
    }
  },
  computed: {
    lang(){
                
            return i18n.locale
        },
    ...mapGetters({
      mountedTable: 'job/list/mountedTable',
      rows: 'job/list/rows',
      count: 'job/list/count',
      loading: 'job/list/loading',
      is_screen_xs: 'layout/is_screen_xs',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
    }),
    hasPermissionToCreate() {
      return new JobPermissions(this.currentUser).create;
    },
  },
  watch:{
    lang(){
            document.title =
                this.i18n('app.title') +
                ' | ' +
                this.i18n('menu.job') 
        },
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args);
    },
      ...mapActions({
        // doFetch: 'job/list/doFetch',
        // doFetchFromRoute: 'job/list/doFetchFromRoute',
        // doFetchJobPositions: 'job/view/doFetchJobPositions',
        // doFetchWorkplaces: 'job/view/doFetchWorkplaces',
        // doFetchCities: 'job/view/doFetchCities',
        // setDisablePrevious:'job/list/setDisablePrevious',
        // setDisableNext:'job/list/setDisableNext',
        // doFetchInBackground: 'job/list/doFetchInBackground',
        setMountedTable: 'job/list/setMountedTable',
        resetCursor: 'job/list/resetCursor'
      }),
         doOpenModal() {
      this.dialogVisible = true;
      this.modalVisible = true;
    },
    onModalClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.modalVisible = false;
      }, 500);
    },
    onModalSuccess() {
      this.onModalClose();
    },
  
    async doFilter() {
      // filterSchema.cast(this.model);
      const filter = {}
      return this.doFetch({
        filter,
      });
    },
    async doFilterInBackground() {
      const filter = {}
      return this.doFetchInBackground({
        filter,
      });
    },
    // async navigateBackwardFroward() {
    //   console.log("navigateBackwardFroward", this.$route);
    //   if (
    //     this.$route.name == "job" 
    //   ) {
    //     console.log("call fun here");
    //     
    //     // this.search = this.$route.params.searchBy
    //     // this.filter = this.$route.query.filter ? this.$route.query.filter
    //     // this.page = this.$route.query.page && Number.isInteger(this.$route.query.page) ? parseInt(this.$route.query.page) : 1
    //     // this.setRows([]);
    //     this.resetCursor();
    //     console.log(this.$route.params.searchBy);
    //     if (
    //       this.$route.params.searchBy &&
    //       this.searchByOptions.includes(this.$route.params.searchBy) 
    //       // && this.$route.query.filter
    //     ) {
    //       await this.doFetchFromRoute({page: this.$route.query.page ? this.$route.query.page : 1, limit: 10, searchBy: this.$route.params.searchBy, filter: this.$route.query.filter ? this.$route.query.filter : ''})
    //     } else {
    //       if (
    //         this.$route.query.page &&
    //         parseInt(this.$route.query.page) > 0 &&
    //         Number.isInteger(this.$route.query.page)
    //       ) {
    //         await this.doFetchFromRoute({
    //           page: parseInt(this.$route.query.page),
    //           limit: 10,
    //         });
    //       } else {
    //         
    //         this.page = 1
    //         await this.doFetch({
    //           page: 1,
    //           limit: 10,
    //         });
    //       }
    //     }
    //     this.rowsOld = this.rows;
    //   } else {
    //     console.log("do not call");
    //   }
    // },
  },
  // mounted() {
  //   var that = this;
  //   window.onpopstate = async function () {
  //     that.navigateBackwardFroward();
  //   };
  // },
  // async created() {
  //     this.doFetchWorkplaces()
  //     this.doFetchJobPositions()
  //     this.doFetchCities()
  //     console.log('$route.query',this.$route.query);
  //     if(this.$route.params.searchBy && this.searchByOptions.includes(this.$route.params.searchBy)){
  //       if(this.$route.query.page){
  //         await this.doFetchFromRoute({page: this.$route.query.page, limit: 10, searchBy: this.$route.params.searchBy, filter: this.$route.query.filter ? this.$route.query.filter : ''})
  //       }
  //       else{
  //         if(this.$route.query.filter){
  //           this.$router.push({
  //             // params: {
  //             //   searchBy: this.$route.params.searchBy
  //             // },
  //             query: {
  //               page: 1,
  //               filter: this.$route.query.filter
  //             }
  //           })
  //         }
  //         else{
  //           this.$router.push({
  //             // params: {
  //             //   searchBy: this.$route.params.searchBy
  //             // },
  //             query: {
  //               page: 1,
  //             }
  //           })
  //         }
          
  //         await this.doFetchFromRoute({page: 1, limit: 10, searchBy: this.$route.params.searchBy ? this.$route.params.searchBy : 'name', filter: this.$route.query.filter ? this.$route.query.filter : ''})
  //       }
  //     }
  //     else{
  //       this.$router.push({
  //         params: {
  //           searchBy: 'name'
  //         },
  //         query: {
  //           page: 1,
  //           // filter: null
  //         }
  //       })
  //       await this.doFetch({limit: 10,searchBy: 'name',filter:''})
  //     }    
  // },
  created(){
      document.title =
                this.i18n('app.title') +
                ' | ' +
                this.i18n('menu.job') 
  },
  destroyed(){
    this.resetCursor()
  }
};
