//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SettingListTable from '@/modules/setting/components/setting-list-table.vue';

export default {
    name: 'app-setting-list-page',

  components: {
    [SettingListTable.name]: SettingListTable,
    // [OfferFormModal.name]: OfferFormModal,
  },

}
