//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { UserModel } from '@/modules/auth/user-model';
import i18n from '@/vueI18n';
import VuePhoneNumberInput from 'vue-phone-number-input';


// import { FileUploader } from '@/shared/firebase/file-uploader';


// import { FormSchema } from '@/shared/form/form-schema';

const { fields } = UserModel;

// const formSchema = new FormSchema([
//   fields.firstName,
//   fields.lastName,
//   fields.phoneNumber,
//   fields.avatarsProfile,
// ]);

// import { VueAvatar } from 'vue-avatar-editor-improved'
import { mapGetters,mapActions } from 'vuex';
import DeleteDialog from '@/modules/home/components/delete-dialog.vue';
// import { i18n } from "@/i18n";
// import firebase from 'firebase/app';
import firebase from 'firebase/app';
import 'firebase/auth';        // for authentication
import 'firebase/storage';     // for storage
import 'firebase/database';    // for realtime database
import 'firebase/firestore';   // for cloud firestore
import 'firebase/messaging';   // for cloud messaging
import 'firebase/functions';   // for cloud functions
import 'firebase/firestore';
export default {
    name:"app-iam-profile",
    props:["usage"],
    data(){
        return{
            nameEnter:false,
            firstName:'',
            lastName:'',
            fullName:'',
            email:'',
            validEmail:true,
            validPhone:false,
            phoneNumber:'',
            phoneNumberFormatted: '',
            rotation: 0,
            scale: 1,
            deleteUser:false,
            user:null,
            // loading: false,
            image:'',
            path:'user/attachments',
            fileName:'',
            file:'',
            rawFile:null,
            downloadURL: null,
            avatar: null,
            progress:0,
            enabledDisabled:false,
            modelMultiple: [],
            options: [
                'owner', 'editor'
            ],
            loadingUploadImage: false,
            exceedAvatarSize: false,
            TRansObject: {
                countrySelectorLabel: 'Code Country',
                countrySelectorError: 'Choose a country',
                phoneNumberLabel: 'Phone Number',
                example: 'Example :',
            },
            TRansObjectAr: {
                countrySelectorLabel: 'كود البلد',
                countrySelectorError: 'اختر دولة',
                phoneNumberLabel: 'رقم الجوال',
                example: 'مثال :',
            },

          countryName: "EG",
          countryCode: "+20"
        }
    },
    computed:{
        progressBar(){
            return this.progress / 100
        },
      ...mapGetters({
        currentUser: 'auth/currentUser',
        record:'iam/view/record',
        loading: 'iam/view/loading',
        saveLoading: 'iam/form/saveLoading',
        loadingUpdateProfile: 'auth/loadingUpdateProfile'
      }),
        fields() {
            return fields;
        },
        isRTL(){
             return i18n.locale == 'ar'
        },
        language(){
            return i18n.locale
        }
    },
components:{
    [DeleteDialog.name]: DeleteDialog,
    VuePhoneNumberInput,

},
    mounted(){
        this.$q.iconSet.field.error = "img:/images/invalid-email.png"
        
        
    },
    async created(){

        if(this.usage == "adminProfile"){
          await this.doFind({id:this.$route.params.id})
            this.user = this.record
            
        }
        if(this.usage == "ownProfile"){
            this.user = this.currentUser
        }
        // this.firstName = this.user.firstName
        // this.lastName = this.user.lastName
        this.fullName = this.user.fullName
        this.email = this.user.email
        this.phoneNumber = this.user.phoneNumber
        this.validPhone = true
        this.countryCode = this.user.countryCode ? this.user.countryCode : '+20'
        this.countryName = this.user.countryName ? this.user.countryName : 'EG'
        if(this.user){
          if(this.user.disabled)
            this.enabledDisabled = this.user.disabled
            this.modelMultiple = []
            if(this.user.roles){
                this.user.roles.forEach((role) => {
                    this.modelMultiple.push(role)
                })
            }  
        }
        
       
    },
  methods: {
      ...mapActions({
          doRefreshCurrentUser: 'auth/doRefreshCurrentUser',
          doFind:'iam/view/doFind',
          doUpdateProfile: 'auth/doUpdateProfile',
          doUpdate: 'iam/form/doUpdate'
      }),
      async removeNode(){
        await firebase.database().ref('drug_update').on("child_added", (snapshot) => {
          
        snapshot.ref.remove();
      });
      },
    onPhoneUpdate(data) {
      this.countryCode = '+' + data.countryCallingCode;
      this.countryName = data.countryCode    
      this.validPhone = data.isValid
      this.errorMessage = ''
      this.phoneNumberFormatted = data.formattedNumber
      // this.validatePhone(data.formattedNumber)
    },
    async doSubmit(){
            if(this.usage == "ownProfile"){
                if(!this.rawFile){
                    this.avatar = this.currentUser.avatar
                    
                    await this.doUpdateProfile({
                        fullName: this.fullName,
                        phoneNumber: this.phoneNumber,
                        countryCode: this.countryCode,
                        countryName: this.countryName,
                        avatar: this.avatar,
                    })
                }
                else{                    
                    this.uploadFromRequest()
                }            
            }
            if(this.usage == "adminProfile"){

                if(!this.rawFile){
                    this.avatar = this.record.avatar
                    await this.doUpdate({
                    id: this.$route.params.id,
                    fullName:this.fullName,
                    phoneNumber: this.phoneNumber,
                    countryCode: this.countryCode,
                    countryName: this.countryName,
                    avatar: this.avatar,
                    roles: this.modelMultiple,
                    disabled: this.enabledDisabled
                })
                }
                else{
                    this.uploadFromRequest()
                }
                // this.$router.push(`/${this.language}/iam/admin`)

            }



    },
    i18n(key, args) {
      // return i18n(key, args);
      return this.$t(key, args);

    }, 
  
    validateEmail(email) {
            const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        if(re.test(String(email).toLowerCase())){
            this.validEmail = true
            return true
        }
        else{
            this.validEmail = false
            return false
        }  
            
    },
    validatePhone(inputtxt){
            const phoneno = /^\d{11}$/
            if (phoneno.test(inputtxt)){
                this.validPhone = true
                return true; 
            }
            else {
                this.validPhone = false
                return false;
            } 
    },
    close(){
          this.deleteUser = false
    },
    saveClicked () {
          var img = this.$refs.vueavatar.getImageScaled()
          this.$refs.image.src = img.toDataURL()
    },
    onImageReady () {
          this.scale = 1
          this.rotation = 0
    },
    // input(value){
    //     console.log('value',value)
    //       this.image = value[0]['publicUrl']
    //   },
    openImageUpload(){
        const fileUpload = document.getElementById('file-upload')
        fileUpload.click()
    },
    async readUrl(event){
       const profilePicture = document.getElementById('profile-pic')
        if (event.target.files && event.target.files[0]) {
            this.rawFile = event.target.files[0]
            if(this.rawFile.size / 1000000 > 1){
                this.exceedAvatarSize = true
            }
            else{
                this.exceedAvatarSize = false
            }
            this.fileName = event.target.files[0].name
            var reader = new FileReader();
            reader.onload = function (e) {
                profilePicture.src =  e.target.result;
                this.file = e.target.result
            }
    
            reader.readAsDataURL(event.target.files[0]);
            
        }
    },
    async uploadFromRequest() {
    var storage = firebase.storage().ref();
    var uploadTask = null
    if(this.usage == 'ownProfile'){
        uploadTask = storage.child(`user/${this.currentUser.id}/avatar`).put(this.rawFile);
    }
    if(this.usage == 'adminProfile'){
        uploadTask = storage.child(`user/${this.user.id}/avatar`).put(this.rawFile);
    }


    uploadTask.on('state_changed', // or 'state_changed'
  (snapshot) => {
    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
    this.loadingUploadImage = true
    this.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    switch (snapshot.state) {
      case firebase.storage.TaskState.PAUSED: // or 'paused'
        break;
      case firebase.storage.TaskState.RUNNING: // or 'running'
        break;
    }
    
  }, 
  (error) => {
    // A full list of error codes is available at
    // https://firebase.google.com/docs/storage/web/handle-errors
    switch (error.code) {
      case 'storage/unauthorized':
        // User doesn't have permission to access the object
        break;
      case 'storage/canceled':
        // User canceled the upload
        break;

      // ...

      case 'storage/unknown':
        // Unknown error occurred, inspect error.serverResponse
        break;
    }
  }, 
  () => {
    // Upload completed successfully, now we can get the download URL
    uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
      this.downloadURL = downloadURL
      if(this.usage == 'ownProfile'){
        await this.doUpdateProfile({
            fullName: this.fullName,
            phoneNumber: this.phoneNumber,
            countryCode: this.countryCode,
            countryName: this.countryName,
            avatar: this.downloadURL,
        })
      }
      if(this.usage == "adminProfile"){
           await this.doUpdate({
                id: this.user.id,
                fullName:this.fullName,
                phoneNumber: this.phoneNumber,
                countryCode: this.countryCode,
                countryName: this.countryName,
                avatar: this.downloadURL,
                roles: this.modelMultiple,
                disabled: this.enabledDisabled
            })
      }
       
    });
    this.loadingUploadImage = false
  }
);
    },
  }
}
