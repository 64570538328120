//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
// import { i18n } from "@/i18n";
import { routerAsync } from '@/app-module';
import i18n from '@/vueI18n'

export default {
  name: 'app-auth-layout',

  computed: {
    ...mapGetters({
      isMobile: 'layout/is_screen_xs',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      currentUser: 'auth/currentUser'
    }),
    lang(){
      return i18n.locale
    },
    isRTL() {
      // return getLanguageCode() == 'ar'
      // return localStorage.getItem('language') == 'ar'
      return i18n.locale == 'ar'
    },
    btnLabel() {
      let label = ''
      const routePath = this.$route.path
      if (routePath.includes('/auth/signin')) {
        label = this.i18n('common.createNewAccount')
        // label = this.i18n('drugDeal.auth.createNewAccount')
        // label = this.$t('drugDeal.auth.createNewAccount',...([]))
      } else {
        label = this.i18n('drugDeal.auth.login')
      } 
      return label
    },
  },
  methods: {
    // i18n(key, args) {
    //   return i18n(key, args);
    // },
    i18n(key, args) {
      // return this.$t(key, args);
      return this.$t(key, ...(args || []));

    },
    goToRegister() {
      // console.log("go to register");
      var elmnt = document.getElementById("register");
      elmnt.scrollIntoView({ behavior: "smooth", block: "end" });
    },
    ...mapActions({
      doSignout: 'auth/doSignout',

    }),
    async doRouteTo() {
      
      const routePath = this.$route.path
      if(this.currentUser){
          await this.doSignout()
        }
      if (routePath.includes('/auth/signin')) {
        routerAsync().push(`/${this.lang}/auth/signup`);
      } else {
        if (this.$route.path !== `/${this.lang}/auth/signin`)
        routerAsync().push(`/${this.lang}/auth/signin`);
      } 
    },
  },
};
