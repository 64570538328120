import { ContactService } from '@/modules/contact/contact-service';
// import offerListExporterFields from '@/modules/offer/offer-list-exporter-fields';
import Errors from '@/shared/error/errors';
// import Exporter from '@/shared/exporter/exporter';
import { firestoreAction,vuexfireMutations } from 'vuexfire'
import firebase from 'firebase/app';

const INITIAL_PAGE_SIZE = 20;

export default {
  namespaced: true,

  state: {
    rows: [],
    rowsFromRoute: [],
    // //#region  vuexfire variables
    loadMoreLastRecord: null,
    loadFirstRecord: null,
    recordAfterLast: [],
    recordBeforeFirst: [],
    totalRecords: null,
    pagesNumber: null,
    pageCount: 0,
    mountedTable: false,
    // //#endregion

    count: 0,
    loading: false,
    backgroundLoading: false,
    filter: {},
    pagination: {},
    sorter: {},

    table: null,
  },

  getters: {
    backgroundLoading: (state) => state.backgroundLoading,
    loading: (state) => state.loading,

    exportLoading: (state) => state.exportLoading,

    rows: (state) => state.rows || [],
    rowsFromRoute: (state) => state.rowsFromRoute,

    // //#region vuexfire getters

    mountedTable: (state) => state.mountedTable,
    loadMoreLastRecord: (state) => {
      return state.loadMoreLastRecord
    },
    loadFirstRecord: (state) =>{
      return state.loadFirstRecord
    },
    recordAfterLast: (state) => state.recordAfterLast,
    recordBeforeFirst: (state) => state.recordBeforeFirst,
    totalRecords: (state) => state.totalRecords,
    pagesNumber: (state) => state.pagesNumber,
    pageCount: (state) => state.pageCount,

    // //#endregion

    count: (state) => state.count,

    hasRows: (state, getters) => getters.count > 0,

    orderBy: (state) => {
      const sorter = state.sorter;

      if (!sorter) {
        return null;
      }

      if (!sorter.prop) {
        return null;
      }

      let direction =
        sorter.order === 'descending' ? 'DESC' : 'ASC';

      return `${sorter.prop}_${direction}`;
    },

    filter: (state) => state.filter,

    limit: (state) => {
      const pagination = state.pagination;

      if (!pagination || !pagination.pageSize) {
        return INITIAL_PAGE_SIZE;
      }

      return pagination.pageSize;
    },

    offset: (state) => {
      const pagination = state.pagination;

      if (!pagination || !pagination.pageSize) {
        return 0;
      }

      const currentPage = pagination.currentPage || 1;

      return (currentPage - 1) * pagination.pageSize;
    },

    pagination: (state, getters) => {
      return {
        ...state.pagination,
        total: getters.count,
        showSizeChanger: true,
      };
    },

    selectedRows: (state) => {
      return state.table ? state.table.selection : [];
    },
  },

  mutations: {
    RESETED(state) {
      state.rows = [];
      state.count = 0;
      state.loading = false;
      state.filter = {};
      state.pagination = {};
      state.sorter = {};
      if (state.table) {
        state.table.clearSelection();
      }
    },

    UNSELECT_ALL(state) {
      if (state.table) {
        state.table.clearSelection();
      }
    },

    TABLE_MOUNTED(state, payload) {
      state.table = payload;
    },

    PAGINATION_CHANGED(state, payload) {
      state.pagination = payload || {};
    },

    PAGINATION_CURRENT_PAGE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: payload || 1,
        pageSize:
          previousPagination.pageSize || INITIAL_PAGE_SIZE,
      };
    },

    PAGINATION_PAGE_SIZE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: previousPagination.currentPage || 1,
        pageSize: payload || INITIAL_PAGE_SIZE,
      };
    },

    SORTER_CHANGED(state, payload) {
      state.sorter = payload || {};
    },

    FETCH_STARTED_IN_BACKGROUND(state, payload) {
      state.backgroundLoading = true;
      if (state.table) {
        state.table.clearSelection();
      }

      state.filter =
        payload && payload.filter ? payload.filter : {};
      state.pagination =
        payload && payload.keepPagination
          ? state.pagination
          : {};
    },

    FETCH_STARTED(state) {
      state.loading = true;

      // if (state.table) {
      //   state.table.clearSelection();
      // }

      // state.filter =
      //   payload && payload.filter ? payload.filter : {};
      // state.pagination =
      //   payload && payload.keepPagination
      //     ? state.pagination
      //     : {};
    },

    FETCH_SUCCESS(state) {
      state.loading = false;
      // state.backgroundLoading = false;
      // state.rows = payload.rows;
      // state.count = payload.count;
    },

    FETCH_ERROR(state) {
      state.loading = false;
      // state.backgroundLoading = false;
      // state.rows = [];
      // state.count = 0;
    },

    EXPORT_STARTED(state) {
      state.exportLoading = true;
    },

    EXPORT_SUCCESS(state) {
      state.exportLoading = false;
    },

    EXPORT_ERROR(state) {
      state.exportLoading = false;
    },


     // //#region VuexfireMutations
     ...vuexfireMutations,
     SET_MOUNTEDTABLE(state,payload){
       state.mountedTable = payload
     },
     SET_LOADMORE_LAST(state) {
       // state.rows.pop()
       // console.log('state.rows',state.rows.pop());
       state.loadMoreLastRecord = state.rows[state.rows.length - 1]._doc
       state.recordAfterLast = state.rows[state.rows.length - 1]
     },
     SET_LOADFIRSTRECORD(state){
       state.loadFirstRecord = state.rows[0]._doc
     },
     RESET_CURSOR(state){
       state.loadMoreLastRecord = null
       state.loadFirstRecord = null
     },
     REMOVELASTELEMENT(state){
       state.rows.pop()
     },
     SET_TOTAL_RECORDS(state,payload){
       state.totalRecords = payload
       state.pagesNumber = Math.ceil(state.totalRecords / 10)
     },
     SET_PAGE_COUNT(state,payload){
       state.pageCount = payload
     },
     SET_ROWS(state, payload){
      state.rows = payload
    },
     // //#endregion
  },

  actions: {
    doUnselectAll({ commit }) {
      commit('UNSELECT_ALL');
    },

    doMountTable({ commit }, table) {
      commit('TABLE_MOUNTED', table);
    },

    async doReset({ commit, dispatch }) {
      commit('RESETED');
      return dispatch('doFetch');
    },

    // async doExport({ commit, getters }) {
    //   try {
    //     if (!offerListExporterFields || !offerListExporterFields.length) {
    //       throw new Error('offerListExporterFields is required');
    //     }

    //     commit('EXPORT_STARTED');

    //     const filter = getters.filter;

    //     await OfferService.list(
    //       filter,
    //       getters.orderBy,
    //       null,
    //       null,
    //       response => {
    //         new Exporter(
    //           offerListExporterFields,
    //           'offer',
    //         ).transformAndExportAsExcelFile(response.rows);
    //       }
    //     );

    //     // new Exporter(
    //     //   offerListExporterFields,
    //     //   'offer',
    //     // ).transformAndExportAsExcelFile(response.rows);

    //     commit('EXPORT_SUCCESS');
    //   } catch (error) {
    //     Errors.handle(error);

    //     commit('EXPORT_ERROR');
    //   }
    // },

    doChangePagination(
      { commit, getters, dispatch },
      pagination,
    ) {
      commit('PAGINATION_CHANGED', pagination);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },

    doChangePaginationPageSize(
      { commit, getters, dispatch },
      pageSize,
    ) {
      commit('PAGINATION_PAGE_SIZE_CHANGED', pageSize);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },

    doChangePaginationCurrentPage(
      { commit, getters, dispatch },
      currentPage,
    ) {
      commit(
        'PAGINATION_CURRENT_PAGE_CHANGED',
        currentPage,
      );
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },

    doChangeSort({ commit, getters, dispatch }, sorter) {
      commit('SORTER_CHANGED', sorter);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },

    // //#region vuexfire
    setMountedTable({commit},value){
      commit('SET_MOUNTEDTABLE',value)
    },
    doFetch:  firestoreAction(async ({bindFirestoreRef,getters,commit},payload) => {
      // getters.pageCount = getters.pageCount + 1
      commit('SET_PAGE_COUNT',getters.pageCount + 1)
      commit('FETCH_STARTED')
      const customSerializer = (doc) => {
        const data = doc.data()
        Object.defineProperty(data, '_doc', { value: doc })
        return data
      }
      const db = firebase.firestore()

      // var contactCollection = getters.loadMoreLastRecord ? 
      // db.collection('contact')
      // .limit(payload.limit || 10)
      // .orderBy('name','asc')
      // .startAfter(getters.loadMoreLastRecord) :
      // db.collection('contact')
      // .orderBy('name','asc')
      // .limit(payload.limit || 10)
      var contactCollection = null
      switch (payload.searchBy){
        case 'name':
          contactCollection = getters.loadMoreLastRecord ? 
            db.collection('contact')
            .limit(payload.limit || 10)
            .orderBy('name','asc')
            .startAt(payload.filter)
            .endAt(payload.filter + "\uf8ff")
            .startAfter(getters.loadMoreLastRecord) :
            db.collection('contact')
            .orderBy('name','asc')
            .limit(payload.limit || 10)
            .startAt(payload.filter)
            .endAt(payload.filter+"\uf8ff")
          break
        case 'email':
          contactCollection = getters.loadMoreLastRecord ? 
            db.collection('contact')
            .limit(payload.limit || 10)
            .orderBy('email','asc')
            .startAt(payload.filter)
            .endAt(payload.filter + "\uf8ff")
            .startAfter(getters.loadMoreLastRecord) :
            db.collection('contact')
            .orderBy('email','asc')
            .limit(payload.limit || 10)
            .startAt(payload.filter)
            .endAt(payload.filter+"\uf8ff")
          break
        case 'phone':
          contactCollection = getters.loadMoreLastRecord ? 
            db.collection('contact')
            .limit(payload.limit || 10)
            .orderBy('phone','asc')
            .startAt(payload.filter)
            .endAt(payload.filter + "\uf8ff")
            .startAfter(getters.loadMoreLastRecord) :
            db.collection('contact')
            .orderBy('phone','asc')
            .limit(payload.limit || 10)
            .startAt(payload.filter)
            .endAt(payload.filter+"\uf8ff")
          break
      }
      await bindFirestoreRef(
        'rows',
        contactCollection,
        { serialize: customSerializer }

      );
      commit('FETCH_SUCCESS')    
    }),
    doFetchFromRoute:  firestoreAction(async ({bindFirestoreRef,commit},payload) => {
      commit('FETCH_STARTED')
      const customSerializer = (doc) => {
        const data = doc.data()
        Object.defineProperty(data, '_doc', { value: doc })
        return data
      }
      const db = firebase.firestore()
      var contactCollection = null
      // const startAt = payload.page * payload.limit - payload.limit
      const endAt = payload.page * payload.limit
      contactCollection =              
          db.collection('contact')
          .orderBy(payload.searchBy,'asc')
          .limit(endAt)
          .where(payload.searchBy, '>=', payload.filter)
          .where(payload.searchBy, '<', payload.filter + "\uf8ff")
      await bindFirestoreRef(
        'rowsFromRoute',
        contactCollection,
        { serialize: customSerializer }
      );
      commit('FETCH_SUCCESS')    
    }),
    setRows({ commit }, payload){
      commit('FETCH_STARTED')
      commit('SET_ROWS',payload)
      commit('FETCH_SUCCESS')
    },
    setLoadMoreLast({commit}){
      commit('SET_LOADMORE_LAST')
    },
    resetCursor({commit}){
      commit('RESET_CURSOR')
    },
    setLoadFirstRecord({commit}){
      commit('SET_LOADFIRSTRECORD')
    },
    removeLastElement({commit}){
      commit('REMOVELASTELEMENT')
    },
    getPreviousRecordsBatch: firestoreAction(async ({bindFirestoreRef,getters,commit},payload) => {
      // getters.pageCount = getters.pageCount - 1
      commit('SET_PAGE_COUNT',getters.pageCount - 1)
      const customSerializer = (doc) => {
        const data = doc.data()
        // console.log('go to Previous ====>',data.name);
        Object.defineProperty(data, '_doc', { value: doc })
        return data
      }
      const db = firebase.firestore()
      // var contactCollection =  db.collection('contact')
      //   .orderBy('name','asc')
      //   .endBefore(getters.loadFirstRecord)
      //   .limitToLast(payload.limit || 10)
      var contactCollection = null      
      switch (payload.searchBy){
        case 'name':
          contactCollection = db.collection('contact')
            .orderBy('name','asc')
            .startAt(payload.filter)
            .endAt(payload.filter + "\uf8ff")
            .endBefore(getters.loadFirstRecord)
            .limitToLast(payload.limit || 10)
          break
        case 'email':
          contactCollection = db.collection('contact')
            .orderBy('email','asc')
            .startAt(payload.filter)
            .endAt(payload.filter + "\uf8ff")
            .endBefore(getters.loadFirstRecord)
            .limitToLast(payload.limit || 10)
          break
        case 'phone':
          contactCollection = db.collection('contact')
            .orderBy('phone','asc')
            .startAt(payload.filter)
            .endAt(payload.filter + "\uf8ff")
            .endBefore(getters.loadFirstRecord)
            .limitToLast(payload.limit || 10)
          break
      }
        await bindFirestoreRef(
          'rows',
          contactCollection
          ,
          { serialize: customSerializer }
        )
    }),
    setDisableNext: firestoreAction(async ({getters, bindFirestoreRef}, payload) => {
      const db = firebase.firestore()
      // var contactCollection = db.collection('contact')
      //     .orderBy('name', 'asc')
      //     .limit(1)
      //     .startAfter(getters.loadMoreLastRecord) 
      // console.log('loadMoreLastJob',getters.loadMoreLastRecord);
      var contactCollection = null
      switch (payload.searchBy){
        case 'name':
          contactCollection = db.collection('contact')
            .limit(1)
            .orderBy('name','asc')
            .startAt(payload.filter)
            .endAt(payload.filter + "\uf8ff")
            .startAfter(getters.loadMoreLastRecord)
          break
        case 'email':
          contactCollection = db.collection('contact')
            .limit(1)
            .orderBy('email','asc')
            .startAt(payload.filter)
            .endAt(payload.filter + "\uf8ff")
            .startAfter(getters.loadMoreLastRecord) 
          break
        case 'phone':
          contactCollection = db.collection('contact')
            .limit(1)
            .orderBy('phone','asc')
            .startAt(payload.filter)
            .endAt(payload.filter + "\uf8ff")
            .startAfter(getters.loadMoreLastRecord) 
          break
        
      }
      await bindFirestoreRef(
          'recordAfterLast',
          contactCollection
        )

    }),
    setDisablePrevious: firestoreAction(async ({getters, bindFirestoreRef}, payload) => {
      const db = firebase.firestore()
      // var contactCollection = db.collection('contact')
      //     .orderBy('name','asc')
      //     .endBefore(getters.loadFirstRecord)
      //     .limitToLast(1) 
      var contactCollection = null
      switch (payload.searchBy){
        case 'name':
          contactCollection = db.collection('contact')
            .orderBy('name','asc')
            .startAt(payload.filter)
            .endAt(payload.filter + "\uf8ff")
            .endBefore(getters.loadFirstRecord)
            .limitToLast(1) 
          break
        case 'email':
          contactCollection = db.collection('contact')
            .orderBy('email','asc')
            .startAt(payload.filter)
            .endAt(payload.filter + "\uf8ff")
            .endBefore(getters.loadFirstRecord)
            .limitToLast(1) 
          break
        case 'phone':
          contactCollection = db.collection('contact')
            .orderBy('phone','asc')
            .startAt(payload.filter)
            .endAt(payload.filter + "\uf8ff")
            .endBefore(getters.loadFirstRecord)
            .limitToLast(1) 
          break

      }
      await bindFirestoreRef(
          'recordBeforeFirst',
          contactCollection
        )

    }),
// //#endregion

    // async doFetch(
    //   { commit, getters },
    //   { filter, keepPagination } = {},
    // ) {
    //   try {
    //     commit('FETCH_STARTED', { filter, keepPagination });

    //     const response = await ContactService.list(
    //       filter,
    //       getters.orderBy,
    //       // getters.limit,
    //       // getters.offset,
    //     );

    //     commit('FETCH_SUCCESS', {
    //       rows: response.rows,
    //       count: response.count,
    //     });
    //   } catch (error) {
    //     Errors.handle(error);
    //     commit('FETCH_ERROR');
    //   }
    // },
    
    async doFetchInBackground(
      { commit, getters },
      { filter, keepPagination } = {},
    ) {
      try {
        commit('FETCH_STARTED_IN_BACKGROUND', { filter, keepPagination });

        const response = await ContactService.list(
          filter,
          getters.orderBy,
          // getters.limit,
          // getters.offset,
        );

        commit('FETCH_SUCCESS', {
          rows: response.rows,
          count: response.count,
        });
      } catch (error) {
        Errors.handle(error);
        commit('FETCH_ERROR');
      }
    },

  },
};
