import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';
import { i18n } from '@/i18n';

const JobListPage = () =>
  import('@/modules/job/components/job-list-page.vue');
// const DistributorListPage = () =>
//   import('@/modules/job/components/job-distributor-page.vue');

// const JobListPage = () =>
//   import('@/modules/job/components/job-list-page.vue');
// const JobFormPage = () =>
//   import('@/modules/job/components/job-form-page.vue');

// const JobExportPage = () => 
//   import('@/modules/job/components/job-export-page.vue')  
// const JobViewPage = () =>
//   import('@/modules/job/components/job-view-page.vue');
// const JobImporterPage = () =>
//   import('@/modules/job/components/job-importer-page.vue');

export default [
  {
    name: '',
    path: '/:lang?',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'job',
        path: 'job/:searchBy?',
        component: JobListPage,
        meta: {
          auth: true,
          permission: Permissions.values.jobView,
        },
        beforeEnter: (to, from, next) => {
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('menu.job') 
          next();
        },
      },
      // {
      //   name: 'distributor',
      //   path: '/distributor',
      //   component: DistributorListPage,
      //   meta: {
      //     auth: true,
      //     permission: Permissions.values.distributorView,
      //   },
      //   beforeEnter: (to, from, next) => {
      //     document.title =
      //       i18n('app.title') +
      //       ' | ' +
      //       i18n('menu.distributor') 
      //     next();
      //   },
      // },
      // {
      //   name: 'job',
      //   path: '/job',
      //   component: JobListPage,
      //   meta: {
      //     auth: true,
      //     permission: Permissions.values.jobView,
      //   },
      // },
      // {
      //   name: 'jobNew',
      //   path: '/job/new',
      //   component: JobFormPage,
      //   meta: {
      //     auth: true,
      //     permission: Permissions.values.jobCreate,
      //   },
      // },
      // {
      //   name: 'jobEdit',
      //   path: '/job/:id/edit',
      //   component: JobFormPage,
      //   meta: {
      //     auth: true,
      //     permission: Permissions.values.jobEdit,
      //   },
      //   props: true,
      // },
      // {
      //   name: 'jobExport',
      //   path: '/job/:id/export',
      //   component: JobExportPage,
      //   meta: {
      //     auth: true,
      //     // permission: Permissions.values.jobEdit,
      //   },
      //   props: true,
      // },
      // {
      //   name: 'jobImporter',
      //   path: '/job/import',
      //   component: JobImporterPage,
      //   meta: {
      //     auth: true,
      //     permission: Permissions.values.jobImport,
      //   },
      // },
      // {
      //   name: 'jobView',
      //   path: '/job/:id',
      //   component: JobViewPage,
      //   meta: {
      //     auth: true,
      //     permission: Permissions.values.jobView,
      //   },
      //   props: true,
      // },
    ],
  },
];
