import Layout from '@/modules/layout/components/layout';
const PropertyListPage = () =>
  import('@/modules/property/components/property-list-page.vue');
// const FacilityDetails = () =>
//   import('@/modules/facilities/components/facility-details.vue');
// const AddFacility = () =>
//   import('@/modules/facilities/components/add-facility.vue');
import Permissions from '@/security/permissions';
import { i18n } from '@/i18n';
  export default [
    {
      path: '/:lang?',
      exact: true,
      component: Layout,
      children: [
        {
            name: 'property',
            path: 'property/:searchBy?',
            component: PropertyListPage,
            meta: {
              auth: true,
              permission: Permissions.values.propertyView,
            },
            beforeEnter: (to, from, next) => {
              document.title =
                i18n('app.title') +
                ' | ' +
                i18n('menu.property') 
              next();
            },
          },
          // {
          //   name: 'facility-details',
          //   path: 'facilities/:id',
          //   component: FacilityDetails,
          // },
          // {
          //   name: 'add-facility',
          //   path: 'add-facility',
          //   component: AddFacility,
          // },
      ]
    }
]