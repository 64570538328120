import { render, staticRenderFns } from "./offer-list-page.vue?vue&type=template&id=c9edc0e8&scoped=true&"
import script from "./offer-list-page.vue?vue&type=script&lang=js&"
export * from "./offer-list-page.vue?vue&type=script&lang=js&"
import style0 from "./offer-list-page.vue?vue&type=style&index=0&id=c9edc0e8&lang=scss&scoped=true&"
import style1 from "./offer-list-page.vue?vue&type=style&index=1&id=c9edc0e8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c9edc0e8",
  null
  
)

export default component.exports
import {QBreadcrumbs,QIcon,QBreadcrumbsEl,QBtn,QCard} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QBreadcrumbs,QIcon,QBreadcrumbsEl,QBtn,QCard})
