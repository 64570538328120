//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';

export default {
  name: 'app-empty-permissions-page',

  methods: {
    ...mapActions({
      doSignout: 'auth/doSignout',
    }),
  },
};
