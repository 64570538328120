//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
// import { i18n } from '@/i18n';
import HomeListCards from '@/modules/home/components/home-list-cards.vue';
import i18n from '@/vueI18n';


export default {
  name: 'app-home-page',

  components: {
    [HomeListCards.name]: HomeListCards,
  },

  data() {
    return {
      productCount:{}
    }
  },
  
watch:{
  lang(){
    document.title =
        this.i18n('app.title') +
        ' | ' +
        this.i18n('menu.home') 
},
},
  computed: {
    lang(){
            return i18n.locale
        },
    ...mapGetters({
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
    }),
    isRTL() {
      // return getLanguageCode() == 'ar'
      // return localStorage.getItem('language') == 'ar'
      return i18n.locale == 'ar'
    },
  },

  created(){
    document.title =
                this.i18n('app.title') +
                ' | ' +
                this.i18n('menu.home')
  },

  methods: {
    i18n(key, args) {
      return this.$t(key, args);
    },
    ...mapActions({
      collapseMenu: 'layout/collapseMenu',
      // doFetch: 'importer/list/doFetch',
      // doFetchEx: 'exporter/list/doFetch',
    }),
    // i18n(code) {
    //   return i18n(code)
    // },
    // setFilterMonth(month){
    //   this.filterMonth = month
    // }
  }
};
