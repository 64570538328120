import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-drug-index';
import graphqlClientSettings from '@/shared/graphql/client';
import firebase from 'firebase/app';
import 'firebase/auth';       // For Authentication
import 'firebase/storage';    // For Storage
import 'firebase/database';   // For Real-Time database
import 'firebase/firestore';  // For Cloud firestore
import 'firebase/messaging';  // For Cloud messaging
import 'firebase/functions';  // For Cloud functions

//#region [ Algolia ]
// const algoliasearch = require('algoliasearch')
// import { createNullCache } from '@algolia/cache-common';
// import { createInMemoryCache } from '@algolia/cache-in-memory';
// For the default version
import algoliasearch from 'algoliasearch';
// const OPTIONS = {
//   hosts: [{ url: 'yourapplication.example.net' }],
//   protocol: 'https',
//   responsesCache: createInMemoryCache(), // Caches responses from Algolia // or createNullCache()
//   requestsCache: createInMemoryCache({ serializable: false }), // Caches Promises with the same request payload // or createNullCache()
// }
const client = algoliasearch('B2AOKDY1OP', 'a8fb78c339cee6569ddf7ead3389c9ce');
const index = client.initIndex('drugIndex');
//#endregion

export class DrugIndexService {
  // static async list(filter, orderBy, limit, offset, isOr) {
  //   
  //   const response = await graphqlClient.query({
  //     query: gql`
  //       query DRUG_INDEX_LIST(
  //         $filter: DrugIndexFilterInput
  //         $orderBy: DrugIndexOrderByEnum
  //         $limit: Int
  //         $offset: Int
  //         $isOr: Boolean
  //         ) {
  //         drugIndexList(
  //           filter: $filter
  //           orderBy: $orderBy
  //           limit: $limit
  //           offset: $offset
  //           isOr: $isOr
  //           ) {
  //             count
  //             rows{
  //               company
  //               drugName
  //               genericName
  //               mechOfAction
  //               oldPrice
  //               price
  //               pharmalogicalUse
  //               route
  //               srde
  //             }
  //         }
  //       }
  //     `,
  //     variables: {
  //       filter,
  //       orderBy,
  //       limit,
  //       offset,
  //       isOr,
  //     }
  //   });

  //   return response.data.drugIndexList;
  // }
  static async listDrugsTest(callback) {
    const myCollectionRef = await firebase.firestore().collection('drugIndexCurrent').get({
      source: 'cache'
    })
    // console.log('my Collection ref',myCollectionRef)
    myCollectionRef.forEach((doc) => {
      callback(doc.data())
    })

    // // const snapshot = await myCollectionRef.doc('00c2mIe8C3U5rHPhiONb').get({ source: 'cache' })
    // // console.log('snapshot',snapshot.data())

    // myCollectionRef.doc('00c2mIe8C3U5rHPhiONb').get({ source: 'cache' })
    //   .then((res) => {
    //     console.log('result',res.data())

    //   })
    //   .catch(async (error)=>{
    //     console.log('error',error)
    //     const snapshot = await myCollectionRef.doc('00c2mIe8C3U5rHPhiONb').get({ source: 'server' })
    //     console.log('snapshot',snapshot.data())
    //   })
  }

  static async listDrugIndexCurrent(callback) {
    firebase.firestore().collection('drugIndexCurrent').onSnapshot({
      includeMetadataChanges: true
    }, coll => {
      // var source = coll.metadata.fromCache ? "local cache" : "server";
      coll.docChanges().forEach(change => {
        let drug = change.doc.data()
        // drug.oldPrice = drug.oldPrice.toString().slice(0,7)
        // drug.price = drug.price.toString().slice(0,7)
        callback(drug, change.type)
      });
    });
  }

  static async listDrugIndexNew(callback) {
    firebase.firestore().collection('drugIndexNew').onSnapshot({
      includeMetadataChanges: true
    }, coll => {
      // var source = coll.metadata.fromCache ? "local cache" : "server";
      coll.docChanges().forEach(change => {
        let drug = change.doc.data()
        // drug.oldPrice = drug.oldPrice.toString().slice(0,7)
        // drug.price = drug.price.toString().slice(0,7)
        callback(drug, change.type)
      });
    });
  }

  
  static async import(values, importHash) {
    const response = await graphqlClient.mutate({
      mutation: gql `
        mutation DRUG_INDEX_IMPORT(
          $data: DrugIndexInput!
          $importHash: String!
        ) {
          drugIndexImport(data: $data, importHash: $importHash)
        }
      `,
      variables: {
        data: values,
        importHash,
      },
    });
    return response.data.drugIndexImport;
  }

  static async settingsUpdate(data) {
    const response = await graphqlClientSettings.mutate({
      mutation: gql `
            mutation SETTINGS_UPDATE(
              $data: SettingsInput! 
            ) {
              settingsUpdate(data: $data){
                totalNumberOfChunks
                currentNumberOfChunks
              }
            }
          `,
      variables: {
        data
      },
    });
    return response.data.settingsUpdate;
  }

  static async fetchDrugIndexNewSettings(callback) {
    firebase.firestore().collection("settings").onSnapshot(settings => {
      // let prescriptions = []
      // this.prescriptionsWithCreators = []
      settings.docChanges().forEach(async (record) => {
        // console.log('changes in setiings',doc.doc.data())
        // console.log('changes in settings id',record.doc.data().id)
        if (record.doc.data().id == "drugIndexNew") {
          // this.drugIndexNewSettings = record.doc.data()
          callback(record.doc.data())
        }

      })
    })

  }
  static async fetchDrugIndexCurrentSettings(callback) {
    firebase.firestore().collection("settings").onSnapshot(settings => {
      // let prescriptions = []
      // this.prescriptionsWithCreators = []
      settings.docChanges().forEach(async (record) => {
        // console.log('changes in setiings',doc.doc.data())
        // 
        if (record.doc.data().id == "drugIndexCurrent") {
          // this.drugIndexNewSettings = record.doc.data()
          callback(record.doc.data())
        }

      })
    })

  }
  static async readFileFromStorage(file, collectionName) {
    // console.log('file',file)
    //  console.log('collectionName',collectionName)
    const response = await graphqlClient.mutate({
      mutation: gql `
            mutation READ_FILE_FROM_STORAGE(
              $file: String!,
              $collectionName: String!
            ) {
              drugIndexReadFile(file: $file, collectionName:$collectionName)
            }
          `,
      variables: {
        file,
        collectionName
      },
    });
    return response.data.readFileFromStorage;
  }
  static async deleteFileFromFirebaseStorage(filePath) {
    try {
      
      const storage = firebase.storage();
      // const filePath = file.response.privateUrl
      const fileRef = storage.ref().child(`${filePath}`);  // Create a reference to the file to delete
      // Delete the file
      await fileRef.delete()
      console.log(`File deleted successfully`); 
      return true
    } catch (error) {
      console.log(`Error for delete file from stoage: `, error); 
      return false
    }
  }
  static async drugIndexUploadFile(file, collectionName) {
    const response = await graphqlClient.mutate({
      mutation: gql `
        mutation DRUG_INDEX_UPLOAD_FILE(
          $file: String!,
          $collectionName: String!
        ) {
          drugIndexUploadFile(file: $file, collectionName:$collectionName)
        }
      `,
      variables: {
        file,
        collectionName
      },
    });
    return response.data.drugIndexUploadFile;
  }

  static async drugIndexPublishFile() {
    // console.log('file',file)
    //  console.log('collectionName',collectionName)
    const response = await graphqlClient.mutate({
      mutation: gql `
          mutation DRUG_INDEX_PUBLISH_FILE{
            drugIndexPublishFile
          }
        `,
    });
    return response.data.drugIndexPublishFile;
  }
  static async destroyAll(ids, collectionName) {
    const response = await graphqlClient.mutate({
      mutation: gql `
        mutation DRUGINDEX_DESTROY($ids: [String!]!,$collectionName: String!) {
          drugIndexDestroy(ids: $ids,collectionName: $collectionName)
        }
      `,
      variables: {
        ids,
        collectionName
      },
    });

    return response.data.drugIndexDestroy;
  }

  static async updateAllToRemoved(ids, collectionName) {
    const response = await graphqlClient.mutate({
      mutation: gql `
        mutation DRUG_INDEX_UPDATE_ALL_TO_REMOVED($ids: [String!]!,$collectionName: String!) {
          drugIndexUpdateAllToRemoved(ids: $ids,collectionName: $collectionName)
        }
      `,
      variables: {
        ids,
        collectionName
      },
    });

    return response.data.drugIndexUpdateAllToRemoved;
  }
  static async update(id, collectionName, data) {
    const response = await graphqlClient.mutate({
      mutation: gql `
        mutation DRUG_INDEX_UPDATE(
          $id: String!
          $collectionName: String!
          $data: DrugIndexInput!
        ) {
          drugIndexUpdate(id: $id, collectionName: $collectionName, data: $data) {
            id
          }
        }
      `,

      variables: {
        id,
        collectionName,
        data,
      },
    });

    return response.data.jobUpdate;
  }
  static async deleteAllAlgoliaRecords() {
    const response = await graphqlClient.mutate({
      mutation: gql `
        mutation DELETE_ALL_ALGOLIA_RECORDS(
        ) {
          deleteAllAlgoliaRecords
        }
      `,

      // variables: {
      //   id,
      //   collectionName,
      //   data,
      // },
    });

    return response.data.deleteAllAlgoliaRecords;
  }

//#region [ ========================== New Functions ========================== ]
  static async publishToAlgolia(filePath) {
    const response = await graphqlClient.mutate({
      mutation: gql `
        mutation DRUG_INDEX_PUBLISH_TO_ALGOLIA($filePath: String!) {
          publishToAlgolia(filePath: $filePath)
        }
      `,
      variables: {
        filePath: filePath,
      },
    });
    return response.data.publishToAlgolia;
  }

  static async findDataFromRealTimeDatabase(reference) {
    return firebase.database().ref(reference).once('value').then((snapshot) => {
      return snapshot.val();
    });
  }
  static async addDataInRealTimeDatabase(reference, data) {
    try {
      await firebase.database().ref(reference).set(data) 
      console.log('Data saved successfully')
    } catch (error) {
      console.log('The write failed: ', error)
    }
  }
  static async updateDataInRealTimeDatabase(reference, data) {
    try {
      await firebase.database().ref(reference).update(data) 
      console.log('Data updated successfully')
    } catch (error) {
      console.log('The write failed: ', error)
    }
  }
  static async deleteDataFromRealTimeDatabase(reference) {
    try {
      await firebase.database().ref(reference).remove() 
      console.log("Remove succeeded.")
      return true
    } catch (error) {
      console.log("Remove failed: " + error.message)
      return false
    }
  }
  static async createRealTimeListener(ref, callback) {
    firebase.database().ref(ref).on('value', (snapshot) => {
      callback(snapshot.val())
    })
  }
  static async listFromAlgolia({ query = '' , startAt = 0, endAt = 0 }) {
    let hits = [];

    await index.browseObjects({
      query: query, // Empty query will match all records
      filters: `index>${startAt} AND index<=${endAt}`,
      batch: batch => {
        hits = hits.concat(batch);
      }
    }).then(() => {
      console.log('hits =', hits)
    });

    return hits
  }
  static async clearAlgolia() {
    index.clearObjects().then(() => {
      console.log('done');
    });
    return true
  }
//#endregion

  
}