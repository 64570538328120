//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import firebase from 'firebase/app';
import 'firebase/auth';        // for authentication
import 'firebase/storage';     // for storage
import 'firebase/database';    // for realtime database
import SettingListCard from '@/modules/setting/components/setting-list-card.vue';
import { mapActions } from 'vuex'
export default {
    name:'app-setting-list-table',
    data(){
      return{
        successDialog: false,
        // jobsCount: 0,
        // prescriptionsCount: 0,
        // propertiesCount: 0,
      }
    },
   
    components: {
        [SettingListCard.name]: SettingListCard,
    },
    methods:{
        i18n(key, args) {
            return this.$t(key, args);
        },
        ...mapActions({
          setPrescriptionMaxCount:'setting/list/setPrescriptionMaxCount',
          setJobMaxCount:'setting/list/setJobMaxCount',
          setPropertyMaxCount:'setting/list/setPropertyMaxCount',
          setPrescriptionExpirationDays: 'setting/list/setPrescriptionExpirationDays'
        })
    },
    async created(){
      await firebase.database().ref('setting/prescription').on('value',(snapshot) => {
        this.setPrescriptionMaxCount(snapshot.val())
        // console.log('prescriptionMaxCount',this.prescriptionMaxCount);
        // this.prescriptionsCount = snapshot.val()
        // this.offersLoading = false
    // realtimeValue = snapshot.val()
    });
    await firebase.database().ref('setting/job').on('value',(snapshot) => {
        this.setJobMaxCount(snapshot.val())
        // console.log('jobMaxCount',this.jobMaxCount);
        // this.jobsCount = snapshot.val()
        // this.offersLoading = false
    // realtimeValue = snapshot.val()
    });
    await firebase.database().ref('setting/property').on('value',(snapshot) => {
        this.setPropertyMaxCount(snapshot.val())
        // console.log('setPropertyMaxCount',this.propertyMaxCount);
        // this.propertiesCount = snapshot.val()
        // this.offersLoading = false
    // realtimeValue = snapshot.val()
    });

    await firebase.database().ref('setting/prescriptionExpirationDays').on('value',(snapshot) => {
        this.setPrescriptionExpirationDays(snapshot.val())
        // console.log('setPropertyMaxCount',this.propertyMaxCount);
        // this.propertiesCount = snapshot.val()
        // this.offersLoading = false
    // realtimeValue = snapshot.val()
    });
    }
}
