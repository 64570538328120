const firebaseConfig = {
  apiKey: "AIzaSyAjzlpO8QLbk-lg8i3kjHZMwthzfDYlISo",
  // authDomain: "drug-deal-6e05f.firebaseapp.com",
  authDomain: "admin.drugdealapp.com",
  databaseURL: "https://drug-deal-6e05f.firebaseio.com",
  projectId: "drug-deal-6e05f",
  storageBucket: "drug-deal-6e05f.appspot.com",
  messagingSenderId: "207604195666",
  appId: "1:207604195666:web:7414d4e224634e780cda8b",
  measurementId: "G-9E29V3W0DF"
};

// var firebaseConfig = {
//   apiKey: "AIzaSyAjzlpO8QLbk-lg8i3kjHZMwthzfDYlISo",
//   authDomain: "drug-deal-6e05f.firebaseapp.com",
//   databaseURL: "https://drug-deal-6e05f.firebaseio.com",
//   projectId: "drug-deal-6e05f",
//   storageBucket: "drug-deal-6e05f.appspot.com",
//   messagingSenderId: "207604195666",
//   appId: "1:207604195666:web:6c84d0b34aa60ebc0cda8b",
//   measurementId: "G-SF15MFBBF8"
// };

// Cloud Functions
const backendUrl = `https://europe-west3-${
  firebaseConfig.projectId
}.cloudfunctions.net/settingsAndEmails`;

const backendUrlAuth = `https://europe-west3-${
  firebaseConfig.projectId
}.cloudfunctions.net/auth`;

const backendUrlCity = `https://europe-west3-${
  firebaseConfig.projectId
}.cloudfunctions.net/city`;

const backendUrlPrescription = `https://europe-west3-${
  firebaseConfig.projectId
}.cloudfunctions.net/prescription`;

const backendUrlJob = `https://europe-west3-${
  firebaseConfig.projectId
}.cloudfunctions.net/job`;

const backendUrlProperty = `https://europe-west3-${
  firebaseConfig.projectId
}.cloudfunctions.net/property`;

const backendUrlDrugIndex = `https://europe-west3-${
  firebaseConfig.projectId
}.cloudfunctions.net/drugIndex`;
// const backendUrlDrugIndex =  "https://europe-west3-drug-deal-6e05f.cloudfunctions.net/apiDrugIndex";
const backendUrlOffer = `https://europe-west3-${
  firebaseConfig.projectId
}.cloudfunctions.net/offer`;

const backendUrlContact = `https://europe-west3-${
  firebaseConfig.projectId
}.cloudfunctions.net/contact`;

// App Engine
// const backendUrl = `<insert app engine url here>`;

export default {
  firebaseConfig,
  backendUrl,
  backendUrlAuth,
  backendUrlCity,
  backendUrlPrescription,
  backendUrlJob,
  backendUrlProperty,
  backendUrlDrugIndex,
  backendUrlOffer,
  backendUrlContact,

};
