import gql from "graphql-tag";
import graphqlClient from "@/shared/graphql/client-job";
// import firebase from 'firebase';
import firebase from "firebase/app";
import "firebase/auth"; // for authentication
import "firebase/storage"; // for storage
import "firebase/database"; // for realtime database
import "firebase/firestore"; // for cloud firestore
import "firebase/messaging"; // for cloud messaging
import "firebase/functions"; // for cloud functions
// import * as securePin from "secure-pin";
// import { storeAsync } from '@/app-module';
// import FirebaseQuery from '@/shared/firebase/firebaseQuery'

export class JobService {
  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation JOB_CREATE($data: JobInput!) {
          jobCreate(data: $data) {
            id
          }
        }
      `,

      variables: {
        data,
      },
    });

    return response.data.jobCreate;
  }

  static async update(id, data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation JOB_UPDATE($id: String!, $data: JobInput!) {
          jobUpdate(id: $id, data: $data) {
            id
          }
        }
      `,

      variables: {
        id,
        data,
      },
    });

    return response.data.jobUpdate;
  }

  static async jobCanBeDeleted(id) {
    return !!(
      await firebase
        .firestore()
        .collection("items")
        .where("jobId", "==", id)
        .get()
    ).docs.length;
  }

  static async destroyAll(ids) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation JOB_DESTROY($ids: [String!]!) {
          jobDestroy(ids: $ids)
        }
      `,

      variables: {
        ids,
      },
    });

    return response.data.jobDestroy;
  }

  static async import(values, importHash) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation JOB_IMPORT($data: JobInput!, $importHash: String!) {
          jobImport(data: $data, importHash: $importHash)
        }
      `,

      variables: {
        data: values,
        importHash,
      },
    });

    return response.data.jobImport;
  }

  static async find(id) {
    const response = await graphqlClient.query({
      query: gql`
        query JOB_FIND($id: String!) {
          jobFind(id: $id) {
            id
            address
            cityId
            city
            description
            dueDate
            emailAddress
            name
            phoneNumber
            positionId
            position
            status
            postStatus
            regionId
            region
            salary
            views
            viewers
            workplaceId
            workplace
            createdAt
            updatedAt
            createdBy
            updatedBy
          }
        }
      `,

      variables: {
        id,
      },
    });

    return response.data.jobFind;
  }

  static async list(filter, orderBy, limit, offset) {
    const response = await graphqlClient.query({
      query: gql`
        query JOB_LIST(
          $filter: JobFilterInput
          $orderBy: JobOrderByEnum
          $limit: Int
          $offset: Int
        ) {
          jobList(
            filter: $filter
            orderBy: $orderBy
            limit: $limit
            offset: $offset
          ) {
            count
            rows {
              id
              address
              cityId
              city
              description
              dueDate
              emailAddress
              name
              phoneNumber
              positionId
              position
              status
              postStatus
              regionId
              region
              salary
              viewers
              workplaceId
              workplace
              createdAt
              updatedAt
              createdBy
              updatedBy
            }
          }
        }
      `,

      variables: {
        filter,
        orderBy,
        limit,
        offset,
      },
    });

    return response.data.jobList;
  }

  static async listAutocomplete(query, limit) {
    const response = await graphqlClient.query({
      query: gql`
        query JOB_AUTOCOMPLETE($query: String, $limit: Int) {
          jobAutocomplete(query: $query, limit: $limit) {
            id
            label
          }
        }
      `,

      variables: {
        query,
        limit,
      },
    });

    return response.data.jobAutocomplete;
  }

  // static mapCollection(collection) {
  //   if (collection.empty) {
  //     return [];
  //   }

  //   const list = [];

  //   collection.forEach((document) => {
  //     const item = Object.assign({}, document.data(), {
  //       id: document.id,
  //     });

  //     // this.replaceAllTimestampToDate(item);
  //     list.push(item);
  //   });

  //   return list;
  // }

  // /**
  //  * Counts the number of Pin Code.
  //  *
  //  * @param {Object} filter
  //  */
  // static async pinCodeCount() {
  //   let chain = firebase.firestore().collection('pinCode');
  //   return (await chain.get()).size;
  // }
  // static async pinCodeList() {
  //   const collection = await firebase.firestore().collection('pinCode').get();
  //   return this.mapCollection(collection)
  //   // collection.forEach(async (doc) => {
  //   //   let city = doc.data();
  //   //   city['id'] = doc.id;
  //   //   response.push(city);
  //   // });
  // }
  // static async pinCodeListByTransaction(t) {
  //   const collection = await t.collection('pinCode').get();
  //   return this.mapCollection(collection)
  // }

  // static async GenerateAndCreatePinCode() {
  //   try {
  //     let currentUser = storeAsync().getters['auth/currentUser']
  //     await firebase.firestore().runTransaction(async (t) => {
  //       const pinCodeCollection = await this.pinCodeListByTransaction()
  //       let limit = pinCodeCollection.length
  //       let pin

  //       while(limit < pinCodeCollection.length + 1 ) {
  //         pin = securePin.generatePinSync(8)

  //         if (pinCodeCollection.length) {
  //           if(pinCodeCollection.indexOf(pin) === -1) limit += 1
  //         } else {
  //           limit += 1
  //         }
  //       }

  //       let docRef = firebase.firestore().doc(`pinCode/${pin}`)
  //       t.set(docRef, {
  //         id: pin,
  //         // pinCode: pin,
  //         createdBy: currentUser.id,
  //         createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  //       });
  //     });

  //      console.log('Transaction success!');
  //   } catch (e) {
  //      console.log('Transaction failure:', e);
  //   }
  // }

  // static async pinCodeGenerators(count) {
  //   try {
  //     const pinCodeCollection = await this.pinCodeList()
  //     // let limit = pinCodeCollection.length
  //     let limit = count
  //     let pinCodes = []

  //     while(pinCodes.length < limit ) {
  //       const code = securePin.generatePinSync(8)

  //       if (pinCodeCollection.length) {
  //         if(pinCodeCollection.indexOf(code) === -1) pinCodes.push(code)
  //       } else {
  //         pinCodes.push(code)
  //       }
  //     }

  //     return pinCodes
  //   } catch (e) {
  //     console.log('Error: ', e);
  //   }
  // }

  // static async pinCodeGenerator() {
  //   try {
  //     const pinCodeCollection = await this.pinCodeList()
  //     let limit = pinCodeCollection.length
  //     let pin

  //     while(limit < pinCodeCollection.length + 1 ) {
  //       pin = securePin.generatePinSync(8)

  //       if (pinCodeCollection.length) {
  //         if(pinCodeCollection.indexOf(pin) === -1) limit += 1
  //       } else {
  //         limit += 1
  //       }
  //     }

  //     return pin
  //   } catch (e) {
  //      console.log('Error: ', e);
  //   }
  // }
  // // static async createPinCode(pinCode) {
  // //   let currentUser = storeAsync().getters['auth/currentUser']

  // //   let batch = firebase.firestore().batch();

  // //   let docRef = firebase.firestore().doc(`pinCode/${pinCode}`)
  // //   batch.set(docRef, {
  // //     id: pinCode,
  // //     // pinCode: pin,
  // //     createdBy: currentUser.id,
  // //     createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  // //   });

  // //   await batch.commit()
  // // }

  // static async createPinCode(data) {
  //   let currentUser = storeAsync().getters['auth/currentUser']

  //   let batch = firebase.firestore().batch();

  //   let docRef = firebase.firestore().doc(`pinCode/${data.pinCode}`)
  //   batch.set(docRef, {
  //     id: data.pinCode,
  //     distributorType: data.distributorType,
  //     wholesalerId: data.wholesalerId,
  //     createdBy: currentUser.id,
  //     createdAt: firebase.firestore.FieldValue.serverTimestamp(),
  //   });

  //   await batch.commit()
  // }

  // static _getRandomInt(min, max) {
  //   return Math.floor( Math.random() * ( max - min + 1 ) ) + min;
  // }
}
