import firebase from 'firebase/app';
import 'firebase/storage';
// import * as uuid from 'uuid/v4';
import { i18n } from '@/i18n';
import filesize from 'filesize';

function extractExtensionFrom(filename) {
  if (!filename) {
    return null;
  }

  const regex = /(?:\.([^.]+))?$/;
  return regex.exec(filename)[1];
}

export class FileUploader {
  static validate(file, schema) {
    if (!schema) {
      return;
    }

    if (schema.image) {
      if (!file.type.startsWith('image')) {
        throw new Error(i18n('fileUploader.image'));
      }
    }

    if (schema.size && file.size > schema.size) {
      throw new Error(
        i18n('fileUploader.size', filesize(schema.size)),
      );
    }

    const extension = extractExtensionFrom(file.name);

    if (schema.formats && !schema.formats.includes(extension)) {
      throw new Error(
        i18n('fileUploader.formats', schema.formats.join('/')),
      );
    }
  }

  static uploadFromRequest(path, request, schema, filename = null) {
    try {
      FileUploader.validate(request.file, schema);
    } catch (error) {
      request.onError(error);
      return;
    }

    const ref = firebase.storage().ref();
    const extension = extractExtensionFrom(request.file.name);
    // const id = uuid();
    // console.log('file uploader',request.file.name)
    const fileName = filename || request.file.name.split('.').slice(0, -1).join('.')
    const fullPath = `${path}/${fileName}.${extension}`;
    const task = ref.child(fullPath).put(request.file);

    task.on(firebase.storage.TaskEvent.STATE_CHANGED, 
      (snapshot) => {
        const percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setTimeout(() => {
          request.onProgress({ percent });
        }, 0);
      },
      (error) => {
        request.onError(error);
      },
      () => {
        task.snapshot.ref
          .getDownloadURL()
          .then((url) => {
            request.onSuccess({
              id: request.file.name,
              // originalFileName: request.file.name,
              // extension: extension,
              name: `${fileName}.${extension}`,
              // sizeInBytes: task.snapshot.totalBytes,
              privateUrl: fullPath,
              publicUrl: url,
              new: true,
            });
          })
          .catch((error) => {
            request.onError(error);
          });
      },
    );
  }

  static async removeFile(file) {
    const storage = firebase.storage();
    const filePath = file.response.privateUrl
    const fileRef = storage.ref().child(filePath);  // Create a reference to the file to delete
    // Delete the file
    fileRef.delete().then(() => {
      console.log(`File deleted successfully`); 
      return true
    }).catch((error) => {
      // Uh-oh, an error occurred!
      console.log(`Error for delete file from stoage: `, error); 
    });
  }
}
