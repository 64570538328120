//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { i18n } from '@/i18n';

export default {
  name: 'app-card-view-page',
  props: ['record','deleteAction'],
  

  data() {
    return {
      dialogVisible: false,
      forwardDialogVisible: false,
      ticketDetails: {},
      isMore: false,
    };
  },
  async created() {
    // await this.doFetchAllUsers();
    // this.doFilter();
  },
  async mounted() {
    // await this.doFetchAllUsers();
    // this.doFilter();
  },

  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
    }),
    isBlank() {
      return !this.record
    },
    itemImage() {
      // if (this.record.photos.length) {
      //   return this.record.photos[0].publicUrl
      // }
      if (this.record.photo) {
        return this.record.photo.publicUrl
      }
      return '/images/no-image.svg' 
      // return 'https://cdn.shopify.com/s/files/1/0222/9834/products/novase_800x.jpg?v=1526705078'
    },
    features() {
      if (!this.record.features) {
        return []
      }
      return this.record.features.split(".").filter(el => el != '')

      // if (this.isMore) {
      //   return this.record.features.split(".").filter(el => el != '')
      // } else {
      //   return this.record.features.split(".").slice(0, 3)
      // }
    },
  },

  methods: {
    ...mapActions({
      // doFetch: 'ticket/list/doFetch',
      // doFetchAllUsers: 'iam/list/doFetchAllUsers',
      // doUpdate: 'ticket/form/doUpdate',
    }),
    i18n(label) {
      return i18n(label);
    },
    readMore() {
      this.isMore = !this.isMore
    },
    doExport() {
      this.$emit('export', this.record.id)
    },
    doEdit() {
      this.$emit('edit', this.record.id)
    },
    doDelete(){
      this.$emit('delete',this.record.id)
    },
    async doFilter() {
      // const filter = {
      //   ticketStatus: 'pending',
      // };
      // return this.doFetch({
      //   filter,
      // });
      this.$emit('doFilter');
    },


    async doSubmit() {
      // this.record.isExternal = !!this.record.isExternal
      // const model = formSchema.initialValues(this.record);
      // const { id, ...values } = formSchema.cast(model);

      // await this.doUpdate({
      //   id,
      //   values
      // });
      // this.onModalClose();
      // // this.doRefreshTickets();
      // // this.doFilter();
    },
  },
};
