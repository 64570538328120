//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NotificationCard from '@/modules/notification/components/notification-card.vue';
import { mapActions, mapGetters } from 'vuex';
import i18n from '@/vueI18n';


export default {
  name: 'app-home-page',
  data(){
    return{
      rowsToBeDisplayed: [],
      size:4,
    }
  },

  components: {
    [NotificationCard.name]: NotificationCard,
  },

  async created() {
    document.title =
                this.i18n('app.title') +
                ' | ' +
                this.i18n('menu.notifications') 
    await this.resetCursor()
    await this.doFetchNotifications({userId: this.currentUser.id, limit: 10})
    // this.rowsToBeDisplayed = this.firstBatch
  },
  mounted(){
    // console.log('notifications',this.firstBatch);
    // console.log('this.rowsToBeDisplayed',this.rowsToBeDisplayed);
    // console.log('rows',this.rows);
  },


  computed: {
    lang(){
            return i18n.locale
        },
    ...mapGetters({
      isMobile: 'layout/isMobile',
      firstBatch:'notification/firstBatch',
      secondBatch:'notification/secondBatch',
      newRowsLength: 'notification/newRowsLength',
      rows: 'notification/rowsToBeDisplayed',
      currentUser:'auth/currentUser',
      loading:'notification/loading',
      backgroundLoading:'notification/backgroundLoading',
    }),
    notificationsList(){
      return this.notifications.slice(0, this.size)
    }
  },
  watch:{
     lang(){
            document.title =
                this.i18n('app.title') +
                ' | ' +
                this.i18n('menu.notifications') 
        },
    firstBatch(newval){
      if(newval.length > 0){
        this.setLoadMoreLast()
        newval.forEach(record =>{
         var foundIndex =  this.rowsToBeDisplayed.findIndex((notif) => notif.id == record.id);
         if(foundIndex == -1) {
           this.rowsToBeDisplayed.push(record)
         }
        })
        this.rowsToBeDisplayed.sort((a, b) =>{
            var keyA = new Date(a.createdAt.seconds),
                keyB = new Date(b.createdAt.seconds);
            if (keyA < keyB) return 1;
            if (keyA > keyB) return -1;
            return 0;
          })
      }
    },
    secondBatch(oldval,newval){
      if(newval.length > 0){
        newval.forEach(record =>{
         var foundIndex =  this.rowsToBeDisplayed.findIndex((notif) => notif.id == record.id);
         if(foundIndex == -1) {
           this.rowsToBeDisplayed.push(record)
         }
        })
        // this.rowsToBeDisplayed.push(...newval)
        // console.log('this.rowsToBeDisplayed',this.rowsToBeDisplayed);
      }
    }
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args);
    },
    onLoad (index, done) {
          setTimeout(() => {
          this.doFetchMore()
            done()
        }, 2000)      
    },
     onScroll ({ target: { scrollTop, clientHeight, scrollHeight }}) {
      if (scrollTop + clientHeight >= scrollHeight) {
        // this.loadMorePosts()
      }
    },
    ...mapActions({
      setHeaderBg:'layout/setHeaderBg',
      collapseMenu: 'layout/collapseMenu',
      doFetchNotifications: 'notification/doFetchNotifications',
      setLoadMoreLast: 'notification/setLoadMoreLast',
      doFetch: 'notification/doFetch',
      resetCursor: 'notification/resetCursor'

      // notificationInit:'notification/notificationInit',
      // notificationCreateAndSend:'notification/notificationCreateAndSend'
    }),
    loadMore(){
      let tempSize = this.size + 2
      if(tempSize <= this.notifications.length){
        this.size = tempSize
      }
      else {
        this.size = this.notifications.length
      }
    },
    doFetchMore(){
      this.doFetch({userId: this.currentUser.id, limit: 10})
      // this.doFetchNotifications({userId: this.currentUser.id, limit: 2})
    }
  }
};
