import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/iam/permission-checker';

export class AdminPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.adminRead,
    );
    this.import = permissionChecker.match(
      Permissions.values.adminImport,
    );
    this.adminAutocomplete = permissionChecker.match(
      Permissions.values.adminAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.adminCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.adminEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.adminDestroy,
    );
  }
}
