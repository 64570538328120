//
//
//
//
//
//
//
//

import Table from '@/modules/home/components/table.vue';
import { i18n } from "@/i18n";
// import firebase from 'firebase/app'
import firebase from 'firebase/app';
import 'firebase/auth';        // for authentication
import 'firebase/storage';     // for storage
import 'firebase/database';    // for realtime database
import 'firebase/firestore';   // for cloud firestore
import 'firebase/messaging';   // for cloud messaging
import 'firebase/functions';   // for cloud functions
import 'firebase/firestore'; 
import { AdminPermissions } from '@/modules/admins/admins-permissions.js';
import { mapGetters } from 'vuex';
export default {
  data(){
    return{
      admins:[],
      
      columns: [
            {
          name: i18n('drugDeal.users.firstName'),
          required: true,
          label: i18n('drugDeal.users.firstName'),
          align: 'center',
          field: 'firstName',
          // style: 'width: 5vw;',
        },
        // { name: i18n('drugDeal.users.lastName'), align: 'center', label: i18n('drugDeal.users.lastName'), field: 'lastName',},
        { name: i18n('drugDeal.users.fullName'), align: 'center', label: i18n('drugDeal.users.fullName'), field: 'fullName',},
        //  { name: 'Last ', align: 'center', label: 'Last', field: 'hey',},
        { name: i18n('common.phoneNumber'), align: 'center', label: i18n('common.phoneNumber'), field: 'phone',},
        { name: i18n('common.emailAddress'), align: 'center', label:  i18n('common.emailAddress'), field: 'email',},
        { name:  i18n('common.status'), align: 'center', label:  i18n('common.status'), field: 'disabled',},
        // { name:  i18n('common.action'), align: 'center', label:  i18n('common.action'), field: 'action',},
         


      ],
      data: [
        {
          id:'1',firstName: 'Ahmed',lastName: 'Ali', phone:'',emailAddress:'ghadayousef@gmail.com', status:'enabled',
        },
        {
          id:'2',firstName: 'Ahmed',lastName: 'Ali', phone: '01093295291', emailAddress:'ghadayousef@gmail.com', status:'disabled',
        },
        {
         id:'3', firstName: 'Ahmed',lastName: 'Ali', phone: '01093295291', emailAddress:'ghadayousef@gmail.com', status:'enabled',
        },
        {
         id:'4', firstName: 'Ahmed',lastName: 'Ali', phone: '01093295291', emailAddress:'ghadayousef@gmail.com', status:'disabled',
        },
        {
          id:'5', firstName: 'Ahmed',lastName: 'Ali', phone: '', emailAddress:'ghadayousef@gmail.com', status:'enabled',
        },
        {
          id:'6',firstName: 'Ahmed',lastName: 'Ali', phone: '01093295291', emailAddress:'ghadayousef@gmail.com', status:'disabled',
        },
        {
          id:'7', firstName: 'Ahmed',lastName: 'Ali', phone: '01093295291', emailAddress:'ghadayousef@gmail.com', status:'enabled',
        },
        {
         id:'8', firstName: 'Ahmed',lastName: 'Ali', phone: '01093295291', emailAddress:'ghadayousef@gmail.com', status:'disabled',
        },
        {
          id:'9',firstName: 'Ahmed',lastName: 'Ali', phone: '01093295291', emailAddress:'ghadayousef@gmail.com', status:'enabled',
        },
        {
          id:'10',firstName: 'Ahmed',lastName: 'Ali', phone:'',emailAddress:'ghadayousef@gmail.com', status:'enabled',
        },
        {
          id:'11',firstName: 'Ahmed',lastName: 'Ali', phone: '01093295291', emailAddress:'ghadayousef@gmail.com', status:'disabled',
        },
        {
         id:'12', firstName: 'Ahmed',lastName: 'Ali', phone: '01093295291', emailAddress:'ghadayousef@gmail.com', status:'enabled',
        },
        {
         id:'13', firstName: 'Ahmed',lastName: 'Ali', phone: '01093295291', emailAddress:'ghadayousef@gmail.com', status:'disabled',
        },
        {
          id:'14', firstName: 'Ahmed',lastName: 'Ali', phone: '', emailAddress:'ghadayousef@gmail.com', status:'enabled',
        },
        {
          id:'15',firstName: 'Ahmed',lastName: 'Ali', phone: '01093295291', emailAddress:'ghadayousef@gmail.com', status:'disabled',
        },
        {
          id:'16', firstName: 'Ahmed',lastName: 'Ali', phone: '01093295291', emailAddress:'ghadayousef@gmail.com', status:'enabled',
        },
        {
         id:'17', firstName: 'Ahmed',lastName: 'Ali', phone: '01093295291', emailAddress:'ghadayousef@gmail.com', status:'disabled',
        },
        {
          id:'9',firstName: 'Ahmed',lastName: 'Ali', phone: '01093295291', emailAddress:'ghadayousef@gmail.com', status:'enabled',
        },
      ],
    }
  },
  computed:{
    ...mapGetters({
      currentUser: 'auth/currentUser'
    }),
    hasPermissionToEdit() {
        return new AdminPermissions(this.currentUser).edit;
      },
      // hasPermissionToImportAdmin() {
      //   return new AdminPermissions(this.currentUser).import;
      // },
      hasPermissionToDestroy() {
        return new AdminPermissions(this.currentUser).destroy;
      },
      // hasPermissionToReadAdmin(){
      //   return new AdminPermissions(this.currentUser).read;
      // },
  },

  components:{
   
    [Table.name]:Table
  },
  methods:{
    i18n(key, args) {
      return i18n(key, args);
    },
  },
  async created(){
    if(this.hasPermissionToEdit && this.hasPermissionToDestroy){
      this.columns[this.columns.length] = { name:  i18n('common.action'), align: 'center', label:  i18n('common.action'), field: 'action',}
    }
    else{
      // this.columns[this.columns.lenght - 1] = 
    }
    await firebase.firestore().collection('user').onSnapshot({ includeMetadataChanges: true },async () => {
      // const user = await firebase.firestore().collection("user").doc(this.currentUser.id).get({source: 'cache'})
      // console.log('user data: ',user.data().roles[0])
      const admins = await firebase.firestore().collection("user").get({source: 'cache'})
      this.admins = []
      // this.cachedPrescriptionsSize = prescriptions.size
      admins.forEach(doc => {
        let admin = doc.data()
        // if(admin.createdAt.seconds){
        //   admin.createdAt = new Date(admin.createdAt.seconds * 1000)
        //   admin.createdAt = admin.createdAt.toString().slice(0,24)
        // }
        if(admin.roles.length > 0 && admin.status !== 'deleted')
          this.admins.push(admin)
      })
      this.loading = false
   })
  }
  
   
  
}
