import Layout from '@/modules/layout/components/layout';

const NotificationListPage = () =>
  import('@/modules/notification/components/notification-list-page.vue');


export default [
  {
    path: '/:lang?',
    exact: true,
    component: Layout,
    children: [
      {
        name: 'notification',
        path: 'notification',
        component: NotificationListPage,
        exact: true,
        meta: { auth: true },
      },
    ],
  },
];
