//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { i18n } from "@/i18n";
import { mapActions,mapGetters } from 'vuex';
// import firebase from 'firebase/app'
import firebase from 'firebase/app';
import 'firebase/auth';        // for authentication
import 'firebase/storage';     // for storage
import 'firebase/database';    // for realtime database
import 'firebase/firestore';   // for cloud firestore
import 'firebase/messaging';   // for cloud messaging
import 'firebase/functions';   // for cloud functions
import 'firebase/firestore';
var today = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = today.getFullYear();
export default {
    name:'app-delete-dialog',
    props:['deleteUser','id','type','job','property','user'],
    data(){
      return {
        date: yyyy + '/' + mm + '/' + dd  ,
      }
    },
    computed:{
      ...mapGetters({
        currentUser: 'auth/currentUser'
      }),
        deleteDialog:{
            get(){
                return this.deleteUser
            },
            set(){
                this.$emit('close')
            }
        }
    },
    methods:{
      ...mapActions({
        // jobDelete : 'jobs/destroy/doDestroy',
        jobUpdate : 'jobs/form/doUpdate',

      }),
      async doDelete(){
        if(this.type == 'job'){
            this.job['postStatus'] = "deleted"
            this.job.updatedBy = this.currentUser.id
            const updatedTime = firebase.firestore.Timestamp.fromDate(new Date()).toDate();
            this.job.updatedAt = updatedTime
            // await this.jobUpdate({id:this.id, values:this.job})
          await firebase.firestore().collection('job').onSnapshot({ includeMetadataChanges: true },async () => {
            await firebase.firestore().collection("job").doc(this.id).set(this.job)
          })
        }
        if(this.type == 'property'){
            this.property['postStatus'] = "deleted"
            this.property.updatedBy = this.currentUser.id
            const updatedTime = firebase.firestore.Timestamp.fromDate(new Date()).toDate();
            this.property.updatedAt = updatedTime
            // await this.jobUpdate({id:this.id, values:this.job})
            await firebase.firestore().collection('property').onSnapshot({ includeMetadataChanges: true },async () => {
              await firebase.firestore().collection("property").doc(this.id).set(this.property)
            })
        }
        if(this.type == 'user') {
          
          //  this.$emit('close')
          //   this.$router.push('/users')
          this.user['status'] = "deleted"
            this.user.updatedBy = this.currentUser.id
            const updatedTime = firebase.firestore.Timestamp.fromDate(new Date()).toDate();
            this.user.updatedAt = updatedTime
              await firebase.firestore().collection("user").doc(this.id).set(this.user)
            this.$emit('close')
            this.$router.push('/users')
        }
        if(this.type == 'admin') {
          //  this.$emit('close')
          //   this.$router.push('/users')
          this.user['status'] = "deleted"
            this.user.updatedBy = this.currentUser.id
            const updatedTime = firebase.firestore.Timestamp.fromDate(new Date()).toDate();
            this.user.updatedAt = updatedTime
          // await firebase.firestore().collection('user').onSnapshot({ includeMetadataChanges: true },async () => {
              await firebase.firestore().collection("user").doc(this.id).set(this.user)
            // })
            this.$emit('close')
            this.$router.push('/admins')
        }
           
            this.$emit('close')
      },
      i18n(key, args) {
        return i18n(key, args);
      },
    },
    


}
