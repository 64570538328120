//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DeleteDialog from '@/modules/home/components/delete-dialog.vue';
import PrescriptionDetails from '@/modules/prescriptions/components/prescription-details.vue';
import JobDetails from '@/modules/job/components/job-details.vue';
import PropertyDetails from '@/modules/properties/components/property-details.vue';
import { i18n } from "@/i18n";
import firebase from 'firebase/app';
import 'firebase/firestore';
// import { mapGetters } from 'vuex'
export default {
    data(){
        return{
            language:'',
            deleteUser:false,
            prescDetails:false,
            jobDetails:false,
            propertyDetails:false,
            prescId:null,
            jobId:null,
            propId:null,
            user:null,
            joinedSince:null,
            prescriptions:[],
            jobs:[],
            properties:[],
            userCreatedAt:null,
        }
    },
   
    methods:{
        close(){
            this.deleteUser = false
        },
        openPresc(id){
            this.prescId = id
            this.prescDetails = true
            
        },
        openJob(id){
            this.jobId = id
            this.jobDetails = true
            
        },
        openProp(id){
            this.propId = id
            this.propertyDetails = true
            
        },
        closePresc(){
            this.prescDetails = false
        },
        closeJob(){
            this.jobDetails = false
        },
        closeProperty(){
            this.propertyDetails = false
        },
         i18n(key, args) {
      return i18n(key, args);
    },
    },
    components:{
         [DeleteDialog.name]: DeleteDialog,
         [PrescriptionDetails.name]: PrescriptionDetails,
         [JobDetails.name]: JobDetails,
         [PropertyDetails.name]: PropertyDetails
    },
    mounted(){
        this.language = localStorage.getItem('language')
        // console.log('current user',this.currentUser)
    },
    async created(){
        if(this.$route.params.id) {
        await firebase.firestore().collection('user').onSnapshot( async () => {
            if(this.$route.params.id) {
                const user = await firebase.firestore().collection("user").doc(this.$route.params.id).get()
                this.user = user.data();
                this.userCreatedAt = new Date(this.user.createdAt.seconds * 1000)
                const userMonth = this.userCreatedAt.toString().slice(4,7)
                const joinedIn = new Date(Date.parse(userMonth +" 1, "+this.userCreatedAt.toString().slice(11,15))).getMonth()+1
                // const joinedIn = this.userCreatedAt.slice(5,7)
                var date = new Date();
                var month = date.getMonth() + 1;
                // console.log('month',month)
                this.joinedSince = month - joinedIn
                // console.log('joinedSince',this.joinedSince)
            }

        })
        await firebase.firestore().collection('prescription').onSnapshot({ includeMetadataChanges: true }, async () => {
            this.prescriptions = []
            const prescriptions = await firebase.firestore().collection("prescription").limit(3).where("createdBy", "==", this.$route.params.id).get({source: 'cache'})
            prescriptions.forEach((doc) => {
                let prescription = doc.data()
                prescription['id'] = doc.id
                prescription.createdAt = new Date(prescription.createdAt.seconds * 1000)
                prescription.createdAt = prescription.createdAt.toString().slice(0,15)
                this.prescriptions.push(prescription)
            })
        })
        await firebase.firestore().collection('job').onSnapshot({ includeMetadataChanges: true }, async () => {
            this.jobs = []
            const jobs = await firebase.firestore().collection("job").limit(3).where("createdBy", "==", this.$route.params.id).get({source: 'cache'})
            jobs.forEach((doc) => {
                let job = doc.data()
                job['id'] = doc.id
                job.createdAt = new Date(job.createdAt.seconds * 1000)
                job.createdAt = job.createdAt.toString().slice(0,15)
                this.jobs.push(job)
            })
        })

        await firebase.firestore().collection('property').onSnapshot({ includeMetadataChanges: true }, async () => {
            this.properties = []
            const properties = await firebase.firestore().collection("property").limit(3).where("createdBy", "==", this.$route.params.id).get({source: 'cache'})
            properties.forEach((doc) => {
                let property = doc.data()
                property['id'] = doc.id
                property.createdAt = new Date(property.createdAt.seconds * 1000)
                property.createdAt = property.createdAt.toString().slice(0,15)
                this.properties.push(property)
            })
        })
        }
    }
    
}
