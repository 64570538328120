import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';
import { i18n } from '@/i18n';

const IamListPage = () =>
  import('@/modules/iam/components/iam-list-page.vue');
const IamNewPage = () =>
  import('@/modules/iam/components/iam-new-page.vue');
const IamEditPage = () =>
  import('@/modules/iam/components/iam-edit-page.vue');
const IamViewPageClient = () =>
  import('@/modules/iam/components/iam-view-page-client.vue');
const IamViewPageAdmin = () =>
  import('@/modules/iam/components/iam-view-page-admin.vue');
const IamImporterPage = () =>
  import('@/modules/iam/components/iam-importer-page.vue');

export default [
  {
    name: '',
    path: '/:lang?',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'iam',
        path: 'iam/:accountType/:searchBy?',
        component: IamListPage,
        meta: {
          auth: true,
          permission: Permissions.values.iamRead,
        },
        beforeEnter: (to, from, next) => {
          let { accountType } = to.params
          if(accountType == 'client'){
            document.title =
            i18n('app.title') +
            ' | ' +
            i18n('menu.user') 
          }
          if(accountType == 'admin'){
            document.title =
            i18n('app.title') +
            ' | ' +
            i18n('menu.admin')
          }
          next();
        },
      },
      // {
      //   name: 'admin',
      //   path: '/admin',
      //   component: IamListPage,
      //   meta: {
      //     auth: true,
      //     permission: Permissions.values.iamRead,
      //   },
      // },
      // {
      //   name: 'client',
      //   path: '/client',
      //   component: IamListPage,
      //   meta: {
      //     auth: true,
      //     permission: Permissions.values.iamRead,
      //   },
      // },
      {
        name: 'iamNew',
        path: '/iam/new',
        component: IamNewPage,
        meta: {
          auth: true,
          permission: Permissions.values.iamCreate,
        },
      },
      {
        name: 'iamImporter',
        path: '/iam/import',
        component: IamImporterPage,
        meta: {
          auth: true,
          permission: Permissions.values.iamImport,
        },
      },
      {
        name: 'iamEdit',
        path: '/iam/:id/edit',
        component: IamEditPage,
        meta: {
          auth: true,
          permission: Permissions.values.iamEdit,
        },
        props: true,
      },
      {
        name: 'iamViewClient',
        path: 'iam/client/view/:id',
        component: IamViewPageClient,
        meta: {
          auth: true,
          permission: Permissions.values.iamRead,
        },
        props: true,
      },
      {
        name: 'iamViewAdmin',
        path: 'iam/admin/view/:id',
        component: IamViewPageAdmin,
        meta: {
          auth: true,
          permission: Permissions.values.iamRead,
        },
        props: true,
      },
    ],
  },
];
