//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IamProfile from '@/modules/iam/components/iam-profile.vue';
// import { mapActions, mapGetters } from 'vuex'


export default {
    data(){
        return{
 
        }
    },
    computed:{
    //  ...mapGetters({
        // record:'iam/view/record',
        // loading: 'iam/view/loading',
    //  })
    },
components:{
      [IamProfile.name]: IamProfile
},
    mounted(){
        // this.$refs.vueavatar.loadImage("/images/default-profile.svg")
        // this.$q.iconSet.field.error = "img:/images/invalid-email.png"
        // const fileUpload = document.getElementById('file-upload')
        // fileUpload.addEventListener('change', function(){
        // this.readUrl(this);
        // });
    },
    async created(){
        // await this.doFind(this.$route.params.id)
  
    },
  methods: {
    // ...mapActions({
    //     doFind:'iam/view/doFind',
    // })
   

  }
}
