//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import RegionForm from '@/modules/cities/components/region-form';

import { mapGetters, mapActions } from 'vuex';
// import { i18n } from '@/i18n';
// import i18n from '@/vueI18n'
import i18n from '@/vueI18n'
export default {
  name: 'app-region-form-modal',

  props: ['visible', 'id'],

  components: {
    [RegionForm.name]: RegionForm,
  },

  data() {
    return {
      // successDialog: true,
      operation: undefined,
      pinCode: undefined,
      cityDialog:false,
      regionEn:'',
      regionAr:'',
    };
  },

  computed: {
     isRTL() {
      // return getLanguageCode() == 'ar'
      // return localStorage.getItem('language') == 'ar'
      return i18n.locale == 'ar'
    },
    ...mapGetters({
      record: 'citiesRegions/form/record',
      findLoading: 'citiesRegions/form/findLoading',
      saveLoading: 'citiesRegions/form/saveLoading',
    //   pinCodeLoading: 'citiesRegions/form/pinCodeLoading',
    }),
    dialogVisible: {
      get: function() {
        return this.visible;
      },

      set: function(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    disable(){
      if(this.regionEn && this.regionAr && !this.saveLoading) {
        return false
      }
      return true
    },
    isEditing() {
      return !!this.id;
    },
    isAddOfferManuel() {
      return this.operation == 'addOffer' || this.offerType == 'offer'
    },
    isGeneratePin() {
      return this.operation == 'generatePin'
    },
  },
  
  // async created() {
  //   if (this.isEditing) {
  //     await this.doFind(this.id);
  //   } else {
  //     await this.doNew();
  //   }
  // },
  async mounted() {
    if (this.isEditing) {
      await this.doFind(this.id);
    } else {
      await this.doNew();
    }
  },

  methods: {
    ...mapActions({
      doFind: 'offer/form/doFind',
      doNew: 'offer/form/doNew',
      doUpdate: 'offer/form/doUpdate',
      doCreate: 'citiesRegions/form/doCreate',
      doGeneratePinCode: 'offer/form/doGeneratePinCode',
    }),
   i18n(key, args) {
      return this.$t(key, args);
    },
    doCancel() {
      this.dialogVisible = false;
    },
    async addCity(){
      const city = {
        name:{
          en: this.cityEn,
          ar:  this.cityAr,
          
        },
        regions:[]
      }
      await this.doCreate(city)
      this.cityDialog = false
    },
    async doGenerate() {
      // this.pinCode = await this.doGeneratePinCode()
      this.operation = 'generatePin'
    },
    async doSubmit(payload) {
    //   if (this.isEditing) {
    //     await this.doUpdate(payload);
    //     this.$emit('close');
    //   } else {
        const isCreated = await this.doCreate(payload.values);
        this.$emit('close');
        if (isCreated) {
          this.$emit('success');
        }
    },
  },
};
