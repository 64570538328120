import { JobModel } from '@/modules/job/job-model';

const { fields } = JobModel;

export default [
  fields.name,
  fields.description,
  fields.unitPrice,
  fields.photos,
];
