//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DeleteDialog from '@/modules/home/components/delete-dialog.vue';
import PrescriptionDetails from '@/modules/prescriptions/components/prescription-details.vue';
import JobDetails from '@/modules/job/components/job-details.vue';
import PropertyDetails from '@/modules/properties/components/property-details.vue';
// import { i18n } from "@/i18n";
import i18n from '@/vueI18n'
// import firebase from 'firebase/app';
// import 'firebase/firestore';
import { mapActions,mapGetters } from 'vuex';
import moment from 'moment';

import PrescriptionViewModal from '@/modules/prescription/components/prescription-view-modal.vue';
import JobViewModal from '@/modules/job/components/job-view-modal.vue';
import PropertyViewModal from '@/modules/property/components/property-view-modal.vue';



export default {
    data(){
        return{
            language:'',
            deleteUser:false,
            prescDetails:false,
            jobDetails:false,
            propertyDetails:false,
            // prescId:null,
            // jobId:null,
            // propId:null,
            user:null,
            joinedSince:null,
            prescriptions:[],
            jobs:[],
            properties:[],
            userCreatedAt:null,

            modalVisible: false,
            dialogVisible: false,
            dialogType: undefined,
            selectedRow: null,
            selectedId: null,
            prescModal:false,
            presc:null,
            prescId:null,
            jobModal:false,
            job:null,
            jobId:null,
            propModal:false,
            prop:null,
            propId:null
        }
    },
    watch:{
        // userJobs(newval){
        // }
    },
   computed:{ 
       isRTL(){
               return i18n.locale == 'ar'
           },
       ...mapGetters({
          

           record:'iam/view/record',
           loading: 'iam/view/loading',
           destroyLoading: 'iam/list/loading',

           prescriptionRows:'prescription/list/rows',
           prescriptionCount:'prescription/list/count',
           prescriptionLoading:'prescription/list/loading',

           jobRows:'job/list/rows',
           jobCount:'job/list/count',
           jobLoading:'job/list/loading',

            userJobs: 'job/list/userJobs',
            userProperties: 'property/list/userProperties',
            userPrescriptions: 'prescription/list/userPrescriptions',




            propertyRows: 'property/list/rows',
            propertyCount: 'property/list/count',
            propertyLoading: 'property/list/loading',

       })
       
   },
    methods:{
        ...mapActions({
            doFind:'iam/view/doFind',
            doFetchUserPrescription:'prescription/list/doFetchUserPrescription',
            doFetchUserProperty:'property/list/doFetchUserProperty',
            doFetchUserJob:'job/list/doFetchUserJob',
            doDisableAllSelected:'iam/list/doDisableAllSelected',

            doFetchJobPositions: 'job/view/doFetchJobPositions',
            doFetchWorkplaces: 'job/view/doFetchWorkplaces',
            doFetchCities: 'job/view/doFetchCities',

            doFetchFacilityTypes:'property/view/doFetchFacilityTypes'

        }),
        onModalOpen(row, operation) {
      
      this.selectedRow = row;
      this.selectedId = row.id;
      switch (operation) {
        case 'delete':
          this.dialogType = 'delete'
          break;
        case 'edit':
          this.dialogType = 'edit'
          break;
        case 'view':
          this.dialogType = 'view'
          break;  
        default:
          this.dialogType = undefined
          break;
      }
      this.modalVisible = true
      this.dialogVisible = true
    },
     onModalClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.modalVisible = false;
        this.dialogType = undefined
      }, 500);
   
    
    
    },
    onModalSuccess() {
      this.onModalClose();
    },
    async doDestroyWithConfirm(id) {
      
      try {
        await this.doDisableAllSelected([id])

        this.onModalClose()
        setTimeout(() => {
                this.$router.push('/iam/client')
            }, 500);
        
      } catch (error) {
        // no
      }
    },
        close(){
            this.deleteUser = false
        },
        openPresc(row){
            // this.prescId = id
            this.presc = row
            this.prescId = row.id
            this.prescModal = true
            this.dialogVisible = true
            
        },
        onModalPrescClose(){
            this.dialogVisible = false;
            setTimeout(() => {
                this.prescModal = false;
                // this.dialogType = undefined
            }, 500);
        },
        openJob(row){
            this.job = row
            this.jobId = row.id
            this.jobModal = true
            this.dialogVisible = true
            // console.log('job row',this.job)
            
        },
        onModalJobClose(){
            this.dialogVisible = false;
            setTimeout(() => {
                this.jobModal = false;
                // this.dialogType = undefined
            }, 500);
        },
        openProp(row){
            // this.propId = id
            // this.propertyDetails = true
            this.prop = row
            this.propId = row.id
            this.propModal = true
            this.dialogVisible = true
            
        },
        onModalPropClose(){
            this.dialogVisible = false;
            setTimeout(() => {
                this.propModal = false;
                // this.dialogType = undefined
            }, 500);
        },
        // closePresc(){
        //     this.prescDetails = false
        // },
        // closeJob(){
        //     this.jobDetails = false
        // },
        // closeProperty(){
        //     this.propertyDetails = false
        // },
        // i18n(key, args) {
        //     return i18n(key, args);
        // },
        i18n(key, args) {
      // return i18n(key, args);
      return this.$t(key, args);

    },
        presenterDate(date) {
            // const val = JobModel.presenter(row, fieldName)
            return date ? moment(date).locale(this.language).format("D MMM, YYYY") : 'ـــــــ'; 
            },
    },
    components:{
         [DeleteDialog.name]: DeleteDialog,
         [PrescriptionDetails.name]: PrescriptionDetails,
         [JobDetails.name]: JobDetails,
         [PropertyDetails.name]: PropertyDetails,


    [PrescriptionViewModal.name]: PrescriptionViewModal,
    [JobViewModal.name]: JobViewModal,
    [PropertyViewModal.name]: PropertyViewModal

    },
    mounted(){
        this.language = localStorage.getItem('language')
        this.refreshLoading = true
        // console.log('current user',this.currentUser)
//         console.log('id',this.$route.params.id)
// console.log('presc rows',this.prescriptionRows)
// console.log('jobRows',this.jobRows)
    },
    async created(){
        await this.doFind({id:this.$route.params.id})
          this.userCreatedAt = new Date(this.record.createdAt.seconds * 1000)
                const userMonth = this.userCreatedAt.toString().slice(4,7)
                const joinedIn = new Date(Date.parse(userMonth +" 1, "+this.userCreatedAt.toString().slice(11,15))).getMonth()+1
                // const joinedIn = this.userCreatedAt.slice(5,7)
                var date = new Date();
                var month = date.getMonth() + 1;
                // console.log('month',month)
                this.joinedSince = month - joinedIn


        // let filter = {
        //     createdBy: this.record.id,
        //     // postStatus:"accepted"
        // }
        // await 
        
        this.doFetchUserPrescription({userId: this.record.id})
        // await 
        // ==========================
        this.doFetchWorkplaces()
        this.doFetchJobPositions()
        this.doFetchCities()
        this.doFetchUserJob({userId: this.record.id})
        // ===========================
        this.doFetchFacilityTypes()
        this.doFetchUserProperty({userId: this.record.id})
        
    
    }
    
}
