//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import OfferForm from '@/modules/offer/components/offer-form';

// import { mapGetters, mapActions } from 'vuex';
// import { i18n } from '@/i18n';
// import i18n from '@/vueI18n';

import firebase from 'firebase/app';
// import 'firebase/auth';        // for authentication
// import 'firebase/storage';     // for storage
import 'firebase/database';    // for realtime database
// import 'firebase/firestore';   // for cloud firestore
// import 'firebase/messaging';   // for cloud messaging
// import 'firebase/functions';   // for cloud functions
import { mapGetters } from 'vuex';



export default {
  name: 'app-setting-form-modal',

  props: ['visible', 'formTitle','message','entity'],

  components: {
    // [OfferForm.name]: OfferForm,
  },

  data() {
    return {
      // successDialog: true,
      operation: undefined,
      pinCode: undefined,
      maxNumber:undefined,
      loading: false,
    };
  },

  computed: {
     ...mapGetters({
            jobMaxCount:'setting/list/jobMaxCount',
            prescriptionMaxCount:'setting/list/prescriptionMaxCount',
            propertyMaxCount:'setting/list/propertyMaxCount',
            prescriptionExpirationDays: 'setting/list/prescriptionExpirationDays'

        }),
    // ...mapGetters({
    //   record: 'offer/form/record',
    //   findLoading: 'offer/form/findLoading',
    //   saveLoading: 'offer/form/saveLoading',
    //   pinCodeLoading: 'offer/form/pinCodeLoading',
    // }),
    dialogVisible: {
      get: function() {
        return this.visible;
      },

      set: function(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    
    // isEditing() {
    //   return !!this.id;
    // },
    // isAddOfferManuel() {
    //   return this.operation == 'addOffer' || this.offerType == 'offer'
    // },
    // isGeneratePin() {
    //   return this.operation == 'generatePin'
    // },
  },
  
  // async created() {
  //   if (this.isEditing) {
  //     await this.doFind(this.id);
  //   } else {
  //     await this.doNew();
  //   }
  // },
//   async mounted() {
//     if (this.isEditing) {
//       await this.doFind(this.id);
//     } else {
//       await this.doNew();
//     }
//   },

  methods: {
    // ...mapActions({
    //   doFind: 'offer/form/doFind',
    //   doNew: 'offer/form/doNew',
    //   doUpdate: 'offer/form/doUpdate',
    //   doCreate: 'offer/form/doCreate',
    //   doGeneratePinCode: 'offer/form/doGeneratePinCode',
    // }),
    validateNumber(n){
        return n == "0" || ((n | 0) > 0 && n % 1 == 0);
    },
    //  validateNumber(inputtxt){
    //     return Number.isInteger(inputtxt)
    //     // const phoneno = /^\d{11}$/
    //     // if (phoneno.test(inputtxt)){
    //     //     this.validPhone = true
    //     //     return true; 
    //     // }
    //     // else {
    //     //     this.validPhone = false
    //     //     return false;
    //     // } 
    //     },
    // i18n(code) {
    //   return i18n(code)
    // },
    i18n(key, args) {
      return this.$t(key, args);
    },
    async doSubmit(){
        this.loading = true 
        await firebase.database().ref(`setting/${this.entity}`).set(parseInt(this.maxNumber))
        this.loading = false
        this.$emit('success');
       
        

    },
    doCancel() {
      this.dialogVisible = false;
    },

    async doGenerate() {
      // this.pinCode = await this.doGeneratePinCode()
      this.operation = 'generatePin'
    },
    // async doSubmit(payload) {
    //   if (this.isEditing) {
    //     await this.doUpdate(payload);
    //     this.$emit('close');
    //   } else {
    //     const isCreated = await this.doCreate(payload.values);
    //     this.$emit('close');
    //     if (isCreated) {
    //       // this.successDialog = true
    //       this.$emit('success');
    //     }
    //   }
    // },
  },
  mounted(){
    this.maxNumber = 0
    if(this.entity == "prescription"){
      this.maxNumber = this.prescriptionMaxCount
    }
    if(this.entity == "property"){
      this.maxNumber = this.propertyMaxCount
    }
    if(this.entity == "job"){
      this.maxNumber = this.jobMaxCount
    }
    if(this.entity == "prescriptionExpirationDays"){
      this.maxNumber = this.prescriptionExpirationDays
    }
  
  },
  
};
