import Layout from '@/modules/layout/components/layout';
const Users = () =>
  import('@/modules/users/components/users.vue');
const UserProfile = () =>
  import('@/modules/users/components/user-profile.vue');
export default [
  {
    path: '',
    exact: true,
    component: Layout,
    children: [
      {
        name: '',
        path: 'users',
        component: Users,
        exact: true,
        meta: { auth: true },
        
      },
      {
        name:'user-profile',
        path:'users/:id',
        component:UserProfile,
        exact: true,
        meta: { auth: true }
      },
    ]
  }

]
