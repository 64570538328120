import { setLanguageCode } from '@/i18n';
// const fs = require('fs');
// const child_process = require('child_process');
// import { exec } from 'child_process';

export class I18nUtil {
  static doChangeLanguage(language) {
    let hostname = location.hostname;
    // let port = location.port;

    // let protocol = window.location.protocol
    let host = window.location.host // js-tricks.info
    // let search = window.location.search // ?s=flexbox
    // let pathname = window.location.pathname // Returns path only : "check-path-example"
    let url = window.location.href // Returns full URL : 'https://js-tricks.info/check-path-example'


    

    if (hostname.includes('localhost')) {
      
      // let child
      // if (language == 'ar') {
      //   child = child_process.exec('cp -R public/index-ar/index.html public/index.html',
      //   (err, stdout, stderr) => {
      //     if (err) {
      //       return;
      //     }
        
   
      //   });
      //   child();
      // } else {
      //   // sh('cp -R public/index-en/index.html public/index.html');
      // }
      setLanguageCode(language);
      document.location.reload(false);
      return
    } 

    if (language == 'en') {
      window.location = url.replace('ar.', '')
      return
    }

    if (language == 'ar') {
      host = host.replace('www.', '')
      window.location = url.replace(host, `ar.${host}`)
      return
    }
  }
}

