//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { i18n, getLanguageCode } from '@/i18n';

export default {
  name: 'app-prescription-view-modal',

  props: ['visible', 'id', 'record'],

  data() {
    return {
      createdAtUpdated: null,
      tab: getLanguageCode() == 'ar' ? 'arabic' : 'english',
      language: getLanguageCode(),
    };
  },

  computed: {
    ...mapGetters({
      // record: 'job/form/record',
      findLoading: 'job/form/findLoading',
      saveLoading: 'job/form/saveLoading',
    }),
    dialogVisible: {
      get: function() {
        return this.visible;
      },

      set: function(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    // tab() {
    //   return getLanguageCode() == 'ar' ? 'english' : 'arabic'
    // }
  },
  
  async created() {
    // console.log('hh',new Date(this.record.createdAt).toString().slice(0,16))
    this.createdAtUpdated = new Date(this.record.createdAt.seconds * 1000).toString().slice(0,16)
  },

  methods: {
    ...mapActions({
      doFind: 'job/form/doFind',
      doNew: 'job/form/doNew',
      doUpdate: 'job/form/doUpdate',
      doCreate: 'job/form/doCreate',
      doGeneratePinCode: 'job/form/doGeneratePinCode',
    }),
    i18n(code, args) {
      return i18n(code, args)
    },
    doCancel() {
      this.dialogVisible = false;
    },
    doChangeLanguage() {
      this.tab == 'arabic'
        ? this.language = 'ar'
        : this.language = 'en'
    },
    close(){
        this.$emit('close')
    }
  },
};
