import { storeAsync } from '@/app-module';
import i18n from '@/vueI18n'
export default {
  async beforeRouteEnter(to, from, next) {
    
    let { lang } = to.params
    // let  lang  = 'en'
    // check for language and redirect to the same path with default language
    // console.log('to',to)
    // console.log('next',next)
    if (!lang || (lang !== 'ar' && lang !== 'en')){
      lang = localStorage.getItem('language') || 'en'
      i18n.locale = lang
      return next({ path: `/${lang}${to.path}` });
    }
    // if(lang !== 'ar' && lang !== 'en'){
    //    lang = 'en'
    // // // i18n.locale = lang
    // // localStorage.setItem("language",lang)
    // console.log('hereeeeeee')
    // }
    if(lang !== 'ar' && lang !== 'en') lang = 'en'
    i18n.locale = lang
    localStorage.setItem("language",lang)


    // if(lang == 'en'){
    //   // this.$q.lang.rtl = false
    //   console.log('language',lang)
    // }
    // if(lang == 'ar'){
    //   // this.$q.lang.rtl = true
    //   console.log('language',lang)

    // }





    if (!to.meta || !to.meta.auth) {
      next();
      return;
    }

    const store = storeAsync();
    let currentUser = localStorage.getItem('currentUser');
    if (currentUser) {
      await store.dispatch('auth/doWaitUntilInit');
    }
    // await store.dispatch('auth/doWaitUntilInit');

    if (!store.getters['auth/signedIn']) {
      // next({ path: '/auth/signin' });
      next({ path: `/${lang}/auth` });
      return;
    }
    
    if (
      to.path !== `/${lang}/auth/email-unverified` &&
      !store.getters['auth/currentUser'].emailVerified
    ) {
      next({ path: `/${lang}/auth/email-unverified` });
      return;
    }

    if (
      to.path !== `/${lang}/auth/empty-permissions` &&
      store.getters['auth/currentUser'].emailVerified &&
      !store.getters['auth/roles'].length
    ) {
      next({ path: `/${lang}/auth/empty-permissions` });
      return;
    }

    next();
  },
};
