import Layout from '@/modules/layout/components/layout';
import { i18n } from '@/vueI18n';

const TermsAndConditions = () =>
  import('@/modules/policies/components/terms-and-conditions.vue');
const PrivacyPolicy = () =>
  import('@/modules/policies/components/privacy-policy.vue');

export default [
  {
    name: '',
    path: '/:lang?',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'termsAndConditions',
        path: 'terms-and-conditions',
        component: TermsAndConditions,
        meta: {
          auth: true,
          // title: i18n('entities.policies.termsAndConditionss')
        },
        beforeEnter: (to, from, next) => {
          // document.title = to.meta.title
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('entities.policies.termsAndConditions')
          next()
        }
      },
      {
        name: 'privacyPolicy',
        path: 'privacy-policy',
        component: PrivacyPolicy,
        meta: {
          auth: true,
          // title: i18n('entities.policies.privacyPolicy')
        },
        beforeEnter: (to, from, next) => {
          // document.title = to.meta.title
          document.title =
            i18n('app.title') +
            ' | ' +
            i18n('entities.policies.privacyPolicy')
          next()
        }
      },
    ],
  },
];
