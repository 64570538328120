//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import i18n from '@/vueI18n'
import moment from 'moment'
import { mapActions,mapGetters } from 'vuex';
export default {
  name: 'app-notification-card',
  props:['notification'],
  data() {
    return {
    };
  },
  computed: {
    isRTL() {
      // return getLanguageCode() == 'ar'
      // return localStorage.getItem('language') == 'ar'
      return i18n.locale == 'ar'
    },
    date(){
      if(this.isRTL){
        return moment(this.notification.createdAt.seconds,'X').locale("ar").format("DD MMMM YYYY - hh:mm a")
      }
      return moment(this.notification.createdAt.seconds,'X').locale("en").format("DD MMMM YYYY - hh:mm a")
    },
    ...mapGetters({
      currentUser:'auth/currentUser'
    })
  },
  methods:{
    ...mapActions({
      doUpdate:'notification/doUpdate'
    }),
  },
  created(){
    if(this.notification.status == "unread"){
      const payload = {
      type:this.notification.type,
      status:'read',
      createdAt: new Date(this.notification.createdAt.seconds * 1000),
      postId: this.notification.postId,
      createdBy: this.notification.createdBy,
      title:this.notification.title,
      description: this.notification.description
    }
    // console.log('pa');
    this.doUpdate({userId:this.currentUser.id,id:this.notification.id,values:payload})
    }
    
  }
};
