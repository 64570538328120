import { i18n } from '@/i18n';
import { GenericModel } from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';
import StringArrayField from '@/shared/fields/string-array-field'
import DecimalField from '@/shared/fields/decimal-field';
import FilesField from '@/shared/fields/files-field';
import DateTimeField from '@/shared/fields/date-time-field';
import IntegerField from '@/shared/fields/integer-field';

function label(name) {
  return i18n(`drugDeal.drugIndex.fields.${name}`);
}

const fields = {
  id: new StringField('id', label('id'), {}),
  index: new IntegerField('index', label('index'), {}),
  objectID: new StringField('objectID', label('objectID'), {}),
  drugName: new StringField('drugName', label('drugName'), {
    "max": 21845
  }),
  genericName: new StringArrayField('genericName', label('genericName'), {
    "required": true,
  }),
  price: new DecimalField('price', label('price'),{
    "required": true,
  }),
  oldPrice: new DecimalField('oldPrice', label('oldPrice'),{
    "required": true,
  }),
  company: new StringArrayField('company', label('company'), {
    "required": true,
  }),
  pharmacologicalUse: new StringArrayField('pharmacologicalUse', label('pharmalogicalUse'), {
    "required": true,
  }),
  mechOfAction: new StringField('mechOfAction', label('mechOfAction'), {
    "required": true,
  }),
  route: new StringArrayField('route', label('route'), {
    "required": true,
  }),
  attachments: new FilesField('attachments', label('attachments'), 'drugIndex/attachments',{
    "size": 100000000,
      // "formats": 
      // "txt",
      // "pdf",
      // "xslx",
      // "csv"
    "max": 3
  }),
  createdBy: new StringField('createdBy', label('createdBy'), { required: true }),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
};

export class DrugIndexModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
