// import productListStore from '@/modules/product/product-list-store';
// import productViewStore from '@/modules/product/product-view-store';
// import productImporterStore from '@/modules/product/product-importer-store';
import citiesRegionsFormStore from '@/modules/cities/citiesRegions-form-store';
import citiesRegionsListStore from '@/modules/cities/citiesRegions-list-store';

// import productDestroyStore from '@/modules/product/product-destroy-store';

export default {
  namespaced: true,

  modules: {
    // destroy: productDestroyStore,
    form: citiesRegionsFormStore,
    list: citiesRegionsListStore
    // list: productListStore,
    // view: productViewStore,
    // importer: productImporterStore,
  },
};
