import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-contact';
// import firebase from 'firebase';
// import * as securePin from "secure-pin";
// import { storeAsync } from '@/app-module';
// import FirebaseQuery from '@/shared/firebase/firebaseQuery'

export class ContactService {
//   static async create(data) {
//     const response = await graphqlClient.mutate({
//       mutation: gql `
//         mutation CONTACT_CREATE($data: ContactInput!) {
//           contactCreate(data: $data) {
//             id
//           }
//         }
//       `,

//       variables: {
//         data,
//       },
//     });

//     return response.data.contactCreate;
//   }

//   static async update(id, data) {
//     const response = await graphqlClient.mutate({
//       mutation: gql `
//         mutation OFFER_UPDATE(
//           $id: String!
//           $data: OfferInput!
//         ) {
//           offerUpdate(id: $id, data: $data) {
//             id
//           }
//         }
//       `,

//       variables: {
//         id,
//         data,
//       },
//     });

//     return response.data.offerUpdate;
//   }

//   static async offerCanBeDeleted(id) {
//     return !!(await firebase.firestore().collection('items').where('offerId', '==', id).get()).docs.length
//   }

//   static async destroyAll(ids) {
//     const response = await graphqlClient.mutate({
//       mutation: gql `
//         mutation OFFER_DESTROY($ids: [String!]!) {
//           offerDestroy(ids: $ids)
//         }
//       `,

//       variables: {
//         ids,
//       },
//     });

//     return response.data.offerDestroy;
//   }


//   static async import(values, importHash) {
//     const response = await graphqlClient.mutate({
//       mutation: gql `
//         mutation OFFER_IMPORT(
//           $data: OfferInput!
//           $importHash: String!
//         ) {
//           offerImport(data: $data, importHash: $importHash)
//         }
//       `,

//       variables: {
//         data: values,
//         importHash,
//       },
//     });

//     return response.data.offerImport;
//   }

//   static async find(id) {
//     const response = await graphqlClient.query({
//       query: gql `
//         query OFFER_FIND($id: String!) {
//           offerFind(id: $id) {
//             id
//             image {
//               id
//               name
//               publicUrl
//             }
//             title {
//               en
//               ar
//             }
//             description {
//               en
//               ar
//             }
//             createdBy {
//               id
//               fullName
//               avatar 
//             }
//             updatedBy {
//               id
//               fullName
//               avatar 
//             }
//             createdAt
//             updatedAt
//           }
//         }
//       `,

//       variables: {
//         id,
//       },
//     });

//     return response.data.offerFind;
//   }

  static async list(filter, orderBy, limit, offset) {
    const response = await graphqlClient.query({
      query: gql `
        query CONTACT_LIST(
          $filter: ContactFilterInput
          $orderBy: ContactOrderByEnum
          $limit: Int
          $offset: Int
        ) {
          contactList(
            filter: $filter
            orderBy: $orderBy
            limit: $limit
            offset: $offset
          ) {
            count
            rows {
              id
              name
              email
              phone
              message              
              createdAt
              updatedAt
            }
          }
        }
      `,

      variables: {
        filter,
        orderBy,
        limit,
        offset,
      },
    });

    return response.data.contactList;
  }

//   static async listAutocomplete(query, limit) {
//     const response = await graphqlClient.query({
//       query: gql `
//         query OFFER_AUTOCOMPLETE(
//           $query: String
//           $limit: Int
//         ) {
//           offerAutocomplete(query: $query, limit: $limit) {
//             id
//             label
//           }
//         }
//       `,

//       variables: {
//         query,
//         limit,
//       },
//     });

//     return response.data.offerAutocomplete;
//   }

 


}