import Layout from '@/modules/layout/components/layout';
import { i18n } from '@/i18n';

// const Regions = () =>
//   import('@/modules/cities/components/regions.vue');
// const Cities = () =>
//   import('@/modules/cities/components/cities.vue');
const CityListPage = () =>
  import('@/modules/cities/components/city-list-page.vue');
const RegionListPage = () =>
  import('@/modules/cities/components/region-list-page.vue');
const CitiesRegions = () =>
  import('@/modules/cities/components/cities-regions.vue');
export default [
    {
      path: '/:lang?',
      exact: true,
      component: Layout,
      children: [
        // {
        //     name: 'Regions',
        //     path: 'regions',
        //     component: Regions,
        //     meta: { auth: true },
        // },
        {
          name: 'region',
          path: 'region',
          component: RegionListPage,
          meta: { auth: true },
          beforeEnter: (to, from, next) => {
            document.title =
              i18n('app.title') +
              ' | ' +
              i18n('menu.region') 
            next();
          },
      },
        {
          name: 'city',
          path: 'city',
          component: CityListPage,
          meta: { auth: true },
          beforeEnter: (to, from, next) => {
            document.title =
              i18n('app.title') +
              ' | ' +
              i18n('menu.city') 
            next();
          },
        },
        // {
        //   name: 'cities',
        //   path: 'cities',
        //   component: Cities,
        //   meta: { auth: true },
        // },
        {
          name: 'cities-regions',
          path: 'cities-regions',
          component: CitiesRegions,
          // meta: { auth: true },
        },
      ]
    }
]