//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import firebase from 'firebase/app'
import 'firebase/firestore';
import { i18n } from "@/i18n";

export default {
    name:'app-property-details',
    props:['propertyDetails','id'],
    data(){
        return{
            noImage :false,
            createdAt:"",
            property:null,
            categoryId:null,
            cityId:null,
            regionId:null,
            listedForId:null,
            category:null,
            city:null,
            region:null,
            listedFor:null,
            images:[
                {
                    id:1,url:'/images/property3.svg'
                },
                {
                    id:2,url:'/images/property2.svg'
                },
                {
                    id:3,url:'/images/property3.svg'
                },
            ],
            language:null
        }
    },
    computed:{
        propertyUserDialog:{
            get(){
                return this.propertyDetails
            },
            set(){
                this.$emit('closeProperty')
            }
        }
    },
    methods:{
        close(){
            this.$emit('closeProperty')
        },
        i18n(key, args) {
            return i18n(key, args);
        },
    },
    async created(){
        this.language = localStorage.getItem('language')
        if (this.id !== null && this.id !== undefined){
        const id = this.id
        await firebase.firestore().collection('property').onSnapshot(async () => {
        // var source = snapshot.metadata.fromCache ? "local cache" : "server";
        this.property = await firebase.firestore().collection("property").doc(id).get()
        if(this.property.data().images[0] === "") {
            this.noImage = true
        }
        else {
            this.noImage = false
        }
      
        this.categoryId = this.property.data().typeId 
        this.cityId = this.property.data().cityId
        this.regionId = this.property.data().regionId
        this.listedForId = this.property.data().listedForId
        if(this.createdAt.seconds) { 
            this.createdAt = new Date(this.property.data().createdAt.seconds * 1000) 
            this.createdAt = this.createdAt.toString().slice(0,15)
        }
        else{
           this.createdAt = new Date(this.property.data().createdAt).toString().slice(0,15)
        }
        


        await firebase.firestore().collection('facilityListedFor').onSnapshot({ includeMetadataChanges: true },async () => {
        this.listedFor = await firebase.firestore().collection("facilityListedFor").doc(this.listedForId).get()
        })
        await firebase.firestore().collection('facilityType').onSnapshot({ includeMetadataChanges: true },async () => {
        this.category = await firebase.firestore().collection("facilityType").doc(this.categoryId).get()
        })
        await firebase.firestore().collection('city').onSnapshot({ includeMetadataChanges: true },async () => {
        this.city = await firebase.firestore().collection("city").doc(this.cityId).get()
        this.region = await firebase.firestore().collection("city").doc(this.cityId).collection("regions").doc(this.regionId).get()
        })


        })
        
        }
    }
}
