//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import{i18n} from '@/i18n'
export default {
    name:'app-drug-dialog',
    props:['openDialog','drug'],
    computed:{
        dialog:{
            get(){
                return this.openDialog
            },
            set(){
                this.$emit('close')
            }
        }
    },
    methods:{
      i18n(key, args) {
      return i18n(key, args);
    },
        close(){
            this.$emit('close')
        }
    },
    created(){
      // console.log('id :',this.id)
    }

}
