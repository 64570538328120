import { DrugIndexService } from '@/modules/drugIndex/drugIndex-service';
import Errors from '@/shared/error/errors';
// import { routerAsync } from '@/app-module';
// import Message from '@/shared/message/message';
// import { i18n } from '@/i18n';
// import { app } from '@/shared/firebase/firebase-init';
// import firebase from 'firebase/app';
// import 'firebase/functions';   // for cloud functions


export default {
  namespaced: true,

  state: {
    findLoading: false,
    saveLoading: false,
    record: null,
    publishLoading: false,
  },

  getters: {
    record: (state) => state.record,
    findLoading: (state) => !!state.findLoading,
    saveLoading: (state) => !!state.saveLoading,
    publishLoading: (state) => !!state.publishLoading,
  },

  mutations: {
    RESET(state) {
      state.findLoading = false;
      state.saveLoading = false;
      state.record = null;
    },

    FIND_STARTED(state) {
      state.record = null;
      state.findLoading = true;
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.findLoading = false;
    },

    FIND_ERROR(state) {
      state.record = null;
      state.findLoading = false;
    },

    CREATE_STARTED(state) {
      state.saveLoading = true;
    },

    CREATE_SUCCESS(state) {
      state.saveLoading = false;
    },

    CREATE_ERROR(state) {
      state.saveLoading = false;
    },

    UPDATE_STARTED(state) {
      state.saveLoading = true;
    },

    UPDATE_SUCCESS(state) {
      state.saveLoading = false;
    },

    UPDATE_ERROR(state) {
      state.saveLoading = false;
    },

    PUBLISH_STARTED(state) {
      state.publishLoading = true;
    },

    PUBLISH_SUCCESS(state) {
      state.publishLoading = false;
    },

    PUBLISH_ERROR(state) {
      state.publishLoading = false;
    },
  },

  actions: {
    doNew({ commit }) {
      commit('RESET');
    },
    async doAddFileDetails({ commit }, file) {
      try {
        commit('CREATE_STARTED');
        // await DrugIndexService.addDataInRealTimeDatabase(`setting/drugIndexFile`, file && file.name ? file.name : '');
        await DrugIndexService.addDataInRealTimeDatabase(`setting/file-details`, file || '');
        commit('CREATE_SUCCESS');
      } catch (error) {
        Errors.handle(error);
        commit('CREATE_ERROR');
      }
    },
    async doUpdateFileDetails({ commit }, value) {
      try {
        commit('UPDATE_STARTED');
        await DrugIndexService.updateDataInRealTimeDatabase(`setting/file-details`, value);
        commit('UPDATE_SUCCESS');
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },
    async doRemoveFileDetails({ commit }) {
      try {
        commit('CREATE_STARTED');
        await DrugIndexService.deleteDataFromRealTimeDatabase(`setting/file-details`);
        commit('CREATE_SUCCESS');
      } catch (error) {
        Errors.handle(error);
        commit('CREATE_ERROR');
      }
    },
    async doPublish({ commit }) {
      try {
        commit('PUBLISH_STARTED');
        const file = await DrugIndexService.findDataFromRealTimeDatabase('setting/file-details')
        const filePath = file.privateUrl
        await DrugIndexService.publishToAlgolia(filePath)
        commit('PUBLISH_SUCCESS');
      } catch (error) {
        Errors.handle(error);
        commit('PUBLISH_ERROR');
      }
    },

    // async doFind({ commit }, id) {
    //   try {
    //     commit('FIND_STARTED');

    //     const record = await ProductService.find(id);

    //     commit('FIND_SUCCESS', record);
    //   } catch (error) {
    //     Errors.handle(error);
    //     commit('FIND_ERROR');
    //     routerAsync().push('/product');
    //   }
    // },

    async settingsUpdate({ commit }, value) {
      try {
        commit('CREATE_STARTED');
        await DrugIndexService.settingsUpdate(value);
        commit('CREATE_SUCCESS');
      } catch (error) {
        Errors.handle(error);
        commit('CREATE_ERROR');
      }
    },

    async drugIndexUploadFile({ commit }, value) {
      try {
        commit('CREATE_STARTED');
        await DrugIndexService.drugIndexUploadFile(value.fileName, value.collectionName);
        commit('CREATE_SUCCESS');
        // var drugIndexApi = firebase.functions().httpsCallable('drugIndexApi');
        // // console.log(drugIndexApi);
        // drugIndexApi({ fileName: value.fileName, collectionName: value.collectionName, currentUser: value.currentUser },{app})
        //   .then((result) => {
        //     // Read result of the Cloud Function.
        //     // var sanitizedMessage = result.data.text;
        //     console.log('result data', result.data);
        //   })
        //   .catch((error) => {
        //     console.log('error',error);
        //     // Getting the Error details.
        //     // var code = error.code;
        //     // var message = error.message;
        //     // var details = error.details;
        //     // ...
        //   });
      } catch (error) {
        Errors.handle(error);
        commit('CREATE_ERROR');
      }
    },
    async drugIndexPublishFile({ commit }) {
      
      try {
        commit('CREATE_STARTED');
        await DrugIndexService.drugIndexPublishFile();
        commit('CREATE_SUCCESS');
      } catch (error) {
        Errors.handle(error);
        commit('CREATE_ERROR');
      }
    },
    async doUpdateAllToRemoved({ commit }, { ids, collectionName }) {
      commit('UPDATE_STARTED');
      try {
        commit('UPDATE_STARTED');
        await DrugIndexService.updateAllToRemoved(ids, collectionName);
        commit('UPDATE_SUCCESS');
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },
    async doUpdate({ commit }, { id, values }) {
      try {
        commit('UPDATE_STARTED');
        // console.log('job store',id)
        // console.log('store values',values)
        await DrugIndexService.update(id, values);
        commit('UPDATE_SUCCESS');
        // Message.success(i18n('entities.job.update.success'));
        // routerAsync().push('/job');
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },
  },
};
