import { NotificationService } from '@/modules/notification/notification-service';
// import offerListExporterFields from '@/modules/offer/offer-list-exporter-fields';
import Errors from '@/shared/error/errors';
// import Exporter from '@/shared/exporter/exporter';
import { firestoreAction,vuexfireMutations } from 'vuexfire'
import firebase from 'firebase/app';

const INITIAL_PAGE_SIZE = 20;

export default {
  namespaced: true,

  state: {
    firstBatch: [],
    secondBatch: [],
    loadMoreLastRecord: null,
    loadFirstRecord: null,
    count: 0,
    loading: false,
    backgroundLoading: false,
    filter: {},
    pagination: {},
    sorter: {},
    saveLoading:false,
    table: null,
    newRowsLength: 0
  
  },

  getters: {
    backgroundLoading: (state) => state.backgroundLoading,
    loading: (state) => state.loading,

    exportLoading: (state) => state.exportLoading,

    firstBatch: (state) => state.firstBatch || [],
    secondBatch: (state) => state.secondBatch || [],
    newRowsLength: (state) => state.newRowsLength,
    loadFirstRecord: (state) => state.loadFirstRecord || [],
    loadMoreLastRecord: (state) => state.loadMoreLastRecord || [],

    count: (state) => state.count,

    hasRows: (state, getters) => getters.count > 0,

    orderBy: (state) => {
      const sorter = state.sorter;

      if (!sorter) {
        return null;
      }

      if (!sorter.prop) {
        return null;
      }

      let direction =
        sorter.order === 'descending' ? 'DESC' : 'ASC';

      return `${sorter.prop}_${direction}`;
    },

    filter: (state) => state.filter,

    limit: (state) => {
      const pagination = state.pagination;

      if (!pagination || !pagination.pageSize) {
        return INITIAL_PAGE_SIZE;
      }

      return pagination.pageSize;
    },

    offset: (state) => {
      const pagination = state.pagination;

      if (!pagination || !pagination.pageSize) {
        return 0;
      }

      const currentPage = pagination.currentPage || 1;

      return (currentPage - 1) * pagination.pageSize;
    },

    pagination: (state, getters) => {
      return {
        ...state.pagination,
        total: getters.count,
        showSizeChanger: true,
      };
    },

    selectedRows: (state) => {
      return state.table ? state.table.selection : [];
    },
  },

  mutations: {
    RESETED(state) {
      state.rows = [];
      state.count = 0;
      state.loading = false;
      state.filter = {};
      state.pagination = {};
      state.sorter = {};
      if (state.table) {
        state.table.clearSelection();
      }
    },

    UNSELECT_ALL(state) {
      if (state.table) {
        state.table.clearSelection();
      }
    },

    TABLE_MOUNTED(state, payload) {
      state.table = payload;
    },

    PAGINATION_CHANGED(state, payload) {
      state.pagination = payload || {};
    },

    PAGINATION_CURRENT_PAGE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: payload || 1,
        pageSize:
          previousPagination.pageSize || INITIAL_PAGE_SIZE,
      };
    },

    PAGINATION_PAGE_SIZE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: previousPagination.currentPage || 1,
        pageSize: payload || INITIAL_PAGE_SIZE,
      };
    },

    SORTER_CHANGED(state, payload) {
      state.sorter = payload || {};
    },

    FETCH_STARTED_IN_BACKGROUND(state, payload) {
      state.backgroundLoading = true;
      if (state.table) {
        state.table.clearSelection();
      }

      state.filter =
        payload && payload.filter ? payload.filter : {};
      state.pagination =
        payload && payload.keepPagination
          ? state.pagination
          : {};
    },
    FETCH_STARTED(state){
      state.loading = true
    },
    FETCH_SUCCESS(state){
      state.loading = false
    },

    // FETCH_STARTED(state, payload) {
    //   state.loading = true;

    //   if (state.table) {
    //     state.table.clearSelection();
    //   }

    //   state.filter =
    //     payload && payload.filter ? payload.filter : {};
    //   state.pagination =
    //     payload && payload.keepPagination
    //       ? state.pagination
    //       : {};
    // },

    // FETCH_SUCCESS(state, payload) {
    //   state.loading = false;
    //   state.backgroundLoading = false;
    //   state.rows = payload.rows;
    //   state.count = payload.count;
    // },

    FETCH_ERROR(state) {
      state.loading = false;
      state.backgroundLoading = false;
      state.rows = [];
      state.count = 0;
    },

    EXPORT_STARTED(state) {
      state.exportLoading = true;
    },

    EXPORT_SUCCESS(state) {
      state.exportLoading = false;
    },

    EXPORT_ERROR(state) {
      state.exportLoading = false;
    },
    UPDATE_STARTED(state) {
      state.saveLoading = true;
    },

    UPDATE_SUCCESS(state) {
      state.saveLoading = false;
    },

    UPDATE_ERROR(state) {
      state.saveLoading = false;
    },
    ...vuexfireMutations,
    APPEND_TO_SECOND_BATCH(state, payload){
      // console.log('hey');
      
      state.secondBatch.push(payload)
    },
    SET_LAST_RECORD_SECOND_BATCH(state) {
      state.loadMoreLastRecord = state.secondBatch[state.secondBatch.length - 1]._doc
    },
    SET_LOADMORE_LAST(state) {
      // state.rows.pop()
      // console.log('state.rows',state.rows.pop());
      // console.log('state.rows length',state.firstBatch.length);
      // console.log('setLoadMoreLast =====>',state.firstBatch[state.firstBatch.length - 1].id);
      state.loadMoreLastRecord = state.firstBatch[state.firstBatch.length - 1]._doc
      state.recordAfterLast = state.firstBatch[state.firstBatch.length - 1]
    },
    SET_LOADFIRSTRECORD(state){
      state.loadFirstRecord = state.rows[0]._doc
    },
    RESET_CURSOR(state){
      state.loadMoreLastRecord = null
      state.loadFirstRecord = null
    },
    NEW_ROWS_LENGTH(state,payload){
      state.newRowsLength = payload
    }

  },

  actions: {
    doUnselectAll({ commit }) {
      commit('UNSELECT_ALL');
    },

    doMountTable({ commit }, table) {
      commit('TABLE_MOUNTED', table);
    },

    async doReset({ commit, dispatch }) {
      commit('RESETED');
      return dispatch('doFetch');
    },

   
    doChangePagination(
      { commit, getters, dispatch },
      pagination,
    ) {
      commit('PAGINATION_CHANGED', pagination);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },

    doChangePaginationPageSize(
      { commit, getters, dispatch },
      pageSize,
    ) {
      commit('PAGINATION_PAGE_SIZE_CHANGED', pageSize);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },

    doChangePaginationCurrentPage(
      { commit, getters, dispatch },
      currentPage,
    ) {
      commit(
        'PAGINATION_CURRENT_PAGE_CHANGED',
        currentPage,
      );
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },

    doChangeSort({ commit, getters, dispatch }, sorter) {
      commit('SORTER_CHANGED', sorter);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },
    async doUpdate({ commit },  { userId, id, values}){
      try {
        commit('UPDATE_STARTED');
        await NotificationService.update(userId, id, values);
        commit('UPDATE_SUCCESS');
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },

    doFetchNotifications:  firestoreAction(async ({bindFirestoreRef,getters,commit},payload) => {
      commit('FETCH_STARTED')
      const customSerializer = (doc) => {
        const data = doc.data()
        Object.defineProperty(data, '_doc', { value: doc })
        return data
      }
      const db = firebase.firestore()
      var notificationCollection = getters.loadMoreLastRecord ? 
      db.collection('user').doc(payload.userId).collection('notification')
      .limit(payload.limit || 10)
      .orderBy('createdAt','desc')
      .startAfter(getters.loadMoreLastRecord) :
      db.collection('user').doc(payload.userId).collection('notification')
      // .orderBy('createdAt','desc')
      .limit(payload.limit || 10)
      await bindFirestoreRef(
        'firstBatch',
        notificationCollection,
        { serialize: customSerializer }

      );
      commit('FETCH_SUCCESS')    
    }),
    // setDisableNext: firestoreAction(async ({getters, bindFirestoreRef}, payload) => {
    //   console.log('setDisableNext');
    //   const db = firebase.firestore()
    //   var notificationCollection = db.collection('job')
    //       .orderBy('name', 'asc')
    //       .limit(1)
    //       .startAfter(getters.loadMoreLastRecord) 
    //   // console.log('loadMoreLastJob',getters.loadMoreLastRecord);
    //   // console.log('loadMoreLastJob',getters.loadMoreLastRecord);    }),

    // }),
    setLoadMoreLast({commit}){
      commit('SET_LOADMORE_LAST')
    },
    resetCursor({commit}){
      commit('RESET_CURSOR')
    },
    setLoadFirstRecord({commit}){
      commit('SET_LOADFIRSTRECORD')
    },

    async doFetch({commit, getters},payload){
      console.log('doFetchMore#####');
      const notifications = await firebase.firestore().collection('user')
      .doc(`${payload.userId}`)
      .collection('notification')
      .limit(payload.limit || 10)
      .orderBy('createdAt','desc')
      .startAfter(getters.loadMoreLastRecord)
      .get()
      if(notifications.docs.length > 0){
        notifications.docs.forEach(doc => {
          let notification = doc.data()
          notification['_doc'] = doc
          // currentNotifications.push(notification)
          commit('APPEND_TO_SECOND_BATCH',notification)
        })
        commit('NEW_ROWS_LENGTH',notifications.docs.length)
        commit('SET_LAST_RECORD_SECOND_BATCH')
      }
      
    },
 
    // async doFetch(
    //   { commit, getters },
    //   { userId, filter, keepPagination } = {},
    // ) {
    //   try {
    //     commit('FETCH_STARTED', { filter, keepPagination });

    //     const response = await NotificationService.list(
    //       userId,
    //       filter,
    //       getters.orderBy,
    //       // getters.limit,
    //       // getters.offset,
    //     );

    //     commit('FETCH_SUCCESS', {
    //       rows: response.rows,
    //       count: response.count,
    //     });
    //   } catch (error) {
    //     Errors.handle(error);
    //     commit('FETCH_ERROR');
    //   }
    // },
    
    async doFetchInBackground(
      { commit, getters },
      { userId, filter, keepPagination } = {},
    ) {
      try {
        commit('FETCH_STARTED_IN_BACKGROUND', { filter, keepPagination });

        const response = await NotificationService.list(
          userId,
          filter,
          getters.orderBy,
          // getters.limit,
          // getters.offset,
        );

        commit('FETCH_SUCCESS', {
          rows: response.rows,
          count: response.count,
        });
      } catch (error) {
        Errors.handle(error);
        commit('FETCH_ERROR');
      }
    },

  },
};
