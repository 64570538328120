//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { OfferModel } from '@/modules/offer/offer-model';
import { OfferPermissions } from '@/modules/offer/offer-permissions';
import { EventBus } from '@/event-bus.js'
// import { getLanguageCode } from '@/i18n';

import i18n from '@/vueI18n'
import moment from 'moment';

import TableViewPage from '@/shared/components/table-view-page.vue';
import OfferFormModal from '@/modules/offer/components/offer-form-modal.vue';
import OfferViewModal from '@/modules/offer/components/offer-view-modal.vue';

const { fields } = OfferModel;

export default {
  name: 'app-offer-list-table',

  components: {
    [TableViewPage.name]: TableViewPage,
    [OfferFormModal.name]:OfferFormModal,
    [OfferViewModal.name]:OfferViewModal,
  },


  data() {
    return {
      filter: '',
      searchBy: 'title',
      modalVisible: false,
      columns: [
        {
          name: 'title',
          field: 'title',
          label: 'title',
          align: 'center',
          format: (val) => `${val}`,
          required: true,
          // sortable: true,
        },
        {
          name: 'description',
          field: 'description',
          label: 'description',
          align: 'center',
          format: (val) => `${val}`,
          required: true,
          // sortable: true,
        },
        {
          name: 'action',
          field: 'action',
          label: 'actions',
          align: 'center',
        },
      ],
      rowsOld: [],
      // language: localStorage.getItem('language'),
      // language: this.$router.params['en'],
      // language:getLanguageCode(),
      dialogVisible: false,
      dialogType: undefined,
      selectedRow: null,
      selectedId: null,
      pageSize: 5,
      nextPage: 2,
      pagination: {
        page: 1,
        rowsPerPage: 10,
        // sortBy: 'desc',
        // descending: false,
        // rowsNumber: xx if getting data from a server
      },
      page: 1,
      limit: 10,
    };
  },
  computed: {
    isRTL(){
        return i18n.locale == 'ar'
    },
    ...mapGetters({
      rows: 'offer/list/rows',
      rowsFromRoute: 'offer/list/rowsFromRoute',
      recordAfterLast: 'offer/list/recordAfterLast',
      recordBeforeFirst: 'offer/list/recordBeforeFirst',
      count: 'offer/list/count',
      // pagination: 'offer/list/pagination',
      // paginationLayout: 'layout/paginationLayout',
      loading: 'offer/list/loading',
      saveLoading: 'offer/form/saveLoading',

      backgroundLoading: 'offer/list/backgroundLoading',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      destroyLoading: 'offer/destroy/loading',
      is_screen_xs: 'layout/is_screen_xs',
    }),
    loadingAndUpdate(){
      if(this.loading || this.saveLoading || this.destroyLoading){
        return true
      }
      return false
    },
    language(){
      return i18n.locale
    },
    hasPermissionToEdit() {
      return new OfferPermissions(this.currentUser).edit;
    },
    hasPermissionToDestroy() {
      return new OfferPermissions(this.currentUser).destroy;
    },
    fields() {
      return fields;
    },
    pagesNumber () {
      return Math.ceil(this.rows.length / this.pagination.rowsPerPage)
    },
    rowsToBeDisplayed(){    
      if(this.rows.length > 0 || !this.loading){
        return this.rows
      }       
      return this.rowsOld
    },
  },
  watch:{
    rowsFromRoute(change){
      // console.log('change',change);
      const page  = this.page
      const limit = this.limit
      // console.log('limit',this.limit);
      // console.log('page',this.page);
      const rowsFromRouteToBeDisplayed = change.slice(page * limit - limit , page * limit + 1)
      // console.log('rowsFromRouteToBeDisplayed',change.slice(page * limit - limit , page * limit + 1));
      this.setRows(rowsFromRouteToBeDisplayed)
    },
    rows(newval){
       if(newval.length > 0){
        this.setLoadFirstRecord()
        this.setLoadMoreLast()
        let language = this.isRTL ? 'ar' : 'en'
        this.setDisablePrevious({searchBy: 'title',filter: this.filter,language})
        this.setDisableNext({searchBy: 'title',filter: this.filter,language})
       
      }
    },
    // rowsToBeDisplayed(newval){
    //   
    // },
    // recordBeforeFirst(newval){
    // }
  },
  methods: {
    ...mapActions({
      doChangeSort: 'offer/list/doChangeSort',
      doChangePaginationCurrentPage: 'offer/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'offer/list/doChangePaginationPageSize',
      doMountTable: 'offer/list/doMountTable',
      doDestroy: 'offer/destroy/doDestroy',
      // doFetchInBackground: 'offer/list/doFetchInBackground',
      doFetch: 'offer/list/doFetch',
      doFetchFromRoute: 'offer/list/doFetchFromRoute',
      getPreviousRecordsBatch:'offer/list/getPreviousRecordsBatch',
      setDisablePrevious:'offer/list/setDisablePrevious',
      setDisableNext:'offer/list/setDisableNext',
      doGetRecordsCount: 'offer/list/doGetRecordsCount',
      setLoadFirstRecord: 'offer/list/setLoadFirstRecord',
      setLoadMoreLast: 'offer/list/setLoadMoreLast',
      resetCursor: 'offer/list/resetCursor',
      setRows: 'offer/list/setRows'

    }),
    isNumeric(value) {
      return /^\d+$/.test(value);
    },
    async loadMore(){
      let language = this.isRTL ? 'ar' : 'en'
      await this.doFetch({limit: 10,searchBy: 'title',filter: this.filter, language})
      this.page = this.page + 1
      if(this.filter != ''){
        this.$router.push({
          query: {
            page: this.page,
            filter: this.filter
          },
          params:{
            searchBy: this.searchBy
          }
        })
      }
      else{
        this.$router.push({
          query: {
            page: this.page,            
          },
          params:{
            searchBy: this.searchBy
          }
        })
      }
      this.rowsOld = this.rows
    },
    async goToPreviousPage(){
      let language = this.isRTL ? 'ar' : 'en'
      await this.getPreviousRecordsBatch({limit: 10,searchBy: 'title',filter: this.filter, language})
      this.page = this.page - 1
      if(this.filter != ''){
      this.$router.push({
        query: {
          page: this.page,
          filter: this.filter
        },
        params:{
          searchBy: this.searchBy
        }
      })
      }
      else{
        this.$router.push({
        query: {
          page: this.page,
        },
        params:{
          searchBy: this.searchBy
        }
      })
      }
      this.rowsOld = this.rows
    },
    async searchByFilter(){
      this.resetCursor()
      let language = this.isRTL ? 'ar' : 'en'
      if(this.filter != ''){
      this.$router.push({
        query: {
          page: this.page,
          filter: this.filter
        },
        params:{
          searchBy: this.searchBy
        }
      })
      }
      else{
         this.$router.push({
        query: {
          page: this.page,           
        },
        params:{
          searchBy: this.searchBy
        }
      })
      } 
      await this.doFetch({limit: 10, searchBy: this.searchBy, filter: this.filter, language})
      this.rowsOld = this.rows
    },
    async removeSearch(){
      this.resetCursor()
      // this.searchBy = 'title'
      this.filter = ''
      let language = this.isRTL ? 'ar' : 'en'
      this.filter = ''
      this.page = 1
      this.$router.push({
        query: {
          page: this.page,
          // filter: ''
        },
        params:{
          searchBy: this.searchBy
        }
      })
      await this.doFetch({limit: 10,searchBy: this.searchBy, filter: this.filter, language})
    },
    i18n(key, args) {
      return this.$t(key, args);
    },
    presenter(row, fieldName) {
      const val = OfferModel.presenter(row, fieldName);
      return val ? val : 'ـــــــ';
    },
    presenterEnum(row, fieldName) {
      const options = this.fields[fieldName].options;
      const val = options.filter(el => el.value == row[fieldName])
      return val.length ? val[0].label : 'ـــــــ';
    },
    presenterMap(row, fieldName) {
      const val = OfferModel.presenter(row, fieldName);
      return val[this.language] ? val[this.language] : null
    },
    presenterDate(row, fieldName) {
      const val = OfferModel.presenter(row, fieldName)
      return val ? moment(val).locale(this.language).format("D MMM, YYYY") : 'ـــــــ'; 
    },
    presenterDay(row, fieldName) {
      return moment(OfferModel.presenter(row, fieldName)).locale(this.language).format("dddd");
    },
    presenterTime(row, fieldName) {
      let now = moment().format('YYYY/MM/DD');
      let time = OfferModel.presenter(row, fieldName)
      let date =  `${now} ${time}`;
      return moment(date).locale(this.language).format("hh:mm a"); 
    },

    onModalOpen(row, operation) {
      this.selectedRow = row;
      this.selectedId = row.id;
      switch (operation) {
        case 'delete':
          this.dialogType = 'delete'
          break;
        case 'edit':
          this.dialogType = 'edit'
          break;
        case 'view':
          this.dialogType = 'view'
          break;  
        default:
          this.dialogType = undefined
          break;
      }
      this.modalVisible = true
      this.dialogVisible = true
    },
    onModalClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.modalVisible = false;
        this.dialogType = undefined
      }, 500);
    },
    onModalSuccess() {
      this.onModalClose();
    },
    closeDialog(){
      this.dialogVisible = false;
      this.dialogType = ''
    },
    openDialog(row, operation){
      this.selectedRow = row;
      this.selectedId = row.id;
      switch (operation) {
        case 'delete':
          this.dialogType = 'delete'
          break;
        case 'edit':
          this.dialogVisible = true
          this.modalVisible = true
          break;
        default:
          break;
      }
      this.dialogVisible = true
    },

    async doDestroyWithConfirm(id) {
      try {
        await this.doDestroy(id);
        this.closeDialog();
      } catch (error) {
        // no
      }
    },

     async navigateBackwardFroward() {
      if(this.$route.name == "offer") {
        this.page = this.$route.query.page && this.$route.query.page > 0 && this.isNumeric(this.$route.query.page) ? parseInt(this.$route.query.page) : 1
        this.searchBy = 'title'        
        this.filter = this.$route.query.filter ? this.$route.query.filter : ''
        let language = this.isRTL ? 'ar' : 'en'


        // this.setRows([]);
        this.resetCursor();
        if (this.$route.params.searchBy && this.$route.params.searchBy == 'title') {
            if(!this.$route.query.page || !this.isNumeric(this.$route.query.page) && this.$route.query.page <= 0){
              if(this.$route.query.filter){
                this.$router.push({
                  query: {
                    page: 1,
                    filter: this.$route.query.filter              
                  }
                })
              }
              else{
                this.$router.push({
                  query: {
                    page: 1,              
                  }
                })
              }
            }
          await this.doFetchFromRoute({page: this.page, limit: 10, searchBy: 'title', filter: this.filter, language})
        } else {
            this.$router.push({
              params: {
                searchBy: 'title'
              },
              query: {
                page: 1,
              }
            })
            this.page = 1
            await this.doFetch({
              limit: 10, searchBy: 'title', filter: '', language
            });
        }
        this.rowsOld = this.rows;
      } 
    },
  },

  mounted() {
    var that = this;
    window.onpopstate = async function () {
      that.navigateBackwardFroward();
    };
  },


  async created(){
    //#region [ ======== to set the searchBy, filter, page variables ======== ]
    this.page = this.$route.query.page && this.isNumeric(this.$route.query.page) && this.$route.query.page > 0 ? parseInt(this.$route.query.page) : 1
    this.searchBy = 'title'
    this.filter = this.$route.query.filter ? this.$route.query.filter : ''
    //#endregion


    let language = this.isRTL ? 'ar' : 'en'

    if(this.$route.params.searchBy && this.$route.params.searchBy == 'title'){
          if(!this.$route.query.page || !this.isNumeric(this.$route.query.page) || this.$route.query.page <= 0){
            if(this.$route.query.filter){
              this.$router.push({
                query: {
                  page: 1,
                  filter: this.$route.query.filter              
                }
              })
            }
            else{
              this.$router.push({
                query: {
                  page: 1,              
                }
              })
            }
          }     
          await this.doFetchFromRoute({page: this.page, limit: 10, searchBy: 'title', filter: this.filter, language})
      }
      else{
        
        this.$router.push({
          params: {
            searchBy: 'title'
          },
          query: {
            page: 1,
            // filter: null
          }
        })
        await this.doFetch({limit: 10,searchBy: 'title',filter:'', language})
      }
      this.rowsOld = this.rows;
      EventBus.$on('resetPage', () =>{
        this.page = 1
      })
  }
};
