import importerStore from '@/shared/importer/importer-store';
import { JobService } from '@/modules/job/job-service';
import jobImporterFields from '@/modules/job/job-importer-fields';
import { i18n } from '@/i18n';

export default importerStore(
  JobService.import,
  jobImporterFields,
  i18n('entities.job.importer.fileName'),
  i18n('entities.job.importer.hint'),
);
