export default {
  namespaced: true,

  state: {
    tab: 'home',
    menuCollapsed: false,
    isMobile: false,
    is_screen_xs: false,
    is_screen_sm: false,
    is_screen_md: false,
    is_screen_lg: false,
    is_labtop: false,

    isScreen_md: false,
    headerBg:'',
    navbar:'',
    footer:false,
    accountType:'',
    currentUserListener: null
  },

  getters: {
    tab: (state) => state.tab,
    menuCollapsed: (state) => state.menuCollapsed,
    isMobile: (state) => !!state.isMobile,
    is_screen_xs: (state) => !!state.is_screen_xs,
    is_screen_sm: (state) => !!state.is_screen_sm,
    is_screen_md: (state) => !!state.is_screen_md,
    is_screen_lg: (state) => !!state.is_screen_lg,
    is_labtop: (state) => !!state.is_labtop,

    isScreen_md: (state) => !!state.isScreen_md,

    paginationLayout: (state) =>
      state.isMobile
        ? 'total, prev, pager, next'
        : 'total, sizes, prev, pager, next',
    labelPosition: (state) =>
      state.isMobile ? 'top' : undefined,
    labelWidthForm: (state) =>
      state.isMobile ? undefined : '180px',
    labelWidthFilter: (state) =>
      state.isMobile ? undefined : '120px',
    headerBg:(state) => state.headerBg,
    navbar:(state) => state.navbar,
    footer:(state) => state.footer,
    accountType:(state) => state.accountType,
    currentUserListener: (state) => state.currentUserListener
  },

  mutations: {
    COLLAPSE_MENU(state) {
      state.menuCollapsed = true;
    },

    TOGGLE_MENU(state) {
      state.menuCollapsed = !state.menuCollapsed;
    },

    RESIZE(state, payload) {
      state.isScreen_md = payload.width < 1024;
      
      state.isMobile = payload.width < 576;
      state.is_screen_xs = payload.width < 600;
      state.is_screen_sm = payload.width >= 600 && payload.width < 1024;
      state.is_screen_md = payload.width >= 1024 && payload.width < 1440;
      state.is_screen_lg = payload.width >= 1440 && payload.width < 1920;
      state.is_labtop = payload.width <= 1400 && payload.height <= 800;
    },
    TOGGLE_TAP(state, payload) {
      state.tab = payload
    },
    SET_HEADERBG(state,payload) {
      state.headerBg = payload
    },
    SET_NAVBAR(state,payload) {
      state.navbar = payload
    },
    SET_FOOTER(state,payload) {
      state.footer = payload
    },
    SET_ACCOUNTTYPE(state,payload) {
      state.accountType = payload
    },
    SET_CURRENT_USER_LISTENER(state, payload){
      state.currentUserListener = payload
    }
  },

  actions: {
    resize({ commit }, payload) {
      commit('RESIZE', payload);
    },

    toggleMenu({ commit }) {
      commit('TOGGLE_MENU');
    },

    collapseMenu({ commit }) {
      commit('COLLAPSE_MENU');
    },

    navigation({ commit }, payload) {
      commit('TOGGLE_TAP', payload);
    },
    
    setHeaderBg({ commit },payload) {
      commit('SET_HEADERBG',payload)
    },

    setNavbar({ commit },payload) {
      commit('SET_NAVBAR',payload)
    },

    setFooter({commit},payload){
      commit('SET_FOOTER',payload)
    },
    setAccountType({commit},payload){
      commit('SET_ACCOUNTTYPE',payload)
    },
    setCurrentUserListener({ commit }, payload){
      commit('SET_CURRENT_USER_LISTENER',payload)
    }
  },
};
