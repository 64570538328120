import { render, staticRenderFns } from "./card-view-page.vue?vue&type=template&id=67788100&scoped=true&"
import script from "./card-view-page.vue?vue&type=script&lang=js&"
export * from "./card-view-page.vue?vue&type=script&lang=js&"
import style0 from "./card-view-page.vue?vue&type=style&index=0&id=67788100&lang=scss&scoped=true&"
import style1 from "./card-view-page.vue?vue&type=style&index=1&id=67788100&lang=scss&scoped=true&"
import style2 from "./card-view-page.vue?vue&type=style&index=2&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67788100",
  null
  
)

export default component.exports
import {QCard,QItem,QItemSection,QAvatar,QItemLabel,QIcon,QTooltip,QSeparator,QCardSection,QCardActions,QBtn} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCard,QItem,QItemSection,QAvatar,QItemLabel,QIcon,QTooltip,QSeparator,QCardSection,QCardActions,QBtn})
