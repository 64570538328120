const ar = {
  common: {
    or: 'or',
    reset: 'Reset',
    save: 'Save',
    search: 'Search',
    edit: 'Edit',
    remove: 'Remove',
    new: 'New',
    export: 'Export to Excel',
    noDataToExport: 'No data to export',
    import: 'Import',
    discard: 'Discard',
    yes: 'Yes',
    no: 'No',
    pause: 'Pause',
    areYouSure: 'Are you sure?',
    view: 'View',
    destroy: 'Delete',
    mustSelectARow: 'Must select a row',
    confirm: 'Confirm',
    publish:'اضف',
    name:'الاسم',
    address:'العنوان',
    description:'الوصف',
    phoneNumber:'رقم الهاتف',
    emailAddress:'البريد الاكترونى',
    spaceArea:'المساحة',
    status:'الحالة',
    postStatus:'حالة المنشور',
    action:'تأثير',
    views:'مشاهدات',
    createdBy:'نشر عن طريق',
    createdAt:'نشر فى',
    uploadDrugFile:'حمل ملف الادوية',
    searchInDrugs:'ابحث فى الادوية',
    id:'الرقم',
    viewDetails:'مزيد من التفاصيل',
    post:'منشور',
    profile:'الملف الشخصى',
    logout:'الخروج',
    deleteThisUser:'امسح هذا المستخدم؟',
    yesDelete:'نعم,امسح',
    noThanks:'لا,شكرا',
    disabled:'غير متاح',
    enabled:'متاح',
    saveChanges:'احفظ التغييرات',
    deleteThisAdmin:'امسح هذا المدير',
    uploadPicture:'حمل الصورة',
    role:'الدور',
    cancel:'الغاء العملية',

    profilePicture:'الصورة الشخصية',
    time:'التوقيت',
    signature:'توقيع',
    undo:'تراجع',
    enterThePinCode:'من فضلك أدخل الرقم السري',
    pinCodePlaceHolder:'أكتب الرقم السري هنا' ,
    close:'إغلاق',
    attachFile: 'إرفاق ملف',
    approve: 'اعتماد',
    ignore: 'تجاهل',
    viewMore: 'عرض المزيد',
    actions: 'العمليات',
    apply: 'تطبيق',
    submit: 'إرسال',
    delete: 'حذف',
    destroyAll: 'حذف الكل',
    continue: 'التالي',
    next: 'التالي',
    previous: 'السابق',
    back: 'رجوع',
    comingSoon: ' قريبـــــا',
    underConstruction: 'هذا الموقع لا يزال قيد الإنشاء',
    today: 'اليوم',
    earlier: 'سابقاً',
    day: 'اليوم',
    date: 'التاريخ',
    print: 'طباعة',
    milady: 'ميلادي',
    hijri: 'هجري',
    add: 'أضف',
    send: 'أرسال',
    accept: 'قبول',
    reject: 'رفض',
    ex: 'مثال',
    rePublish: 'إعادة النشر',
    mins: 'دقيقة',
    loading: ' جاري التحميل... ',
    searching: ' جاري البحث... ',
    to: 'إلي',
    congratulations: 'مبــروك!',
    attachedFile: 'الملف المرفق',
    addQualification: 'إضافة مؤهل',
    addCourse: 'إضافة دورة',
    other: 'آخرى',
    chooseDate: 'اختر التاريخ',
    employee: ' موظف ',
    employees: ' موظفين ',
    addNote: 'اضف ملاحظة',
    successfully: 'بنجاح',
    noteAdded: 'تمت إضافة ملاحظتك',
    submitForm: 'إرسال نموذج',
    submitRequest: 'إرسال طلب',
    history: 'السجل',
    create: 'إنشاء',
    update: 'تحديث',
    from: 'من',
    subject: 'الموضوع',
    reply: 'رد',
    ticketComplete: 'اكتملت التذكرة',
    showDetails: 'عرض التفاصيل',
    updatedAt: 'تم التحديث في',
    updatedBy: 'تم التحديث بواسطة',
    noResults: 'لا توجد نتائج',
    stop: 'توقف',
    readMore: 'قراءة المزيد',
    readLess: 'قراءة أقل',
    forward: 'إعادة توجيه',
    done: 'تم',
    features: 'المميزات',
    deleteThisItem: 'هل تريد حذف هذا العنصر؟',
    uploadPhoto: 'حمل الصورة',
    page: 'صفحة',
    preview: 'معاينة',
    total: 'الإجمالي',
    exportationItems: 'العناصر المصدرة',
    items: 'العناصر',
    createMainData: 'إنشاء البيانات الأساسية',
    addDetails: 'إضافة التفاصيل',
    addParts: 'إضافة أجزاء المنتج',
    partsSelected: 'الأجزاء التي تم اختيارها',
    chooseYourParts: 'إختر القطع',
    year: 'سنة',
    qrCode: 'رمز الاستجابة السريعة',
    url: 'Url',
    finish: 'إنهاء',
    error: 'خطأ',
    tryAgain: 'حاول مرة أخري',
    nextItem: 'العنصر التالي',
    startExport: 'ابدأ التصدير',
    scanItems: 'فحص العنصر',
    scan: 'فحص',
    waitingSellerCheck: 'في انتظار فحص البائع',
    distributorName: ' اسم الموزع ',
    bills:'الفواتير',
    uploadNewPicture: 'تحميل صورة جديدة',
    barcode: 'الباركود',
    printBarcode: 'طباعة الباركود',
    chooseOperation: 'اختر العملية',
    generatePin: 'إنشاء رمز PIN',
    addDistributor: 'إضافة موزع',
    updateBrowser: 'يرجى تحديث المتصفح الخاص بك إلى أحدث إصدار.',
    notifyUpdateBrowser: 'متصفح الويب الخاص بك ({0}) قديم. قم بتحديث المستعرض الخاص بك لمزيد من الأمان والسرعة وأفضل تجربة على هذا الموقع.',
    generate: 'إنشاء رموز',
    english: 'إنجليزي',
    arabic: 'عربي',
  },

  app: {
    title: 'Drug Deal',
  },

  menu: {
    contact:"جهات الاتصال",
    offer: 'العروض',
    home:"الصفحة الرئيسية",
    job: "فرص العمل",
    prescription:"الوصفات الطبية",
    property:"الخصائص",
    user:"المستخدمون",
    admin:"المشرفون",
    region:"المناطق",
    city:"المدن",
    setting:"الاعدادات"

  },

  drugDeal: {
    splash:{
      theBest:'افضل',
      medicalReference:'مرجع طبى',
      youNeed:'تحتاج اليه فى',
      place:'مكان واحد',
      getStarted:'ابدأ'
    },
    auth:{
      login: 'ادخل',
      createNewAccount: 'إنشاء حساب جديد',
      orCreate: 'افتح حساب جديد بالبريد الالكترونى',
      orLogin: 'ادخل حساب جديد بالبريد الالكترونى',
      sign:'  افتح',
      up:'حساب',
      log:' تسجيل',
      in:'الدخول ',
      useGoogle:'استخدم حساب جوجل',
      useFacebook:'استخدم حساب فيس بوك',
      forgetPassword:'انسيت كلمة المرور؟',
      join:'سجل',
      emailPlaceholder:'ادخل البريد الاكترونى',
      passwordPlaceholder:'ادخل كلمة المرور'
    },
    layout:{
      dashboard:'لوحة التحكم',
      database:'قاعدة البيانات',
      users:'المستخدمين',
      admins:'المدراء',
      drugIndex:'فهرس الادوية',
      prescriptions:'الوصفات الطبية',
      jobOpportunities:'فرص العمل',
      properties:'الممتلكات',
      regions:'المناطق',
      cities:'المدن',
      id:'الرقم',
      city:'المدينة',
      region:'المنطقة',
      settings:'الإعدادات',
      jan:'يناير',
      feb:'فبراير',
      mar:'مارس',
      apr:'ابريل',
      may:'مايو',
      jun:'يونيو',
      jul:'يوليو',
      aug:'اغسطس',
      sep:'سبتمبر',
      oct:'اكتوبر',
      nov:'نوفمبر',
      dec:'ديسمبر'
    },
    city:{
      newCity:'اضف مدينة جديدة',
      newRegion:'اضف منطقة جديدة',
      youMustSelectTheCity:'يجب ان تختار المدينة',
      selectYourCity:'اختر مدينتك',
      enterRegionNameAr:'ادخل اسم المنطقة بالعربية',
      enterRegionNameEn:'ادخل اسم المنطقة بالانجليزية',
      enterCityNameAr:'ادخل اسم المدينة بالعربية',
      enterCityNameEn:'ادخل اسم المدينة بالانجليزية'
    },
    job:{
      aboutCompany:'عن الشركة',
      workplace:'مكان العمل',
      position:'الوظيفة',
      salary:'المرتب',
      jobInfo:'معلومات عن الوظيفة'
    },
    property:{
      price:'السعر',
      dailyIncome:'العائد اليومى',
      category:'النوع',
      currentStatus:'الحالة',
      le:'جنيه مصرى',
      opened:'مفتوح',
      closed:'مغلق'
    },
    prescription:{
      title:'العنوان',
    },
    drugIndex:{
      company:'الشركة',
      drugName:'اسم الدواء',
      genericName:'الاسم العام',
      oldPrice:'السعر السابق',
      price:'السعر',
      pharmacologicalUse:'الاستخدام الدوائي',
      route:'المسار',
      srde:'المادة الفعالة',
      le:'جنيه مصرى',
      newPrice:'السعر الجديد',
      category: 'التصنيف',
      manifacturingCompany: 'الشركة المصنعة',
      additionalInfo:'معلومات اضافية',
      noFilesHere:'لا توجد ملفات هنا',
      pleaseWaitYourFileIsUploading:'الرجاء الانتظار يتم تحميل الملف الخاص بك'
    },
    users:{
      firstName:'الاسم الاول',
      lastName:'اللقب',
      fullName:'اللقب',

    },
    common: {
      drugDeal: 'Drug Deal',
      drug: 'DRUG',
      deal: 'DEAL',
      about: 'عن',
      home: 'الرئيسية',
      aboutUs: 'عن التطبيق',
      services:'خدمات',
      login:'تسجيل الدخول',
      features: 'مميزات التطبيق',
      contactUs: 'تواصل معنا',
      privacyPolicy: 'سياسة الخصوصية',
      termsAndConditions: 'الشروط والأحكام',
      
    },

    header: {
      welcomeTo: 'مرحبا بك في',
      message: 'أفضل مرجع طبي و دوائي في مكان واحد.',
      downloadOnTheAppStore: 'تحميل على متجر التطبيقات',
      getItOn: 'احصل عليه',
      googlePlay: 'تطبيقات جوجل'
    },
    about: {
      message: 'العديد من المزايا المقنعة لاستخدام تطبيقات الهاتف المحمول Drug Deal',
      point1: 'تحديث فرص العمل بانتظام.',
      point2: 'المساعدة الرقمية للموظفين الطبيين والعملاء.',
      timeline1: 'مريح وسهل الاستخدام.',
      timeline2: 'توفير معلومات طبية دقيقة و مفصلة.',
      timeline3: 'توفير فرص عمل محدثة بانتظام.',
      timeline4: 'المساعدة الرقمية للتسهيل علي الأطقم الطبية والعملاء.',
    },
    features: {
      mobileApp: 'تطبيق جوال',
      message: 'Stay on top of the best deals',
      searchFor: 'البحث عن الادوية',
      'searchFor-description': 'تصفح دليل الأدوية و الأدوية البديلة و المشابهة',
      drugInteraction: 'التعارضات الدوائية',
      'drugInteraction-description': 'اكتب اسم الدواء و اعرف اكثر عن تفاعلاته و الأدوية المعارضه له و ارشادات عدم استخدامه',
      prescriptions: 'الوصفات الطبية',
      'prescriptions-description': 'تصفح الكثير من الوصفات الطبية و العلاجية',
      jobOpportunities: 'فرص العمل',
      'jobOpportunities-description': 'ابحث عن الكثير من فرص عمل والوظائف المحدثة بشكل دوري',
      offers: 'العروض',
      'offers-description': 'احصل على عروض يومية على جميع المنتجات الطبية',
      sellAndBuyandrent: 'الخدمات التجارية و العقارية',
      'sellAndBuyandrent-description': 'يمكنك بيع، شراءأو تأجير صيدلية بسهولة. عن طريق التطبيق مع إعطاءوصف كامل للمكان ',
      keepMessage: 'قم باستخدام التطبيق و استفيد بأحدث العروض والعروض الترويجيه المستمرة على فئات التطبيق المختلفه.',
    },
    footer: {
      by: 'كل',
      madeWith: 'قدمت مع',
      // copyRight : `جميع الحقوق محفوظة لـ  Drug Deal `,
      // c: '© 2020',
      copyRight: ` جميع الحقوق محفوظة لـ 2020 © Drug Deal`
    },
  },

  entities: {
    offer: {
      name: 'offer',
      label: 'Offers',
      menu: 'Offers',
      exporterFileName: 'offer_export',
      list: {
        menu: 'Offers',
        title: 'Offers',
      },
      create: {
        success: 'Offer saved successfully',
      },
      update: {
        success: 'Offer saved successfully',
      },
      destroy: {
        success: 'Offer deleted successfully',
      },
      destroyAll: {
        success: 'Offer(s) deleted successfully',
      },
      edit: {
        title: 'Edit Offer',
      },
      fields: {
        id: 'Id',
        'title': 'Title',
        'description': 'Description',
        "price": "السعر",
        "percentage": "النسبة المئوية",
        "oldPrice": "السعر القديم",

        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {
        // 'gender': {
        //   'male': 'Male',
        //   'female': 'Female',
        // },
      },
      new: {
        title: 'New Offer',
      },
      view: {
        title: 'View Offer',
      },
      step: {
        one: 'أدخل البيانات باللغة الإنجليزية',
        two: 'أدخل البيانات باللغة العربية',
        three: "ادخل الاسعار",
        four: '',
        five: '',
      },
      importer: {
        title: 'Import Offers',
        fileName: 'offer_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    product: {
      name: 'product',
      label: 'Products',
      menu: 'Products',
      exporterFileName: 'product_export',
      list: {
        menu: 'Products',
        title: 'Products',
      },
      create: {
        success: 'Product saved successfully',
      },
      update: {
        success: 'Product saved successfully',
      },
      destroy: {
        success: 'Product deleted successfully',
      },
      destroyAll: {
        success: 'Product(s) deleted successfully',
      },
      edit: {
        title: 'Edit Product',
      },
      fields: {
        id: 'Id',
        'name': 'Name',
        'description': 'Description',
        'unitPriceRange': 'Unit Price',
        'unitPrice': 'Unit Price',
        'photos': 'Photos',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {

      },
      new: {
        title: 'New Product',
      },
      view: {
        title: 'View Product',
      },
      importer: {
        title: 'Import Products',
        fileName: 'product_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },

    order: {
      name: 'order',
      label: 'Orders',
      menu: 'Orders',
      exporterFileName: 'order_export',
      list: {
        menu: 'Orders',
        title: 'Orders',
      },
      create: {
        success: 'Order saved successfully',
      },
      update: {
        success: 'Order saved successfully',
      },
      destroy: {
        success: 'Order deleted successfully',
      },
      destroyAll: {
        success: 'Order(s) deleted successfully',
      },
      edit: {
        title: 'Edit Order',
      },
      fields: {
        id: 'Id',
        'offer': 'Offer',
        'products': 'Products',
        'employee': 'Employee',
        'delivered': 'Delivered',
        'attachments': 'Attachments',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
      },
      enumerators: {

      },
      new: {
        title: 'New Order',
      },
      view: {
        title: 'View Order',
      },
      importer: {
        title: 'Import Orders',
        fileName: 'order_import_template',
        hint: 'Files/Images columns must be the URLs of the files separated by space.',
      },
    },
  },

  auth: {
    error: {
      emailRequired: 'البريد الالكتروني مطلوب',
      passwordRequired: 'كلمة المرور مطلوبة',
      confirmRequired: 'تأكيد كلمة المرور مطلوب',
      confirmNotMatched: 'تأكيد كلمة المرور غير متطابقة',
      phoneRequired: 'رقم الهاتف مطلوب',
      codeRequired: 'Verification Code is required',
      idNumberRequired: 'Id Number is required',
      PhoneMustBe_11_Digits: 'The Phone number must be 9 digits',
      firstNameRequired: 'First Name is required',
      lastNameRequired: 'Last Name is required',
      idNumberShouldOnlyBeNumbers: 'The ID number should only be numbers',
      verificationCodeShouldOnlyBeNumbers: 'The Verification Code should only be numbers',
    },
    splash: {
      title: '',
      subTitle: '',
      hint: '',
      letsGetStarted: 'لنبدأ',
    },
    profile: {
      title: 'الملف الشخصي',
      edit: 'تعديل الملف الشخصي',
      view: 'عرض بيانات الملف الشخصي',
      success: 'تم تحديث الملف الشخصي بنجاح',
    },
    fields: {
      email: 'عنوان البريد الإلكتروني',
      confirm: 'تأكيد كلمة المرور',
      phoneNumber: 'رقم الهاتف',
      password: 'كلمه المرور',
    },
    placeholder: {
      email: 'أدخل عنوان البريد الإلكتروني هنا',
      confirm: 'أدخل تأكيد كلمة المرور هنا',
      phoneNumber: 'رقم الهاتف',
      password: 'أدخل كلمة المرور هنا',
    },
    welcomeTo: 'مرحبا بك في ',
    puricomEgypt: 'بيوريكم ايجيبت ',
    message: 'نظام احترافي لمعالجة المياه',

    verifyAccount: 'تحقق من حسابك',
    createdSuccessfully: 'تم إنشاء حسابك الجديد بنجاح',
    createdNewAccount: 'إنشاء حسابك الجديد',
    registrationSteps: 'Registration Steps',
    DoNotHaveAccount: 'ليس لديك حساب؟ ',
    createAnAccount: 'إنشاء حساب',
    rememberMe: 'تذكرني',
    forgotPassword: 'نسيت كلمة المرور؟',
    forgotPasswordTitle: 'هل نسيت كلمة المرور',
    signin: 'تسجيل الدخول',
    signup: 'إنشاء حساب',
    signout: 'تسجيل الخروج',
    login: 'تسجيل الدخول',
    

    alreadyHaveAnAccount: ' لديك حساب بالفعل؟ ',
    signinWithAnotherAccount: 'تسجيل الدخول باستخدام حساب آخر',
    signUp: {
      sign: 'إنشاء ',
      up: 'حساب',
      message: `يرجى إدخال عنوان بريدك الإلكتروني وكلمة المرور لإنشاء حساب جديد`,
    },
    logIn: {
      log: 'تسجيل ',
      in: 'دخول',
      message: `يرجى إدخال عنوان بريدك الإلكتروني وكلمة المرور لتسجيل الدخول إلى حسابك`,
    },
    emailUnverified: {
      title: 'تأكيد عنوان البريد الإلكتروني',
      message: `يرجى التأكد من صحة البريد الإلكتروني الخاص بك على <strong style="color: #00B2E3;">{0}</strong> للمتابعة.`,
      submit: `إعادة إرسال البريد الإلكتروني للتحقق`,
      verify: 'التحقق من عنوان البريد الإلكتروني',
      confirm: 'تأكيد ',
      emailAddress: 'عنوان البريد الإلكتروني',
    },
    forgetPassword: {
      title: 'نسيت كلمة المرور؟',
      message: `أدخل عنوان بريدك الإلكتروني الذي تستخدمه لحسابك، وسوف نرسل لك رابط إعادة تعيين كلمة المرور`,
      submit: `Request Reset link`,
      placeholder: 'أدخل عنوان بريدك الالكتروني هنا',
      forget: 'إعادة تعيين ',
      password: 'كلمة المرور',
      resendLink: 'إعادة إرسال الرابط',
    },
    emptyPermissions: {
      // title: 'Pending request',
      title: 'إذن الحساب',
      account: 'إذن ',
      permission: 'حساب',
      message: `ليس لديك صلاحيات حتى الآن. انتظر حتى يمنحك المسؤول صلاحية`,
    },
    passwordResetEmail: {
      message: 'إرسال بريد إلكتروني لإعادة تعيين كلمة المرور',
      error: `لم يتم التعرف على البريد الإلكتروني`,
    },
    passwordReset: {
      message: 'إعادة تعيين كلمة المرور',
    },
    emailAddressVerificationEmail: {
      error: `لم يتم التعرف على البريد الإلكتروني`,
    },
    verificationEmailSuccess: `Verification email sent successfully`,
    passwordResetEmailSuccess: `Password reset email sent successfully`,
    passwordResetSuccess: `Password changed successfully`,
    verifyEmail: {
      success: 'Email successfully verified',
      message: 'Just a moment, your email is being verified...',
    },
  },

  roles: {
    owner: {
      label: 'Owner',
      description: 'Full access to all resources',
    },
    editor: {
      label: 'Editor',
      description: 'Edit access to all resources',
    },
    viewer: {
      label: 'Viewer',
      description: 'View access to all resources',
    },
    auditLogViewer: {
      label: 'Audit Log Viewer',
      description: 'Access to view audit logs',
    },
    iamSecurityReviewer: {
      label: 'Security Reviewer',
      description: `Full access to manage users roles`,
    },
    entityEditor: {
      label: 'Entity Editor',
      description: 'Edit access to all entities',
    },
    entityViewer: {
      label: 'Entity Viewer',
      description: 'View access to all entities',
    },
    offerEditor: {
      label: 'Offer Editor',
      description: 'Edit access to Offers',
    },
    offerViewer: {
      label: 'Offer Viewer',
      description: 'View access to Offers',
    },
    productEditor: {
      label: 'Product Editor',
      description: 'Edit access to Products',
    },
    productViewer: {
      label: 'Product Viewer',
      description: 'View access to Products',
    },
    orderEditor: {
      label: 'Order Editor',
      description: 'Edit access to Orders',
    },
    orderViewer: {
      label: 'Order Viewer',
      description: 'View access to Orders',
    },
  },

  iam: {
    title: 'Identity and Access Management',
    menu: 'IAM',
    disable: 'Disable',
    disabled: 'Disabled',
    enabled: 'Enabled',
    enable: 'Enable',
    doEnableSuccess: 'User enabled successfully',
    doDisableSuccess: 'User disabled successfully',
    doDisableAllSuccess: 'User(s) disabled successfully',
    doEnableAllSuccess: 'User(s) enabled successfully',
    doAddSuccess: 'User(s) saved successfully',
    doUpdateSuccess: 'User saved successfully',
    viewBy: 'View By',
    users: {
      name: 'users',
      label: 'Users',
      exporterFileName: 'users_export',
      doRemoveAllSelectedSuccess: 'Permissions removed successfully',
    },
    roles: {
      label: 'Roles',
      doRemoveAllSelectedSuccess: 'Permissions removed successfully',
    },
    edit: {
      title: 'Edit User',
    },
    new: {
      title: 'New User(s)',
      titleModal: 'New User',
      emailsHint: 'Separate multiple email addresses using the comma character.',
    },
    view: {
      title: 'View User',
      activity: 'Activity',
    },
    importer: {
      title: 'Import Users',
      fileName: 'users_import_template',
      hint: 'Files/Images columns must be the URLs of the files separated by space. Relationships must be the ID of the referenced records separated by space. Roles must be the role ids separated by space.',
    },
    errors: {
      userAlreadyExists: 'User with this email already exists',
      userNotFound: 'User not found',
      disablingHimself: `You can't disable yourself`,
      revokingOwnPermission: `You can't revoke your own owner permission`,
    },
  },

  user: {
    fields: {
      id: 'Id',
      authenticationUid: 'Authentication Uid',
      avatars: 'Avatar',
      email: 'Email',
      emails: 'Email(s)',
      fullName: 'Name',
      firstName: 'First Name',
      lastName: 'Last Name',
      status: 'Status',
      disabled: 'Disabled',
      phoneNumber: 'Phone Number',
      role: 'Role',
      createdAt: 'Created at',
      updatedAt: 'Updated at',
      roleUser: 'Role/User',
      roles: 'Roles',
      createdAtRange: 'Created at',
      password: 'Password',
      rememberMe: 'Remember me',
    },
    enabled: 'Enabled',
    disabled: 'Disabled',
    validations: {
      // eslint-disable-next-line
      email: 'Email ${value} is invalid',
    },
  },

  auditLog: {
    menu: 'Audit Logs',
    title: 'Audit Logs',
    exporterFileName: 'audit_log_export',
    entityNamesHint: 'Separate multiple entities using the comma character.',
    fields: {
      id: 'Id',
      timestampRange: 'Period',
      entityName: 'Entity',
      entityNames: 'Entities',
      entityId: 'Entity ID',
      action: 'Action',
      values: 'Values',
      timestamp: 'Date',
      createdByEmail: 'User Email',
    },
  },
  settings: {
    title: 'Settings',
    menu: 'Settings',
    save: {
      success: 'Settings saved successfully. The page will reload in {0} seconds for changes to take effect.',
    },
    fields: {
      theme: 'Theme',
    },
    colors: {
      default: 'Default',
      cyan: 'Cyan',
      'geek-blue': 'Geek Blue',
      gold: 'Gold',
      lime: 'Lime',
      magenta: 'Magenta',
      orange: 'Orange',
      'polar-green': 'Polar Green',
      purple: 'Purple',
      red: 'Red',
      volcano: 'Volcano',
      yellow: 'Yellow',
    },
  },
  home: {
    menu: 'الرئيسية',
    prescriptions: 'الوصفات الطبية',
    drugIndex: 'الأدوية',
    jobs: 'الوظائف', 
    offers: 'العروض',
    visits: 'الزيارات',
    registers: 'التسجيل',
    message: `This page uses fake data for demonstration purposes only. You can edit it at frontend/src/modules/home/components/home-page.vue.`,
    charts: {
      day: 'Day',
      red: 'Red',
      green: 'Green',
      yellow: 'Yellow',
      grey: 'Grey',
      blue: 'Blue',
      orange: 'Orange',
      months: {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
      },
      eating: 'Eating',
      drinking: 'Drinking',
      sleeping: 'Sleeping',
      designing: 'Designing',
      coding: 'Coding',
      cycling: 'Cycling',
      running: 'Running',
      offer: 'Offer',
    },
  },
  errors: {
    itemCannotBeDeleted:'لا يمكن إتمام عملية الحذف لان هذا العنصر قيد الإستخدام',
    incorrectPinCode:'الرقم السري غير صحيح',
    required: 'هذا الحقل مطلوب',
    minValue: 'الحد الأدنى للقيمة هو {0}',
    maxValue: 'القيمة القصوى هي {0}',
    maxCharCount: 'الرجاء استخدام الحد الأقصى لعدد الأحرف {0}',
    typeRequired: 'النوع مطلوب',
    languageRequired: 'اللغة مطلوبة',
    destinationRequired: 'الجهة مطلوبة',
    purposeRequired: 'هذا الحقل مطلوب',
    workAtRequired: 'هذا الحقل مطلوب',
    wifeNameRequired: 'الإسم مطلوب',
    dateRequired: 'التاريخ مطلوب',
    timeRequired: 'الوقت مطلوب',
    hospitalNameRequired: 'إسم المستشفي مطلوب',
    nameRequired: 'الإسم مطلوب',
    workPositionRequired: 'هذا الحقل مطلوب',
    signatureRequired: 'التوقيع مطلوب',
    birthDateRequired: 'تاريخ الميلاد مطلوب',
    relativeRelationRequired: 'صلة القرابة مطلوبة',
    placeOfBusinessRequired: 'مكان العمل مطلوب',
    placeOfResidenceRequired: 'مكان الإقامة مطلوب',
    buildingRequired: 'المبني مطلوب',
    floorRequired: 'الدور مطلوب',
    accountTypeRequired: 'نوع الحساب مطلوب',
    managerTypeRequired: 'الإدارة مطلوبة',
    invalidPhoneNumber: 'رقم الهاتف غير صالح',
    invalidNoteField: 'حقل ملاحظة غير صالح',
    invalidReasonOfPermission: 'سبب غير صالح لحقل الإذن',
    departmentRequired: 'القسم مطلوب',
    subDepartmentRequired: 'القسم الفرعي مطلوب',
    jobTitleRequired: 'المسمي الوظيفي مطلوب',
    employeeRequired: 'الموظف مطلوب',
    employeesRequired: 'الموظف مطلوب',

    recordIsFound: 'This record is already exist with the same barcode',

    backToHome: 'الرجوع للرئيسية',
    403: `عذرًا ، لا يمكنك الوصول إلى هذه الصفحة`,
    404: 'عذرًا ، الصفحة التي قمت بزيارتها غير موجودة',
    500: 'عذرًا ، يبلغ الخادم عن وجود خطأ',
    forbidden: {
      message: 'ممنوع',
    },
    validation: {
      message: 'حدث خطأ',
    },
    defaultErrorMessage: 'عفوًا ، حدث خطأ',
  },

  // See https://github.com/jquense/yup#using-a-custom-locale-dictionary
  /* eslint-disable */
  validation: {
    mixed: {
      default: '${path} is invalid',
      required: '${path} is required',
      oneOf: '${path} must be one of the following values: ${values}',
      notOneOf: '${path} must not be one of the following values: ${values}',
      notType: ({
        path,
        type,
        value,
        originalValue
      }) => {
        return `${path} must be a ${type}`;
      },
    },
    string: {
      length: '${path} must be exactly ${length} characters',
      min: '${path} must be at least ${min} characters',
      max: '${path} must be at most ${max} characters',
      matches: '${path} must match the following: "${regex}"',
      email: '${path} must be a valid email',
      url: '${path} must be a valid URL',
      trim: '${path} must be a trimmed string',
      lowercase: '${path} must be a lowercase string',
      uppercase: '${path} must be a upper case string',
      selected: '${path} must be selected',
    },
    number: {
      min: '${path} must be greater than or equal to ${min}',
      max: '${path} must be less than or equal to ${max}',
      lessThan: '${path} must be less than ${less}',
      moreThan: '${path} must be greater than ${more}',
      notEqual: '${path} must be not equal to ${notEqual}',
      positive: '${path} must be a positive number',
      negative: '${path} must be a negative number',
      integer: '${path} must be an integer',
      invalid: '${path} must be a number',
    },
    date: {
      min: '${path} field must be later than ${min}',
      max: '${path} field must be at earlier than ${max}',
    },
    boolean: {},
    object: {
      noUnknown: '${path} field cannot have keys not specified in the object shape',
    },
    array: {
      min: '${path} field must have at least ${min} items',
      max: '${path} field must have less than or equal to ${max} items',
    },
  },
  /* eslint-disable */
  fileUploader: {
    upload: 'Upload',
    image: 'You must upload an image',
    size: 'File is too big. Max allowed size is {0}',
    formats: `Invalid format. Must be '{0}'.`,
  },
  importer: {
    line: 'Line',
    status: 'Status',
    pending: 'Pending',
    imported: 'Imported',
    error: 'Error',
    total: `{0} imported, {1} pending and {2} with error`,
    importedMessage: `Processed {0} of {1}.`,
    noNavigateAwayMessage: 'Do not navigate away from this page or import will be stopped.',
    completed: {
      success: 'Import completed. All rows were successfully imported.',
      someErrors: 'Processing completed, but some rows were unable to be imported.',
      allErrors: 'Import failed. There are no valid rows.',
    },
    form: {
      downloadTemplate: 'Download the template',
      hint: 'Click or drag the file to this area to continue',
    },
    list: {
      discardConfirm: 'Are you sure? Non-imported data will be lost.',
    },
    errors: {
      invalidFileEmpty: 'The file is empty',
      invalidFileExcel: 'Only excel (.xlsx) files are allowed',
      invalidFileUpload: 'Invalid file. Make sure you are using the last version of the template.',
      importHashRequired: 'Import hash is required',
      importHashExistent: 'Data has already been imported',
    },
  },

  autocomplete: {
    loading: 'Loading...',
  },

  imagesViewer: {
    noImage: 'No image',
  },

  firebaseErrors: {
    'auth/user-disabled': 'Your account is disabled',
    'auth/user-not-found': `Sorry, we don't recognize your credentials`,
    'auth/wrong-password': `Sorry, we don't recognize your credentials`,
    'auth/weak-password': 'This password is too weak',
    'auth/email-already-in-use': 'Email is already in use',
    'auth/invalid-email': 'Please provide a valid email',
    'auth/account-exists-with-different-credential': 'Email is already in use for a different authentication method.',
    'auth/credential-already-in-use': 'Credentials are already in use',
  },

  privacyPolicy: {
    content: `  <div>
                  <strong class="privacy-policy-title fontResize" style="color: #C6426E;">سياسة </strong>
                  <strong class="privacy-policy-title fontResize" style="color: #000000;">الخصوصية.</strong> 
                  <img src="/images/short-line.png" class="short-line-w short-line-h widthResize heightResize" style="padding: 1.389vw 0 5.764vw !important" />
                  <img src="/images/lock.png" class="img-lock" />

                             <p> بنى RunProf تطبيق Drug Deal كتطبيق مجاني. يتم توفير هذه الخدمة عن طريق Drug Deal بدون أي تكلفة وهي مخصصة للاستخدام كما هي.
                            </p> <p>
                              تُستخدم هذه الصفحة لإعلام الزوار فيما يتعلق بسياساتنا فيما يتعلق بجمع المعلومات الشخصية واستخدامها والكشف عنها إذا قرر أي شخص استخدام خدمتنا.
                            </p> <p>
                              إذا اخترت استخدام خدمتنا ، فأنت توافق على جمع المعلومات واستخدامها فيما يتعلق بهذه السياسة. يتم استخدام المعلومات الشخصية التي نجمعها لتوفير الخدمة وتحسينها. لن نستخدم أو نشارك معلوماتك مع أي شخص باستثناء ما هو موضح في سياسة الخصوصية هذه.
                            </p> <p>
                              المصطلحات المستخدمة في سياسة الخصوصية هذه لها نفس المعاني كما هي في الشروط والأحكام الخاصة بنا ، والتي يمكن الوصول إليها في Drug Deal ما لم يتم تحديد خلاف ذلك في سياسة الخصوصية هذه.
                            </p> <p><strong>جمع المعلومات واستخدامها</strong></p> <p>
                              للحصول على تجربة أفضل ، أثناء استخدام خدمتنا ، قد نطلب منك تزويدنا ببعض المعلومات المحددة للهوية الشخصية. سنحتفظ بالمعلومات التي نطلبها واستخدامها كما هو موضح في سياسة الخصوصية هذه.
                            </p> <div><p>
                              يستخدم التطبيق خدمات الطرف الثالث التي قد تجمع المعلومات المستخدمة لتحديد هويتك.
                              </p> <p>
                              رابط إلى سياسة الخصوصية لموفري خدمة الطرف الثالث المستخدمة من قبل التطبيق
                              </p> <ul><li>
                              <a style="color: blue !important;" href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer">Google Play Services</a></li><!----><li>
                              <a style="color: blue !important;" href="https://firebase.google.com/policies/analytics" target="_blank" rel="noopener noreferrer">Google Analytics for Firebase</a></li><li>
                              <a style="color: blue !important;" href="https://firebase.google.com/support/privacy/" target="_blank" rel="noopener noreferrer">Firebase Crashlytics</a></li><li>
                              <a style="color: blue !important;" href="https://www.facebook.com/about/privacy/update/printable" target="_blank" rel="noopener noreferrer">Facebook</a></li><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----></ul></div> <p><strong>تسجيل البيانات</strong></p> <p>
                              نريد إبلاغك أنه كلما استخدمت خدمتنا ، في حالة وجود خطأ في التطبيق ، نقوم بجمع البيانات والمعلومات (من خلال منتجات الطرف الثالث) على هاتفك تسمى Log Data. قد تتضمن بيانات السجل هذه معلومات مثل عنوان بروتوكول الإنترنت ("IP" الخاص بجهازك ، واسم الجهاز ، وإصدار نظام التشغيل ، وتكوين التطبيق عند استخدام خدمتنا ، ووقت وتاريخ استخدامك للخدمة ، وإحصاءات أخرى .
                            </p> <p><strong>ملفات تعريف الارتباط</strong></p> <p>
                              ملفات تعريف الارتباط هي ملفات تحتوي على كمية صغيرة من البيانات التي يتم استخدامها بشكل شائع كمعرفات فريدة مجهولة الهوية. يتم إرسالها إلى متصفحك من مواقع الويب التي تزورها ويتم تخزينها على الذاكرة الداخلية لجهازك.
                            </p> <p>
                              لا تستخدم هذه الخدمة "ملفات تعريف الارتباط" هذه بشكل صريح. ومع ذلك ، قد يستخدم التطبيق رمزًا ومكتبات تابعة لجهة خارجية تستخدم "ملفات تعريف الارتباط" لجمع المعلومات وتحسين خدماتها. لديك خيار إما قبول أو رفض ملفات تعريف الارتباط هذه ومعرفة متى يتم إرسال ملف تعريف ارتباط إلى جهازك. إذا اخترت رفض ملفات تعريف الارتباط الخاصة بنا ، فقد لا تتمكن من استخدام بعض أجزاء هذه الخدمة.
                            </p> <p><strong>مقدمي الخدمة</strong></p> <p>
                            يجوز لنا توظيف شركات وأفراد من جهات خارجية للأسباب التالية:
                            </p> <ul><li>لتسهيل خدمتنا ؛</li> <li>لتقديم الخدمة نيابة عنا ؛
                            </li> <li>لأداء الخدمات المتعلقة بالخدمة ؛ أو
                            </li> <li>لمساعدتنا في تحليل كيفية استخدام خدمتنا.
                            </li></ul> <p>
                              نريد إبلاغ مستخدمي هذه الخدمة أن هذه الأطراف الثالثة يمكنها الوصول إلى معلوماتك الشخصية. والسبب هو أداء المهام المسندة إليهم نيابة عنا. ومع ذلك ، فإنهم ملزمون بعدم الكشف عن المعلومات أو استخدامها لأي غرض آخر.
                            </p> <p><strong>الأمان</strong></p> <p>
                            نحن نقدر ثقتك في تزويدنا بمعلوماتك الشخصية ، وبالتالي نسعى جاهدين لاستخدام وسائل مقبولة تجاريًا لحمايتها. ولكن تذكر أنه لا توجد طريقة إرسال عبر الإنترنت ، أو طريقة تخزين إلكترونية آمنة وموثوق بها بنسبة 100٪ ، ولا يمكننا ضمان أمانها المطلق.
                            </p> <p><strong>روابط لمواقع أخرى</strong></p> <p>
                              قد تحتوي هذه الخدمة على روابط لمواقع أخرى. إذا قمت بالنقر فوق ارتباط جهة خارجية ، فسيتم توجيهك إلى هذا الموقع. لاحظ أن هذه المواقع الخارجية لا نقوم بتشغيلها. لذلك ، ننصحك بشدة بمراجعة سياسة الخصوصية لهذه المواقع. لا نتحكم ولا نتحمل أي مسؤولية عن المحتوى أو سياسات الخصوصية أو الممارسات الخاصة بأي مواقع أو خدمات تابعة لجهات خارجية.
                            </p> <p><strong>خصوصية الأطفال</strong></p> <p>
                            لا تتعامل هذه الخدمات مع أي شخص يقل عمره عن 13 عامًا. نحن لا نجمع عن قصد معلومات تحديد الهوية الشخصية من الأطفال تحت سن 13 عامًا. في حالة اكتشافنا أن طفلًا أقل من 13 عامًا قدم لنا معلومات شخصية ، فإننا نحذفها على الفور من خوادمنا. إذا كنت أحد الوالدين أو الوصي وكنت على علم بأن طفلك قد قدم لنا معلومات شخصية ، يرجى الاتصال بنا حتى نتمكن من القيام بالإجراءات اللازمة.

                            </p> <p><strong>التغييرات على سياسة الخصوصية هذه</strong></p> <p>
                              قد نقوم بتحديث سياسة الخصوصية الخاصة بنا من وقت لآخر. وبالتالي ، ننصحك بمراجعة هذه الصفحة بشكل دوري لأية تغييرات. سنقوم بإعلامك بأي تغييرات عن طريق نشر سياسة الخصوصية الجديدة على هذه الصفحة.

                            </p> <p>تسري هذه السياسة اعتبارًا من 2020-07-07</p> <p><strong>اتصل بنا</strong></p> <p>
                              إذا كان لديك أي أسئلة أو اقتراحات حول سياسة الخصوصية الخاصة بنا ، فلا تتردد في الاتصال بنا على support@drugdealapp.com.
                            </p>
                </div>`
  },

  termsAndConditions: {
    content: `<div>
                <strong></strong> 
                <strong class="privacy-policy-title fontResize" style="color: #C6426E;">الشروط و </strong>
                <strong class="privacy-policy-title fontResize" style="color: #000000;">الأحكام</strong> 
                <img src="/images/short-line.png" class="short-line-w short-line-h widthResize heightResize" style="padding: 1.389vw 0 5.764vw !important" />
                <img src="/images/lock.png" class="img-lock" />

                <p>
                                من خلال تنزيل التطبيق أو استخدامه ، سيتم تطبيق هذه الشروط عليك تلقائيًا - يجب عليك التأكد من قراءتها بعناية قبل استخدام التطبيق. لا يُسمح لك بنسخ أو تعديل التطبيق أو أي جزء من التطبيق أو علاماتنا التجارية بأي شكل من الأشكال. لا يُسمح لك بمحاولة استخراج شفرة المصدر للتطبيق ، كما يجب ألا تحاول ترجمة التطبيق إلى لغات أخرى ، أو إنشاء إصدارات مشتقة. التطبيق نفسه ، وجميع العلامات التجارية وحقوق التأليف والنشر وحقوق قواعد البيانات وحقوق الملكية الفكرية الأخرى المتعلقة به ، لا تزال تنتمي إلى Drug Deal.
                              </p> <p>
                                تلتزم Drug Deal بضمان أن التطبيق مفيد وفعال قدر الإمكان. لهذا السبب ، نحتفظ بالحق في إجراء تغييرات على التطبيق أو فرض رسوم على خدماته ، في أي وقت ولأي سبب. لن نقوم أبدًا بتحصيل رسوم منك مقابل التطبيق أو خدماته دون أن نوضح لك تمامًا ما تدفعه مقابل ذلك.

                              </p> <p>
                               يقوم تطبيق Drug Deal بتخزين ومعالجة البيانات الشخصية التي قدمتها لنا ، من أجل تقديم خدمتنا. تقع على عاتقك مسؤولية الحفاظ على هاتفك والوصول إلى التطبيق آمنًا. لذلك ، نوصي بعدم كسر حماية الهاتف أو القضاء عليه ، وهي عملية إزالة قيود البرامج والقيود التي يفرضها نظام التشغيل الرسمي لجهازك. يمكن أن يجعل هاتفك عرضة للبرامج الضارة / الفيروسات / البرامج الضارة ، ويعرّض ميزات الأمان لهاتفك للخطر ، وقد يعني أن تطبيق Drug Deal لن يعمل بشكل صحيح أو على الإطلاق.
                              </p> <div><p>
                                  يستخدم التطبيق خدمات الطرف الثالث التي تعلن الشروط والأحكام الخاصة بها.

                                </p> <p>
                                  رابط إلى شروط وأحكام مزودي الخدمة التابعين للجهات الخارجية الذين يستخدمهم التطبيق
                                </p> <ul><li>
                                <a style="color: blue !important;" href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Google Play Services</a></li><!----><li>
                                <a style="color: blue !important;" href="https://firebase.google.com/terms/analytics" target="_blank" rel="noopener noreferrer">Google Analytics for Firebase</a></li><li>
                                <a style="color: blue !important;" href="https://firebase.google.com/terms/crashlytics" target="_blank" rel="noopener noreferrer">Firebase Crashlytics</a></li><li>
                                <a style="color: blue !important;" href="https://www.facebook.com/legal/terms/plain_text_terms" target="_blank" rel="noopener noreferrer">Facebook</a></li><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----></ul></div> <p>
                                يجب أن تدرك أن هناك أشياء معينة لن تتحملها Drug Deal. تتطلب وظائف معينة في التطبيق أن يكون للتطبيق اتصال إنترنت نشط. يمكن أن يكون الاتصال Wi-Fi ، أو مقدمًا من قِبل مزود شبكة الهاتف المحمول ، ولكن لا يمكن أن تتحمل Drug Deal مسؤولية عدم عمل التطبيق بكامل وظائفه إذا لم يكن لديك وصول إلى Wi-Fi ، ولم يكن لديك أي من ترك بدل البيانات الخاصة بك.

                              </p> <p></p> <p>
                                إذا كنت تستخدم التطبيق خارج منطقة مزودة بشبكة Wi-Fi ، يجب أن تتذكر أن شروط الاتفاقية الخاصة بك مع مزود شبكة الهاتف المحمول ستظل سارية. ونتيجة لذلك ، قد يفرض موفر خدمة الجوال الخاص بك رسومًا على تكلفة البيانات طوال مدة الاتصال أثناء الوصول إلى التطبيق ، أو رسوم الطرف الثالث الأخرى. عند استخدام التطبيق ، فأنت تقبل المسؤولية عن أي رسوم من هذا القبيل ، بما في ذلك رسوم بيانات التجوال إذا كنت تستخدم التطبيق خارج إقليمك الأصلي (أي المنطقة أو البلد) دون إيقاف تجوال البيانات. إذا لم تكن دافع الفواتير للجهاز الذي تستخدم التطبيق عليه ، فيرجى العلم أننا نفترض أنك تلقيت إذنًا من دافع الفواتير لاستخدام التطبيق.

                              </p> <p>
                               على نفس المنوال ، لا يمكن لصفقة الدواء تحمل المسؤولية دائمًا عن الطريقة التي تستخدم بها التطبيق ، أي أنك تحتاج إلى التأكد من بقاء جهازك مشحونًا - إذا نفد شحن البطارية ولا يمكنك تشغيله للاستفادة من الخدمة ، لا يمكن للصفقة تحمل المسؤولية.
                              </p> <p>
                                فيما يتعلق بمسؤولية Drug Deal عن استخدامك للتطبيق ، عند استخدام التطبيق ، من المهم أن تضع في اعتبارك أنه على الرغم من سعينا لضمان تحديثه وتصحيحه في جميع الأوقات ، فإننا نعتمد على أطراف ثالثة في توفير المعلومات لنا حتى نتمكن من إتاحتها لك. لا تقبل Drug Deal أي مسؤولية عن أي خسارة ، مباشرة أو غير مباشرة ، تواجهها نتيجة الاعتماد بالكامل على هذه الوظيفة للتطبيق.

                              </p> <p>
                               في وقت ما ، قد نرغب في تحديث التطبيق. التطبيق متاح حاليًا على نظام التشغيل Android & iOS - قد تتغير متطلبات كلا النظامين (وأي أنظمة إضافية نقرر تمديد توفر التطبيق إليها) ، وستحتاج إلى تنزيل التحديثات إذا كنت ترغب في الاستمرار في استخدام تطبيق. لا تعد شركة Drug Deal بأنها ستقوم دائمًا بتحديث التطبيق بحيث يكون مناسبًا لك و / أو يعمل مع إصدار Android & iOS الذي قمت بتثبيته على جهازك. ومع ذلك ، فإنك تتعهد بقبول تحديثات التطبيق دائمًا عند تقديمها لك ، وقد نرغب أيضًا في التوقف عن تقديم التطبيق ، وقد ننهي استخدامه في أي وقت دون إشعارك بالإنهاء. ما لم نخبرك بخلاف ذلك ، عند أي إنهاء ، (أ) تنتهي الحقوق والتراخيص الممنوحة لك في هذه الشروط ؛ (ب) يجب عليك التوقف عن استخدام التطبيق و (إذا لزم الأمر) حذفه من جهازك.

                              </p> <p><strong>التغييرات على هذه الشروط والأحكام</strong></p> <p>
                               قد نقوم بتحديث الشروط والأحكام الخاصة بنا من وقت لآخر. وبالتالي ، ننصحك بمراجعة هذه الصفحة بشكل دوري لأية تغييرات. سنقوم بإعلامك بأي تغييرات عن طريق نشر الشروط والأحكام الجديدة على هذه الصفحة.

                              </p> <p>
                                تسري هذه الشروط والأحكام اعتبارًا من 2020-07-07
                              </p> <p><strong>اتصل بنا</strong></p> <p>
                                إذا كان لديك أي أسئلة أو اقتراحات حول الشروط والأحكام الخاصة بنا ، فلا تتردد في الاتصال بنا على support@drugdealapp.com.
                              </p>
                </div>`
  },

};

export default ar;