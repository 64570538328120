//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import firebase from 'firebase/app'
import 'firebase/firestore';
// import prescriptionDialogVue from './prescription-dialog.vue';
export default {
name:'app-prescription-details',
props:['prescDetails','id'],
data(){
    return{
       prescription:null, 
       createdAt:null
    }
    
},
computed:{
    prescDialog:{
        get(){
            return this.prescDetails
        },
        set(){
            this.$emit('closePresc')
        }
    }
},
methods:{
    close(){
        this.$emit('closePresc')
    }
},
async created(){

    // this.loading = true
//     let prescriptions = []
//     await firebase.firestore().collection("prescription").onSnapshot({ includeMetadataChanges: true }, function(snapshot) {
//       snapshot.docChanges().forEach(function(change) {
//           let prescription =  change.doc.data()
//           prescription.createdAt = new Date(prescription.createdAt.seconds * 1000)
//           prescription.createdAt = prescription.createdAt.toString().slice(0,24)
//           console.log('drug',prescription)
//           prescriptions.push(prescription)
//       });
//       console.log('this ',prescriptions.length)
//   });
//   this.data = prescriptions
//   this.loading = false
// { includeMetadataChanges: true },
    await firebase.firestore().collection('prescription').onSnapshot(async () => {
        this.prescription = await firebase.firestore().collection("prescription").doc(this.id).get()
        this.createdAt = new Date(this.prescription.data().createdAt.seconds * 1000).toString().slice(0,16)
    })
    // const comments = await firebase.firestore().collection(`prescription/${this.id}/comments`).get()
    // const comments = await firebase.firestore().collection('prescription').doc(this.id).collection('comments').get()
    // comments.forEach(doc =>{
    // })  

   
}
}
