//
//
//
//
//
//
//
//
//
//
//
//
//

import { I18nUtil } from '@/shared/i18n/i18n-util';
import { getLanguages } from '@/i18n';

export default {
  name: 'app-i18n-flags',

  computed: {
    languages() {
      return getLanguages();
    },
  },

  methods: {
    doChangeLanguage(language) {
      I18nUtil.doChangeLanguage(language);
    },
  },
};
