// import { PropertyService } from '@/modules/property/property-service';
// import jobListExporterFields from '@/modules/job/job-list-exporter-fields';
// import Errors from '@/shared/error/errors';
// import Exporter from '@/shared/exporter/exporter';

const INITIAL_PAGE_SIZE = 20;

export default {
  namespaced: true,

  state: {
    rows: [],
    count: 0,
    loading: false,
    backgroundLoading: false,
    filter: {},
    pagination: {},
    sorter: {},

    table: null,
    jobMaxCount: 0,
    prescriptionMaxCount: 0,
    propertyMaxCount: 0,
    prescriptionExpirationDays: 0
  },

  getters: {
    backgroundLoading: (state) => state.backgroundLoading,
    loading: (state) => state.loading,

    exportLoading: (state) => state.exportLoading,

    rows: (state) => state.rows || [],

    count: (state) => state.count,

    hasRows: (state, getters) => getters.count > 0,

    orderBy: (state) => {
      const sorter = state.sorter;

      if (!sorter) {
        return null;
      }

      if (!sorter.prop) {
        return null;
      }

      let direction =
        sorter.order === 'descending' ? 'DESC' : 'ASC';

      return `${sorter.prop}_${direction}`;
    },

    filter: (state) => state.filter,

    limit: (state) => {
      const pagination = state.pagination;

      if (!pagination || !pagination.pageSize) {
        return INITIAL_PAGE_SIZE;
      }

      return pagination.pageSize;
    },

    offset: (state) => {
      const pagination = state.pagination;

      if (!pagination || !pagination.pageSize) {
        return 0;
      }

      const currentPage = pagination.currentPage || 1;

      return (currentPage - 1) * pagination.pageSize;
    },

    pagination: (state, getters) => {
      return {
        ...state.pagination,
        total: getters.count,
        showSizeChanger: true,
      };
    },

    selectedRows: (state) => {
      return state.table ? state.table.selection : [];
    },
    jobMaxCount: (state) => state.jobMaxCount,
    prescriptionMaxCount: (state) => state.prescriptionMaxCount,
    propertyMaxCount: (state) => state.propertyMaxCount,
    prescriptionExpirationDays: (state) => state.prescriptionExpirationDays
  },

  mutations: {
    RESETED(state) {
      state.rows = [];
      state.count = 0;
      state.loading = false;
      state.filter = {};
      state.pagination = {};
      state.sorter = {};
      if (state.table) {
        state.table.clearSelection();
      }
    },

    UNSELECT_ALL(state) {
      if (state.table) {
        state.table.clearSelection();
      }
    },

    TABLE_MOUNTED(state, payload) {
      state.table = payload;
    },

    PAGINATION_CHANGED(state, payload) {
      state.pagination = payload || {};
    },

    PAGINATION_CURRENT_PAGE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: payload || 1,
        pageSize:
          previousPagination.pageSize || INITIAL_PAGE_SIZE,
      };
    },

    PAGINATION_PAGE_SIZE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: previousPagination.currentPage || 1,
        pageSize: payload || INITIAL_PAGE_SIZE,
      };
    },

    SORTER_CHANGED(state, payload) {
      state.sorter = payload || {};
    },

    FETCH_STARTED_IN_BACKGROUND(state, payload) {
      state.backgroundLoading = true;
      if (state.table) {
        state.table.clearSelection();
      }

      state.filter =
        payload && payload.filter ? payload.filter : {};
      state.pagination =
        payload && payload.keepPagination
          ? state.pagination
          : {};
    },

    FETCH_STARTED(state, payload) {
      state.loading = true;

      if (state.table) {
        state.table.clearSelection();
      }

      state.filter =
        payload && payload.filter ? payload.filter : {};
      state.pagination =
        payload && payload.keepPagination
          ? state.pagination
          : {};
    },

    FETCH_SUCCESS(state, payload) {
      state.loading = false;
      state.backgroundLoading = false;
      state.rows = payload.rows;
      state.count = payload.count;
    },

    FETCH_ERROR(state) {
      state.loading = false;
      state.backgroundLoading = false;
      state.rows = [];
      state.count = 0;
    },

    EXPORT_STARTED(state) {
      state.exportLoading = true;
    },

    EXPORT_SUCCESS(state) {
      state.exportLoading = false;
    },

    EXPORT_ERROR(state) {
      state.exportLoading = false;
    },
    SET_JOB(state,payload) {
        state.jobMaxCount = payload;
      },
    SET_PRESCRIPTION(state,payload) {
      state.prescriptionMaxCount = payload;
    },
    SET_PROPERTY(state,payload) {
        state.propertyMaxCount = payload;
    },
    SET_PRESCRIPTION_EXPIRATION_DAYS(state, payload){
      state.prescriptionExpirationDays = payload
    }
  },
actions:{
    setJobMaxCount({ commit },value){
        commit('SET_JOB',value)
    },
    setPrescriptionMaxCount({ commit },value){
        commit('SET_PRESCRIPTION',value)
    },
    setPropertyMaxCount({ commit },value){
        commit('SET_PROPERTY',value)
    },
    setPrescriptionExpirationDays({commit}, value){
      commit('SET_PRESCRIPTION_EXPIRATION_DAYS',value)
    }
}
//   actions: {
//     doUnselectAll({ commit }) {
//       commit('UNSELECT_ALL');
//     },

//     doMountTable({ commit }, table) {
//       commit('TABLE_MOUNTED', table);
//     },

//     async doReset({ commit, dispatch }) {
//       commit('RESETED');
//       return dispatch('doFetch');
//     },

    

//     doChangePagination(
//       { commit, getters, dispatch },
//       pagination,
//     ) {
//       commit('PAGINATION_CHANGED', pagination);
//       const filter = getters.filter;
//       dispatch('doFetch', { filter, keepPagination: true });
//     },

//     doChangePaginationPageSize(
//       { commit, getters, dispatch },
//       pageSize,
//     ) {
//       commit('PAGINATION_PAGE_SIZE_CHANGED', pageSize);
//       const filter = getters.filter;
//       dispatch('doFetch', { filter, keepPagination: true });
//     },

//     doChangePaginationCurrentPage(
//       { commit, getters, dispatch },
//       currentPage,
//     ) {
//       commit(
//         'PAGINATION_CURRENT_PAGE_CHANGED',
//         currentPage,
//       );
//       const filter = getters.filter;
//       dispatch('doFetch', { filter, keepPagination: true });
//     },

//     doChangeSort({ commit, getters, dispatch }, sorter) {
//       commit('SORTER_CHANGED', sorter);
//       const filter = getters.filter;
//       dispatch('doFetch', { filter, keepPagination: true });
//     },

//     async doFetch(
//       { commit,  },
//       { filter, keepPagination } = {},
//     ) {
//       try {
//         commit('FETCH_STARTED', { filter, keepPagination });

//         const response = await PropertyService.list(
//           filter,
//         //   getters.orderBy,
//           // getters.limit,
//           // getters.offset,
//         );
//             console.log('response rows',response.rows)
//         commit('FETCH_SUCCESS', {
//           rows: response.rows,
//           count: response.count,
//         });
//       } catch (error) {
//         Errors.handle(error);
//         commit('FETCH_ERROR');
//       }
//     },
    
//     async doFetchInBackground(
//       { commit, },
//       { filter, keepPagination } = {},
//     ) {
//       try {
//         commit('FETCH_STARTED_IN_BACKGROUND', { filter, keepPagination });

//         const response = await PropertyService.list(
//         //   filter,
//         //   getters.orderBy,
//           // getters.limit,
//           // getters.offset,
//         );

//         commit('FETCH_SUCCESS', {
//           rows: response.rows,
//           count: response.count,
//         });
//       } catch (error) {
//         Errors.handle(error);
//         commit('FETCH_ERROR');
//       }
//     },

//   },
};
