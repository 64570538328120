//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { UserModel } from '@/modules/auth/user-model';
// import { i18n } from '@/i18n';

const { fields } = UserModel;

export default {
  name: 'app-forgot-password-page',

  data() {
    return {
      rules: {
        email: fields.email.forFormRules(),
      },
      model: {},

      emailEnter: false,
      passwordEnter: false,
      validPassword: false,
      validEmail: false,
      typePassword: true,
    };
  },

  computed: {
    ...mapGetters({
      loading: 'auth/loadingPasswordResetEmail',
      isMobile: 'layout/is_screen_xs',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',

      // registerError: 'auth/registerError',
      // signinError: 'auth/signinError',
    }),

    fields() {
      return fields;
    },
  },

  methods: {
    ...mapActions({
      doSendPasswordResetEmail:
        'auth/doSendPasswordResetEmail',
    }),
    // i18n(code, args) {
    //   return i18n(code, args);
    // },
    i18n(key, args) {
      return this.$t(key, args);
    },
    validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      if(re.test(String(email).toLowerCase())){
        this.validEmail = true
        return true
      } else{
        this.validEmail = false
        return false
      }  
    },
    validatePassword(password) {
      if(password.length >= 6){
        this.validPassword = true
        return true
      } else{
        this.validPassword = false
        return false
      }  
    },
    async doSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }

      await this.doSendPasswordResetEmail(this.model.email);
    },
  },
};
