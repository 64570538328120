//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { i18n } from '@/i18n';
import { mapActions, mapGetters } from 'vuex';
// import Message from '@/shared/message/message';
export default {
  name: 'app-signautre-item-page',
  props: ['id', 'loading', 'storePath'],

  data() {
    return {
      pinCode:'',
      options: {
      penColor: "#00f"
    }
    };
  },
  computed: {
    ...mapGetters({settings:'settings/settings'})
  },
  created(){
    this.doFind()
  },
  methods: {
    ...mapActions({doFind:'settings/doFind',doSave:'settings/doSave'}),
    i18n(text) {
      return i18n(text);
    },
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    async doSubmit() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if(!isEmpty){
        // this.$emit('submit',data)
        this.settings.signature = data
        this.doSave(this.settings)
      }
    },
  },
};
