//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PropertyListTable from '@/modules/property/components/property-list-table.vue';

import { mapGetters, mapActions } from 'vuex';
import { OfferPermissions } from '@/modules/offer/offer-permissions';
// import firebase from 'firebase';
// import firebase from 'firebase/app';
import 'firebase/auth';        // for authentication
import 'firebase/storage';     // for storage
import 'firebase/database';    // for realtime database
import 'firebase/firestore';   // for cloud firestore
import 'firebase/messaging';   // for cloud messaging
import 'firebase/functions';   // for cloud functions
import i18n from '@/vueI18n';


export default {
  name: 'app-offer-list-page',

  components: {
    [PropertyListTable.name]: PropertyListTable,
  },
  data() {
    return {
      isListView: false,
      modalVisible: false,
      dialogVisible: false,
      successDialog: false,
      searchByOptions: ['name', 'address', 'spaceArea', 'postStatus', 'isClosed']

    }
  },
  watch:{
    lang(){
            document.title =
                this.i18n('app.title') +
                ' | ' +
                this.i18n('menu.property') 
        },
  },
  computed: {
    lang(){
                
            return i18n.locale
        },
    ...mapGetters({
      rows: 'proprty/list/rows',
      count: 'property/list/count',
      loading: 'property/list/loading',
      is_screen_xs: 'layout/is_screen_xs',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      mountedTable:'property/list/mountedTable'
    }),
    hasPermissionToCreate() {
      return new OfferPermissions(this.currentUser).create;
    },
  },
  created(){
    document.title =
                this.i18n('app.title') +
                ' | ' +
                this.i18n('menu.property')
  },
  // async created() {
  //   this.doFetchFacilityTypes()
  //       if(this.$route.params.searchBy && this.searchByOptions.includes(this.$route.params.searchBy)){
  //       if(this.$route.query.page){
  //         await this.doFetchFromRoute({page: this.$route.query.page, limit: 10, searchBy: this.$route.params.searchBy, filter: this.$route.query.filter ? this.$route.query.filter : ''})
  //       }
  //       else{
  //         if(this.$route.query.filter){
  //           this.$router.push({
  //             query: {
  //               page: 1,
  //               filter: this.$route.query.filter
  //             }
  //           })
  //         }
  //         else{
  //           this.$router.push({
  //             query: {
  //               page: 1,
  //             }
  //           })
  //         }
  //         await this.doFetchFromRoute({page: 1, limit: 10, searchBy: this.$route.params.searchBy ? this.$route.params.searchBy : 'name', filter: this.$route.query.filter ? this.$route.query.filter : ''})
  //       }
  //     }
  //     else{
  //       this.$router.push({
  //         params: {
  //           searchBy: 'name'
  //         },
  //         query: {
  //           page: 1,
  //         }
  //       })
  //       await this.doFetch({limit: 10,searchBy: 'name',filter:''})
  //     }
 
  // },
  async mounted() {
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args);
    },
    ...mapActions({
      doFetch: 'property/list/doFetch',
      doFetchInBackground: 'property/list/doFetchInBackground',
      setMountedTable:'property/list/setMountedTable',
      doFetchFacilityTypes:'property/view/doFetchFacilityTypes',
      doFetchFromRoute: 'property/list/doFetchFromRoute'
    }),

    // displaySwitch(view) {
    //   switch (view) {
    //     case 'card':
    //       this.isListView = false
    //       break;
    //     case 'list':
    //       this.isListView = true
    //       break;
    //     default:
    //       break;
    //   }
    // },
    doOpenModal() {
      this.dialogVisible = true;
      this.modalVisible = true;
    },
    onModalClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.modalVisible = false;
      }, 500);
    },
    onModalSuccess() {
      this.successDialog = true
    },
  

  },

};
