//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ContactListTable from '@/modules/contact/components/contact-list-table.vue';
// import OfferFormModal from '@/modules/offer/components/offer-form-modal.vue';

import { mapGetters, mapActions } from 'vuex';
// import { ContactPermissions } from '@/modules/contact/contact-permissions';
// import firebase from 'firebase';
// import firebase from 'firebase/app';
import 'firebase/auth';        // for authentication
import 'firebase/storage';     // for storage
import 'firebase/database';    // for realtime database
import 'firebase/firestore';   // for cloud firestore
import 'firebase/messaging';   // for cloud messaging
import 'firebase/functions';   // for cloud functions
import i18n from '@/vueI18n';


export default {
  name: 'app-offer-list-page',

  components: {
    [ContactListTable.name]: ContactListTable,
    // [OfferFormModal.name]: OfferFormModal,
  },
  data() {
    return {
      isListView: false,
      modalVisible: false,
      dialogVisible: false,
      successDialog: false,
      searchByOptions: ['name', 'phone', 'email']
    }
  },
  watch:{
    lang(){
            document.title =
                this.i18n('app.title') +
                ' | ' +
                this.i18n('menu.contact') 
        },
  },
  computed: {
    lang(){
                
            return i18n.locale
        },
    ...mapGetters({
      rows: 'contact/list/rows',
      count: 'contact/list/count',
      loading: 'contact/list/loading',
      is_screen_xs: 'layout/is_screen_xs',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
    }),
    // hasPermissionToCreate() {
    //   return new OfferPermissions(this.currentUser).create;
    // },
  },
  created(){
        document.title =
                this.i18n('app.title') +
                ' | ' +
                this.i18n('menu.contact')
  },
  // async created() {
  //   // if(!this.mountedTable){
  //   //   await this.doFetch({limit: 10,searchBy: 'name',filter:''})
  //   //   this.setMountedTable(true)
  //   // }
  //     if(this.$route.params.searchBy && this.searchByOptions.includes(this.$route.params.searchBy)){
  //       if(this.$route.query.page){
  //         await this.doFetchFromRoute({page: this.$route.query.page, limit: 10, searchBy: this.$route.params.searchBy, filter: this.$route.query.filter ? this.$route.query.filter : ''})
  //       }
  //       else{
  //         if(this.$route.query.filter){
  //           this.$router.push({
  //             // params: {
  //             //   searchBy: this.$route.params.searchBy
  //             // },
  //             query: {
  //               page: 1,
  //               filter: this.$route.query.filter
  //             }
  //           })
  //         }
  //         else{
  //           this.$router.push({
  //             // params: {
  //             //   searchBy: this.$route.params.searchBy
  //             // },
  //             query: {
  //               page: 1,
  //             }
  //           })
  //         }
          
  //         await this.doFetchFromRoute({page: 1, limit: 10, searchBy: this.$route.params.searchBy ? this.$route.params.searchBy : 'name', filter: this.$route.query.filter ? this.$route.query.filter : ''})
  //       }
  //     }
  //     else{
  //       this.$router.push({
  //         params: {
  //           searchBy: 'name'
  //         },
  //         query: {
  //           page: 1,
  //           // filter: null
  //         }
  //       })
  //       await this.doFetch({limit: 10,searchBy: 'name',filter:''})
  //     }
  // },
  async mounted() {
    // await this.doFilter();
  },
  
  methods: {
    i18n(key, args) {
      return this.$t(key, args);
    },
    ...mapActions({
      doFetch: 'contact/list/doFetch',
      doFetchInBackground: 'contact/list/doFetchInBackground',
      setMountedTable: 'contact/list/setMountedTable',
      doFetchFromRoute: 'contact/list/doFetchFromRoute'
    }),

    // displaySwitch(view) {
    //   switch (view) {
    //     case 'card':
    //       this.isListView = false
    //       break;
    //     case 'list':
    //       this.isListView = true
    //       break;
    //     default:
    //       break;
    //   }
    // },
    doOpenModal() {
      this.dialogVisible = true;
      this.modalVisible = true;
    },
    onModalClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.modalVisible = false;
      }, 500);
    },
    onModalSuccess() {
      this.successDialog = true
    },
  
    async doFilter() {
      // filterSchema.cast(this.model);
      const filter = {}
      return this.doFetch({
        filter,
      });
    },
    async doFilterInBackground() {
      const filter = {}
      return this.doFetchInBackground({
        filter,
      });
    },
  },

};
