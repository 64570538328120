import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';
import { i18n } from '@/i18n';
const PrescriptionListPage = () =>
  import('@/modules/prescription/components/prescription-list-page.vue');
// const PrescriptionDetails = () =>
//   import('@/modules/prescriptions/components/prescription-details.vue');
// const EditPrescription = () =>
//   import('@/modules/prescriptions/components/edit-prescription.vue');

  export default [
    {
      path: '/:lang?',
      exact: true,
      component: Layout,
      children: [
        {
          name: 'prescription',
          path: 'prescription/:searchBy?',
          component: PrescriptionListPage,
          // meta: { auth: true },
          meta: {
            auth: true,
            permission: Permissions.values.prescriptionView,
          },
          beforeEnter: (to, from, next) => {
            document.title =
              i18n('app.title') +
              ' | ' +
              i18n('menu.prescription') 
            next();
          },
        
        },
        
        // {
        //   name: 'prescription-details',
        //   path: 'prescriptions/:id',
        //   component: PrescriptionDetails,
        //   // meta: { auth: true },
        // },
        // {
        //   name: 'edit-prescription',
        //   path: 'edit-prescription/:id',
        //   component: EditPrescription,
        //   // meta: { auth: true },
        // },

      ],
    },]