import importerStore from '@/shared/importer/importer-store';
import { DrugIndexService } from '@/modules/drugIndex/drugIndex-service';
import DrugIndexImporterFields from '@/modules/drugIndex/drugIndex-importer-fields';
import { i18n } from '@/i18n';
// import { date } from 'yup';
export default importerStore(
    DrugIndexService.import,
    DrugIndexImporterFields,
    i18n('drugDeal.drugIndex.importer.fileName'),
    i18n('drugDeal.drugIndex.importer.hint'),
  // date,
);
