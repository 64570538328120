//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
// import { i18n, getLanguageCode } from '@/i18n';
// import firebase from 'firebase/app'
import i18n from '@/vueI18n'
export default {
  name: 'app-property-view-modal',

  props: ['visible', 'id', 'record'],

  data() {
    return {
      noImage:false,
      // tab: getLanguageCode() == 'ar' ? 'arabic' : 'english',
      // language: getLanguageCode(),
      dialogType:undefined,
      modalVisible:false,
      dialogDeleteVisible:false,
      selectedRow: null,
      selectedId: null,

    };
  },

  computed: {
    isRTL() {
      // return getLanguageCode() == 'ar'
      // return localStorage.getItem('language') == 'ar'
      return i18n.locale == 'ar'
    },
    language(){
      return i18n.locale
    },
    ...mapGetters({
      // record: 'job/form/record',
      // findLoading: 'job/form/findLoading',
      saveLoading: 'property/form/saveLoading',
      facilityTypes: 'property/view/facilityTypes'
    }),
    dialogVisible: {
      get: function() {
        return this.visible;
      },

      set: function(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    // tab() {
    //   return getLanguageCode() == 'ar' ? 'english' : 'arabic'
    // }
  },
  
  async created() {
    this.record['facilityType'] = this.facilityTypes.find(el => el.id == this.record.typeId).name

    if(Object.keys(this.record.images).length > 1) {
            this.noImage = false
        }
        else {
            this.noImage = true
        }
  },

  methods: {
    ...mapActions({
      doFind: 'job/form/doFind',
      doNew: 'job/form/doNew',
      doUpdate: 'property/form/doUpdate',
      doCreate: 'job/form/doCreate',
      doGeneratePinCode: 'job/form/doGeneratePinCode',
    }),
    // i18n(code, args) {
    //   return i18n(code, args)
    // },
    // async doAcceptJob(){
    //   let id = this.record.id
    //   let job = Object.assign({}, this.record);
    //     job.postStatus = "accepted"
    //     delete job.id
    //     delete job.workplace
    //     delete job.position
    //     delete job.city
    //     delete job.region
    //     delete job.createdAtUpdated
    //     console.log('job',job)
    //     await this.doUpdate({id, values:job})
    //     if(!this.saveLoading){
    //       this.close()
    //     }
    // },
    onModalOpen(row, operation) {
      this.selectedRow = row;
      this.selectedId = row.id;
      switch (operation) {
        case 'delete':
          this.dialogType = 'delete'
          break;
        case 'edit':
          this.dialogType = 'edit'
          break;
        case 'view':
          this.dialogType = 'view'
          break;  
        default:
          this.dialogType = undefined
          break;
      }
      this.modalVisible = true
      this.dialogDeleteVisible = true
    },
    async doDestroyWithConfirm({id, rejectionReason}) {
      try {
        let updatedProperty = Object.assign({}, this.selectedRow);
          updatedProperty.createdAt = new Date(updatedProperty.createdAt.seconds * 1000)
          updatedProperty.updatedAt = new Date(updatedProperty.updatedAt.seconds * 1000)
          updatedProperty.postStatus = "destroyed"
          updatedProperty.rejectionReason = rejectionReason
          delete updatedProperty.id
          delete updatedProperty.facilityType
          delete updatedProperty.city
          delete updatedProperty.region
          delete updatedProperty.facilityListedFor
          await this.doUpdate({id,values:updatedProperty})
          this.onModalClose();
          if(!this.saveLoading){
          this.close()
        }
      } catch (error) {
        // no
      }
    },
    onModalClose() {
      this.dialogDeleteVisible = false;
      setTimeout(() => {
        this.modalVisible = false;
        this.dialogType = undefined
      }, 500);
    },
    onModalSuccess() {
      this.onModalClose();
    },
    async doAcceptProperty() {
      try {
        // let updatedProperty = row
        let id = this.record.id
        let updatedProperty = Object.assign({}, this.record);
          updatedProperty.createdAt = new Date(updatedProperty.createdAt.seconds * 1000)
          updatedProperty.updatedAt = new Date(updatedProperty.updatedAt.seconds * 1000)
          updatedProperty.postStatus = "accepted"
          updatedProperty.rejectionReason = null
          delete updatedProperty.id
          delete updatedProperty.facilityType
          delete updatedProperty.city
          delete updatedProperty.region
          delete updatedProperty.facilityListedFor
          await this.doUpdate({id,values:updatedProperty})
          if(!this.saveLoading){
          this.close()
        }
        // this.onModalClose();
      } catch (error) {
        // no
      }
    },
    i18n(key, args) {
      return this.$t(key, args);
    },
    doCancel() {
      this.dialogVisible = false;
    },
    doChangeLanguage() {
      this.tab == 'arabic'
        ? this.language = 'ar'
        : this.language = 'en'
    },
    close(){
        this.$emit('close')
    }
  },
};
