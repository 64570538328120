import { DrugIndexService } from '@/modules/drugIndex/drugIndex-service';
// import customerListExporterFields from '@/modules/customer/customer-list-exporter-fields';
// import Exporter from '@/shared/exporter/exporter';
import Errors from '@/shared/error/errors';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/database';
import { 
  vuexfireMutations,
  firestoreAction, 
  // firebaseAction 
} from 'vuexfire';

const INITIAL_PAGE_SIZE = 10;

export default {
  namespaced: true,

  state: {
    rows: [],
    count: 0,
    currentPage: 1,
    rowsFromRoute: [],
    loading: false,
    loadingCurrent: false,
    loadingNew: false,
    filter: {},
    pagination: {
      sortBy: 'desc',
      descending: false,
      page: 1,
      // rowsPerPage: this.$q.screen.width == 1024 ? 14 : 7
      rowsPerPage: 10
    },
    sorter: {},
    limit: {},
    table: null,
    drugs: [],
    selectedRows: [],
    oldFilter: '',
    drugsNew: [],
    loadMoreLastDrug: null,
    loadFirstDrug: null,
    recordBeforeFirst: [],
    recordAfterLast: [],
    mountedTable: false,
    searchFilter: '',
    drugIndexUpdatesSettings: {},
    drugIndexCurrentSettings: {},
    unbindDrugs: false,
    drugIndexVersion: 0,
    fileUploaded: null,
    startPublish: false,
  },

  getters: {
    fileUploaded: (state) => state.fileUploaded,
    startPublish: (state) => state.startPublish,
    loading: (state) => state.loading,
    loadingCurrent: (state) => state.loadingCurrent,
    loadingNew: (state) => state.loadingNew,
    currentPage: (state) => state.currentPage,
    mountedTable: (state) => state.mountedTable,
    exportLoading: (state) => state.exportLoading,
    drugIndexVersion: (state) => state.drugIndexVersion,
    rows: (state) => state.rows || [],
    rowsFromRoute: (state) => state.rowsFromRoute || [],
    count: (state) => state.count,
    hasRows: (state, getters) => getters.count > 0,
    orderBy: (state) => {
      const sorter = state.sorter;

      if (!sorter) {
        return null;
      }

      if (!sorter.prop) {
        return null;
      }

      let direction =
        sorter.order === 'descending' ? 'DESC' : 'ASC';

      return `${sorter.prop}_${direction}`;
    },
    filter: (state) => state.filter,
    limit: (state) => state.limit,
    // limit: (state) => {
    //   const pagination = state.pagination;

    //   if (!pagination || !pagination.pageSize) {
    //     return INITIAL_PAGE_SIZE;
    //   }

    //   return pagination.pageSize;
    // },
    offset: (state) => {
      const pagination = state.pagination;

      if (!pagination || !pagination.pageSize) {
        return 0;
      }

      const currentPage = pagination.currentPage || 1;

      return (currentPage - 1) * pagination.pageSize;
    },
    pagination: (state, getters) => {
      return {
        ...state.pagination,
        total: getters.count,
        showSizeChanger: true,
      };
    },
    selectedRows: (state) => state.selectedRows,
    drugs: (state) => state.drugs,
    oldFilter: (state) => state.oldFilter,
    drugsNew: (state) => state.drugsNew,
    loadMoreLastDrug: (state) => {
      return state.loadMoreLastDrug
    },
    loadFirstDrug: (state) =>{
      return state.loadFirstDrug
    },
    recordBeforeFirst: (state) => {
      return state.recordBeforeFirst
    },
    recordAfterLast: (state) => {
      return state.recordAfterLast
    },
    searchFilter: (state)=> state.searchFilter,
    drugIndexUpdatesSettings: (state) => state.drugIndexUpdatesSettings,
    drugIndexCurrentSettings: (state) => state.drugIndexCurrentSettings,
    unbindDrugs: (state) => state.unbindDrugs
  },

  mutations: {
    RESETED(state) {
      state.rows = [];
      state.count = 0;
      state.loading = false;
      state.filter = {};
      state.pagination = {};
      state.sorter = {};
      if (state.table) {
        state.table.clearSelection();
      }
    },
    UNSELECT_ALL(state) {
      if (state.table) {
        state.table.clearSelection();
      }
    },
    TABLE_MOUNTED(state, payload) {
      state.table = payload;
    },
    PAGINATION_CHANGED(state, payload) {
      state.pagination = payload || {};
    },
    PAGINATION_CURRENT_PAGE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: payload || 1,
        pageSize: previousPagination.pageSize || INITIAL_PAGE_SIZE,
      };
    },
    PAGINATION_PAGE_SIZE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: previousPagination.currentPage || 1,
        pageSize: payload || INITIAL_PAGE_SIZE,
      };
    },
    SORTER_CHANGED(state, payload) {
      state.sorter = payload || {};
    },

    
    // FETCH_CURRENT_STARTED(state) {
    //   // console.log('fetch current started')
    //   state.loadingCurrent = true;
    // },
    
    

    FETCH_STARTED(state, payload) {
      state.loading = true;
      if (state.table) {
        state.table.clearSelection();
      }

      state.filter = payload && payload.filter ? payload.filter : {};
      state.pagination = payload && payload.keepPagination
        ? state.pagination
        : {};
    },
    FETCH_SUCCESS(state, payload) {
      state.loading = false;
      state.rows = payload.rows;
      state.count = payload.count;
    },
    FETCH_ERROR(state) {
      state.loading = false;
      state.rows = [];
      state.count = 0;
    },

    FETCH_NEW_STARTED(state) {
      state.loadingNew = true;
    },
    FETCH_NEW_SUCCESS(state) {
      state.loadingNew = false;
    },
    
    // EXPORT_STARTED(state) {
    //   state.exportLoading = true;
    // },
    // EXPORT_SUCCESS(state) {
    //   state.exportLoading = false;
    // },
    // EXPORT_ERROR(state) {
    //   state.exportLoading = false;
    // },

    SET_LIMIT(state, payload) {
      state.limit = payload
    },
    SET_SEARCH(state,payload){
      state.searchFilter = payload
      // value.company.toString().toLowerCase().includes(this.search.toLowerCase())
      state.drugs = state.drugs.filter((el) => el.drugName.toString().toLowerCase().includes(payload.toLowerCase()))
      // console.log('state drugs after',state.drugs)
    },
    SET_MOUNTEDTABLE(state,payload){
      state.mountedTable = payload
    },
    APPEND_TO_DRUGS_CURRENT(state,payload){
      if(payload.docType == "added"){
        state.drugs.push(payload.doc)
      }
      if(payload.docType == "removed"){
        state.drugs = state.drugs.filter((el) => el.drugName.toString().toLowerCase() !== payload.doc.drugName.toString().toLowerCase())
      }
      if(payload.docType == "modified"){
        var foundIndex = state.drugs.findIndex((el) => el.drugName.toString().toLowerCase() == payload.doc.drugName.toLowerCase());
        Object.assign(state.drugs[foundIndex], payload.doc)
      }
    },
    APPEND_TO_DRUGS_NEW(state,payload){
      if(payload.docType == "added"){
        state.drugsNew.push(payload.doc)
      }
      if(payload.docType == "removed"){
        state.drugsNew = state.drugsNew.filter((el) => el.drugName.toString().toLowerCase() !== payload.doc.drugName.toLowerCase())
      }
      if(payload.docType == "modified"){
        var foundIndex = state.drugsNew.findIndex((el) => el.drugName.toString().toLowerCase() == payload.doc.drugName.toLowerCase());
        Object.assign(state.drugsNew[foundIndex], payload.doc)
      }
    },

    ...vuexfireMutations,
    SET_LOADMORE_LAST(state) {
      state.loadMoreLastDrug = state.drugs[state.drugs.length - 1]._doc
      state.recordAfterLast = state.drugs[state.drugs.length - 1]
    },
    SET_LOADFIRSTDRUG(state){
      state.loadFirstDrug = state.drugs[0]._doc
    },
    RESET_CURSOR(state){
      state.loadMoreLastDrug = null
      state.loadFirstDrug = null
    },
    SET_OLDFILTER(state,payload){
      state.oldFilter = payload
    },
    SELECT_ROW(state, payload){
      let drug = state.drugs.find((drug) => drug.id == payload.id)
      drug.selected = payload.selected
    },
    PUSH_IN_SELECTED_ROWS(state,payload){
      state.selectedRows.push(payload)
    },
    REMOVE_FROM_SELECTED_ROWS(state,payload){
      state.selectedRows = state.selectedRows.filter(el => el != payload)
    },
    SET_ROWS(state, payload){
      state.drugs = payload
    },
    SET_UNBIND_DRUGS(state, payload){
      state.unbindDrugs = payload
    },

    UPDATE_DRUG_INDEX_VERSION(state, payload) {
      state.drugIndexVersion = payload
    },
    UPDATE_DRUG_INDEX_FILE(state, payload) {
      state.fileUploaded = payload
    },
    CHANGE_START_PUBLISH_STATUS(state, payload) {
      state.startPublish = payload
    },
    FETCH_FROM_ALGOLIA_STARTED(state) {
      state.loading = true
    },
    FETCH_FROM_ALGOLIA_SUCCESS(state, payload) {
      state.rows = payload.rows
      state.count = payload.count
      state.loading = false
    },
    FETCH_FROM_ALGOLIA_ERROR(state) {
      state.loading = false
    },
    CURRENT_PAGE_CHANGED(state, payload) {
      state.currentPage = payload || 1;
    },
  },

  actions: {
    async runListenerForCurrentVersion({ commit }) {
      DrugIndexService.createRealTimeListener('setting/drugIndexVersion', (value) => {
        commit('UPDATE_DRUG_INDEX_VERSION', value)
      })
      DrugIndexService.createRealTimeListener('setting/file-details', (value) => {
        commit('UPDATE_DRUG_INDEX_FILE', value)
      })
      DrugIndexService.createRealTimeListener('setting/startPublish', (value) => {
        commit('CHANGE_START_PUBLISH_STATUS', value)
      })
    },
    changeCurrentPage({ commit }, value) {
      commit('CURRENT_PAGE_CHANGED', value)
    },
    /**
     * Fetch drugs from algolia
     * @param {*} param0 
     * @param {Object} query 
     * @param {Number} query.pageSize 
     * @param {'current'|'next'|'prev'} query.actions 
     */
    async doFetchFromAlgolia({ commit, getters, dispatch }, { 
      pageSize = INITIAL_PAGE_SIZE, 
      action = 'current' 
    }) {
      try {
        commit('FETCH_FROM_ALGOLIA_STARTED')
        switch (action) {
          case 'next':
            dispatch('changeCurrentPage', getters.currentPage + 1)
            break;
          case 'prev':
            dispatch('changeCurrentPage', getters.currentPage - 1)
            break;
          case 'current':
            
            break;
          default:
            break;
        }
        const PAGE_SIZE = pageSize || INITIAL_PAGE_SIZE
        const page = getters.currentPage
        const last = PAGE_SIZE * page
        const first = last - PAGE_SIZE

        const response = await DrugIndexService.listFromAlgolia({
          query: '',
          startAt: first,
          endAt: last,
        })
        
        commit('FETCH_FROM_ALGOLIA_SUCCESS', {
          rows: response,
          count: response.length,
        })
      } catch (error) {
        Errors.handle(error);
        commit('FETCH_FROM_ALGOLIA_ERROR')
      }
    },

    setSearch( { commit },value){
      commit('SET_SEARCH',value)
    },
    setMountedTable({commit},value){
      commit('SET_MOUNTEDTABLE',value)
    },
    setUnbindDrugs({commit}, payload){
      commit('SET_UNBIND_DRUGS',payload)
    },
    setRows({ commit }, payload){
      commit('FETCH_STARTED')
      commit('SET_ROWS',payload)
      commit('FETCH_SUCCESS')
    },
    setLoadMoreLast({commit}){
      commit('SET_LOADMORE_LAST')
    },
    resetCursor({commit}){
      commit('RESET_CURSOR')
    },
    setLoadFirstDrug({commit}){
      commit('SET_LOADFIRSTDRUG')
    },
    setOldFilter({commit},payload){
      commit('SET_OLDFILTER',payload)
    },
    setSelectRow({commit},id){
      commit('SELECT_ROW',id)
    },
    pushInSelectedRows({commit},id){
      commit('PUSH_IN_SELECTED_ROWS',id)
    },
    removeFromSelectedRows({commit},id){
      commit('REMOVE_FROM_SELECTED_ROWS',id)
    },
    async doFetchDrugIndexNew({ commit }) {
      try {
         await DrugIndexService.listDrugIndexNew((doc, docType) => {
          commit('APPEND_TO_DRUGS_NEW', { 
            doc,
            docType 
          })
          commit('FETCH_NEW_SUCCESS');
        })
      } catch (error) {
        Errors.handle(error);
        commit('FETCH_ERROR');
      }
    },
    doFetch: firestoreAction(async ({bindFirestoreRef,unbindFirestoreRef,getters,commit},payload) => {
        commit('FETCH_STARTED')

        const customSerializer = (doc) => {
          const data = doc.data()
          Object.defineProperty(data, '_doc', { value: doc })
          // Object.defineProperty(data, 'selected', { value: false })
          data['selected'] = false

          return data
        }
        const db = firebase.firestore()
        var drugIndexCollection = null
        if(payload.versionTab == 'currentVersion'){
          switch(payload.searchBy){
            case 'drugName':
                drugIndexCollection = getters.loadMoreLastDrug ? 
                  db.collection('drugIndexCurrent')                  
                  // .where('status', 'in', ['added','modified'])
                  .orderBy('drugName','asc')
                  .limit(payload.limit || 10)
                  .startAt(payload.filter)
                  .endAt(payload.filter + "\uf8ff")
                  .startAfter(getters.loadMoreLastDrug) :
                  db.collection('drugIndexCurrent')
                  // .where('status', 'in', ['added','modified'])
                  .orderBy('drugName','asc')
                  .startAt(payload.filter)
                  .endAt(payload.filter + "\uf8ff")
                  .limit(payload.limit || 10)
              break;
            case 'company':
              break;
            case 'genericName':
              break;
          }
        }

        if(payload.versionTab == 'newVersion'){
          // console.log('loadMoreLastDrug',getters.loadMoreLastDrug);
          // drugIndexCollection = getters.loadMoreLastDrug ? 
          // db.collection('drugIndexNew')
          // .limit(payload.limit || 10)
          // .orderBy('drugName','asc')
          // .startAfter(getters.loadMoreLastDrug) :
          // db.collection('drugIndexNew')
          // .orderBy('drugName','asc')
          // .limit(payload.limit || 10)
          switch(payload.searchBy){
            case 'drugName':
                drugIndexCollection = getters.loadMoreLastDrug ? 
                db.collection('drugIndexUpdates')
                // .where('status', 'in', ['added','modified'])
                .orderBy('drugName','asc')
                .limit(payload.limit || 10)                
                .startAt(payload.filter)
                .endAt(payload.filter + "\uf8ff")
                .startAfter(getters.loadMoreLastDrug) :
                db.collection('drugIndexUpdates')
                // .where('status', 'in', ['added','modified'])
                .orderBy('drugName','asc')
                .limit(payload.limit || 10)
                .startAt(payload.filter)
                .endAt(payload.filter+"\uf8ff")
              break;
            case 'company':
              break;
            case 'genericName':
              break;
          }
        }
      
        await bindFirestoreRef(
          'drugs',
          drugIndexCollection,
          { serialize: customSerializer }
        );
          if(getters.drugs.length == 0){
            commit('SET_UNBIND_DRUGS',true)
            unbindFirestoreRef('drugs')
          }
         
        commit('FETCH_SUCCESS')      
    }),
    doFetchFromRoute: firestoreAction(async ({bindFirestoreRef,unbindFirestoreRef,getters,commit},payload) => {
      commit('FETCH_STARTED')
      const customSerializer = (doc) => {
        const data = doc.data()
        Object.defineProperty(data, '_doc', { value: doc })
        data['selected'] = false

        return data
      }
      const db = firebase.firestore()
      var drugIndexCollection = null
      // const startAt = payload.page * payload.limit - payload.limit
      const endAt = payload.page * payload.limit
      // .startAt(payload.filter)
      // .endAt(payload.filter + "\uf8ff")    
      if(payload.versionTab == 'currentVersion'){
        drugIndexCollection = db.collection('drugIndexCurrent')
          // .where('status', 'in', ['added','modified'])
          .orderBy('drugName','asc')
          .startAt(payload.filter)
          .endAt(payload.filter + "\uf8ff")
          .limit(endAt)
      }
      if(payload.versionTab == 'newVersion'){
        drugIndexCollection = db.collection('drugIndexUpdates')
          // .where('status', 'in', ['added','modified'])
          .orderBy('drugName','asc')
          .limit(endAt)
          .startAt(payload.filter)
          .endAt(payload.filter+"\uf8ff")
      }
      await bindFirestoreRef(
        'rowsFromRoute',
        drugIndexCollection,
        { serialize: customSerializer }
      );
      if(getters.rowsFromRoute.length == 0){
        commit('SET_UNBIND_DRUGS',true)
        unbindFirestoreRef('rowsFromRoute')
      }
      
      
      commit('FETCH_SUCCESS')    
    }),
    getPreviousDrugsBatch: firestoreAction(async ({bindFirestoreRef,getters},payload) => {
      const customSerializer = (doc) => {
        const data = doc.data()
        Object.defineProperty(data, '_doc', { value: doc })
        data['selected'] = false
        
        return data
      }
      const db = firebase.firestore()
      var drugIndexCollection = null
      if(payload.versionTab == 'currentVersion') {
        switch (payload.searchBy){
          case 'drugName':
            drugIndexCollection = db.collection('drugIndexCurrent')
              // .where('status', 'in', ['added','modified'])
              .orderBy('drugName','asc')
              .startAt(payload.filter)
              .endAt(payload.filter + "\uf8ff")
              .endBefore(getters.loadFirstDrug)
              .limitToLast(payload.limit || 10)
            break
          case 'company':
            break;
          case 'genericName':
            break;
        }
      }
      if(payload.versionTab == 'newVersion') {
        // drugIndexCollection =  db.collection('drugIndexNew')
        // .orderBy('drugName','asc')
        // .endBefore(getters.loadFirstDrug)
        // .limitToLast(payload.limit || 10)
        switch (payload.searchBy){
          case 'drugName':
            drugIndexCollection = db.collection('drugIndexUpdates')
              .orderBy('drugName','asc')
              .startAt(payload.filter)
              .endAt(payload.filter + "\uf8ff")
              .endBefore(getters.loadFirstDrug)
              .limitToLast(payload.limit || 10)
            break
          case 'company':
            break;
          case 'genericName':
            break;
        }
      } 
      await bindFirestoreRef(
          'drugs',
          drugIndexCollection
          ,
          { serialize: customSerializer }
        )
      
    }),
    setDisableNext: firestoreAction(async ({getters, bindFirestoreRef}, payload) => {
      const db = firebase.firestore()
      var drugIndexCollection = null
      if(payload.versionTab == 'currentVersion'){
        // drugIndexCollection = db.collection('drugIndexCurrent')
        //   .orderBy('drugName', 'asc')
        //   .limit(1)
        //   .startAfter(getters.loadMoreLastDrug) 
      switch (payload.searchBy){
        case 'drugName':
          drugIndexCollection = db.collection('drugIndexCurrent')
            // .where('status', 'in', ['added','modified'])
            .orderBy('drugName','asc')
            .startAt(payload.filter)
            .endAt(payload.filter + "\uf8ff")
            .startAfter(getters.loadMoreLastDrug)
            .limit(1)
          break
      }
      }
      if(payload.versionTab == 'newVersion'){
        // drugIndexCollection = db.collection('drugIndexNew')
        //   .orderBy('drugName', 'asc')
        //   .limit(1)
        //   .startAfter(getters.loadMoreLastDrug) 
        switch (payload.searchBy){
          case 'drugName':
            drugIndexCollection = db.collection('drugIndexUpdates')
              .orderBy('drugName','asc')
              .startAt(payload.filter)
              .endAt(payload.filter + "\uf8ff")
              .startAfter(getters.loadMoreLastDrug)
              .limit(1)
            break
        }
      }
      
      await bindFirestoreRef(
          'recordAfterLast',
          drugIndexCollection
        )

    }),
    setDisablePrevious: firestoreAction(async ({getters, bindFirestoreRef}, payload) => {
      const db = firebase.firestore()
      var drugIndexCollection = null
      if(payload.versionTab == 'currentVersion'){
        // drugIndexCollection = db.collection('drugIndexCurrent')
        //   .orderBy('drugName','asc')
        //   .endBefore(getters.loadFirstDrug)
        //   .limitToLast(1) 
        switch (payload.searchBy){
            case 'drugName':
              drugIndexCollection = db.collection('drugIndexCurrent')
                // .where('status', 'in', ['added','modified'])
                .orderBy('drugName','asc')
                .startAt(payload.filter)
                .endAt(payload.filter + "\uf8ff")
                .endBefore(getters.loadFirstDrug)
                .limitToLast(1) 
              break
        }
      }
      if(payload.versionTab == 'newVersion'){
      //   drugIndexCollection = db.collection('drugIndexNew')
      //     .orderBy('drugName','asc')
      //     .endBefore(getters.loadFirstDrug)
      //     .limitToLast(1) 
      switch (payload.searchBy){
        case 'drugName':
          drugIndexCollection = db.collection('drugIndexUpdates')
            .orderBy('drugName','asc')
            .startAt(payload.filter)
            .endAt(payload.filter + "\uf8ff")
            .endBefore(getters.loadFirstDrug)
            .limitToLast(1) 
          break
        }
      }     
      await bindFirestoreRef(
          'recordBeforeFirst',
          drugIndexCollection
        )

    }),
    doFetchDrugIndexUpdatesSettings: firestoreAction(async ({ bindFirestoreRef}) => {
      const db = firebase.firestore()
      const drugIndexUpdatesSettingsCollection = db.collection("settings").doc('drugIndexUpdates')
      await bindFirestoreRef(
        'drugIndexUpdatesSettings',
        drugIndexUpdatesSettingsCollection
      )
    }),
    doFetchDrugIndexCurrentSettings: firestoreAction(async ({ bindFirestoreRef}) => {
      const db = firebase.firestore()
      const drugIndexCurrentSettingsCollection = db.collection("settings").doc('drugIndexCurrent')
      await bindFirestoreRef(
        'drugIndexCurrentSettings',
        drugIndexCurrentSettingsCollection
      )
    }),
  },
};