//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { UserModel } from '@/modules/auth/user-model';
// import { i18n } from '@/i18n';

const { fields } = UserModel;

export default {
  name: 'app-signin-page',

  data() {
    return {
      rules: {
        email: fields.email.forFormRules(),
        password: fields.password.forFormRules(),
        rememberMe: fields.rememberMe.forFormRules(),
      },
      model: {
        rememberMe: true,
        password: '',
      },
      isPwd: true,

      emailEnter: false,
      passwordEnter: false,
      validPassword: false,
      validEmail: false,
      typePassword: true,
    };
  },

  computed: {
    ...mapGetters({
      loading: 'auth/loading',
      loadingSocial: 'auth/loadingSocial',
      isMobile: 'layout/is_screen_xs',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',

      // registerError: 'auth/registerError',
      // signinError: 'auth/signinError',
    }),

    fields() {
      return fields;
    },
    getProgress() {
      let progress = 0

      // at least one number
      if (/\d/.test(this.model.password)) {
        progress += 20
      }

      // at least one capital letter
      if (/(.*[A-Z].*)/.test(this.model.password)) {
        progress += 20
      }

      // at menons a lowercase
      if (/(.*[a-z].*)/.test(this.model.password)) {
        progress += 20
      }

      // more than 5 digits
      if (this.model.password.length >= 6) {
        progress += 20
      }

      // at least one special character
      if (/[^A-Za-z0-9]/.test(this.model.password)) {
        progress += 20
      }

      return progress
    }
  },

//  mounted(){
//         this.language = localStorage.getItem('language')
//         this.$q.iconSet.field.error = "img:/images/invalid-email.png"
//         if(this.register =='login')
//             this.login = true
//         else
//             this.login = false
//     },
  methods: {
    ...mapActions({
      doSigninSocial: 'auth/doSigninSocial',
      doSigninWithEmailAndPassword: 'auth/doSigninWithEmailAndPassword',
    }),
    // i18n(code, args) {
    //   return i18n(code, args);
    // },
    i18n(key, args) {
      return this.$t(key, args);
    },
    validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      if(re.test(String(email).toLowerCase())){
        this.validEmail = true
        return true
      } else{
        this.validEmail = false
        return false
      }  
    },
    validatePassword(password) {
      if(password.length >= 6){
        this.validPassword = true
        return true
      } else{
        this.validPassword = false
        return false
      }  
    },

    async doSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }

      await this.doSigninWithEmailAndPassword({
        email: this.model.email,
        password: this.model.password,
        rememberMe: this.model.rememberMe,
      });
      
      this.$router.push(`/${localStorage.getItem('language')}`)
      .catch((err) => {
        throw new Error(`Problem handling something: ${err}.`);
    });
    },
  },
};
