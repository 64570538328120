import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/iam/permission-checker';

export class JobPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.jobRead,
    );
    this.import = permissionChecker.match(
      Permissions.values.jobImport,
    );
    this.jobAutocomplete = permissionChecker.match(
      Permissions.values.jobAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.jobCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.jobEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.jobDestroy,
    );
  }
}
