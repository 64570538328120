//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import OfferForm from '@/modules/offer/components/offer-form';

import { mapGetters, mapActions } from 'vuex';
import { EventBus } from '@/event-bus.js';
// import { i18n } from '@/i18n';

export default {
  name: 'app-offer-form-modal',

  props: ['visible', 'id'],

  components: {
    [OfferForm.name]: OfferForm,
  },

  data() {
    return {
      // successDialog: true,
      operation: undefined,
      pinCode: undefined,
    };
  },

  computed: {
    ...mapGetters({
      record: 'offer/form/record',
      findLoading: 'offer/form/findLoading',
      saveLoading: 'offer/form/saveLoading',
      pinCodeLoading: 'offer/form/pinCodeLoading',
    }),
    dialogVisible: {
      get: function() {
        return this.visible;
      },

      set: function(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    isEditing() {
      return !!this.id;
    },
    isAddOfferManuel() {
      return this.operation == 'addOffer' || this.offerType == 'offer'
    },
    isGeneratePin() {
      return this.operation == 'generatePin'
    },
  },
  
  // async created() {
  //   if (this.isEditing) {
  //     await this.doFind(this.id);
  //   } else {
  //     await this.doNew();
  //   }
  // },
  async mounted() {
    if (this.isEditing) {
      await this.doFind(this.id);
    } else {
      await this.doNew();
    }
  },

  methods: {
    ...mapActions({
      doFind: 'offer/form/doFind',
      doNew: 'offer/form/doNew',
      doUpdate: 'offer/form/doUpdate',
      doCreate: 'offer/form/doCreate',
      doFetch: 'offer/list/doFetch',
      resetCursor:'offer/list/resetCursor',
      doGeneratePinCode: 'offer/form/doGeneratePinCode',
    }),
    // i18n(code) {
    //   return i18n(code)
    // },
     i18n(key, args) {
      return this.$t(key, args);
    },
    doCancel() {
      this.dialogVisible = false;
    },

    async doGenerate() {
      // this.pinCode = await this.doGeneratePinCode()
      this.operation = 'generatePin'
    },
    async doSubmit(payload) {
      if (this.isEditing) {
        await this.doUpdate(payload);
        this.$emit('close');
      } 
      else {
        const isCreated = await this.doCreate(payload.values);
         
        this.$emit('close');
        if (isCreated) {
          await this.resetCursor()
          // await this.doFetch({limit: 10,searchBy: 'name',filter:''})
          let language = this.isRTL ? 'ar' : 'en'
          await this.doFetch({limit: 10,searchBy: 'title',filter: '', language})
          if(this.$route.query.page != 1 && !this.$route.params.filter){
            this.$router.push({
              query: {
                page: 1
              }
            })
            this.$emit('success');
            EventBus.$emit('resetPage')
          }
          
        }
      }
    },
  },
};
