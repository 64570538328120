import { render, staticRenderFns } from "./setting-form-modal.vue?vue&type=template&id=24c2f999&scoped=true&"
import script from "./setting-form-modal.vue?vue&type=script&lang=js&"
export * from "./setting-form-modal.vue?vue&type=script&lang=js&"
import style0 from "./setting-form-modal.vue?vue&type=style&index=0&id=24c2f999&lang=scss&scoped=true&"
import style1 from "./setting-form-modal.vue?vue&type=style&index=1&id=24c2f999&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24c2f999",
  null
  
)

export default component.exports
import {QDialog,QCard,QCardSection,QSpinnerIos,QForm,QInput,QBtn,QField} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QDialog,QCard,QCardSection,QSpinnerIos,QForm,QInput,QBtn,QField})
