//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { OfferModel } from '@/modules/offer/offer-model';
import { OfferPermissions } from '@/modules/offer/offer-permissions';
// import { i18n, getLanguageCode } from '@/i18n';
import moment from 'moment';

import TableViewPage from '@/shared/components/table-view-page.vue';
import OfferFormModal from '@/modules/offer/components/offer-form-modal.vue';
import PropertyViewModal from '@/modules/property/components/property-view-modal.vue';
// import firebase from 'firebase/app'

const { fields } = OfferModel;
import i18n from '@/vueI18n'
export default {
  name: 'app-city-list-table',

  components: {
    [TableViewPage.name]: TableViewPage,
    [OfferFormModal.name]:OfferFormModal,
    [PropertyViewModal.name]:PropertyViewModal,
  },

  

  data() {
    return {
      filter: '',
      modalVisible: false,
      rowsToBeDisplayed: [],
      columns: [       
        { name: 'city', align: 'center', label: 'city', field: 'city',},
      ],
      // rows:[],
      // language: getLanguageCode(),
      dialogVisible: false,
      dialogType: undefined,
      selectedRow: null,
      selectedId: null,
      pageSize: 5,
      nextPage: 2,
      pagination: {
        page: 1,
        rowsPerPage: 10,
        // sortBy: 'desc',
        // descending: false,
        // rowsNumber: xx if getting data from a server
      },
      // page: 1
    };
  },
  computed: {
     isRTL() {
      // return getLanguageCode() == 'ar'
      // return localStorage.getItem('language') == 'ar'
      return i18n.locale == 'ar'
    },
    ...mapGetters({
      rows: 'citiesRegions/list/rows',
      count: 'citiesRegions/list/count',
      // pagination: 'offer/list/pagination',
      // paginationLayout: 'layout/paginationLayout',
      loading: 'citiesRegions/list/loading',
      backgroundLoading: 'citiesRegions/list/backgroundLoading',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      destroyLoading: 'offer/destroy/loading',
      is_screen_xs: 'layout/is_screen_xs',
      regionsOfCity: 'citiesRegions/list/regionsOfCity',

    }),

    hasPermissionToEdit() {
      return new OfferPermissions(this.currentUser).edit;
    },
    hasPermissionToDestroy() {
      return new OfferPermissions(this.currentUser).destroy;
    },
    fields() {
      return fields;
    },
    pagesNumber () {
      return Math.ceil(this.rows.length / this.pagination.rowsPerPage)
    }
  },
  watch:{
    // filter(newval){
    //   console.log('filter newval',newval);
        
    //   if(this.$route.query.filter != newval && newval != ''){
    //     this.changeRouteFilter(newval)
    //   }
    //   if(newval == ''){
    //     let query = Object.assign({}, this.$route.query);
    //     delete query.filter;
    //     console.log('query',query);
    //     this.$router.replace({ query })
    //     // this.removeSearch()
    //   }
    // },
    pagination(newval){
      if(this.$route.query.page != newval.page){
        this.changeRoutePage(newval.page)
      }
    },
    // regionsOfCity(newval){
    // },
    rows(newval){
      this.rowsToBeDisplayed = newval  
    },

  },

  methods: {
    ...mapActions({
      doChangeSort: 'offer/list/doChangeSort',
      doChangePaginationCurrentPage: 'offer/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'offer/list/doChangePaginationPageSize',
      doMountTable: 'offer/list/doMountTable',
      doDestroy: 'offer/destroy/doDestroy',
   
    }),
    // i18n(code, args) {
    //   return i18n(code, args)
    // },
    i18n(key, args) {
      return this.$t(key, args);
    },
    searchInCity(){
      this.pagination.page = 1
      let query = {}
      

      if(this.filter != ''){
        query['filter'] = this.filter
      }
      query['page'] = 1
      this.$router.push({
        query
      })

      if(!this.isRTL){
        this.rowsToBeDisplayed = this.rows.filter(el => el.name.en.toLowerCase().includes(this.filter.toLowerCase()))
      }
      else{
        this.rowsToBeDisplayed = this.rows.filter(el => el.name.ar.toLowerCase().includes(this.filter.toLowerCase()))
      }
    },
    removeSearch(){
      this.filter = ''
      this.rowsToBeDisplayed = this.rows
      // let query = Object.assign({}, this.$route.query);
      // delete query.filter;
      // console.log('query',query);
      // this.$router.replace({ query })
    },
    presenter(row, fieldName) {
      const val = OfferModel.presenter(row, fieldName);
      return val ? val : 'ـــــــ';
    },
    presenterEnum(row, fieldName) {
      const options = this.fields[fieldName].options;
      const val = options.filter(el => el.value == row[fieldName])
      return val.length ? val[0].label : 'ـــــــ';
    },
    presenterMap(row, fieldName) {
      const val = OfferModel.presenter(row, fieldName);
      return val[this.language] ? val[this.language] : null
    },
    presenterDate(row, fieldName) {
      const val = OfferModel.presenter(row, fieldName)
      return val ? moment(val).locale(this.language).format("D MMM, YYYY") : 'ـــــــ'; 
    },
    presenterDay(row, fieldName) {
      return moment(OfferModel.presenter(row, fieldName)).locale(this.language).format("dddd");
    },
    presenterTime(row, fieldName) {
      let now = moment().format('YYYY/MM/DD');
      let time = OfferModel.presenter(row, fieldName)
      let date =  `${now} ${time}`;
      return moment(date).locale(this.language).format("hh:mm a"); 
    },

    onModalOpen(row, operation) {
      this.selectedRow = row;
      this.selectedId = row.id;
      switch (operation) {
        case 'delete':
          this.dialogType = 'delete'
          break;
        case 'edit':
          this.dialogType = 'edit'
          break;
        case 'view':
          this.dialogType = 'view'
          break;  
        default:
          this.dialogType = undefined
          break;
      }
      this.modalVisible = true
      this.dialogVisible = true
    },
    onModalClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.modalVisible = false;
        this.dialogType = undefined
      }, 500);
    },
    onModalSuccess() {
      this.onModalClose();
    },
    closeDialog(){
      this.dialogVisible = false;
      this.dialogType = ''
    },
    openDialog(row, operation){
      this.selectedRow = row;
      this.selectedId = row.id;
      switch (operation) {
        case 'delete':
          this.dialogType = 'delete'
          break;
        case 'edit':
          this.dialogVisible = true
          this.modalVisible = true
          break;
        default:
          break;
      }
      this.dialogVisible = true
    },

    async doDestroyWithConfirm(id) {
      try {
        await this.doDestroy(id);
        this.closeDialog();
      } catch (error) {
        // no
      }
    },
   
    changeRoutePage(val){
      if(this.filter != ''){
        this.$router.push({
        query:{
          filter: this.filter,
          page: val,
        }
      })
      }
      else{
        this.$router.push({
        query:{
          page: val,
        }
      })
      }
    },
    changeRouteFilter(val){
      this.$router.push({
        query:{
          filter: val,
          page: this.pagination.page
        }
      })
    },
    isNumeric(value) {
      return /^\d+$/.test(value);
    },
    navigateBackwardFroward(){
      
      if(this.$route.name == "city") {
        this.pagination.page = this.$route.query.page && this.isNumeric(this.$route.query.page) ?  parseInt(this.$route.query.page) : 1        
        if(this.$route.query.filter){
          this.filter = this.$route.query.filter
        }  
        else{
          this.filter = ''
        }
        if(!this.isRTL){
          this.rowsToBeDisplayed = this.rows.filter(el => el.name.en.toLowerCase().includes(this.filter.toLowerCase()))
        }
        else{
          this.rowsToBeDisplayed = this.rows.filter(el => el.name.ar.toLowerCase().includes(this.filter.toLowerCase()))
        }
      }
    }
  },
  async created(){
    // setTimeout(() => {
    //   console.log('rows city',this.rows)
    // },3000)
    if(this.$route.query.page){
      this.pagination.page = parseInt(this.$route.query.page)
    }
    if(this.$route.query.filter){
      this.filter = this.$route.query.filter
    }
  },
  mounted() {
    this.doMountTable(this.$refs.table);
    var that = this;
    window.onpopstate = async function () {
      that.navigateBackwardFroward();
    };
  },
};
