import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/iam/permission-checker';

export class OfferPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.offerRead,
    );
    this.import = permissionChecker.match(
      Permissions.values.offerImport,
    );
    this.offerAutocomplete = permissionChecker.match(
      Permissions.values.offerAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.offerCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.offerEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.offerDestroy,
    );
  }
}
