// import { RequestService } from '@/modules/policies/policies-service';
import FirebaseRepository from '@/shared/firebase/firebase-repository';
import firebase from 'firebase'
import 'firebase/firestore'

import Errors from '@/shared/error/errors';
import { routerAsync } from '@/app-module';
import Message from '@/shared/message/message';
import { i18n } from '@/vueI18n';

export default {
  namespaced: true,

  state: {
    record: null,
    findLoading: false,
    saveLoading: false,
  },

  getters: {
    record: (state) => state.record,
    findLoading: (state) => !!state.findLoading,
    saveLoading: (state) => state.saveLoading,
    collectionName: () => 'policies'
  },

  mutations: {
    RESET(state) {
      state.findLoading = false;
      state.saveLoading = false;
      state.record = null;
    },

    FIND_STARTED(state) {
      state.record = null;
      state.findLoading = true;
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.findLoading = false;
    },

    FIND_ERROR(state) {
      state.record = null;
      state.findLoading = false;
    },

    UPDATE_STARTED(state) {
      state.saveLoading = true;
    },

    UPDATE_SUCCESS(state) {
      state.saveLoading = false;
    },

    UPDATE_ERROR(state) {
      state.saveLoading = false;
    },
  },

  actions: {
    doNew({ commit }) {
      commit('RESET');
    },
    async doFind({ commit, getters}, id) {
      try {
        commit('FIND_STARTED');
        const record = await FirebaseRepository.findDocument(getters.collectionName, id)
        commit('FIND_SUCCESS', record);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        routerAsync().push('/');
      }
    },

    async doCreate({ commit, getters, rootGetters }, { id, values }) {
      try {
        commit('UPDATE_STARTED');

        const batch = await FirebaseRepository.createBatch()
        const docRef = firebase.firestore().doc(`${getters.collectionName}/${id}`)
        const options = { batch: batch, currentUser: rootGetters[`auth/currentUser`] }
        
        await FirebaseRepository.executeOrAddToBatch('set', docRef, {
          id: id,
          value: values,
          createdAt: FirebaseRepository.serverTimestamp(),
          createdBy: FirebaseRepository.getCurrentUser(options).id,
        }, options)
        await FirebaseRepository.commitBatch(batch)

        commit('UPDATE_SUCCESS');
        Message.success(i18n('entities.policies.update.success', i18n(`entities.policies.${id}`)));
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },

    async doUpdate({ commit, getters, rootGetters }, { id, values }) {
      try {
        commit('UPDATE_STARTED');

        const batch = await FirebaseRepository.createBatch()
        const docRef = firebase.firestore().doc(`${getters.collectionName}/${id}`)
        const options = { batch: batch, currentUser: rootGetters[`auth/currentUser`] }

        await FirebaseRepository.executeOrAddToBatch('update', docRef, {
          id: id,
          value: values,
          updatedAt: FirebaseRepository.serverTimestamp(),
          updatedBy: FirebaseRepository.getCurrentUser(options).id,
        }, options)
        await FirebaseRepository.commitBatch(batch)

        commit('UPDATE_SUCCESS');
        Message.success(i18n('entities.policies.update.success', i18n(`entities.policies.${id}`)));
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },
  },
};
