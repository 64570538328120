//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { OfferModel } from '@/modules/offer/offer-model';
// import { i18n, getLanguageCode } from '@/i18n';

const { fields } = OfferModel;
const formSchema = new FormSchema([
  fields.id,
  fields.title,
  fields.description,
  fields.image,
]);

export default {
  name: 'app-city-form',

  props: ['isEditing', 'record', 'saveLoading', 'modal'],

  data() {
    return {
      step: 1,
      active: false,
      model: null,
      rules: formSchema.rules(),
      // language: getLanguageCode(),
      cityEn:null,
      cityAr:null
    };
  },

  async created() {
    this.model = formSchema.initialValues(this.record || {});
    if (!this.isEditing) {
      this.model.title = {
        en: undefined,
        ar: undefined,
      }
      this.model.description = {
        en: undefined,
        ar: undefined,
      }
    } else if (this.model.image && !Array.isArray(this.model.image)) {
      // this.model.image.length ? this.model.image = [this.model.image] : undefined
      this.model.image = [this.model.image]
    }
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      is_screen_xs: 'layout/is_screen_xs',
    }),

    fields() {
      return fields;
    },
    // citiesList() {
    //   return this.cities.map(item => {
    //     return {
    //       label: item.name[this.language],
    //       value: item.id, 
    //     }
    //   })
    // },
    // regionsInCityList() {
    //   let regions = []
    //   let selected_city = this.cities.filter(city => city.id == this.model.cityId)
      
    //   if (selected_city.length) {
    //     regions = selected_city[0].regions.map(region => {
    //       return {
    //         label: region.name[this.language],
    //         value: region.id, 
    //       }
    //     })
    //   }
    //   return regions
    // },
  },

  methods: {
    ...mapActions({
      // doFetchSparePart: 'sparePart/list/doFetch',
      doCreate: 'citiesRegions/form/doCreate'
    }),
    doReset() {
      this.model = formSchema.initialValues(this.record);
    },
    i18n(key, args) {
      return this.$t(key, args);
    },
    doCancel() {
      this.$emit('cancel');
    },

    // OnClear() {
    //   this.model.offerParts = []
    // },
    // filterGovernorateFn (val, update) {
    //   if (val === '') {
    //     update(() => {
    //       this.citiesOptions = this.citiesList

    //       // with Quasar v1.7.4+
    //       // here you have access to "ref" which
    //       // is the Vue reference of the QSelect
    //     })
    //     return
    //   }

    //   update(() => {
    //     const needle = val.toLowerCase()
    //     this.citiesOptions = this.citiesList.filter(v => v.label.toLowerCase().indexOf(needle) > -1)
    //   })
    // },
    // filterCitiesFn (val, update) {
    //   if (val === '') {
    //     update(() => {
    //       this.regionsOptions = this.regionsInCityList

    //       // with Quasar v1.7.4+
    //       // here you have access to "ref" which
    //       // is the Vue reference of the QSelect
    //     })
    //     return
    //   }

    //   update(() => {
    //     const needle = val.toLowerCase()
    //     this.regionsOptions = this.regionsInCityList.filter(v => v.label.toLowerCase().indexOf(needle) > -1)
    //   })
    // },
    async doSubmit(){
        if (this.step < 2) {
        return this.$refs.stepper.next()
      }

        // const { id, ...values } = formSchema.cast(this.model);
    //   this.model.image.length
    //     ? values.image = this.model.image[0]
    //     : values.image = null
      const values = {
        name:{
          en: this.cityEn,
          ar:  this.cityAr,
          
        },
        regions:[]
      }
    //   await this.doCreate(city)

    //   this.cityDialog = false
    return this.$emit('submit', {
        // id,
        values,
      });
    },
    // async doSubmit() {
    //   if (this.step < 2) {
    //     return this.$refs.stepper.next()
    //   }

    //   const { id, ...values } = formSchema.cast(this.model);
    //   this.model.image.length
    //     ? values.image = this.model.image[0]
    //     : values.image = null
    //   return this.$emit('submit', {
    //     id,
    //     values,
    //   });
    // },
  },
};
