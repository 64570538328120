//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { i18n } from "@/i18n";
import i18n from '@/vueI18n'
export default {
  name: "splash-screen-page",
  data(){
    return{
      // lang:undefind
    }
  },
  methods: {
    isScreenxs() {
      if (this.$q.screen.width <= 540) {
        return true;
      }
      return false;
    },
    i18n(key, args) {
      return this.$t(key, args);
    },
    goToSignIn(){
      // if(this.$oute.path !)
      
      this.$router.push(`/${localStorage.getItem('language')}/auth/signin`)
    }
  },
  computed: {
    isRTL() {
      return i18n.locale == 'ar'
      // return localStorage.getItem("language") == "ar";
    },
  },
  mounted() {},
};
