import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client';
import firebase from 'firebase';

export class NotificationService {

  static async init(id,callback) {
    firebase.firestore().collection('notification').doc(id).collection('list').onSnapshot(snap => {
      const notifications = snap.docChanges().map(I => I.doc.data())
      callback(notifications)
    })
  }
  static async create(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation notificationCreate($data: JSON!) {
          notificationCreate(data: $data)
        }
      `,
      variables: {
        data,
      },
    });

    return response.data.notificationCreate;
  }

  static async update(userId,id, data) {
    // console.log('serviceee',id)
    // console.log('data',data)$data: CommentInput!,$prescriptionId: String!,$commentId: String!
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation NOTIFICATION_UPDATE(
          $userId: String!
          $id: String!
          $data: NotificationInput!
        ) {
          notificationUpdate(userId:$userId,id: $id, data: $data) {
            id
            title{
              en
              ar
            }
          }
        }
      `,

      variables: {
        userId,
        id,
        data,
      },
    });
    // console.log('response.data.prescriptionUpdate',response.data.prescriptionUpdate)
    return response.data.notificationUpdate;
  }



  static async send(data) {
    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation notificationSend($data: JSON!) {
          notificationSend(data: $data)
        }
      `,
      variables: {
        data,
      },
    });

    return response.data.notificationSend;
  }

  // static async list(userId,callback){
  //   await firebase.firestore().collection('user').doc(userId).collection('notification').onSnapshot(async (coll) => {
  //           // callback(coll.data())
  //           // console.log('coll notif',coll)
  //           coll.docChanges().forEach(async (doc) => {
  //             // console.log('doc notif',doc.doc.data())
  //             let notification =  doc.doc.data()
  //             
  //             callback(notification,doc.type)
  //           })
  // })
  // }
  static async list(userId,filter, orderBy, limit, offset) {
    const response = await graphqlClient.query({
      query: gql `
        query NOTIFICATION_LIST(
          $userId:String!
          $filter: NotificationFilterInput
          $orderBy: NotificationOrderByEnum
          $limit: Int
          $offset: Int
        ) {
          notificationList(
            userId:$userId
            filter: $filter
            orderBy: $orderBy
            limit: $limit
            offset: $offset
          ) {
            count
            rows {
              id
              title{
                en
                ar
              }
              description{
                en
                ar
              }
              status
              type
              new
              createdAt
              updatedAt
            }
          }
        }
      `,

      variables: {
        userId,
        filter,
        orderBy,
        limit,
        offset,
      },
    });

    return response.data.notificationList;
  }

}