//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { i18n } from "@/i18n";

export default {
  name: "app-drug-view-modal",
  props: ["id", "record", "visible"],

  // data(){
  //     return{
  //         drug:null
  //     }
  // },
  computed: {
    dialogVisible: {
      get: function() {
        return this.visible;
      },
      set: function(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  created() {
    // this.drug = this.record
    this.record["mechOfAction"] = this.capitalize(this.record["mechOfAction"]);
    this.record["genericName"] = this.record["genericName"].map((element) => {
      element = element.trim();
      return this.capitalize(element);
    });
    this.record["company"] = this.record["company"].map((element) => {
      element = element.trim();
      return this.capitalize(element);
    });
    this.paragraphs = this.record["mechOfAction"].split("\n");
  },
  methods: {
    
    i18n(key, args) {
      return i18n(key, args);
    },
    close() {
      this.$emit("close");
    },
    capitalize(s) {
      if (typeof s !== "string") return "";
      s = s.toLowerCase();
      s = s.charAt(0).toUpperCase() + s.slice(1);
      return s;
    },
  },
  
};
