//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'app-contact-view-modal',
    props: ['visible', 'id', 'record'],
    
    computed: { 
        dialogVisible: {
        get: function() {
            return this.visible;
        },
        set: function(value) {
            if (!value) {
            this.$emit('close');
            }
        },
        },
    },
    created(){

    }
}
