import { OfferService } from '@/modules/offer/offer-service';
import Errors from '@/shared/error/errors';
import { routerAsync } from '@/app-module';
import Message from '@/shared/message/message';
import { i18n } from '@/i18n';

export default {
  namespaced: true,

  state: {
    findLoading: false,
    saveLoading: false,
    pinCodeLoading: false,
    record: null,
  },

  getters: {
    record: (state) => state.record,
    findLoading: (state) => !!state.findLoading,
    saveLoading: (state) => !!state.saveLoading,
    pinCodeLoading: (state) => !!state.pinCodeLoading,
  },

  mutations: {
    RESET(state) {
      state.findLoading = false;
      state.saveLoading = false;
      state.record = null;
    },

    FIND_STARTED(state) {
      state.record = null;
      state.findLoading = true;
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.findLoading = false;
    },

    FIND_ERROR(state) {
      state.record = null;
      state.findLoading = false;
    },

    CREATE_STARTED(state) {
      state.saveLoading = true;
    },

    CREATE_SUCCESS(state) {
      state.saveLoading = false;
    },

    CREATE_ERROR(state) {
      state.saveLoading = false;
    },

    UPDATE_STARTED(state) {
      state.saveLoading = true;
    },

    UPDATE_SUCCESS(state) {
      state.saveLoading = false;
    },

    UPDATE_ERROR(state) {
      state.saveLoading = false;
    },

    GENERATE_PIN_CODE_STARTED(state) {
      state.pinCodeLoading = true;
    },
    GENERATE_PIN_CODE_SUCCESS(state) {
      state.pinCodeLoading = false;
    },
    GENERATE_PIN_CODE_ERROR(state) {
      state.pinCodeLoading = false;
    },
  },

  actions: {
    doNew({ commit }) {
      commit('RESET');
    },

    async doFind({ commit }, id) {
      try {
        commit('FIND_STARTED');
        
        const record = await OfferService.find(id);

        commit('FIND_SUCCESS', record);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        routerAsync().push('/offer');
      }
    },

    async doCreate({ commit }, values) {
      try {
        commit('CREATE_STARTED');
        // const foundRecord = await OfferService.find(values.barcode)
        // if (foundRecord) {
          // throw { customError: { message: i18n('errors.recordIsFound') } }
        // }
        await OfferService.create(values);
        commit('CREATE_SUCCESS');
        Message.success(i18n('entities.offer.create.success'));
        // routerAsync().push('/offer');
        return true
      } catch (error) {
        Errors.handle(error);
        commit('CREATE_ERROR');
        return false
      }
    },
    
    async doUpdate({ commit }, { id, values }) {
      try {
        commit('UPDATE_STARTED');
        await OfferService.update(id, values);
        commit('UPDATE_SUCCESS');
        Message.success(i18n('entities.offer.update.success'));
        // routerAsync().push('/offer');
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },


    // async doCreatePinCode({ commit }, pinCode) {
    //   try {
    //     commit('GENERATE_PIN_CODE_STARTED');
    //     await OfferService.createPinCode(pinCode);
    //     commit('GENERATE_PIN_CODE_SUCCESS');
    //     Message.success(i18n('entities.offer.createPin.success'));
    //   } catch (error) {
    //     Errors.handle(error);
    //     commit('GENERATE_PIN_CODE_ERROR');
    //     return false
    //   }
    // },
    // async doGeneratePinCode({ commit }) {
    //   try {
    //     commit('GENERATE_PIN_CODE_STARTED');
    //     const pinCode = await OfferService.pinCodeGenerator();
    //     commit('GENERATE_PIN_CODE_SUCCESS');

    //     return pinCode
    //   } catch (error) {
    //     Errors.handle(error);
    //     commit('GENERATE_PIN_CODE_ERROR');
    //     return false
    //   }
    // },
    // async doCreatePinCodes({ commit }, values) {
    //   try {
    //     commit('GENERATE_PIN_CODE_STARTED');
    //     const pinCodes = values.pinCodes
    //     for (let index = 0; index < pinCodes.length; index++) {
    //       const record = {
    //         pinCode: pinCodes[index],
    //         distributorType: values.distributorType,
    //         wholesalerId: values.wholesalerId,
    //       }
    //       await OfferService.createPinCode(record);
    //     }
    //     commit('GENERATE_PIN_CODE_SUCCESS');
    //     Message.success(i18n('entities.offer.createPin.success'));
    //   } catch (error) {
    //     Errors.handle(error);
    //     commit('GENERATE_PIN_CODE_ERROR');
    //     return false
    //   }
    // },
    // async doGeneratePinCodes({ commit }, count) {
    //   try {
    //     commit('GENERATE_PIN_CODE_STARTED');
    //     const pinCode = await OfferService.pinCodeGenerators(count);
    //     commit('GENERATE_PIN_CODE_SUCCESS');

    //     return pinCode
    //   } catch (error) {
    //     Errors.handle(error);
    //     commit('GENERATE_PIN_CODE_ERROR');
    //     return false
    //   }
    // },
  },
};
