import firebase from 'firebase/app';
import 'firebase/auth';
import config from '@/config';
import 'firebase/firestore';
// import 'firebase/app-check';
import 'firebase/analytics'
let app 

export default function firebaseInit() {
  app = firebase.initializeApp(config.firebaseConfig);
  // firebase.analytics();
  // const appCheck = firebase.appCheck();
  // appCheck.activate('6LfELiUbAAAAALY2Yg5-T1JqIkh1o-MlxfOdcHM3');

  firebase.firestore().enablePersistence({ synchronizeTabs: true })
  .catch(function(err) {
    if (err.code == 'failed-precondition') {
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
    } else if (err.code == 'unimplemented') {
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
    }
  });
  app.auth();
}

export { app } 