import { render, staticRenderFns } from "./header.vue?vue&type=template&id=4c7300d0&scoped=true&"
import script from "./header.vue?vue&type=script&lang=js&"
export * from "./header.vue?vue&type=script&lang=js&"
import style0 from "./header.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./header.vue?vue&type=style&index=1&id=4c7300d0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c7300d0",
  null
  
)

export default component.exports
import {QBtn,QIcon,QBadge,QAvatar,QMenu,QImg,QList,QItem,QItemSection,QItemLabel,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QBtn,QIcon,QBadge,QAvatar,QMenu,QImg,QList,QItem,QItemSection,QItemLabel})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
