//
//
//
//
//
//
//
//

import Table from '@/modules/home/components/table.vue';
import { i18n } from "@/i18n";
import firebase from 'firebase/app'
import 'firebase/firestore';
import { mapGetters } from 'vuex';
export default {
  data(){
    return{
      users:[],
      loading:true,
      columns: [
            {
          name: i18n('drugDeal.users.firstName'),
          required: true,
          label: i18n('drugDeal.users.firstName'),
          align: 'center',
          field: 'firstName',
          // style: 'width: 5vw;',
        },
        // { name: i18n('drugDeal.users.lastName'), align: 'center', label: i18n('drugDeal.users.lastName'), field: 'lastName',},
        { name: i18n('drugDeal.users.fullName'), align: 'center', label: i18n('drugDeal.users.fullName'), field: 'fullName',},
        //  { name: 'Last ', align: 'center', label: 'Last', field: 'hey',},
        { name: i18n('common.phoneNumber'), align: 'center', label: i18n('common.phoneNumber'), field: 'phone',},
        { name: i18n('common.emailAddress'), align: 'center', label:  i18n('common.emailAddress'), field: 'email',},
        { name:  i18n('common.status'), align: 'center', label:  i18n('common.status'), field: 'disabled',},
        { name:  i18n('common.action'), align: 'center', label:  i18n('common.action'), field: 'action',},
         


      ],
      data: [
        {
          id:'1',firstName: 'Ahmed',lastName: 'Ali', phone:'01093295291',emailAddress:'ghadayousef@gmail.com', status:'enabled',
        },
        {
          id:'2',firstName: 'Ahmed',lastName: 'Ali', phone: '01093295291', emailAddress:'ghadayousef@gmail.com', status:'disabled',
        },
        {
         id:'3', firstName: 'Ahmed',lastName: 'Ali', phone: '01093295291', emailAddress:'ghadayousef@gmail.com', status:'enabled',
        },
        {
         id:'4', firstName: 'Ahmed',lastName: 'Ali', phone: '01093295291', emailAddress:'ghadayousef@gmail.com', status:'disabled',
        },
        {
          id:'5', firstName: 'Ahmed',lastName: 'Ali', phone: '01093295291', emailAddress:'ghadayousef@gmail.com', status:'enabled',
        },
        {
          id:'6',firstName: 'Ahmed',lastName: 'Ali', phone: '01093295291', emailAddress:'ghadayousef@gmail.com', status:'disabled',
        },
        {
          id:'7', firstName: 'Ahmed',lastName: 'Ali', phone: '01093295291', emailAddress:'ghadayousef@gmail.com', status:'enabled',
        },
        {
         id:'8', firstName: 'Ahmed',lastName: 'Ali', phone: '01093295291', emailAddress:'ghadayousef@gmail.com', status:'disabled',
        },
        {
          id:'9',firstName: 'Ahmed',lastName: 'Ali', phone: '01093295291', emailAddress:'ghadayousef@gmail.com', status:'enabled',
        },
        {
          id:'10',firstName: 'Ahmed',lastName: 'Ali', phone:'01093295291',emailAddress:'ghadayousef@gmail.com', status:'enabled',
        },
        {
          id:'11',firstName: 'Ahmed',lastName: 'Ali', phone: '01093295291', emailAddress:'ghadayousef@gmail.com', status:'disabled',
        },
        {
         id:'12', firstName: 'Ahmed',lastName: 'Ali', phone: '01093295291', emailAddress:'ghadayousef@gmail.com', status:'enabled',
        },
        {
         id:'13', firstName: 'Ahmed',lastName: 'Ali', phone: '01093295291', emailAddress:'ghadayousef@gmail.com', status:'disabled',
        },
        {
          id:'14', firstName: 'Ahmed',lastName: 'Ali', phone: '01093295291', emailAddress:'ghadayousef@gmail.com', status:'enabled',
        },
        {
          id:'15',firstName: 'Ahmed',lastName: 'Ali', phone: '01093295291', emailAddress:'ghadayousef@gmail.com', status:'disabled',
        },
        {
          id:'16', firstName: 'Ahmed',lastName: 'Ali', phone: '01093295291', emailAddress:'ghadayousef@gmail.com', status:'enabled',
        },
        {
         id:'17', firstName: 'Ahmed',lastName: 'Ali', phone: '01093295291', emailAddress:'ghadayousef@gmail.com', status:'disabled',
        },
        {
          id:'18',firstName: 'Ahmed',lastName: 'Ali', phone: '01093295291', emailAddress:'ghadayousef@gmail.com', status:'enabled',
        },
      ],
    }
  },
  components:{
    [Table.name]:Table
  },
  methods:{
    i18n(key, args) {
      return i18n(key, args);
    },
  },
  computed:{
    ...mapGetters({
      authenticationUser: 'auth/authenticationUser',
      roles : 'auth/roles',
      // currentUser:'auth/currentUser'
    })
  },
  async created(){
    await firebase.firestore().collection('user').onSnapshot({ includeMetadataChanges: true },async () => {
      // const user = await firebase.firestore().collection("user").doc(this.currentUser.id).get({source: 'cache'})
      // console.log('user data: ',user.data().roles[0])
      const users = await firebase.firestore().collection("user").get({source: 'cache'})
      this.users = []
      // this.cachedPrescriptionsSize = prescriptions.size
      users.forEach(doc => {
        let user = doc.data()
        // user.createdAt = new Date(user.createdAt.seconds * 1000)
        // user.createdAt = user.createdAt.toString().slice(0,24)
        // if(!user.roles.includes('owner') && !user.roles.includes('pending') && user.status !== "deleted")
        if(user.roles.length == 0 && user.status !== "deleted")
          this.users.push(user)
      })
      this.loading = false
   })
  }
   
  
}
