import { render, staticRenderFns } from "./region-form-modal.vue?vue&type=template&id=8de8517a&scoped=true&"
import script from "./region-form-modal.vue?vue&type=script&lang=js&"
export * from "./region-form-modal.vue?vue&type=script&lang=js&"
import style0 from "./region-form-modal.vue?vue&type=style&index=0&id=8de8517a&lang=scss&scoped=true&"
import style1 from "./region-form-modal.vue?vue&type=style&index=1&id=8de8517a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8de8517a",
  null
  
)

export default component.exports
import {QDialog,QCard,QCardSection,QSpinnerIos} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QDialog,QCard,QCardSection,QSpinnerIos})
