//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { UserModel } from '@/modules/auth/user-model';
// import { FileUploader } from '@/shared/firebase/file-uploader';
const { fields } = UserModel;

// import { VueAvatar } from 'vue-avatar-editor-improved'
import DeleteDialog from '@/modules/home/components/delete-dialog.vue';
import { i18n } from "@/i18n";
// import firebase from 'firebase/app';
import firebase from 'firebase/app';
import 'firebase/auth';        // for authentication
import 'firebase/storage';     // for storage
import 'firebase/database';    // for realtime database
import 'firebase/firestore';   // for cloud firestore
import 'firebase/messaging';   // for cloud messaging
import 'firebase/functions';   // for cloud functions
import 'firebase/firestore';
import { mapGetters } from 'vuex';
export default {
    data(){
        return{
            nameEnter:false,
            firstName:'',
            lastName:'',
            email:'',
            validEmail:true,
            validPhone:true,
            phoneNumber:'',
            rotation: 0,
            scale: 1,
            deleteUser:false,
            admin:null,
            loading: false,
            image:'',
            path:'user/attachments',
            fileName:'',
            file:'',
            rawFile:null,
            downloadURL: '',
            enabledDisabled:false,
            // modelSingle: 'Apple',
            modelMultiple: [],
            options: [
                'owner', 'editor'
            ]
            // adminCreatedAt:null
        }
    },
    computed:{
        fields() {
            return fields;
        },
        ...mapGetters({
            currentUser: 'auth/currentUser'
        })
    },
components:{
    //  VueAvatar: VueAvatar,
      [DeleteDialog.name]: DeleteDialog,
},
    mounted(){
        // this.$refs.vueavatar.loadImage("/images/default-profile.svg")
        this.$q.iconSet.field.error = "img:/images/invalid-email.png"
        // const fileUpload = document.getElementById('file-upload')
        // fileUpload.addEventListener('change', function(){
        // this.readUrl(this);
        // });
    },
    async created(){

        if(this.$route.params.id) {
        await firebase.firestore().collection('user').onSnapshot( async () => {
            if(this.$route.params.id) {
                const admin = await firebase.firestore().collection("user").doc(this.$route.params.id).get()
                this.admin = admin.data();
                this.firstName = this.admin.firstName
                if(this.admin.lastName) {
                    this.lastName = this.admin.lastName
                }
                else{
                   this.lastName = this.admin.fullName
                }
                
                this.email = this.admin.email
                this.phoneNumber = this.admin.phoneNumber
                if(this.admin.disabled)
                this.enabledDisabled = this.admin.disabled
                this.modelMultiple = []
                if(this.admin.roles){
                    this.admin.roles.forEach((role) => {
                        this.modelMultiple.push(role)
                    })
                }
                
                // this.adminCreatedAt = this.admin.createdAt
                // const joinedIn = this.adminCreatedAt.slice(5,7)
                // var date = new Date();
                // var month = date.getMonth() + 1;
                // console.log('month',month)
                // this.joinedSince = month - joinedIn
                // console.log('joinedSince',this.joinedSince)
            }

        })
        }
    },
  methods: {

    async doSubmit(){
        const updatedAdmin = this.admin
        updatedAdmin.firstName = this.firstName,
        updatedAdmin.lastName =  this.lastName,
        updatedAdmin.email = this.email,
        updatedAdmin.phoneNumber = this.phoneNumber
        updatedAdmin.fullName = this.firstName + ' ' + this.lastName
        updatedAdmin.disabled = this.enabledDisabled
        updatedAdmin.createdAt = this.admin.createdAt
        updatedAdmin.updatedAt = firebase.firestore.Timestamp.fromDate(new Date()).toDate();
        updatedAdmin.updatedBy = this.currentUser.id
      


        if(this.fileName){
            updatedAdmin.avatar = this.downloadURL
        }


        updatedAdmin.roles = []
            this.modelMultiple.forEach((role) => {
                updatedAdmin.roles.push(role)
            })

        this.loading = true
        await firebase.firestore().collection("user").doc(this.$route.params.id).set(updatedAdmin)
        this.loading = false
        this.modelMultiple = []
        this.$router.push('/admins')
    },
    i18n(key, args) {
      return i18n(key, args);
    },
  
    validateEmail(email) {
            const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        if(re.test(String(email).toLowerCase())){
            this.validEmail = true
            return true
        }
        else{
            this.validEmail = false
            return false
        }  
            
    },
    validatePhone(inputtxt){
            const phoneno = /^\d{11}$/
            if (phoneno.test(inputtxt)){
                this.validPhone = true
                return true; 
            }
            else {
                this.validPhone = false
                return false;
            } 
    },
    close(){
          this.deleteUser = false
    },
    saveClicked () {
          var img = this.$refs.vueavatar.getImageScaled()
          this.$refs.image.src = img.toDataURL()
    },
    onImageReady () {
          this.scale = 1
          this.rotation = 0
    },
    openImageUpload(){
        const fileUpload = document.getElementById('file-upload')
        fileUpload.click()
    },
    readUrl(event){
       const profilePicture = document.getElementById('profile-pic')
    //    console.log('profilePicture',profilePicture)
        if (event.target.files && event.target.files[0]) {
            this.rawFile = event.target.files[0]
            this.fileName = event.target.files[0].name
            var reader = new FileReader();
            reader.onload = function (e) {
                profilePicture.src =  e.target.result;
                this.file = e.target.result
            }
    
            reader.readAsDataURL(event.target.files[0]);
        }
    },
    async uploadFromRequest() {
    //   console.log('schema : ',this.fields.avatarsIam.fileSchema)
    var storage = firebase.storage().ref();
    var uploadTask = storage.child(`user/avatars/profile/${this.$route.params.id}/${this.fileName}`).put(this.rawFile);
    await uploadTask.on('state_changed', 
    // function(snapshot){
    //   var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    // //   console.log('Upload is ' + progress + '% done');
    // //   switch (snapshot.state) {
    // //     case firebase.storage.TaskState.PAUSED:
    // //       break;
    // //     case firebase.storage.TaskState.RUNNING:
    // //       console.log('Upload is running');
    // //       break;
    // //   }
    // },
    //  function(error) {
    //   console.log('error',error)
    // }, 
    async () => {
        const downloadURL = await uploadTask.snapshot.ref.getDownloadURL()
        this.downloadURL = downloadURL
    });

    },
    
  }
}
