//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "app-drugIndex-upload",
};
