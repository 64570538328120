import { DrugIndexModel } from '@/modules/drugIndex/drugIndex-model';

const { fields } = DrugIndexModel;

export default [
  fields.drugName,
  fields.genericName,
  fields.price,
  fields.oldPrice,
  fields.company,
  fields.pharmalogicalUse,
  fields.srde,
  fields.mechOfAction,
  fields.route,
  
];
