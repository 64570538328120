//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { ContactModel } from '@/modules/contact/contact-model';
import { OfferPermissions } from '@/modules/offer/offer-permissions';
// import { getLanguageCode } from '@/i18n';

import i18n from '@/vueI18n'
import moment from 'moment';

import TableViewPage from '@/shared/components/table-view-page.vue';
// import OfferFormModal from '@/modules/offer/components/offer-form-modal.vue';
import ContactViewModal from '@/modules/contact/components/contact-view-modal.vue';

const { fields } = ContactModel;

export default {
  name: 'app-contact-list-table',

  components: {
    [TableViewPage.name]: TableViewPage,
    // [OfferFormModal.name]:OfferFormModal,
    [ContactViewModal.name]: ContactViewModal,
  },

  mounted() {
    var that = this;
    window.onpopstate = async function () {
      that.navigateBackwardFroward();
    };
  },

  data() {
    return {
      filter: '',
      searchBy: 'name',
      modalVisible: false,
      columns: [
        {
          name: 'name',
          field: 'name',
          label: 'name',
          align: 'center',
          format: (val) => `${val}`,
          required: true,
          // sortable: true,
        },
        {
          name: 'email',
          field: 'email',
          label: 'email',
          align: 'center',
          format: (val) => `${val}`,
          required: true,
          // sortable: true,
        },
        {
          name: 'phone',
          field: 'phone',
          label: 'phone',
          align: 'center',
          format: (val) => `${val}`,
          required: true,
          // sortable: true,
        },
        {
          name: 'message',
          field: 'message',
          label: 'message',
          align: 'center',
          format: (val) => `${val}`,
          required: true,
          // sortable: true,
        },
        {
          name: 'action',
          field: 'action',
          label: 'actions',
          align: 'center',
        },
      ],
      rowsOld:[],
      // language: localStorage.getItem('language'),
      // language: this.$router.params['en'],
      // language:getLanguageCode(),
      dialogVisible: false,
      dialogType: undefined,
      selectedRow: null,
      selectedId: null,
      pageSize: 5,
      nextPage: 2,
      pagination: {
        page: 1,
        rowsPerPage: 10,
        // sortBy: 'desc',
        // descending: false,
        // rowsNumber: xx if getting data from a server
      },
      page: 1,
      limit: 10,
      searchByOptions: ['name', 'phone', 'email']

    };
  },
  computed: {
    ...mapGetters({
      rows: 'contact/list/rows',
      rowsFromRoute: 'contact/list/rowsFromRoute',
      recordAfterLast: 'contact/list/recordAfterLast',
      recordBeforeFirst: 'contact/list/recordBeforeFirst',
      count: 'contact/list/count',
      // pagination: 'offer/list/pagination',
      // paginationLayout: 'layout/paginationLayout',
      loading: 'contact/list/loading',
      // saveLoading: 'contact/form/saveLoading',

      backgroundLoading: 'contact/list/backgroundLoading',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      // destroyLoading: 'contact/destroy/loading',
      is_screen_xs: 'layout/is_screen_xs',
    }),
    loadingAndUpdate(){
      if(this.loading || this.saveLoading || this.destroyLoading){
        return true
      }
      return false
    },
    language(){
      return i18n.locale
    },
    hasPermissionToEdit() {
      return new OfferPermissions(this.currentUser).edit;
    },
    hasPermissionToDestroy() {
      return new OfferPermissions(this.currentUser).destroy;
    },
    fields() {
      return fields;
    },
    pagesNumber () {
      return Math.ceil(this.rows.length / this.pagination.rowsPerPage)
    },
    rowsToBeDisplayed(){    
        
      if(this.rows.length > 0)
        return this.rows
      return this.rowsOld
    },
  },
   watch:{
    rowsFromRoute(change){
      const page  = this.$route.query.page
      const limit = this.limit
      const rowsFromRouteToBeDisplayed = change.slice(page * limit - limit, page * limit + 1)
      this.setRows(rowsFromRouteToBeDisplayed)

    },
    rows(newval){
       if(newval.length > 0){
        
        // this.removeLastElement()
        this.setLoadFirstRecord()
        this.setLoadMoreLast()
        this.setDisablePrevious({searchBy: this.searchBy,filter: this.filter})
        this.setDisableNext({searchBy: this.searchBy,filter: this.filter})
       
      }
    }
  },

  methods: {
    ...mapActions({
      doChangeSort: 'contact/list/doChangeSort',
      doChangePaginationCurrentPage: 'contact/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'contact/list/doChangePaginationPageSize',
      doMountTable: 'contact/list/doMountTable',
      doDestroy: 'contact/destroy/doDestroy',
      // doFetchInBackground: 'offer/list/doFetchInBackground',
      // doFetch: 'offer/list/doFetch',
      doFetch: 'contact/list/doFetch',
      doFetchFromRoute: 'contact/list/doFetchFromRoute',
      getPreviousRecordsBatch:'contact/list/getPreviousRecordsBatch',
      setDisablePrevious:'contact/list/setDisablePrevious',
      setDisableNext:'contact/list/setDisableNext',
      doGetRecordsCount: 'contact/list/doGetRecordsCount',
      setLoadFirstRecord: 'contact/list/setLoadFirstRecord',
      setLoadMoreLast:'contact/list/setLoadMoreLast',
      resetCursor: 'contact/list/resetCursor',
      setRows: 'contact/list/setRows'

    }),
    // i18n(code, args) {
    //   return i18n(code, args)
    // },
    isNumeric(value) {
      return /^\d+$/.test(value);
    },
    async loadMore(){
      await this.doFetch({limit: 10,searchBy: this.searchBy, filter: this.filter})
      this.page = this.page + 1
      if(this.filter != ''){
        this.$router.push({
          query: {
            page: this.page,
            filter: this.filter
          },
          params:{
            searchBy: this.searchBy
          }
        })
      }
      else{
        this.$router.push({
          query: {
            page: this.page,            
          },
          params:{
            searchBy: this.searchBy
          }
        })
      }
      this.rowsOld = this.rows
    },
    async goToPreviousPage(){
      await this.getPreviousRecordsBatch({limit: 10,searchBy: this.searchBy, filter: this.filter})
      this.page = this.page - 1
      if(this.filter != ''){
      this.$router.push({
        query: {
          page: this.page,
          filter: this.filter
        },
        params:{
          searchBy: this.searchBy
        }
      })
      }
      else{
        this.$router.push({
        query: {
          page: this.page,
        },
        params:{
          searchBy: this.searchBy
        }
      })
      }
      this.rowsOld = this.rows
    },
    async searchByFilter(){
      this.resetCursor()
      this.page = 1
      if(this.filter != ''){
      this.$router.push({
        query: {
          page: this.page,
          filter: this.filter
        },
        params:{
          searchBy: this.searchBy
        }
      })
      }
      else{
         this.$router.push({
        query: {
          page: this.page,           
        },
        params:{
          searchBy: this.searchBy
        }
      })
      } 
      await this.doFetch({limit: 10, searchBy: this.searchBy, filter: this.filter})
      this.rowsOld = this.rows
      

    },
    async removeSearch(){
      this.resetCursor()
      // this.searchBy = 'name'
      this.filter = ''
      this.page = 1
      this.$router.push({
        query: {
          page: this.page,
          // filter: ''
        },
        params:{
          searchBy: this.searchBy
        }
      })
      await this.doFetch({limit: 10,searchBy: this.searchBy, filter: this.filter})
    },
      i18n(key, args) {
      return this.$t(key, args);
    },
    presenter(row, fieldName) {
      const val = ContactModel.presenter(row, fieldName);
      return val ? val : 'ـــــــ';
    },
    presenterEnum(row, fieldName) {
      const options = this.fields[fieldName].options;
      const val = options.filter(el => el.value == row[fieldName])
      return val.length ? val[0].label : 'ـــــــ';
    },
    presenterMap(row, fieldName) {
      // console.log('language in presenterMap',this.language)
      const val = ContactModel.presenter(row, fieldName);
      return val[this.language] ? val[this.language] : null
    },
    presenterDate(row, fieldName) {
      const val = ContactModel.presenter(row, fieldName)
      return val ? moment(val).locale(this.language).format("D MMM, YYYY") : 'ـــــــ'; 
    },
    presenterDay(row, fieldName) {
      return moment(ContactModel.presenter(row, fieldName)).locale(this.language).format("dddd");
    },
    presenterTime(row, fieldName) {
      let now = moment().format('YYYY/MM/DD');
      let time = ContactModel.presenter(row, fieldName)
      let date =  `${now} ${time}`;
      return moment(date).locale(this.language).format("hh:mm a"); 
    },

    onModalOpen(row, operation) {
      this.selectedRow = row;
      this.selectedId = row.id;
      switch (operation) {
        case 'delete':
          this.dialogType = 'delete'
          break;
        case 'edit':
          this.dialogType = 'edit'
          break;
        case 'view':
          this.dialogType = 'view'
          break;  
        default:
          this.dialogType = undefined
          break;
      }
      this.modalVisible = true
      this.dialogVisible = true
    },
    onModalClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.modalVisible = false;
        this.dialogType = undefined
      }, 500);
    },
    onModalSuccess() {
      this.onModalClose();
    },
    closeDialog(){
      this.dialogVisible = false;
      this.dialogType = ''
    },
    openDialog(row, operation){
      this.selectedRow = row;
      this.selectedId = row.id;
      switch (operation) {
        case 'delete':
          this.dialogType = 'delete'
          break;
        case 'edit':
          this.dialogVisible = true
          this.modalVisible = true
          break;
        default:
          break;
      }
      this.dialogVisible = true
    },

    async doDestroyWithConfirm(id) {
      try {
        await this.doDestroy(id);
        this.closeDialog();
      } catch (error) {
        // no
      }
    },

    async navigateBackwardFroward() {
      if (this.$route.name == "contact") {
        this.page = this.$route.query.page && this.$route.query.page > 0 && this.isNumeric(this.$route.query.page) ? parseInt(this.$route.query.page) : 1      
        this.filter = this.$route.query.filter ? this.$route.query.filter : ''
        if(this.$route.params.searchBy && this.searchByOptions.includes(this.$route.params.searchBy)){
          this.searchBy = this.$route.params.searchBy
        }

        this.resetCursor();
        if(this.$route.params.searchBy && this.searchByOptions.includes(this.$route.params.searchBy)) {
            if(!this.$route.query.page || !this.isNumeric(this.$route.query.page) && this.$route.query.page <= 0){
              if(this.$route.query.filter){
                this.$router.push({
                  query: {
                    page: 1,
                    filter: this.$route.query.filter              
                  }
                })
              }
              else{
                this.$router.push({
                  query: {
                    page: 1,              
                  }
                })
              }
            }
          await this.doFetchFromRoute({page: this.page, limit: 10, searchBy: this.searchBy, filter: this.filter})
        
        } else {
            this.$router.push({
              params: {
                searchBy: 'name'
              },
              query: {
                page: 1,
              }
            })
            this.page = 1
            await this.doFetch({
              limit: 10, searchBy: 'name', filter: ''
            });
        }
        this.rowsOld = this.rows;
      } 
    },
  },
  async created(){
    //#region [ ======== to set the searchBy, filter, page variables ======== ]
    this.page = this.$route.query.page && this.isNumeric(this.$route.query.page) && this.$route.query.page > 0 ? parseInt(this.$route.query.page) : 1
    if(this.$route.params.searchBy && this.searchByOptions.includes(this.$route.params.searchBy)){
      this.searchBy = this.$route.params.searchBy
    }
    this.filter = this.$route.query.filter ? this.$route.query.filter : ''
    //#endregion

      if(this.$route.params.searchBy && this.searchByOptions.includes(this.$route.params.searchBy)){
          if(!this.$route.query.page || !this.isNumeric(this.$route.query.page || this.$route.query.page <= 0)){
            if(this.$route.query.filter){
              this.$router.push({
                query: {
                  page: 1,
                  filter: this.$route.query.filter              
                }
              })
            }
            else{
              this.$router.push({
                query: {
                  page: 1,              
                }
              })
            }
          }     
          
          await this.doFetchFromRoute({page: this.page, limit: 10, searchBy: this.searchBy, filter: this.filter})
          
      }
      else{
        this.$router.push({
          params: {
            searchBy: 'name'
          },
          query: {
            page: 1,
          }
        })
        await this.doFetch({limit: 10,searchBy: 'name',filter:''})
      }
      this.rowsOld = this.rows;
  }
};
