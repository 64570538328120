//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import { i18n, getLanguageCode } from '@/i18n';


export default {
  name: 'app-table-view-page',
  props: ['rows', 'columns', 'loading', 'destroyLoading'],

  data() {
    return {
      language: getLanguageCode(),
      dialogVisible: false,
      dialogType: '',
      selectedRow: null,
      selectedId: null,
      pageSize: 5,
      nextPage: 2,
      pagination: {
        page: 1,
        rowsPerPage: 10,
        // sortBy: 'desc',
        // descending: false,
        // rowsNumber: xx if getting data from a server
      },
    };
  },

  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      is_screen_xs: 'layout/is_screen_xs'
    }),
    pagesNumber () {
      return Math.ceil(this.rows.length / this.pagination.rowsPerPage)
    }
  },

  methods: {
    i18n(key, args) {
      return i18n(key, args);
    },
    // presenter(row, fieldName) {
    //   return ProductModel.presenter(row, fieldName);
    // },
    // presenterMap(row, fieldName) {
    //   const val = ImporterModel.presenter(row, fieldName);
    //   return val[this.language]
    // },
    // presenterDate(row, fieldName) {
    //   return moment(ImporterModel.presenter(row, fieldName)).locale(this.language).format("D MMM, YYYY"); 
    // },
    // presenterDay(row, fieldName) {
    //   return moment(ImporterModel.presenter(row, fieldName)).locale(this.language).format("dddd");
    // },
    // presenterTime(row, fieldName) {
    //   let now = moment().format('YYYY/MM/DD');
    //   let time = ImporterModel.presenter(row, fieldName)
    //   let date =  `${now} ${time}`;
    //   return moment(date).locale(this.language).format("hh:mm a"); 
    // },
    closeDialog(){
      this.dialogVisible = false;
      this.dialogType = ''
    },

    openDialog(row, operation){
      this.selectedRow = row;
      this.selectedId = row.id;
      switch (operation) {
        case 'delete':
          this.dialogType = 'delete'
          break;
        case 'view':
          this.dialogType = 'view'
          break;
        default:
          break;
      }
      this.dialogVisible = true
    },

    async doDelete(id) {
      try {
        await this.$emit('doDelete', id)
      } catch (error) {
        //  console.log(error)
      }
    },
      
  },
};
