import propertyFormStore from '@/modules/property/property-form-store';
import propertyListStore from '@/modules/property/property-list-store';
import propertyViewStore from '@/modules/property/property-view-store';


export default {
    namespaced: true,
  
    modules: {
      form: propertyFormStore,
      list: propertyListStore,
      view: propertyViewStore
    },
  };
  