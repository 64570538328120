//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from "vuex";
import { OfferModel } from "@/modules/offer/offer-model";
import { OfferPermissions } from "@/modules/offer/offer-permissions";
// import { i18n, getLanguageCode } from '@/i18n';
import moment from "moment";
import firebase from "firebase/app";
import "firebase/firestore";

import TableViewPage from "@/shared/components/table-view-page.vue";
import OfferFormModal from "@/modules/offer/components/offer-form-modal.vue";
import PropertyViewModal from "@/modules/property/components/property-view-modal.vue";
// import firebase from 'firebase/app'

const { fields } = OfferModel;

export default {
  name: "app-property-list-table",
  data() {
    return {
      filter: "",
      searchBy: "name",
      modalVisible: false,
      rowsOld: [],
      columns: [
        {
          name: "name",
          required: true,
          label: "name",
          align: "center",
          field: "name",
          // style: 'width: 5vw;',
        },
        {
          name: "address",
          align: "center",
          label: "address",
          field: "address",
        },
        {
          name: "description",
          align: "center",
          label: "description",
          field: "description",
        },
        {
          name: "phoneNumber",
          align: "center",
          label: "phoneNumber",
          field: "phoneNumber",
        },
        {
          name: "spaceArea",
          align: "center",
          label: "spaceArea",
          field: "spaceArea",
        },
        { name: "status", align: "center", label: "status", field: "isClosed" },
        {
          name: "postStatus",
          align: "center",
          label: "postStatus",
          field: "postStatus",
        },
        // { name: 'Created By', align: 'center', label: 'Created By', field: 'createdBy',},
        // { name: 'Created At', align: 'center', label: 'Created At', field: 'createdAt',},
        { name: "action", align: "center", label: "action", field: "action" },
      ],
      pendingToggle: false,
      openedToggle: false,
      // rows:[],
      // language: getLanguageCode(),
      dialogVisible: false,
      dialogType: undefined,
      selectedRow: null,
      selectedId: null,
      pageSize: 5,
      nextPage: 2,
      pagination: {
        page: 1,
        rowsPerPage: 10,
        // sortBy: 'desc',
        // descending: false,
        // rowsNumber: xx if getting data from a server
      },
      removeTogglePointer: true,
      page: 1,
      limit: 10,
      searchByOptions: ["name", "address", "spaceArea"],

      postStatus: "",
      openedStatus: "",
      pendingOpenedSuccessively: false,

      // filter for search
    };
  },
  components: {
    [TableViewPage.name]: TableViewPage,
    [OfferFormModal.name]: OfferFormModal,
    [PropertyViewModal.name]: PropertyViewModal,
  },
  computed: {
    ...mapGetters({
      rows: "property/list/rows",
      rowsFromRoute: "property/list/rowsFromRoute",
      recordAfterLast: "property/list/recordAfterLast",
      recordBeforeFirst: "property/list/recordBeforeFirst",
      count: "property/list/count",
      totalRecords: "property/list/totalRecords",
      pagesNumber: "property/list/pagesNumber",
      pageCount: "property/list/pageCount",
      // pagination: 'offer/list/pagination',
      // paginationLayout: 'layout/paginationLayout',
      loading: "property/list/loading",
      saveLoading: "property/form/saveLoading",
      backgroundLoading: "property/list/backgroundLoading",
      isMobile: "layout/isMobile",
      currentUser: "auth/currentUser",
      destroyLoading: "property/form/saveLoading",
      is_screen_xs: "layout/is_screen_xs",
    }),
    loadingAndUpdate() {
      if (this.loading || this.saveLoading) {
        return true;
      }
      return false;
    },
    hasPermissionToEdit() {
      return new OfferPermissions(this.currentUser).edit;
    },
    hasPermissionToDestroy() {
      return new OfferPermissions(this.currentUser).destroy;
    },
    fields() {
      return fields;
    },
    // pagesNumber () {
    //   return Math.ceil(this.rows.length / this.pagination.rowsPerPage)
    // },
    rowsToBeDisplayed() {
      if (this.rows.length > 0) return this.rows;
      return this.rowsOld;
    },
    disablePrevious() {
      return this.pageCount == 1;
    },
    disableNext() {
      return this.pageCount == this.pagesNumber;
    },
  },
  watch: {
    rowsFromRoute(change) {
      const page = this.$route.query.page;
      const limit = this.limit;
      const rowsFromRouteToBeDisplayed = change.slice(
        page * limit - limit,
        page * limit + 1
      );
      this.setRows(rowsFromRouteToBeDisplayed);
    },
    rows(newval) {
      if (newval.length > 0) {
        this.setLoadFirstRecord();
        this.setLoadMoreLast();
        // this.setDisablePrevious({searchBy: this.searchBy,filter: this.filter})
        // this.setDisableNext({searchBy: this.searchBy,filter: this.filter})
        this.setDisablePrevious({
          searchBy: this.searchBy,
          filter: this.filter,
          postStatus: this.postStatus,
          openedStatus: this.openedStatus,
        });
        this.setDisableNext({
          searchBy: this.searchBy,
          filter: this.filter,
          postStatus: this.postStatus,
          openedStatus: this.openedStatus,
        });
      }
    },
    //#region
    // async pendingToggle(newval){
    //   console.log('pendingToggle',newval);
    //   if(newval){
    //     this.openedToggle = false
    //     this.resetCursor()
    //     this.searchBy = 'postStatus'
    //      // in case of clicking pending toggle before reloading
    //     // because when reloading we don't need to push the router again it is already pushed with same query and params
    //     if(this.$route.params.searchBy != 'postStatus') {
    //       this.page = 1
    //       if(this.filter != ''){
    //         this.$router.push({
    //         params:{
    //           searchBy: 'postStatus'
    //         },
    //         query:{
    //           page: this.page,
    //           filter: this.filter
    //         }
    //       })
    //       }
    //       else{
    //         this.$router.push({
    //         params:{
    //           searchBy: 'postStatus'
    //         },
    //         query:{
    //           page: this.page,
    //         }
    //       })
    //       }
    //     }
    //     await this.doFetch({limit: 10,searchBy: this.searchBy,filter: this.filter})
    //     this.rowsOld = this.rows
    //   }
    //   else{
    //     if(!this.openedToggle && this.removeTogglePointer){
    //       this.searchBy = 'name'
    //       this.filter = ''
    //       this.resetCursor()
    //       if(this.filter != ''){
    //         this.$router.push({
    //           params:{
    //             searchBy: this.searchBy
    //           },
    //           query:{
    //             page: this.page,
    //             filter: this.filter
    //           }
    //         })
    //       }
    //       else{
    //         this.$router.push({
    //           params:{
    //             searchBy: this.searchBy
    //           },
    //           query:{
    //             page: this.page,
    //           }
    //         })
    //       }
    //       await this.doFetch({limit: 10,searchBy: this.searchBy, filter: this.filter})
    //       this.rowsOld = this.rows
    //       this.removeTogglePointer = true
    //     }
    //     if(!this.openedToggle && !this.removeTogglePointer){
    //       this.filter = ''
    //       this.resetCursor()
    //       this.removeTogglePointer = true
    //     }

    //   }
    // },
    // async openedToggle(newval){
    //   console.log('openedToggle',newval);
    //   if(newval){
    //     this.pendingToggle = false
    //     this.searchBy = 'isClosed'
    //     // in case of clicking pending toggle before reloading
    //     // because when reloading we don't need to push the router again it is already pushed with same query and params
    //     if(this.$route.params.searchBy != 'isClosed') {
    //       this.page = 1
    //       if(this.filter != ''){
    //       this.$router.push({
    //         params:{
    //           searchBy: 'isClosed'
    //         },
    //         query:{
    //           page: this.page,
    //           filter: this.filter
    //         }
    //       })
    //       }
    //       else{
    //         this.$router.push({
    //         params:{
    //           searchBy: 'isClosed'
    //         },
    //         query:{
    //           page: this.page,
    //         }
    //       })
    //       }
    //     }
    //     this.resetCursor()
    //     await this.doFetch({limit: 10,searchBy: this.searchBy,filter: this.filter})
    //     this.rowsOld = this.rows

    //   }
    //   else{
    //     if(!this.pendingToggle && this.removeTogglePointer){
    //       this.searchBy = 'name'
    //       // this.filter = ''
    //       this.page = 1
    //       this.resetCursor()
    //       if(this.filter != ''){
    //       this.$router.push({
    //         params:{
    //           searchBy: this.searchBy
    //         },
    //         query:{
    //           page: this.page,
    //           filter: this.filter
    //         }
    //       })
    //       }
    //       else{
    //         this.$router.push({
    //         params:{
    //           searchBy: this.searchBy
    //         },
    //         query:{
    //           page: this.page,
    //         }
    //       })
    //       }
    //       await this.doFetch({limit: 10,searchBy: this.searchBy, filter: this.filter})
    //       this.rowsOld = this.rows
    //       this.removeTogglePointer = true
    //     }
    //     if(!this.pendingToggle && !this.removeTogglePointer){
    //       this.resetCursor()

    //       // await this.doFetch({limit: 10,searchBy: this.searchBy, filter: this.filter})
    //       this.removeTogglePointer = true
    //     }

    //   }
    // },
    //#endregion
  },
  mounted() {
    var that = this;
    window.onpopstate = async function () {
      that.navigateBackwardFroward();
    };
  },
  async created() {
    //#region [ ======== to set the searchBy, filter, page variables ======== ]
    // this.page = this.$route.query.page && this.isNumeric(this.$route.query.page) ?  parseInt(this.$route.query.page) : 1
    // if(this.$route.params.searchBy && this.searchByOptions.includes(this.$route.params.searchBy)){
    //   this.searchBy = this.$route.params.searchBy
    //   if(this.searchBy == 'postStatus'){
    //     this.pendingToggle = true
    //     this.removeTogglePointer = !this.removeTogglePointer
    //   }
    //   if(this.searchBy == 'isClosed'){
    //     this.openedToggle = true
    //     this.removeTogglePointer = !this.removeTogglePointer
    //   }
    // }
    // this.filter = this.$route.query.filter ? this.$route.query.filter : ''
    //#endregion
    //#region [ ======== to set the searchBy, filter, page variables ======== ]
    this.page =
      this.$route.query.page && this.isNumeric(this.$route.query.page)
        ? parseInt(this.$route.query.page)
        : 1;
    if (this.$route.query.postStatus == "pending") {
      this.postStatus = "pending";
      this.pendingToggle = true;
    }
    if (this.$route.query.openedStatus == "opened") {
      this.openedStatus = "opened";
      this.openedToggle = true;
    }
    // this.postStatus = this.$route.query.postStatus == 'pending' ? 'pending' : ''
    // this.status = this.$route.query.status == 'hiring' ? 'hiring' : ''
    this.searchBy =
      this.$route.params.searchBy &&
      this.searchByOptions.includes(this.$route.params.searchBy)
        ? this.$route.params.searchBy
        : "name";
    this.filter = this.$route.query.filter ? this.$route.query.filter : "";
    //#endregion

    this.doFetchFacilityTypes();
    if (
      this.$route.params.searchBy &&
      this.searchByOptions.includes(this.$route.params.searchBy)
    ) {
      if (!this.$route.query.page || !this.isNumeric(this.$route.query.page)) {
        if (this.$route.query.filter) {
          this.$router.push({
            query: {
              page: 1,
              filter: this.$route.query.filter,
            },
          });
        } else {
          this.$router.push({
            query: {
              page: 1,
            },
          });
        }
      }

      await this.doFetchFromRoute({
        page: this.page,
        limit: 10,
        searchBy: "",
        filter: this.filter,
        postStatus: this.postStatus,
        openedStatus: this.openedStatus,
      });
    } else {
      this.$router.push({
        params: {
          searchBy: "name",
        },
        query: {
          page: 1,
        },
      });
      await this.doFetch({
        limit: 10,
        searchBy: "name",
        filter: "",
        postStatus: this.postStatus,
        openedStatus: this.openedStatus,
      });
    }
    this.rowsOld = this.rows;
  },
  methods: {
    ...mapActions({
      doChangeSort: "offer/list/doChangeSort",
      doChangePaginationCurrentPage: "offer/list/doChangePaginationCurrentPage",
      doChangePaginationPageSize: "offer/list/doChangePaginationPageSize",
      doMountTable: "offer/list/doMountTable",
      doUpdate: "property/form/doUpdate",
      // doDestroy: 'offer/destroy/doDestroy',
      // doFetchInBackground: 'property/list/doFetchInBackground',
      doFetchFromRoute: "property/list/doFetchFromRoute",
      doFetchFacilityTypes: "property/view/doFetchFacilityTypes",
      doFetch: "property/list/doFetch",
      resetCursor: "property/list/resetCursor",
      getPreviousRecordsBatch: "property/list/getPreviousRecordsBatch",
      doGetRecordsCount: "property/list/doGetRecordsCount",
      setLoadFirstRecord: "property/list/setLoadFirstRecord",
      setLoadMoreLast: "property/list/setLoadMoreLast",
      setDisablePrevious: "property/list/setDisablePrevious",
      setDisableNext: "property/list/setDisableNext",
      setRows: "property/list/setRows",
    }),
    // i18n(code, args) {
    //   return i18n(code, args)
    // },
    i18n(key, args) {
      return this.$t(key, args);
    },
    presenter(row, fieldName) {
      const val = OfferModel.presenter(row, fieldName);
      return val ? val : "ـــــــ";
    },
    presenterEnum(row, fieldName) {
      const options = this.fields[fieldName].options;
      const val = options.filter((el) => el.value == row[fieldName]);
      return val.length ? val[0].label : "ـــــــ";
    },
    presenterMap(row, fieldName) {
      const val = OfferModel.presenter(row, fieldName);
      return val[this.language] ? val[this.language] : null;
    },
    presenterDate(row, fieldName) {
      const val = OfferModel.presenter(row, fieldName);
      return val
        ? moment(val).locale(this.language).format("D MMM, YYYY")
        : "ـــــــ";
    },
    presenterDay(row, fieldName) {
      return moment(OfferModel.presenter(row, fieldName))
        .locale(this.language)
        .format("dddd");
    },
    presenterTime(row, fieldName) {
      let now = moment().format("YYYY/MM/DD");
      let time = OfferModel.presenter(row, fieldName);
      let date = `${now} ${time}`;
      return moment(date).locale(this.language).format("hh:mm a");
    },

    onModalOpen(row, operation) {
      this.selectedRow = row;
      this.selectedId = row.id;
      switch (operation) {
        case "delete":
          this.dialogType = "delete";
          break;
        case "edit":
          this.dialogType = "edit";
          break;
        case "view":
          this.dialogType = "view";
          break;
        default:
          this.dialogType = undefined;
          break;
      }
      this.modalVisible = true;
      this.dialogVisible = true;
    },
    onModalClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.modalVisible = false;
        this.dialogType = undefined;
      }, 500);
    },
    onModalSuccess() {
      this.onModalClose();
    },
    closeDialog() {
      this.dialogVisible = false;
      this.dialogType = "";
    },
    openDialog(row, operation) {
      this.selectedRow = row;
      this.selectedId = row.id;
      switch (operation) {
        case "delete":
          this.dialogType = "delete";
          break;
        case "edit":
          this.dialogVisible = true;
          this.modalVisible = true;
          break;
        default:
          break;
      }
      this.dialogVisible = true;
    },
    async doAcceptProperty(id, row) {
      try {
        // let updatedProperty = row
        let updatedProperty = Object.assign({}, row);
        updatedProperty.createdAt = new Date(
          updatedProperty.createdAt.seconds * 1000
        );
        updatedProperty.updatedAt = new Date(
          updatedProperty.updatedAt.seconds * 1000
        );
        const updatedTime = firebase.firestore.Timestamp.fromDate(
          new Date()
        ).toDate();
        updatedProperty.publishedAt = updatedTime;
        updatedProperty.postStatus = "accepted";
        updatedProperty.rejectionReason = null;
        delete updatedProperty.id;
        delete updatedProperty.facilityType;
        delete updatedProperty.city;
        delete updatedProperty.region;
        delete updatedProperty.facilityListedFor;
        await this.doUpdate({ id, values: updatedProperty });
        // this.onModalClose();
      } catch (error) {
        // no
      }
    },
    async doDestroyWithConfirm({ id, rejectionReason }) {
      try {
        let updatedProperty = Object.assign({}, this.selectedRow);
        updatedProperty.createdAt = new Date(
          updatedProperty.createdAt.seconds * 1000
        );
        updatedProperty.updatedAt = new Date(
          updatedProperty.updatedAt.seconds * 1000
        );

        updatedProperty.publishedAt = new Date(
          updatedProperty.publishedAt.seconds * 1000
        );

        updatedProperty.postStatus = "destroyed";
        updatedProperty.rejectionReason = rejectionReason;
        delete updatedProperty.id;
        delete updatedProperty.facilityType;
        delete updatedProperty.city;
        delete updatedProperty.region;
        delete updatedProperty.facilityListedFor;
        await this.doUpdate({ id, values: updatedProperty });
        this.onModalClose();
      } catch (error) {
        // no
      }
    },
    isNumeric(value) {
      return /^\d+$/.test(value);
    },
    async loadMore() {
      // this.rowsOld = this.rows
      // await this.doFetch({limit: 10, searchBy: this.searchBy, filter: this.filter})
      // this.page = this.page + 1
      // if(this.filter != ''){
      //   this.$router.push({
      //     query: {
      //       page: this.page,
      //       filter: this.filter
      //     },
      //     params:{
      //       searchBy: this.searchBy
      //     }
      //   })
      // }
      // else{
      //   this.$router.push({
      //     query: {
      //       page: this.page,
      //     },
      //     params:{
      //       searchBy: this.searchBy
      //     }
      //   })
      // }
      await this.doFetch({
        limit: 10,
        searchBy: this.searchBy,
        filter: this.filter,
        postStatus: this.postStatus,
        openedStatus: this.openedStatus,
      });
      this.page = this.page + 1;

      let query = {};
      query["page"] = this.page;
      if (this.filter != "") {
        query["filter"] = this.filter;
      }
      if (this.postStatus == "pending") {
        query["postStatus"] = "pending";
      }
      if (this.openedStatus == "opened") {
        query["openedStatus"] = "opened";
      }
      let params = {};
      params["searchBy"] = this.searchBy;

      this.$router.push({
        params,
        query,
      });
      this.rowsOld = this.rows;
    },
    async goToPreviousPage() {
      await this.getPreviousRecordsBatch({
        limit: 10,
        searchBy: this.searchBy,
        filter: this.filter,
        postStatus: this.postStatus,
        openedStatus: this.openedStatus,
      });

      this.page = this.page - 1;

      let query = {};
      query["page"] = this.page;
      if (this.filter != "") {
        query["filter"] = this.filter;
      }
      if (this.postStatus == "pending") {
        query["postStatus"] = "pending";
      }
      if (this.openedStatus == "opened") {
        query["openedStatus"] = "opened";
      }
      let params = {};
      params["searchBy"] = this.searchBy;

      this.$router.push({
        params,
        query,
      });
      this.rowsOld = this.rows;
    },

    async pendingToggleFetch() {
      this.resetCursor();

      this.page = 1;
      let query = {};
      query["page"] = this.page;
      if (this.filter != "") {
        query["filter"] = this.filter;
      }
      if (this.openedStatus == "opened") {
        query["openedStatus"] = this.openedStatus;
      }
      let params = {};
      params["searchBy"] = this.searchBy;

      if (this.pendingToggle) {
        this.postStatus = "pending";
        query["postStatus"] = this.postStatus;
      } else {
        this.postStatus = "";
      }
      this.$router.push({
        params,
        query,
      });

      await this.doFetch({
        limit: 10,
        searchBy: this.searchBy,
        filter: this.filter,
        postStatus: this.postStatus,
        openedStatus: this.openedStatus,
      });
      // must add
      this.rowsOld = this.rows;
    },
    async openedToggleFetch() {
      this.resetCursor();

      this.page = 1;
      let query = {};
      query["page"] = this.page;
      if (this.filter != "") {
        query["filter"] = this.filter;
      }
      let params = {};
      params["searchBy"] = this.searchBy;
      if (this.postStatus == "pending") {
        query["postStatus"] = this.postStatus;
      }

      if (this.openedToggle) {
        // must push in route
        this.openedStatus = "opened";
        query["openedStatus"] = this.openedStatus;
      } else {
        // must remove from route
        this.openedStatus = "";
      }

      this.$router.push({
        params,
        query,
      });
      await this.doFetch({
        limit: 10,
        searchBy: this.searchBy,
        filter: this.filter,
        postStatus: this.postStatus,
        openedStatus: this.openedStatus,
      });
      this.rowsOld = this.rows;
      // this.removeTogglePointer = !this.removeTogglePointer
    },

    async searchByFilter() {
      this.resetCursor();
      this.page = 1;

      let query = {};
      query["page"] = this.page;
      if (this.filter != "") {
        query["filter"] = this.filter;
      }
      if (this.postStatus == "pending") {
        query["postStatus"] = "pending";
      }
      if (this.openedStatus == "opened") {
        query["openedStatus"] = "opened";
      }
      let params = {};
      params["searchBy"] = this.searchBy;

      this.$router.push({
        params,
        query,
      });

      await this.doFetch({
        limit: 10,
        searchBy: this.searchBy,
        filter: this.filter,
        postStatus: this.postStatus,
        openedStatus: this.openedStatus,
      });

      this.rowsOld = this.rows;
    },
    async removeSearch() {
      this.resetCursor();
      this.filter = "";
      this.page = 1;

      let query = {};
      query["page"] = this.page;
      // query['filter'] = this.filter

      if (this.postStatus == "pending") {
        query["postStatus"] = "pending";
      }
      if (this.openedStatus == "opened") {
        query["openedStatus"] = "opened";
      }
      let params = {};
      params["searchBy"] = this.searchBy;

      this.$router.push({
        params,
        query,
      });
      await this.doFetch({
        limit: 10,
        searchBy: this.searchBy,
        filter: this.filter,
        postStatus: this.postStatus,
        openedStatus: this.openedStatus,
      });
    },
    // removeToggle(){
    //   this.searchByFilter()
    //   // this.openedToggle = false
    //   // this.pendingToggle = false
    // },
    // removePendingOpenedToggle(){
    //   this.rowsOld = []
    //   // this condition is because when i choose pending [postStatus] then opened [isClosed]
    //   // then i click any of the radio button the radio clicked goes back to the name
    //   //  so I needed this condition to handle if pending and closed are clicked successively
    //   //  then address or space area are chosen
    //   // if(!this.pendingOpenedSuccessively){
    //     this.removeTogglePointer = !this.removeTogglePointer
    //   // }
    //   // else{
    //   //   this.pendingOpenedSuccessively = false
    //   // }
    //   // console.log(this.searchBy);

    // },

    async navigateBackwardFroward() {
      if (this.$route.name == "property") {
        this.page =
          this.$route.query.page && this.isNumeric(this.$route.query.page)
            ? parseInt(this.$route.query.page)
            : 1;
        if (this.$route.query.postStatus == "pending") {
          this.postStatus = "pending";
          this.pendingToggle = true;
        } else {
          this.postStatus = "";
          this.pendingToggle = false;
        }
        if (this.$route.query.openedStatus == "opened") {
          this.openedStatus = "opened";
          this.openedToggle = true;
        } else {
          this.openedStatus = "";
          this.openedToggle = false;
        }
        this.searchBy =
          this.$route.params.searchBy &&
          this.searchByOptions.includes(this.$route.params.searchBy)
            ? this.$route.params.searchBy
            : "name";
        this.filter = this.$route.query.filter ? this.$route.query.filter : "";

        // this.page = this.$route.query.page && this.isNumeric(this.$route.query.page) ? parseInt(this.$route.query.page) : 1
        // if(this.$route.params.searchBy && this.searchByOptions.includes(this.$route.params.searchBy)){
        //   this.searchBy = this.$route.params.searchBy
        // }
        // else{
        //   this.searchBy = 'name'
        // }
        // this.filter = this.$route.query.filter ? this.$route.query.filter : ''

        // this.setRows([]);
        this.resetCursor();
        if (
          this.$route.params.searchBy &&
          this.searchByOptions.includes(this.$route.params.searchBy)
        ) {
          if (
            !this.$route.query.page ||
            !this.isNumeric(this.$route.query.page)
          ) {
            if (this.$route.query.filter) {
              this.$router.push({
                query: {
                  page: 1,
                  filter: this.$route.query.filter,
                },
              });
            } else {
              this.$router.push({
                query: {
                  page: 1,
                },
              });
            }
          }
          await this.doFetchFromRoute({
            page: this.page,
            limit: 10,
            searchBy: this.searchBy,
            filter: this.filter,
            postStatus: this.postStatus,
            openedStatus: this.openedStatus,
          });
        } else {
          this.$router.push({
            params: {
              searchBy: "name",
            },
            query: {
              page: 1,
            },
          });
          this.page = 1;
          await this.doFetch({
            limit: 10,
            searchBy: "name",
            filter: "",
            postStatus: this.postStatus,
            openedStatus: this.openedStatus,
          });
        }
        this.rowsOld = this.rows;
      }
    },
  },
};
